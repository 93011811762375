<ng-container
  *ngIf="{collections: collections$ | async, projectPermissions: projectPermissions$ | async} as data">
  <button type="button"
          *ngIf="data.projectPermissions?.roles?.LinkContribute && canLinkCollection && data.collections.length"
          aria-haspopup="true"
          class="btn btn-sm btn-primary my-2"
          [matMenuTriggerFor]="contextMenu">
    <i class="fa fa-plus-circle me-1" aria-hidden="true"></i>
    <span class="fw-bold" i18n="@@perspective.detail.linkType.add">Create New Link</span>
  </button>

  <mat-menu #contextMenu="matMenu">

    <button *ngFor="let collection of data.collections"
            mat-menu-item
            (click)="onUseCollection(collection)">
      <icons-presenter [colors]="[collection.color]"
                       [icons]="[collection.icon]"
                       class="me-2">
      </icons-presenter>
      <span [title]="collection.name"
            data-test="table-header-add-collection-option">
      {{collection.name | truncate:20}}
      </span>
    </button>

  </mat-menu>

</ng-container>
