<div class="row justify-content-center">
  <div class="col-lg-6 col-sm-8 my-4">
    <payments-state [organization]="organization"
                    [serviceLimits]="serviceLimits">
    </payments-state>
  </div>
  <div id="orderService" class="col-lg-6 col-sm-10 mt-4">
    <payments-order [serviceLimits]="serviceLimits"
                    (pay)="createPayment($event)">
    </payments-order>
  </div>
</div>
<div class="row justify-content-center mt-4 pb-5">
  <div class="col-xl-10 col-lg-11 col-md-12 col-sm-12">
    <payments-list [organization]="organization"
                   (repay)="callGoPay($event)">
    </payments-list>
  </div>
</div>
