<ng-container *ngIf="{files: fileAttachments$ | async} as data">
  <file-attachment-button *ngFor="let file of data.files"
                          [fileAttachment]="file">
  </file-attachment-button>

  <file-button *ngFor="let file of value?.filesInMemory"
               [file]="file">
  </file-button>

  <files-dropdown *ngIf="!readonly || (uploadProgress$ | async)"
                  [filesAttachments]="data.files"
                  [files]="value?.filesInMemory"
                  [origin]="element"
                  [uploadProgress]="uploadProgress$ | async"
                  (add)="onAdd($event)"
                  (removeFileAttachment)="onRemoveFileAttachment($event, data.files)"
                  (removeFile)="onRemoveFile($event)"
                  (cancel)="onCancel()">
  </files-dropdown>
  <input #hiddenInput
         class="hidden-input position-absolute"
         tabindex="-1"
         [style.z-index]="-1"
         type="text"/>
</ng-container>
