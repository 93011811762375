<hidden-input (newInput)="onNewHiddenInput($event)"
              class="position-fixed">
</hidden-input>

<ng-container *ngIf="{rows: [rows, (newRows$ | async)] | concat} as data">
  <tr *ngIf="data.rows.length === 0">
    <td [attr.colspan]="columns.length" class="text-center">
      <span i18n="@@shared.preview.results.noResults">No records found.</span>
    </td>
  </tr>

  <tr *ngFor="let row of data.rows; trackBy: trackByRow; let index = index"
      links-list-table-row #tableRow
      [columns]="columns"
      [constraintData]="constraintData"
      [row]="row"
      [linkPermissions]="row.linkInstance | dataResourcePermissions:linkType:linkTypePermissions:currentUser:constraintData"
      [documentPermissions]="row.document | dataResourcePermissions:collection:collectionPermissions:currentUser:constraintData"
      [linkType]="linkType"
      [collection]="collection"
      [documentId]="document?.id"
      [allowSelect]="allowSelect"
      [allowUnlink]="allowUnlink"
      [workspace]="workspace"
      [preventEventBubble]="preventEventBubble"
      [attributeEditing]="attributeEditing$ | async"
      (onEdit)="onEdit(index,$event)"
      (onFocus)="onFocus(index,$event)"
      (resetFocusAndEdit)="onResetFocusAndEdit(index,$event)"
      (newValue)="onNewValue(index,$event)"
      (columnFocus)="columnFocus.emit($event)"
      (columnEdit)="onColumnEdit(row, $event)"
      (detail)="detail.emit(row)"
      (unLink)="unlink.emit(row)"
      (newLink)="newLink.emit($event)"
      (createLink)="createLink.emit($event)"
      (updateLink)="updateLink.emit($event)">
  </tr>
</ng-container>
