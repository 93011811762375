<div [formGroup]="form">
  <p class="text-muted"
     i18n="@@constraint.coordinates.description">
    Defines the exact position on the surface of the planet Earth.
  </p>

  <div class=" mt-3">
    <label class="form-label" i18n="@@constraint.coordinates.format.label">
      Format
    </label>
    <select-item class="w-100 d-block"
                 [selectedId]="formatControl.value"
                 [removable]="false"
                 [items]="formatItems"
                 (selected)="onFormatSelect($event)"
                 data-test="coordinates-constraint-format-select"
                 buttonClasses="flex-grow-1 text-truncate">
    </select-item>
  </div>

  <div class="mt-3">
    <label class="form-label" i18n="@@constraint.coordinates.precision.label">
      Precision
    </label>
    <select-item class="w-100 d-block"
                 [items]="formatControl.value === coordinatesFormat.DecimalDegrees ? ddPrecisionItems : dmsPrecisionItems"
                 [removable]="false"
                 [selectedId]="precisionControl.value"
                 (selected)="onPrecisionSelect($event)"
                 buttonClasses="flex-grow-1 text-truncate"
                 data-test="coordinates-constraint-precision-select">
    </select-item>
  </div>

  <div class="text-muted mt-3">
    <span i18n="@@constraint.example">Example:</span> {{ exampleValue$ | async | formatDataValue }}
  </div>
</div>
