<div [formGroup]="formGroup">

  <div [formArrayName]="controlName" class="d-flex flex-wrap pb-1 pe-1">

    <div class="d-inline-flex tag py-1 px-2 ms-1 mt-1 align-items-baseline"
         *ngFor="let tag of tags$ | async; let index = index">
      <span class="me-2">{{tag}}</span>
      <i (click)="removeTag(index)"
         class="fas fa-times remove cursor-pointer"
         [title]="removeTagTitle || removeTagTitleDefault">
      </i>
    </div>

    <input class="flex-grow-1 ms-1 mt-1 px-2 form-control h-auto py-0" type="text"
           #input
           [placeholder]="placeholderTitle || placeholderTitleDefault"
           [value]="text"
           (input)="onInput($event)"
           (keydown)="onKeyDown($event)"
           (focus)="onFocus()"
           (blur)="onBlur()"
           autoSizeInput/>
  </div>

</div>

<options-dropdown *ngIf="suggestionOptions?.length"
                  [options]="suggestionOptions | filterUsedSuggestions:(tags$ | async) | filterDropdownOptions:text"
                  [showBackdrop]="false"
                  [closeOnClickOutside]="false"
                  [origin]="input"
                  [highlightedText]="text"
                  [firstItemActive]="false"
                  (selectOption)="onSelectOption($event)">
</options-dropdown>
