<div [formGroup]="form">
  <cron-configuration-form class="d-block pb-4"
                           [configForm]="form"
                           [collection]="collection"
                           [linkType]="linkType"
                           [rule]="rule">
  </cron-configuration-form>
  <div class="d-flex flex-wrap align-items-center justify-content-start my-3">
    <div class="text-nowrap fw-bold" i18n="@@collection.config.tab.rules.blockly.editAutomation.message">Edit Automation Code:</div>
    <div class="flex-grow-1 text-end">
      <div class="form-check me-3 d-inline-block">
        <input class="form-check-input" type="checkbox" formControlName="blocklyDryRun" id="blocklyDryRun">
        <label class="form-check-label" for="blocklyDryRun" i18n="@@collection.config.tab.rules.blockly.dryRun">
          Dry Run
        </label>
      </div>
      <blockly-debugger-remote
        (displayEvent)="display($event)"
        [displayButtons]="debugButtons">
      </blockly-debugger-remote>
    </div>
  </div>
  <blockly-debugger
    (displayEvent)="display($event)"
    [displayDebug]="displayDebug"
    [blocklyJs]="blocklyJs"
    [blocklyError]="blocklyError"
    [blocklyDryRunResult]="blocklyDryRunResult"
    [blocklyResultTimestamp]="blocklyResultTimestamp">
  </blockly-debugger>

  <blockly-editor
    (onJsUpdate)="onJsUpdate($event)"
    (onXmlUpdate)="onXmlUpdate($event)"
    [serviceLimits]="serviceLimits$ | async"
    [toolbox]="functionToolbox"
    [collections]="collections$ | async"
    [views]="views$ | async"
    [linkTypes]="linkTypes$ | async"
    [variables]="variables"
    [variableNames]="variableNames$ | async"
    [selectionLists]="selectionLists$ | async"
    [xml]="blocklyXml">
  </blockly-editor>

</div>
