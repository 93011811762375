<tr>
  <th *ngFor="let column of columns; trackBy:trackByColumn; let index = index"
      class="sticky-header table-border-left table-border-top table-border-right border-bottom-0 position-relative"
      [style.background-color]="column.color && (column.color | lightenColor:0.5) || 'white'"
      [style.font-weight]="column.bold ? 'bold' : 'normal'"
      [style.width.px]="column.width"
      [title]="column.attribute | attributeTitle:'':true"
      scope="col" #tableHeader
      (contextmenu)="onContextMenu(index, $event)">
    <div class="d-flex flex-nowrap flex-row align-items-center">
      <div class="flex-grow-1 overflow-hidden">{{column.attribute.name}}</div>

      <div *ngIf="column?.attribute?.description" class="ms-1"
           [tooltip]="descriptionTooltip" [adaptivePosition]="false" placement="bottom" containerClass="bigger-tooltip">
        <i class="far fa-question-circle ms-1"></i>
      </div>
      <ng-template #descriptionTooltip>
        <div [innerHTML]="column?.attribute | attributeTitle | safeHtml"></div>
      </ng-template>
      <ng-container *ngIf="{iconName: (column.attribute.constraint | constraintTypeIcon)} as typeData">
        <div class="d-flex flex-column align-items-center justify-content-center ms-1 icons"
             (click)="onContextMenu(index, $event)">
          <i *ngIf="(column.attribute | attributeFunctionDefined)"
             [class.mb-1]="typeData.iconName"
             class="fas fa-function fa-fw icon-small">
          </i>
          <i *ngIf="typeData.iconName" class="fa-fw icon-small"
             [ngClass]="typeData.iconName"
             [title]="column.attribute.constraint | constraintTypeIconTitle"></i>
          <i *ngIf="!(column.attribute | attributeFunctionDefined) && !(typeData.iconName)"
             title="Column settings..."
             i18n-title="@@table.header.column.settings"
             class="fas fa-caret-down">
          </i>
        </div>
      </ng-container>
    </div>
    <links-list-header-menu [permissions]="column.collectionId ? collectionPermissions : linkTypePermissions"
                            [attribute]="column?.attribute"
                            (attributeFunction)="onAttributeFunction(index)"
                            (attributeType)="onAttributeType(index)"
                            (attributeDescription)="onAttributeDescription(index)">
    </links-list-header-menu>
  </th>
  <th class="border-0 bg-white fixed-header">&nbsp;</th>
</tr>
<div *ngFor="let column of columns; trackBy:trackByColumn; let index = index"
     [style.left]="'calc(' + (columns | columnHandleLeft:index) + 'px - 0.5rem)'" cdkDragLockAxis="x" cdkDrag
     (cdkDragMoved)="onDragMoved($event, index)"
     (cdkDragEnded)="onDragEnd($event, index)"
     #resizeHandle class="resize-handle">
</div>
