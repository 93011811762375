<div class="d-flex pb-2">
  <div class="flex-grow-1 text-end">
    <div class="form-check me-3 d-inline-block" *ngIf="!isFunction">
      <input class="form-check-input" type="checkbox" [checked]="recursiveControl.value"
             (change)="recursiveControl.patchValue($event.target.checked)" id="recursive">
      <label class="form-check-label" for="recursive">
        <span i18n="@@collection.config.tab.rules.blockly.recursive">Recursive</span>
        <i class="far fa-question-circle ms-1" tooltip="Trigger also on changes made by other automations" i18n-tooltip="@@collection.config.tab.rules.blockly.recursive.tooltip"></i>
      </label>
    </div>

    <div class="form-check me-3 d-inline-block" *ngIf="!isFunction">
      <input class="form-check-input" type="checkbox" [checked]="dryRunControl.value"
             (change)="dryRunControl.patchValue($event.target.checked)" id="dryRun">
      <label class="form-check-label" for="dryRun" i18n="@@collection.config.tab.rules.blockly.dryRun">
        Dry Run
      </label>
    </div>
    <blockly-debugger-remote
      (displayEvent)="onDisplay($event)"
      [displayButtons]="debugButtons">
    </blockly-debugger-remote>
  </div>
</div>
<blockly-debugger class="d-block"
                  (displayEvent)="onDisplay($event)"
                  [blocklyJs]="jsControl.value"
                  [blocklyResultTimestamp]="attributeFunction?.timestamp"
                  [blocklyError]="attributeFunction?.errorReport"
                  [blocklyDryRunResult]="attributeFunction?.dryRunResult"
                  [displayDebug]="displayControl.value">
</blockly-debugger>

<blockly-editor
  (onJsUpdate)="onJsUpdate($event)"
  (onXmlUpdate)="onXmlUpdate($event)"
  [serviceLimits]="serviceLimits"
  [toolbox]="toolbox"
  [masterType]="masterType"
  [collections]="collections"
  [views]="views"
  [linkTypes]="linkTypes"
  [variables]="variables"
  [variableNames]="variableNames"
  [selectionLists]="selectionLists"
  [attribute]="attribute"
  [thisCollectionId]="collection?.id"
  [thisLinkTypeId]="linkType?.id"
  [xml]="attributeFunction?.xml">
</blockly-editor>
