<div [formGroup]="form">
  <p class="text-muted"
     i18n="@@constraint.select.options.description">
    Keeps a predefined set of valid values from which one can be selected. At least one value must be configured.
  </p>

  <div class="mt-3 mb-0">

    <div class="d-flex flex-row flex-nowrap pt-1 align-items-center">
      <label class="switch" [for]="formControlName.Multi">
        <input type="checkbox" [formControlName]="formControlName.Multi" [id]="formControlName.Multi" #multi>
        <span class="slider round" (click)="$event.stopPropagation()"></span>
      </label>
      <span class="ps-2 cursor-pointer user-select-none" i18n="@@constraint.select.multi.label" (click)="multi.click()">Allow select multiple values</span>
    </div>

  </div>

  <div class="mt-3 mb-0">
    <label class="form-label" i18n="@@constraint.select.lists.label">
      Selection list
    </label>
    <select-item class="w-100 d-block"
                 [selectedId]="selectionListControl.value"
                 [removable]="false"
                 [items]="selectionListsItems$ | async"
                 (selected)="onSelectionListSelected($event)"
                 buttonClasses="flex-grow-1 text-truncate">
    </select-item>
  </div>

  <div *ngIf="canCreateSelectionLists$ | async" i18n="@@constraint.select.lists.create" class="mt-1 text-muted">
    Create or edit custom lists in <a [routerLink]="selectionListsLink$ | async" target="_blank">Project settings</a>
  </div>

  <select-constraint-options-form class="mt-3 d-block"
                                  [form]="form"
                                  [dataValues]="dataValues$ | async"
                                  [options]="(overrideOptions$ | async) || config?.options">
  </select-constraint-options-form>
</div>
