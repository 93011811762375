<ng-container *ngIf="variables$ | async as variables">
  <resource-variable-header
    [currentKeys]="variables | resourceVariablesKeys"
    [resourceType]="resourceType"
    [resourceId]="resource.id"
    (addVariable)="onAddVariable($event)">
  </resource-variable-header>
  <h4 class="mt-4" i18n="@@projects.tab.variables.existing.title">Existing variables</h4>
  <resource-variables-table class="d-block mt-2"
                            [variables]="variables"
                            [resource]="resource"
                            (changeVariable)="onChange($event)"
                            (deleteVariable)="onDelete($event)">
  </resource-variables-table>
</ng-container>
