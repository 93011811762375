<div class="list-group list-group-horizontal py-3">
  <a *ngFor="let item of importItems" class="list-group-item list-group-item-action"
     [class.active]="typeControl.value === item.id"
     (click)="setType(item.id)">
    <div class="text-center py-2"><i class="{{item.icons[0]}}"></i></div>
    <div class="text-center py-2">{{item.value}}</div>
  </a>
</div>

<div *ngIf="typeControl.value === importType.Append" i18n="@@collection.import.type.append.description">
  New imported rows will be added to the end of the table.
</div>

<div *ngIf="typeControl.value === importType.Update" i18n="@@collection.import.type.update.description">
  Rows with the same value of the selected key attribute will be updated (overwritten). Remaining rows will be added to the end of the table.
</div>

<div *ngIf="typeControl.value === importType.Replace" i18n="@@collection.import.type.replace.description">
  The content of the table will be completely replaced by new data. All existing rows will be deleted!
</div>

<select-item *ngIf="typeControl.value === importType.Update"
             class="d-block mt-2"
             [items]="collectionSelectItems"
             emptyValue="Select the key attribute"
             i18n-emptyValue="@@collection.import.type.update.select"
             [selectedId]="mergeAttributeIdControl.value"
             (selected)="onSelectAttribute($event)">
</select-item>
