<dropdown [origin]="origin"
          [showBackdrop]="false"
          [closeOnClickOutside]="true"
          [positions]="dropdownPositions"
          [minWidth]="320"
          [minHeight]="434"
          (onCloseByClickOutside)="onCloseByClickOutside()">

  <div *ngIf="showPicker$ | async"
       [colorPicker]="color"
       [cpToggle]="true"
       (colorPickerSelect)="onSelect($event)"
       (colorPickerCancel)="onCancel()"
       (colorPickerChange)="onChange($event)"
       [cpSaveClickOutside]="false"
       [cpCloseClickOutside]="false"
       [cpPresetColors]="localPalette"
       cpWidth="320"
       cpDialogDisplay="inline"
       cpPresetLabel=""
       cpAlphaChannel="disabled"
       cpDisableInput="true"
       cpCancelButton="true"
       cpCancelButtonText="Cancel"
       i18n-cpCancelButtonText="@@button.cancel"
       cpCancelButtonClass="btn btn-sm btn-outline-secondary me-2"
       cpOKButton="true"
       cpOKButtonText="Save"
       i18n-cpOKButtonText="@@button.save"
       cpOKButtonClass="btn btn-sm btn-primary">
  </div>

</dropdown>
