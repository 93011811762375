<dropdown [origin]="origin"
          [positions]="dropdownPositions"
          [closeOnClickOutside]="true"
          [closeOnClickOrigin]="true"
          [pushOnScreen]="true"
          [showBackdrop]="false"
          (close)="onClose()"
          (onCloseByClickOutside)="onClose()">

  <div class="card p-0 overflow-auto" [style.min-width.px]="320">

    <div class="fw-bold h5 mb-0 px-2 py-2">{resourceType, select, organization {Organization Rights} project {Project Rights} collection {Table Rights} view_collection {Table Rights} view {View Rights} link_type {Link Type Rights} view_link_type {Link Type Rights} }</div>

    <role *ngFor="let role of groups | flattenUngroupedRoles; trackBy:trackByRole"
          class="px-2 py-1"
          [role]="role"
          [checked]="role | isRoleSelected:selectedRoles"
          (checkedChange)="onCheckedChange(role, $event)">
    </role>

    <accordion [closeOthers]="true" *ngIf="selectedRoles$ | async as selectedRoles">

      <accordion-group
        *ngFor="let group of groups | filterGroupedRolesWithHeader; trackBy:trackByGroup"
        [isOpen]="(expandedGroups$ | async) | includes:group.order"
        (isOpenChange)="onOpenChange($event, group.order)"
        panelClass="lmr-accordion">

        <role-group class="header-wrapper" accordion-heading
                    [group]="group"
                    [selectedRoles]="selectedRoles"
                    [isOpened]="(expandedGroups$ | async) | includes:group.order"
                    (checkedChange)="toggleAllInGroup(group, $event)">
        </role-group>
        <role *ngFor="let role of group.roles; trackBy:trackByRole"
              class="px-2 py-1"
              [role]="role"
              [checked]="role | isRoleSelected:selectedRoles"
              (checkedChange)="onCheckedChange(role, $event)">
        </role>

      </accordion-group>

    </accordion>
  </div>

</dropdown>
