<input #dateTimeInput
       [id]="id"
       [readonly]="readonly"
       [value]="value | formatDate : format:asUtc"
       [placeholder]="placeholder"
       (focus)="onFocus()"
       (click)="$event.stopPropagation()"
       (blur)="onBlur()"
       (input)="onInput()"
       (keydown)="onKeyDown($event)"
       (clickOutside)="onClickOutside($event)"
       [clickOutsideEnabled]="!readonly"
       autocomplete="off"
       class="form-control"
       inputmode="numeric"
       data-test="date-time-input"
       type="text">

<date-time-picker *ngIf="!readonly"
                  [min]="min"
                  [max]="max"
                  [options]="options"
                  [origin]="dateTimeInput"
                  [value]="value"
                  [asUtc]="asUtc"
                  (valueChange)="onSave($event)"
                  (save)="onSave($event)"
                  (cancel)="onCancel()">
</date-time-picker>
