<div class="d-flex flex-row justify-content-start align-items-center" *ngIf="resource" id="dropdown-header">
  <i class="fas fa-chevron-left chevron me-2 cursor-pointer clickable"
     title="Back to App" i18n-title="@@resource.settings.back"
     (click)="onBack()"></i>
  <i class="fa-fw {{ resource.icon }} cursor-pointer"
     [style.fontSize.rem]="2"
     [style.color]="resource.color" (click)="togglePicker()" #pickerOrigin></i>
  <icon-color-picker *ngIf="permissions?.roles?.Manage"
                     [origin]="pickerOrigin"
                     [color]="resource.color"
                     [icon]="resource.icon"
                     (preview)="onIconColorChange($event)"
                     (selected)="onIconColorChange($event)"
                     (cancel)="onIconColorChange($event)"
                     (save)="onIconColorSubmit($event)">
  </icon-color-picker>

  <input-box
    class="flex-grow-1 px-2"
    [style.width.px]="1"
    [initialValue]="firstLineValue"
    [color]="resource.color"
    [canStayEmpty]="false"
    [emitAllChanges]="true"
    [fontSizeRem]="2"
    [maxLines]="1"
    [editable]="permissions?.roles?.Manage"
    [minLength]="codeVisible ? 2 : 0"
    [maxLength]="codeVisible ? 5 : undefined"
    [title]="firstLineValue"
    (blur)="onFirstLineBlur()"
    (focus)="onFirstLineFocus()"
    [placeholder]="firstLinePlaceholder"
    (newValue)="onNewFirstLine($event)"
    [filter]="firstLineFilter">
  </input-box>

  <div class="d-flex flex-row flex-nowrap align-items-center ms-auto">
    <ng-content></ng-content>
    <div class="d-flex flex-row flex-nowrap align-items-center cursor-pointer color-danger">
      <i *ngIf="!resource.nonRemovable && permissions?.roles?.Manage" class="far fa-fw fa-trash-alt"
         (click)="onDelete()"
         [title]="deleteTitle"
         aria-hidden="true"></i>
    </div>
  </div>

</div>

<small class="position-relative text-danger" [style.left.px]="80" *ngIf="isDuplicate$ | async" i18n="@@resource.value.error.duplicity">
  This {resourceType, select, organization {code} project {code} collection {name}} is already taken. Try another one!
</small>

<div class="col me-auto py-2 px-0" *ngIf="codeVisible">
  <input-box
    [initialValue]="secondLineValue"
    [color]="resource.color"
    [canStayEmpty]="true"
    [fontSizeRem]="1.5"
    [editable]="permissions?.roles?.Manage"
    [maxLines]="1"
    [filter]="secondLineFilter"
    [filterAllChanges]="false"
    [title]="secondLineValue"
    [placeholder]="secondLinePlaceholder"
    (newValue)="onNewSecondLine($event)">
  </input-box>

</div>

<div class="pt-3 px-3 d-flex ms-2-5" *ngIf="descriptionVisible">
  <i class="fas fa-2x fa-quote-right pe-2 mt-2"></i>
  <input-box
    class="d-flex pb-3 pt-1 ms-1"
    [initialValue]="resource.description"
    [canStayEmpty]="true"
    [editable]="permissions?.roles?.Manage"
    [fontSizeRem]="1"
    [maxLines]="2"
    [title]="resource.description"
    placeholder="Fill in description"
    i18n-placeholder="@@resource.description"
    (newValue)="onNewDescription($event)">
  </input-box>
</div>
