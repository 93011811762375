/*
 * Lumeer: Modern Data Definition and Processing Platform
 *
 * Copyright (C) since 2017 Lumeer.io, s.r.o. and/or its affiliates.
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */

export const iconsMap: Record<string, string> = {
  'fa-0': '\u0030',
  'fa-1': '\u0031',
  'fa-2': '\u0032',
  'fa-3': '\u0033',
  'fa-4': '\u0034',
  'fa-5': '\u0035',
  'fa-6': '\u0036',
  'fa-7': '\u0037',
  'fa-8': '\u0038',
  'fa-9': '\u0039',
  'fa-00': '\ue467',
  'fa-360-degrees': '\ue2dc',
  'fa-a': '\u0041',
  'fa-abacus': '\uf640',
  'fa-accent-grave': '\u0060',
  'fa-acorn': '\uf6ae',
  'fa-address-book': '\uf2b9',
  'fa-address-card': '\uf2bb',
  'fa-air-conditioner': '\uf8f4',
  'fa-airplay': '\ue089',
  'fa-alarm-clock': '\uf34e',
  'fa-alarm-exclamation': '\uf843',
  'fa-alarm-plus': '\uf844',
  'fa-alarm-snooze': '\uf845',
  'fa-album': '\uf89f',
  'fa-album-circle-plus': '\ue48c',
  'fa-album-circle-user': '\ue48d',
  'fa-album-collection': '\uf8a0',
  'fa-album-collection-circle-plus': '\ue48e',
  'fa-album-collection-circle-user': '\ue48f',
  'fa-alicorn': '\uf6b0',
  'fa-alien': '\uf8f5',
  'fa-alien-8bit': '\uf8f6',
  'fa-align-center': '\uf037',
  'fa-align-justify': '\uf039',
  'fa-align-left': '\uf036',
  'fa-align-right': '\uf038',
  'fa-align-slash': '\uf846',
  'fa-alt': '\ue08a',
  'fa-amp-guitar': '\uf8a1',
  'fa-ampersand': '\u0026',
  'fa-anchor': '\uf13d',
  'fa-anchor-circle-check': '\ue4aa',
  'fa-anchor-circle-exclamation': '\ue4ab',
  'fa-anchor-circle-xmark': '\ue4ac',
  'fa-anchor-lock': '\ue4ad',
  'fa-angel': '\uf779',
  'fa-angle': '\ue08c',
  'fa-angle-90': '\ue08d',
  'fa-angle-down': '\uf107',
  'fa-angle-left': '\uf104',
  'fa-angle-right': '\uf105',
  'fa-angle-up': '\uf106',
  'fa-angles-down': '\uf103',
  'fa-angles-left': '\uf100',
  'fa-angles-right': '\uf101',
  'fa-angles-up': '\uf102',
  'fa-ankh': '\uf644',
  'fa-apartment': '\ue468',
  'fa-aperture': '\ue2df',
  'fa-apostrophe': '\u0027',
  'fa-apple-core': '\ue08f',
  'fa-apple-whole': '\uf5d1',
  'fa-archway': '\uf557',
  'fa-arrow-down': '\uf063',
  'fa-arrow-down-1-9': '\uf162',
  'fa-arrow-down-9-1': '\uf886',
  'fa-arrow-down-a-z': '\uf15d',
  'fa-arrow-down-arrow-up': '\uf883',
  'fa-arrow-down-big-small': '\uf88c',
  'fa-arrow-down-from-dotted-line': '\ue090',
  'fa-arrow-down-from-line': '\uf345',
  'fa-arrow-down-left': '\ue091',
  'fa-arrow-down-left-and-arrow-up-right-to-center': '\ue092',
  'fa-arrow-down-long': '\uf175',
  'fa-arrow-down-right': '\ue093',
  'fa-arrow-down-short-wide': '\uf884',
  'fa-arrow-down-small-big': '\uf88d',
  'fa-arrow-down-square-triangle': '\uf889',
  'fa-arrow-down-to-arc': '\ue4ae',
  'fa-arrow-down-to-bracket': '\ue094',
  'fa-arrow-down-to-dotted-line': '\ue095',
  'fa-arrow-down-to-line': '\uf33d',
  'fa-arrow-down-to-square': '\ue096',
  'fa-arrow-down-triangle-square': '\uf888',
  'fa-arrow-down-up-across-line': '\ue4af',
  'fa-arrow-down-up-lock': '\ue4b0',
  'fa-arrow-down-wide-short': '\uf160',
  'fa-arrow-down-z-a': '\uf881',
  'fa-arrow-left': '\uf060',
  'fa-arrow-left-from-line': '\uf344',
  'fa-arrow-left-long': '\uf177',
  'fa-arrow-left-long-to-line': '\ue3d4',
  'fa-arrow-left-to-line': '\uf33e',
  'fa-arrow-pointer': '\uf245',
  'fa-arrow-right': '\uf061',
  'fa-arrow-right-arrow-left': '\uf0ec',
  'fa-arrow-right-from-arc': '\ue4b1',
  'fa-arrow-right-from-bracket': '\uf08b',
  'fa-arrow-right-from-line': '\uf343',
  'fa-arrow-right-long': '\uf178',
  'fa-arrow-right-long-to-line': '\ue3d5',
  'fa-arrow-right-to-arc': '\ue4b2',
  'fa-arrow-right-to-bracket': '\uf090',
  'fa-arrow-right-to-city': '\ue4b3',
  'fa-arrow-right-to-line': '\uf340',
  'fa-arrow-rotate-left': '\uf0e2',
  'fa-arrow-rotate-right': '\uf01e',
  'fa-arrow-trend-down': '\ue097',
  'fa-arrow-trend-up': '\ue098',
  'fa-arrow-turn-down': '\uf149',
  'fa-arrow-turn-down-left': '\ue2e1',
  'fa-arrow-turn-down-right': '\ue3d6',
  'fa-arrow-turn-up': '\uf148',
  'fa-arrow-up': '\uf062',
  'fa-arrow-up-1-9': '\uf163',
  'fa-arrow-up-9-1': '\uf887',
  'fa-arrow-up-a-z': '\uf15e',
  'fa-arrow-up-arrow-down': '\ue099',
  'fa-arrow-up-big-small': '\uf88e',
  'fa-arrow-up-from-arc': '\ue4b4',
  'fa-arrow-up-from-bracket': '\ue09a',
  'fa-arrow-up-from-dotted-line': '\ue09b',
  'fa-arrow-up-from-ground-water': '\ue4b5',
  'fa-arrow-up-from-line': '\uf342',
  'fa-arrow-up-from-square': '\ue09c',
  'fa-arrow-up-from-water-pump': '\ue4b6',
  'fa-arrow-up-left': '\ue09d',
  'fa-arrow-up-left-from-circle': '\ue09e',
  'fa-arrow-up-long': '\uf176',
  'fa-arrow-up-right': '\ue09f',
  'fa-arrow-up-right-and-arrow-down-left-from-center': '\ue0a0',
  'fa-arrow-up-right-dots': '\ue4b7',
  'fa-arrow-up-right-from-square': '\uf08e',
  'fa-arrow-up-short-wide': '\uf885',
  'fa-arrow-up-small-big': '\uf88f',
  'fa-arrow-up-square-triangle': '\uf88b',
  'fa-arrow-up-to-dotted-line': '\ue0a1',
  'fa-arrow-up-to-line': '\uf341',
  'fa-arrow-up-triangle-square': '\uf88a',
  'fa-arrow-up-wide-short': '\uf161',
  'fa-arrow-up-z-a': '\uf882',
  'fa-arrows-cross': '\ue0a2',
  'fa-arrows-down-to-line': '\ue4b8',
  'fa-arrows-down-to-people': '\ue4b9',
  'fa-arrows-from-dotted-line': '\ue0a3',
  'fa-arrows-from-line': '\ue0a4',
  'fa-arrows-left-right': '\uf07e',
  'fa-arrows-left-right-to-line': '\ue4ba',
  'fa-arrows-maximize': '\uf31d',
  'fa-arrows-minimize': '\ue0a5',
  'fa-arrows-repeat': '\uf364',
  'fa-arrows-repeat-1': '\uf366',
  'fa-arrows-retweet': '\uf361',
  'fa-arrows-rotate': '\uf021',
  'fa-arrows-spin': '\ue4bb',
  'fa-arrows-split-up-and-left': '\ue4bc',
  'fa-arrows-to-circle': '\ue4bd',
  'fa-arrows-to-dot': '\ue4be',
  'fa-arrows-to-dotted-line': '\ue0a6',
  'fa-arrows-to-eye': '\ue4bf',
  'fa-arrows-to-line': '\ue0a7',
  'fa-arrows-turn-right': '\ue4c0',
  'fa-arrows-turn-to-dots': '\ue4c1',
  'fa-arrows-up-down': '\uf07d',
  'fa-arrows-up-down-left-right': '\uf047',
  'fa-arrows-up-to-line': '\ue4c2',
  'fa-asterisk': '\u002a',
  'fa-at': '\u0040',
  'fa-atom': '\uf5d2',
  'fa-atom-simple': '\uf5d3',
  'fa-audio-description': '\uf29e',
  'fa-audio-description-slash': '\ue0a8',
  'fa-austral-sign': '\ue0a9',
  'fa-avocado': '\ue0aa',
  'fa-award': '\uf559',
  'fa-award-simple': '\ue0ab',
  'fa-axe': '\uf6b2',
  'fa-axe-battle': '\uf6b3',
  'fa-b': '\u0042',
  'fa-baby': '\uf77c',
  'fa-baby-carriage': '\uf77d',
  'fa-backpack': '\uf5d4',
  'fa-backward': '\uf04a',
  'fa-backward-fast': '\uf049',
  'fa-backward-step': '\uf048',
  'fa-bacon': '\uf7e5',
  'fa-bacteria': '\ue059',
  'fa-bacterium': '\ue05a',
  'fa-badge': '\uf335',
  'fa-badge-check': '\uf336',
  'fa-badge-dollar': '\uf645',
  'fa-badge-percent': '\uf646',
  'fa-badge-sheriff': '\uf8a2',
  'fa-badger-honey': '\uf6b4',
  'fa-badminton': '\ue33a',
  'fa-bag-shopping': '\uf290',
  'fa-bagel': '\ue3d7',
  'fa-bags-shopping': '\uf847',
  'fa-baguette': '\ue3d8',
  'fa-bahai': '\uf666',
  'fa-baht-sign': '\ue0ac',
  'fa-ball-pile': '\uf77e',
  'fa-balloon': '\ue2e3',
  'fa-balloons': '\ue2e4',
  'fa-ballot': '\uf732',
  'fa-ballot-check': '\uf733',
  'fa-ban': '\uf05e',
  'fa-ban-bug': '\uf7f9',
  'fa-ban-parking': '\uf616',
  'fa-ban-smoking': '\uf54d',
  'fa-banana': '\ue2e5',
  'fa-bandage': '\uf462',
  'fa-bangladeshi-taka-sign': '\ue2e6',
  'fa-banjo': '\uf8a3',
  'fa-barcode': '\uf02a',
  'fa-barcode-read': '\uf464',
  'fa-barcode-scan': '\uf465',
  'fa-bars': '\uf0c9',
  'fa-bars-filter': '\ue0ad',
  'fa-bars-progress': '\uf828',
  'fa-bars-sort': '\ue0ae',
  'fa-bars-staggered': '\uf550',
  'fa-baseball': '\uf433',
  'fa-baseball-bat-ball': '\uf432',
  'fa-basket-shopping': '\uf291',
  'fa-basket-shopping-simple': '\ue0af',
  'fa-basketball': '\uf434',
  'fa-basketball-hoop': '\uf435',
  'fa-bat': '\uf6b5',
  'fa-bath': '\uf2cd',
  'fa-battery-bolt': '\uf376',
  'fa-battery-empty': '\uf244',
  'fa-battery-exclamation': '\ue0b0',
  'fa-battery-full': '\uf240',
  'fa-battery-half': '\uf242',
  'fa-battery-low': '\ue0b1',
  'fa-battery-quarter': '\uf243',
  'fa-battery-slash': '\uf377',
  'fa-battery-three-quarters': '\uf241',
  'fa-bed': '\uf236',
  'fa-bed-bunk': '\uf8f8',
  'fa-bed-empty': '\uf8f9',
  'fa-bed-front': '\uf8f7',
  'fa-bed-pulse': '\uf487',
  'fa-bee': '\ue0b2',
  'fa-beer-mug': '\ue0b3',
  'fa-beer-mug-empty': '\uf0fc',
  'fa-bell': '\uf0f3',
  'fa-bell-concierge': '\uf562',
  'fa-bell-exclamation': '\uf848',
  'fa-bell-on': '\uf8fa',
  'fa-bell-plus': '\uf849',
  'fa-bell-school': '\uf5d5',
  'fa-bell-school-slash': '\uf5d6',
  'fa-bell-slash': '\uf1f6',
  'fa-bells': '\uf77f',
  'fa-bench-tree': '\ue2e7',
  'fa-bezier-curve': '\uf55b',
  'fa-bicycle': '\uf206',
  'fa-binary': '\ue33b',
  'fa-binary-circle-check': '\ue33c',
  'fa-binary-lock': '\ue33d',
  'fa-binary-slash': '\ue33e',
  'fa-binoculars': '\uf1e5',
  'fa-biohazard': '\uf780',
  'fa-bird': '\ue469',
  'fa-bitcoin-sign': '\ue0b4',
  'fa-blanket': '\uf498',
  'fa-blanket-fire': '\ue3da',
  'fa-blender': '\uf517',
  'fa-blender-phone': '\uf6b6',
  'fa-blinds': '\uf8fb',
  'fa-blinds-open': '\uf8fc',
  'fa-blinds-raised': '\uf8fd',
  'fa-block': '\ue46a',
  'fa-block-brick': '\ue3db',
  'fa-block-brick-fire': '\ue3dc',
  'fa-block-question': '\ue3dd',
  'fa-block-quote': '\ue0b5',
  'fa-blog': '\uf781',
  'fa-blueberries': '\ue2e8',
  'fa-bold': '\uf032',
  'fa-bolt': '\uf0e7',
  'fa-bolt-auto': '\ue0b6',
  'fa-bolt-lightning': '\ue0b7',
  'fa-bolt-slash': '\ue0b8',
  'fa-bomb': '\uf1e2',
  'fa-bone': '\uf5d7',
  'fa-bone-break': '\uf5d8',
  'fa-bong': '\uf55c',
  'fa-book': '\uf02d',
  'fa-book-arrow-right': '\ue0b9',
  'fa-book-arrow-up': '\ue0ba',
  'fa-book-atlas': '\uf558',
  'fa-book-bible': '\uf647',
  'fa-book-blank': '\uf5d9',
  'fa-book-bookmark': '\ue0bb',
  'fa-book-circle-arrow-right': '\ue0bc',
  'fa-book-circle-arrow-up': '\ue0bd',
  'fa-book-copy': '\ue0be',
  'fa-book-font': '\ue0bf',
  'fa-book-heart': '\uf499',
  'fa-book-journal-whills': '\uf66a',
  'fa-book-medical': '\uf7e6',
  'fa-book-open': '\uf518',
  'fa-book-open-cover': '\ue0c0',
  'fa-book-open-reader': '\uf5da',
  'fa-book-quran': '\uf687',
  'fa-book-section': '\ue0c1',
  'fa-book-skull': '\uf6b7',
  'fa-book-sparkles': '\uf6b8',
  'fa-book-tanakh': '\uf827',
  'fa-book-user': '\uf7e7',
  'fa-bookmark': '\uf02e',
  'fa-bookmark-slash': '\ue0c2',
  'fa-books': '\uf5db',
  'fa-books-medical': '\uf7e8',
  'fa-boombox': '\uf8a5',
  'fa-boot': '\uf782',
  'fa-boot-heeled': '\ue33f',
  'fa-booth-curtain': '\uf734',
  'fa-border-all': '\uf84c',
  'fa-border-bottom': '\uf84d',
  'fa-border-bottom-right': '\uf854',
  'fa-border-center-h': '\uf89c',
  'fa-border-center-v': '\uf89d',
  'fa-border-inner': '\uf84e',
  'fa-border-left': '\uf84f',
  'fa-border-none': '\uf850',
  'fa-border-outer': '\uf851',
  'fa-border-right': '\uf852',
  'fa-border-top': '\uf855',
  'fa-border-top-left': '\uf853',
  'fa-bore-hole': '\ue4c3',
  'fa-bottle-droplet': '\ue4c4',
  'fa-bottle-water': '\ue4c5',
  'fa-bow-arrow': '\uf6b9',
  'fa-bowl-chopsticks': '\ue2e9',
  'fa-bowl-chopsticks-noodles': '\ue2ea',
  'fa-bowl-food': '\ue4c6',
  'fa-bowl-hot': '\uf823',
  'fa-bowl-rice': '\ue2eb',
  'fa-bowl-scoop': '\ue3de',
  'fa-bowl-scoops': '\ue3df',
  'fa-bowl-soft-serve': '\ue46b',
  'fa-bowl-spoon': '\ue3e0',
  'fa-bowling-ball': '\uf436',
  'fa-bowling-ball-pin': '\ue0c3',
  'fa-bowling-pins': '\uf437',
  'fa-box': '\uf466',
  'fa-box-archive': '\uf187',
  'fa-box-ballot': '\uf735',
  'fa-box-check': '\uf467',
  'fa-box-circle-check': '\ue0c4',
  'fa-box-dollar': '\uf4a0',
  'fa-box-heart': '\uf49d',
  'fa-box-open': '\uf49e',
  'fa-box-open-full': '\uf49c',
  'fa-box-taped': '\uf49a',
  'fa-box-tissue': '\ue05b',
  'fa-boxes-packing': '\ue4c7',
  'fa-boxes-stacked': '\uf468',
  'fa-boxing-glove': '\uf438',
  'fa-bracket-curly': '\u007b',
  'fa-bracket-curly-right': '\u007d',
  'fa-bracket-round': '\u0028',
  'fa-bracket-round-right': '\u0029',
  'fa-bracket-square': '\u005b',
  'fa-bracket-square-right': '\u005d',
  'fa-brackets-curly': '\uf7ea',
  'fa-brackets-round': '\ue0c5',
  'fa-brackets-square': '\uf7e9',
  'fa-braille': '\uf2a1',
  'fa-brain': '\uf5dc',
  'fa-brain-arrow-curved-right': '\uf677',
  'fa-brain-circuit': '\ue0c6',
  'fa-brake-warning': '\ue0c7',
  'fa-brazilian-real-sign': '\ue46c',
  'fa-bread-loaf': '\uf7eb',
  'fa-bread-slice': '\uf7ec',
  'fa-bread-slice-butter': '\ue3e1',
  'fa-bridge': '\ue4c8',
  'fa-bridge-circle-check': '\ue4c9',
  'fa-bridge-circle-exclamation': '\ue4ca',
  'fa-bridge-circle-xmark': '\ue4cb',
  'fa-bridge-lock': '\ue4cc',
  'fa-bridge-suspension': '\ue4cd',
  'fa-bridge-water': '\ue4ce',
  'fa-briefcase': '\uf0b1',
  'fa-briefcase-arrow-right': '\ue2f2',
  'fa-briefcase-blank': '\ue0c8',
  'fa-briefcase-medical': '\uf469',
  'fa-brightness': '\ue0c9',
  'fa-brightness-low': '\ue0ca',
  'fa-bring-forward': '\uf856',
  'fa-bring-front': '\uf857',
  'fa-broccoli': '\ue3e2',
  'fa-broom': '\uf51a',
  'fa-broom-ball': '\uf458',
  'fa-browser': '\uf37e',
  'fa-browsers': '\ue0cb',
  'fa-brush': '\uf55d',
  'fa-bucket': '\ue4cf',
  'fa-bug': '\uf188',
  'fa-bug-slash': '\ue490',
  'fa-bugs': '\ue4d0',
  'fa-building': '\uf1ad',
  'fa-building-circle-arrow-right': '\ue4d1',
  'fa-building-circle-check': '\ue4d2',
  'fa-building-circle-exclamation': '\ue4d3',
  'fa-building-circle-xmark': '\ue4d4',
  'fa-building-columns': '\uf19c',
  'fa-building-flag': '\ue4d5',
  'fa-building-lock': '\ue4d6',
  'fa-building-ngo': '\ue4d7',
  'fa-building-shield': '\ue4d8',
  'fa-building-un': '\ue4d9',
  'fa-building-user': '\ue4da',
  'fa-building-wheat': '\ue4db',
  'fa-buildings': '\ue0cc',
  'fa-bullhorn': '\uf0a1',
  'fa-bullseye': '\uf140',
  'fa-bullseye-arrow': '\uf648',
  'fa-bullseye-pointer': '\uf649',
  'fa-burger': '\uf805',
  'fa-burger-cheese': '\uf7f1',
  'fa-burger-fries': '\ue0cd',
  'fa-burger-glass': '\ue0ce',
  'fa-burger-lettuce': '\ue3e3',
  'fa-burger-soda': '\uf858',
  'fa-burrito': '\uf7ed',
  'fa-burst': '\ue4dc',
  'fa-bus': '\uf207',
  'fa-bus-school': '\uf5dd',
  'fa-bus-simple': '\uf55e',
  'fa-business-time': '\uf64a',
  'fa-butter': '\ue3e4',
  'fa-c': '\u0043',
  'fa-cabin': '\ue46d',
  'fa-cabinet-filing': '\uf64b',
  'fa-cable-car': '\uf7da',
  'fa-cactus': '\uf8a7',
  'fa-cake-candles': '\uf1fd',
  'fa-cake-slice': '\ue3e5',
  'fa-calculator': '\uf1ec',
  'fa-calculator-simple': '\uf64c',
  'fa-calendar': '\uf133',
  'fa-calendar-arrow-down': '\ue0d0',
  'fa-calendar-arrow-up': '\ue0d1',
  'fa-calendar-check': '\uf274',
  'fa-calendar-circle-exclamation': '\ue46e',
  'fa-calendar-circle-minus': '\ue46f',
  'fa-calendar-circle-plus': '\ue470',
  'fa-calendar-circle-user': '\ue471',
  'fa-calendar-clock': '\ue0d2',
  'fa-calendar-day': '\uf783',
  'fa-calendar-days': '\uf073',
  'fa-calendar-exclamation': '\uf334',
  'fa-calendar-heart': '\ue0d3',
  'fa-calendar-image': '\ue0d4',
  'fa-calendar-lines': '\ue0d5',
  'fa-calendar-lines-pen': '\ue472',
  'fa-calendar-minus': '\uf272',
  'fa-calendar-pen': '\uf333',
  'fa-calendar-plus': '\uf271',
  'fa-calendar-range': '\ue0d6',
  'fa-calendar-star': '\uf736',
  'fa-calendar-week': '\uf784',
  'fa-calendar-xmark': '\uf273',
  'fa-calendars': '\ue0d7',
  'fa-camcorder': '\uf8a8',
  'fa-camera': '\uf030',
  'fa-camera-cctv': '\uf8ac',
  'fa-camera-movie': '\uf8a9',
  'fa-camera-polaroid': '\uf8aa',
  'fa-camera-retro': '\uf083',
  'fa-camera-rotate': '\ue0d8',
  'fa-camera-security': '\uf8fe',
  'fa-camera-slash': '\ue0d9',
  'fa-camera-viewfinder': '\ue0da',
  'fa-camera-web': '\uf832',
  'fa-camera-web-slash': '\uf833',
  'fa-campfire': '\uf6ba',
  'fa-campground': '\uf6bb',
  'fa-can-food': '\ue3e6',
  'fa-candle-holder': '\uf6bc',
  'fa-candy': '\ue3e7',
  'fa-candy-bar': '\ue3e8',
  'fa-candy-cane': '\uf786',
  'fa-candy-corn': '\uf6bd',
  'fa-cannabis': '\uf55f',
  'fa-capsules': '\uf46b',
  'fa-car': '\uf1b9',
  'fa-car-battery': '\uf5df',
  'fa-car-bolt': '\ue341',
  'fa-car-building': '\uf859',
  'fa-car-bump': '\uf5e0',
  'fa-car-burst': '\uf5e1',
  'fa-car-bus': '\uf85a',
  'fa-car-circle-bolt': '\ue342',
  'fa-car-garage': '\uf5e2',
  'fa-car-mirrors': '\ue343',
  'fa-car-on': '\ue4dd',
  'fa-car-rear': '\uf5de',
  'fa-car-side': '\uf5e4',
  'fa-car-side-bolt': '\ue344',
  'fa-car-tilt': '\uf5e5',
  'fa-car-tunnel': '\ue4de',
  'fa-car-wash': '\uf5e6',
  'fa-car-wrench': '\uf5e3',
  'fa-caravan': '\uf8ff',
  'fa-caravan-simple': '\ue000',
  'fa-card-club': '\ue3e9',
  'fa-card-diamond': '\ue3ea',
  'fa-card-heart': '\ue3eb',
  'fa-card-spade': '\ue3ec',
  'fa-cards': '\ue3ed',
  'fa-cards-blank': '\ue4df',
  'fa-caret-down': '\uf0d7',
  'fa-caret-left': '\uf0d9',
  'fa-caret-right': '\uf0da',
  'fa-caret-up': '\uf0d8',
  'fa-carrot': '\uf787',
  'fa-cars': '\uf85b',
  'fa-cart-arrow-down': '\uf218',
  'fa-cart-arrow-up': '\ue3ee',
  'fa-cart-circle-arrow-down': '\ue3ef',
  'fa-cart-circle-arrow-up': '\ue3f0',
  'fa-cart-circle-check': '\ue3f1',
  'fa-cart-circle-exclamation': '\ue3f2',
  'fa-cart-circle-plus': '\ue3f3',
  'fa-cart-circle-xmark': '\ue3f4',
  'fa-cart-flatbed': '\uf474',
  'fa-cart-flatbed-boxes': '\uf475',
  'fa-cart-flatbed-empty': '\uf476',
  'fa-cart-flatbed-suitcase': '\uf59d',
  'fa-cart-minus': '\ue0db',
  'fa-cart-plus': '\uf217',
  'fa-cart-shopping': '\uf07a',
  'fa-cart-shopping-fast': '\ue0dc',
  'fa-cart-xmark': '\ue0dd',
  'fa-cash-register': '\uf788',
  'fa-cassette-betamax': '\uf8a4',
  'fa-cassette-tape': '\uf8ab',
  'fa-cassette-vhs': '\uf8ec',
  'fa-castle': '\ue0de',
  'fa-cat': '\uf6be',
  'fa-cat-space': '\ue001',
  'fa-cauldron': '\uf6bf',
  'fa-cedi-sign': '\ue0df',
  'fa-cent-sign': '\ue3f5',
  'fa-certificate': '\uf0a3',
  'fa-chair': '\uf6c0',
  'fa-chair-office': '\uf6c1',
  'fa-chalkboard': '\uf51b',
  'fa-chalkboard-user': '\uf51c',
  'fa-champagne-glass': '\uf79e',
  'fa-champagne-glasses': '\uf79f',
  'fa-charging-station': '\uf5e7',
  'fa-chart-area': '\uf1fe',
  'fa-chart-bar': '\uf080',
  'fa-chart-bullet': '\ue0e1',
  'fa-chart-candlestick': '\ue0e2',
  'fa-chart-column': '\ue0e3',
  'fa-chart-gantt': '\ue0e4',
  'fa-chart-line': '\uf201',
  'fa-chart-line-down': '\uf64d',
  'fa-chart-line-up': '\ue0e5',
  'fa-chart-mixed': '\uf643',
  'fa-chart-network': '\uf78a',
  'fa-chart-pie': '\uf200',
  'fa-chart-pie-simple': '\uf64e',
  'fa-chart-pyramid': '\ue0e6',
  'fa-chart-radar': '\ue0e7',
  'fa-chart-scatter': '\uf7ee',
  'fa-chart-scatter-3d': '\ue0e8',
  'fa-chart-scatter-bubble': '\ue0e9',
  'fa-chart-simple': '\ue473',
  'fa-chart-simple-horizontal': '\ue474',
  'fa-chart-tree-map': '\ue0ea',
  'fa-chart-user': '\uf6a3',
  'fa-chart-waterfall': '\ue0eb',
  'fa-check': '\uf00c',
  'fa-check-double': '\uf560',
  'fa-check-to-slot': '\uf772',
  'fa-cheese': '\uf7ef',
  'fa-cheese-swiss': '\uf7f0',
  'fa-cherries': '\ue0ec',
  'fa-chess': '\uf439',
  'fa-chess-bishop': '\uf43a',
  'fa-chess-bishop-piece': '\uf43b',
  'fa-chess-board': '\uf43c',
  'fa-chess-clock': '\uf43d',
  'fa-chess-clock-flip': '\uf43e',
  'fa-chess-king': '\uf43f',
  'fa-chess-king-piece': '\uf440',
  'fa-chess-knight': '\uf441',
  'fa-chess-knight-piece': '\uf442',
  'fa-chess-pawn': '\uf443',
  'fa-chess-pawn-piece': '\uf444',
  'fa-chess-queen': '\uf445',
  'fa-chess-queen-piece': '\uf446',
  'fa-chess-rook': '\uf447',
  'fa-chess-rook-piece': '\uf448',
  'fa-chestnut': '\ue3f6',
  'fa-chevron-down': '\uf078',
  'fa-chevron-left': '\uf053',
  'fa-chevron-right': '\uf054',
  'fa-chevron-up': '\uf077',
  'fa-chevrons-down': '\uf322',
  'fa-chevrons-left': '\uf323',
  'fa-chevrons-right': '\uf324',
  'fa-chevrons-up': '\uf325',
  'fa-child': '\uf1ae',
  'fa-child-dress': '\ue59c',
  'fa-child-reaching': '\ue59d',
  'fa-child-rifle': '\ue4e0',
  'fa-children': '\ue4e1',
  'fa-chimney': '\uf78b',
  'fa-chopsticks': '\ue3f7',
  'fa-church': '\uf51d',
  'fa-circle': '\uf111',
  'fa-circle-0': '\ue0ed',
  'fa-circle-1': '\ue0ee',
  'fa-circle-2': '\ue0ef',
  'fa-circle-3': '\ue0f0',
  'fa-circle-4': '\ue0f1',
  'fa-circle-5': '\ue0f2',
  'fa-circle-6': '\ue0f3',
  'fa-circle-7': '\ue0f4',
  'fa-circle-8': '\ue0f5',
  'fa-circle-9': '\ue0f6',
  'fa-circle-a': '\ue0f7',
  'fa-circle-ampersand': '\ue0f8',
  'fa-circle-arrow-down': '\uf0ab',
  'fa-circle-arrow-down-left': '\ue0f9',
  'fa-circle-arrow-down-right': '\ue0fa',
  'fa-circle-arrow-left': '\uf0a8',
  'fa-circle-arrow-right': '\uf0a9',
  'fa-circle-arrow-up': '\uf0aa',
  'fa-circle-arrow-up-left': '\ue0fb',
  'fa-circle-arrow-up-right': '\ue0fc',
  'fa-circle-b': '\ue0fd',
  'fa-circle-bolt': '\ue0fe',
  'fa-circle-book-open': '\ue0ff',
  'fa-circle-bookmark': '\ue100',
  'fa-circle-c': '\ue101',
  'fa-circle-calendar': '\ue102',
  'fa-circle-camera': '\ue103',
  'fa-circle-caret-down': '\uf32d',
  'fa-circle-caret-left': '\uf32e',
  'fa-circle-caret-right': '\uf330',
  'fa-circle-caret-up': '\uf331',
  'fa-circle-check': '\uf058',
  'fa-circle-chevron-down': '\uf13a',
  'fa-circle-chevron-left': '\uf137',
  'fa-circle-chevron-right': '\uf138',
  'fa-circle-chevron-up': '\uf139',
  'fa-circle-d': '\ue104',
  'fa-circle-dashed': '\ue105',
  'fa-circle-divide': '\ue106',
  'fa-circle-dollar': '\uf2e8',
  'fa-circle-dollar-to-slot': '\uf4b9',
  'fa-circle-dot': '\uf192',
  'fa-circle-down': '\uf358',
  'fa-circle-down-left': '\ue107',
  'fa-circle-down-right': '\ue108',
  'fa-circle-e': '\ue109',
  'fa-circle-ellipsis': '\ue10a',
  'fa-circle-ellipsis-vertical': '\ue10b',
  'fa-circle-envelope': '\ue10c',
  'fa-circle-exclamation': '\uf06a',
  'fa-circle-exclamation-check': '\ue10d',
  'fa-circle-f': '\ue10e',
  'fa-circle-g': '\ue10f',
  'fa-circle-h': '\uf47e',
  'fa-circle-half': '\ue110',
  'fa-circle-half-stroke': '\uf042',
  'fa-circle-heart': '\uf4c7',
  'fa-circle-i': '\ue111',
  'fa-circle-info': '\uf05a',
  'fa-circle-j': '\ue112',
  'fa-circle-k': '\ue113',
  'fa-circle-l': '\ue114',
  'fa-circle-left': '\uf359',
  'fa-circle-location-arrow': '\uf602',
  'fa-circle-m': '\ue115',
  'fa-circle-microphone': '\ue116',
  'fa-circle-microphone-lines': '\ue117',
  'fa-circle-minus': '\uf056',
  'fa-circle-n': '\ue118',
  'fa-circle-nodes': '\ue4e2',
  'fa-circle-notch': '\uf1ce',
  'fa-circle-o': '\ue119',
  'fa-circle-p': '\ue11a',
  'fa-circle-parking': '\uf615',
  'fa-circle-pause': '\uf28b',
  'fa-circle-phone': '\ue11b',
  'fa-circle-phone-flip': '\ue11c',
  'fa-circle-phone-hangup': '\ue11d',
  'fa-circle-play': '\uf144',
  'fa-circle-plus': '\uf055',
  'fa-circle-q': '\ue11e',
  'fa-circle-quarter': '\ue11f',
  'fa-circle-quarters': '\ue3f8',
  'fa-circle-question': '\uf059',
  'fa-circle-r': '\ue120',
  'fa-circle-radiation': '\uf7ba',
  'fa-circle-right': '\uf35a',
  'fa-circle-s': '\ue121',
  'fa-circle-small': '\ue122',
  'fa-circle-sort': '\ue030',
  'fa-circle-sort-down': '\ue031',
  'fa-circle-sort-up': '\ue032',
  'fa-circle-star': '\ue123',
  'fa-circle-stop': '\uf28d',
  'fa-circle-t': '\ue124',
  'fa-circle-three-quarters': '\ue125',
  'fa-circle-trash': '\ue126',
  'fa-circle-u': '\ue127',
  'fa-circle-up': '\uf35b',
  'fa-circle-up-left': '\ue128',
  'fa-circle-up-right': '\ue129',
  'fa-circle-user': '\uf2bd',
  'fa-circle-v': '\ue12a',
  'fa-circle-video': '\ue12b',
  'fa-circle-w': '\ue12c',
  'fa-circle-waveform-lines': '\ue12d',
  'fa-circle-x': '\ue12e',
  'fa-circle-xmark': '\uf057',
  'fa-circle-y': '\ue12f',
  'fa-circle-z': '\ue130',
  'fa-citrus': '\ue2f4',
  'fa-citrus-slice': '\ue2f5',
  'fa-city': '\uf64f',
  'fa-clapperboard': '\ue131',
  'fa-clapperboard-play': '\ue132',
  'fa-clarinet': '\uf8ad',
  'fa-claw-marks': '\uf6c2',
  'fa-clipboard': '\uf328',
  'fa-clipboard-check': '\uf46c',
  'fa-clipboard-list': '\uf46d',
  'fa-clipboard-list-check': '\uf737',
  'fa-clipboard-medical': '\ue133',
  'fa-clipboard-prescription': '\uf5e8',
  'fa-clipboard-question': '\ue4e3',
  'fa-clipboard-user': '\uf7f3',
  'fa-clock': '\uf017',
  'fa-clock-desk': '\ue134',
  'fa-clock-eight': '\ue345',
  'fa-clock-eight-thirty': '\ue346',
  'fa-clock-eleven': '\ue347',
  'fa-clock-eleven-thirty': '\ue348',
  'fa-clock-five': '\ue349',
  'fa-clock-five-thirty': '\ue34a',
  'fa-clock-four-thirty': '\ue34b',
  'fa-clock-nine': '\ue34c',
  'fa-clock-nine-thirty': '\ue34d',
  'fa-clock-one': '\ue34e',
  'fa-clock-one-thirty': '\ue34f',
  'fa-clock-rotate-left': '\uf1da',
  'fa-clock-seven': '\ue350',
  'fa-clock-seven-thirty': '\ue351',
  'fa-clock-six': '\ue352',
  'fa-clock-six-thirty': '\ue353',
  'fa-clock-ten': '\ue354',
  'fa-clock-ten-thirty': '\ue355',
  'fa-clock-three': '\ue356',
  'fa-clock-three-thirty': '\ue357',
  'fa-clock-twelve': '\ue358',
  'fa-clock-twelve-thirty': '\ue359',
  'fa-clock-two': '\ue35a',
  'fa-clock-two-thirty': '\ue35b',
  'fa-clone': '\uf24d',
  'fa-closed-captioning': '\uf20a',
  'fa-closed-captioning-slash': '\ue135',
  'fa-clothes-hanger': '\ue136',
  'fa-cloud': '\uf0c2',
  'fa-cloud-arrow-down': '\uf0ed',
  'fa-cloud-arrow-up': '\uf0ee',
  'fa-cloud-bolt': '\uf76c',
  'fa-cloud-bolt-moon': '\uf76d',
  'fa-cloud-bolt-sun': '\uf76e',
  'fa-cloud-check': '\ue35c',
  'fa-cloud-drizzle': '\uf738',
  'fa-cloud-exclamation': '\ue491',
  'fa-cloud-fog': '\uf74e',
  'fa-cloud-hail': '\uf739',
  'fa-cloud-hail-mixed': '\uf73a',
  'fa-cloud-meatball': '\uf73b',
  'fa-cloud-minus': '\ue35d',
  'fa-cloud-moon': '\uf6c3',
  'fa-cloud-moon-rain': '\uf73c',
  'fa-cloud-music': '\uf8ae',
  'fa-cloud-plus': '\ue35e',
  'fa-cloud-question': '\ue492',
  'fa-cloud-rain': '\uf73d',
  'fa-cloud-rainbow': '\uf73e',
  'fa-cloud-showers': '\uf73f',
  'fa-cloud-showers-heavy': '\uf740',
  'fa-cloud-showers-water': '\ue4e4',
  'fa-cloud-slash': '\ue137',
  'fa-cloud-sleet': '\uf741',
  'fa-cloud-snow': '\uf742',
  'fa-cloud-sun': '\uf6c4',
  'fa-cloud-sun-rain': '\uf743',
  'fa-cloud-word': '\ue138',
  'fa-cloud-xmark': '\ue35f',
  'fa-clouds': '\uf744',
  'fa-clouds-moon': '\uf745',
  'fa-clouds-sun': '\uf746',
  'fa-clover': '\ue139',
  'fa-club': '\uf327',
  'fa-coconut': '\ue2f6',
  'fa-code': '\uf121',
  'fa-code-branch': '\uf126',
  'fa-code-commit': '\uf386',
  'fa-code-compare': '\ue13a',
  'fa-code-fork': '\ue13b',
  'fa-code-merge': '\uf387',
  'fa-code-pull-request': '\ue13c',
  'fa-code-pull-request-closed': '\ue3f9',
  'fa-code-pull-request-draft': '\ue3fa',
  'fa-code-simple': '\ue13d',
  'fa-coffee-bean': '\ue13e',
  'fa-coffee-beans': '\ue13f',
  'fa-coffee-pot': '\ue002',
  'fa-coffin': '\uf6c6',
  'fa-coffin-cross': '\ue051',
  'fa-coin': '\uf85c',
  'fa-coin-blank': '\ue3fb',
  'fa-coin-front': '\ue3fc',
  'fa-coin-vertical': '\ue3fd',
  'fa-coins': '\uf51e',
  'fa-colon': '\u003a',
  'fa-colon-sign': '\ue140',
  'fa-columns-3': '\ue361',
  'fa-comet': '\ue003',
  'fa-comma': '\u002c',
  'fa-command': '\ue142',
  'fa-comment': '\uf075',
  'fa-comment-arrow-down': '\ue143',
  'fa-comment-arrow-up': '\ue144',
  'fa-comment-arrow-up-right': '\ue145',
  'fa-comment-captions': '\ue146',
  'fa-comment-check': '\uf4ac',
  'fa-comment-code': '\ue147',
  'fa-comment-dollar': '\uf651',
  'fa-comment-dots': '\uf4ad',
  'fa-comment-exclamation': '\uf4af',
  'fa-comment-image': '\ue148',
  'fa-comment-lines': '\uf4b0',
  'fa-comment-medical': '\uf7f5',
  'fa-comment-middle': '\ue149',
  'fa-comment-middle-top': '\ue14a',
  'fa-comment-minus': '\uf4b1',
  'fa-comment-music': '\uf8b0',
  'fa-comment-pen': '\uf4ae',
  'fa-comment-plus': '\uf4b2',
  'fa-comment-question': '\ue14b',
  'fa-comment-quote': '\ue14c',
  'fa-comment-slash': '\uf4b3',
  'fa-comment-smile': '\uf4b4',
  'fa-comment-sms': '\uf7cd',
  'fa-comment-text': '\ue14d',
  'fa-comment-xmark': '\uf4b5',
  'fa-comments': '\uf086',
  'fa-comments-dollar': '\uf653',
  'fa-comments-question': '\ue14e',
  'fa-comments-question-check': '\ue14f',
  'fa-compact-disc': '\uf51f',
  'fa-compass': '\uf14e',
  'fa-compass-drafting': '\uf568',
  'fa-compass-slash': '\uf5e9',
  'fa-compress': '\uf066',
  'fa-compress-wide': '\uf326',
  'fa-computer': '\ue4e5',
  'fa-computer-classic': '\uf8b1',
  'fa-computer-mouse': '\uf8cc',
  'fa-computer-mouse-scrollwheel': '\uf8cd',
  'fa-computer-speaker': '\uf8b2',
  'fa-container-storage': '\uf4b7',
  'fa-conveyor-belt': '\uf46e',
  'fa-conveyor-belt-boxes': '\uf46f',
  'fa-conveyor-belt-empty': '\ue150',
  'fa-cookie': '\uf563',
  'fa-cookie-bite': '\uf564',
  'fa-copy': '\uf0c5',
  'fa-copyright': '\uf1f9',
  'fa-corn': '\uf6c7',
  'fa-corner': '\ue3fe',
  'fa-couch': '\uf4b8',
  'fa-cow': '\uf6c8',
  'fa-cowbell': '\uf8b3',
  'fa-cowbell-circle-plus': '\uf8b4',
  'fa-crab': '\ue3ff',
  'fa-crate-apple': '\uf6b1',
  'fa-crate-empty': '\ue151',
  'fa-credit-card': '\uf09d',
  'fa-credit-card-blank': '\uf389',
  'fa-credit-card-front': '\uf38a',
  'fa-cricket-bat-ball': '\uf449',
  'fa-croissant': '\uf7f6',
  'fa-crop': '\uf125',
  'fa-crop-simple': '\uf565',
  'fa-cross': '\uf654',
  'fa-crosshairs': '\uf05b',
  'fa-crosshairs-simple': '\ue59f',
  'fa-crow': '\uf520',
  'fa-crown': '\uf521',
  'fa-crutch': '\uf7f7',
  'fa-crutches': '\uf7f8',
  'fa-cruzeiro-sign': '\ue152',
  'fa-crystal-ball': '\ue362',
  'fa-cube': '\uf1b2',
  'fa-cubes': '\uf1b3',
  'fa-cubes-stacked': '\ue4e6',
  'fa-cucumber': '\ue401',
  'fa-cup-straw': '\ue363',
  'fa-cup-straw-swoosh': '\ue364',
  'fa-cup-togo': '\uf6c5',
  'fa-cupcake': '\ue402',
  'fa-curling-stone': '\uf44a',
  'fa-custard': '\ue403',
  'fa-d': '\u0044',
  'fa-dagger': '\uf6cb',
  'fa-dash': '\ue404',
  'fa-database': '\uf1c0',
  'fa-deer': '\uf78e',
  'fa-deer-rudolph': '\uf78f',
  'fa-delete-left': '\uf55a',
  'fa-delete-right': '\ue154',
  'fa-democrat': '\uf747',
  'fa-desktop': '\uf390',
  'fa-desktop-arrow-down': '\ue155',
  'fa-dharmachakra': '\uf655',
  'fa-diagram-cells': '\ue475',
  'fa-diagram-lean-canvas': '\ue156',
  'fa-diagram-nested': '\ue157',
  'fa-diagram-next': '\ue476',
  'fa-diagram-predecessor': '\ue477',
  'fa-diagram-previous': '\ue478',
  'fa-diagram-project': '\uf542',
  'fa-diagram-sankey': '\ue158',
  'fa-diagram-subtask': '\ue479',
  'fa-diagram-successor': '\ue47a',
  'fa-diagram-venn': '\ue15a',
  'fa-dial': '\ue15b',
  'fa-dial-high': '\ue15c',
  'fa-dial-low': '\ue15d',
  'fa-dial-max': '\ue15e',
  'fa-dial-med': '\ue15f',
  'fa-dial-med-low': '\ue160',
  'fa-dial-min': '\ue161',
  'fa-dial-off': '\ue162',
  'fa-diamond': '\uf219',
  'fa-diamond-exclamation': '\ue405',
  'fa-diamond-turn-right': '\uf5eb',
  'fa-dice': '\uf522',
  'fa-dice-d10': '\uf6cd',
  'fa-dice-d12': '\uf6ce',
  'fa-dice-d20': '\uf6cf',
  'fa-dice-d4': '\uf6d0',
  'fa-dice-d6': '\uf6d1',
  'fa-dice-d8': '\uf6d2',
  'fa-dice-five': '\uf523',
  'fa-dice-four': '\uf524',
  'fa-dice-one': '\uf525',
  'fa-dice-six': '\uf526',
  'fa-dice-three': '\uf527',
  'fa-dice-two': '\uf528',
  'fa-diploma': '\uf5ea',
  'fa-disc-drive': '\uf8b5',
  'fa-disease': '\uf7fa',
  'fa-display': '\ue163',
  'fa-display-arrow-down': '\ue164',
  'fa-display-code': '\ue165',
  'fa-display-medical': '\ue166',
  'fa-display-slash': '\ue2fa',
  'fa-distribute-spacing-horizontal': '\ue365',
  'fa-distribute-spacing-vertical': '\ue366',
  'fa-ditto': '\u0022',
  'fa-divide': '\uf529',
  'fa-dna': '\uf471',
  'fa-do-not-enter': '\uf5ec',
  'fa-dog': '\uf6d3',
  'fa-dog-leashed': '\uf6d4',
  'fa-dollar-sign': '\u0024',
  'fa-dolly': '\uf472',
  'fa-dolly-empty': '\uf473',
  'fa-dolphin': '\ue168',
  'fa-dong-sign': '\ue169',
  'fa-donut': '\ue406',
  'fa-door-closed': '\uf52a',
  'fa-door-open': '\uf52b',
  'fa-dove': '\uf4ba',
  'fa-down': '\uf354',
  'fa-down-from-dotted-line': '\ue407',
  'fa-down-from-line': '\uf349',
  'fa-down-left': '\ue16a',
  'fa-down-left-and-up-right-to-center': '\uf422',
  'fa-down-long': '\uf309',
  'fa-down-right': '\ue16b',
  'fa-down-to-bracket': '\ue4e7',
  'fa-down-to-dotted-line': '\ue408',
  'fa-down-to-line': '\uf34a',
  'fa-download': '\uf019',
  'fa-dragon': '\uf6d5',
  'fa-draw-circle': '\uf5ed',
  'fa-draw-polygon': '\uf5ee',
  'fa-draw-square': '\uf5ef',
  'fa-dreidel': '\uf792',
  'fa-drone': '\uf85f',
  'fa-drone-front': '\uf860',
  'fa-droplet': '\uf043',
  'fa-droplet-degree': '\uf748',
  'fa-droplet-percent': '\uf750',
  'fa-droplet-slash': '\uf5c7',
  'fa-drum': '\uf569',
  'fa-drum-steelpan': '\uf56a',
  'fa-drumstick': '\uf6d6',
  'fa-drumstick-bite': '\uf6d7',
  'fa-dryer': '\uf861',
  'fa-dryer-heat': '\uf862',
  'fa-duck': '\uf6d8',
  'fa-dumbbell': '\uf44b',
  'fa-dumpster': '\uf793',
  'fa-dumpster-fire': '\uf794',
  'fa-dungeon': '\uf6d9',
  'fa-e': '\u0045',
  'fa-ear': '\uf5f0',
  'fa-ear-deaf': '\uf2a4',
  'fa-ear-listen': '\uf2a2',
  'fa-ear-muffs': '\uf795',
  'fa-earth-africa': '\uf57c',
  'fa-earth-americas': '\uf57d',
  'fa-earth-asia': '\uf57e',
  'fa-earth-europe': '\uf7a2',
  'fa-earth-oceania': '\ue47b',
  'fa-eclipse': '\uf749',
  'fa-egg': '\uf7fb',
  'fa-egg-fried': '\uf7fc',
  'fa-eggplant': '\ue16c',
  'fa-eject': '\uf052',
  'fa-elephant': '\uf6da',
  'fa-elevator': '\ue16d',
  'fa-ellipsis': '\uf141',
  'fa-ellipsis-stroke': '\uf39b',
  'fa-ellipsis-stroke-vertical': '\uf39c',
  'fa-ellipsis-vertical': '\uf142',
  'fa-empty-set': '\uf656',
  'fa-engine': '\ue16e',
  'fa-engine-warning': '\uf5f2',
  'fa-envelope': '\uf0e0',
  'fa-envelope-circle-check': '\ue4e8',
  'fa-envelope-dot': '\ue16f',
  'fa-envelope-open': '\uf2b6',
  'fa-envelope-open-dollar': '\uf657',
  'fa-envelope-open-text': '\uf658',
  'fa-envelopes': '\ue170',
  'fa-envelopes-bulk': '\uf674',
  'fa-equals': '\u003d',
  'fa-eraser': '\uf12d',
  'fa-escalator': '\ue171',
  'fa-ethernet': '\uf796',
  'fa-euro-sign': '\uf153',
  'fa-exclamation': '\u0021',
  'fa-expand': '\uf065',
  'fa-expand-wide': '\uf320',
  'fa-explosion': '\ue4e9',
  'fa-eye': '\uf06e',
  'fa-eye-dropper': '\uf1fb',
  'fa-eye-dropper-full': '\ue172',
  'fa-eye-dropper-half': '\ue173',
  'fa-eye-evil': '\uf6db',
  'fa-eye-low-vision': '\uf2a8',
  'fa-eye-slash': '\uf070',
  'fa-eyes': '\ue367',
  'fa-f': '\u0046',
  'fa-face-angry': '\uf556',
  'fa-face-angry-horns': '\ue368',
  'fa-face-anguished': '\ue369',
  'fa-face-anxious-sweat': '\ue36a',
  'fa-face-astonished': '\ue36b',
  'fa-face-awesome': '\ue409',
  'fa-face-beam-hand-over-mouth': '\ue47c',
  'fa-face-clouds': '\ue47d',
  'fa-face-confounded': '\ue36c',
  'fa-face-confused': '\ue36d',
  'fa-face-cowboy-hat': '\ue36e',
  'fa-face-diagonal-mouth': '\ue47e',
  'fa-face-disappointed': '\ue36f',
  'fa-face-disguise': '\ue370',
  'fa-face-dizzy': '\uf567',
  'fa-face-dotted': '\ue47f',
  'fa-face-downcast-sweat': '\ue371',
  'fa-face-drooling': '\ue372',
  'fa-face-exhaling': '\ue480',
  'fa-face-explode': '\ue2fe',
  'fa-face-expressionless': '\ue373',
  'fa-face-eyes-xmarks': '\ue374',
  'fa-face-fearful': '\ue375',
  'fa-face-flushed': '\uf579',
  'fa-face-frown': '\uf119',
  'fa-face-frown-open': '\uf57a',
  'fa-face-frown-slight': '\ue376',
  'fa-face-glasses': '\ue377',
  'fa-face-grimace': '\uf57f',
  'fa-face-grin': '\uf580',
  'fa-face-grin-beam': '\uf582',
  'fa-face-grin-beam-sweat': '\uf583',
  'fa-face-grin-hearts': '\uf584',
  'fa-face-grin-squint': '\uf585',
  'fa-face-grin-squint-tears': '\uf586',
  'fa-face-grin-stars': '\uf587',
  'fa-face-grin-tears': '\uf588',
  'fa-face-grin-tongue': '\uf589',
  'fa-face-grin-tongue-squint': '\uf58a',
  'fa-face-grin-tongue-wink': '\uf58b',
  'fa-face-grin-wide': '\uf581',
  'fa-face-grin-wink': '\uf58c',
  'fa-face-hand-over-mouth': '\ue378',
  'fa-face-hand-peeking': '\ue481',
  'fa-face-hand-yawn': '\ue379',
  'fa-face-head-bandage': '\ue37a',
  'fa-face-holding-back-tears': '\ue482',
  'fa-face-hushed': '\ue37b',
  'fa-face-icicles': '\ue37c',
  'fa-face-kiss': '\uf596',
  'fa-face-kiss-beam': '\uf597',
  'fa-face-kiss-closed-eyes': '\ue37d',
  'fa-face-kiss-wink-heart': '\uf598',
  'fa-face-laugh': '\uf599',
  'fa-face-laugh-beam': '\uf59a',
  'fa-face-laugh-squint': '\uf59b',
  'fa-face-laugh-wink': '\uf59c',
  'fa-face-lying': '\ue37e',
  'fa-face-mask': '\ue37f',
  'fa-face-meh': '\uf11a',
  'fa-face-meh-blank': '\uf5a4',
  'fa-face-melting': '\ue483',
  'fa-face-monocle': '\ue380',
  'fa-face-nauseated': '\ue381',
  'fa-face-nose-steam': '\ue382',
  'fa-face-party': '\ue383',
  'fa-face-pensive': '\ue384',
  'fa-face-persevering': '\ue385',
  'fa-face-pleading': '\ue386',
  'fa-face-pouting': '\ue387',
  'fa-face-raised-eyebrow': '\ue388',
  'fa-face-relieved': '\ue389',
  'fa-face-rolling-eyes': '\uf5a5',
  'fa-face-sad-cry': '\uf5b3',
  'fa-face-sad-sweat': '\ue38a',
  'fa-face-sad-tear': '\uf5b4',
  'fa-face-saluting': '\ue484',
  'fa-face-scream': '\ue38b',
  'fa-face-shush': '\ue38c',
  'fa-face-sleeping': '\ue38d',
  'fa-face-sleepy': '\ue38e',
  'fa-face-smile': '\uf118',
  'fa-face-smile-beam': '\uf5b8',
  'fa-face-smile-halo': '\ue38f',
  'fa-face-smile-hearts': '\ue390',
  'fa-face-smile-horns': '\ue391',
  'fa-face-smile-plus': '\uf5b9',
  'fa-face-smile-relaxed': '\ue392',
  'fa-face-smile-tear': '\ue393',
  'fa-face-smile-tongue': '\ue394',
  'fa-face-smile-upside-down': '\ue395',
  'fa-face-smile-wink': '\uf4da',
  'fa-face-smiling-hands': '\ue396',
  'fa-face-smirking': '\ue397',
  'fa-face-spiral-eyes': '\ue485',
  'fa-face-sunglasses': '\ue398',
  'fa-face-surprise': '\uf5c2',
  'fa-face-swear': '\ue399',
  'fa-face-thermometer': '\ue39a',
  'fa-face-thinking': '\ue39b',
  'fa-face-tired': '\uf5c8',
  'fa-face-tissue': '\ue39c',
  'fa-face-tongue-money': '\ue39d',
  'fa-face-tongue-sweat': '\ue39e',
  'fa-face-unamused': '\ue39f',
  'fa-face-viewfinder': '\ue2ff',
  'fa-face-vomit': '\ue3a0',
  'fa-face-weary': '\ue3a1',
  'fa-face-woozy': '\ue3a2',
  'fa-face-worried': '\ue3a3',
  'fa-face-zany': '\ue3a4',
  'fa-face-zipper': '\ue3a5',
  'fa-falafel': '\ue40a',
  'fa-family': '\ue300',
  'fa-family-dress': '\ue301',
  'fa-family-pants': '\ue302',
  'fa-fan': '\uf863',
  'fa-fan-table': '\ue004',
  'fa-farm': '\uf864',
  'fa-faucet': '\ue005',
  'fa-faucet-drip': '\ue006',
  'fa-fax': '\uf1ac',
  'fa-feather': '\uf52d',
  'fa-feather-pointed': '\uf56b',
  'fa-fence': '\ue303',
  'fa-ferris-wheel': '\ue174',
  'fa-ferry': '\ue4ea',
  'fa-field-hockey-stick-ball': '\uf44c',
  'fa-file': '\uf15b',
  'fa-file-arrow-down': '\uf56d',
  'fa-file-arrow-up': '\uf574',
  'fa-file-audio': '\uf1c7',
  'fa-file-binary': '\ue175',
  'fa-file-certificate': '\uf5f3',
  'fa-file-chart-column': '\uf659',
  'fa-file-chart-pie': '\uf65a',
  'fa-file-check': '\uf316',
  'fa-file-circle-check': '\ue5a0',
  'fa-file-circle-exclamation': '\ue4eb',
  'fa-file-circle-info': '\ue493',
  'fa-file-circle-minus': '\ue4ed',
  'fa-file-circle-plus': '\ue494',
  'fa-file-circle-question': '\ue4ef',
  'fa-file-circle-xmark': '\ue5a1',
  'fa-file-code': '\uf1c9',
  'fa-file-contract': '\uf56c',
  'fa-file-csv': '\uf6dd',
  'fa-file-dashed-line': '\uf877',
  'fa-file-excel': '\uf1c3',
  'fa-file-exclamation': '\uf31a',
  'fa-file-export': '\uf56e',
  'fa-file-heart': '\ue176',
  'fa-file-image': '\uf1c5',
  'fa-file-import': '\uf56f',
  'fa-file-invoice': '\uf570',
  'fa-file-invoice-dollar': '\uf571',
  'fa-file-lines': '\uf15c',
  'fa-file-lock': '\ue3a6',
  'fa-file-magnifying-glass': '\uf865',
  'fa-file-medical': '\uf477',
  'fa-file-minus': '\uf318',
  'fa-file-music': '\uf8b6',
  'fa-file-pdf': '\uf1c1',
  'fa-file-pen': '\uf31c',
  'fa-file-plus': '\uf319',
  'fa-file-plus-minus': '\ue177',
  'fa-file-powerpoint': '\uf1c4',
  'fa-file-prescription': '\uf572',
  'fa-file-shield': '\ue4f0',
  'fa-file-signature': '\uf573',
  'fa-file-slash': '\ue3a7',
  'fa-file-spreadsheet': '\uf65b',
  'fa-file-user': '\uf65c',
  'fa-file-video': '\uf1c8',
  'fa-file-waveform': '\uf478',
  'fa-file-word': '\uf1c2',
  'fa-file-xmark': '\uf317',
  'fa-file-zipper': '\uf1c6',
  'fa-files': '\ue178',
  'fa-files-medical': '\uf7fd',
  'fa-fill': '\uf575',
  'fa-fill-drip': '\uf576',
  'fa-film': '\uf008',
  'fa-film-canister': '\uf8b7',
  'fa-film-simple': '\uf3a0',
  'fa-film-slash': '\ue179',
  'fa-films': '\ue17a',
  'fa-filter': '\uf0b0',
  'fa-filter-circle-dollar': '\uf662',
  'fa-filter-circle-xmark': '\ue17b',
  'fa-filter-list': '\ue17c',
  'fa-filter-slash': '\ue17d',
  'fa-filters': '\ue17e',
  'fa-fingerprint': '\uf577',
  'fa-fire': '\uf06d',
  'fa-fire-burner': '\ue4f1',
  'fa-fire-extinguisher': '\uf134',
  'fa-fire-flame': '\uf6df',
  'fa-fire-flame-curved': '\uf7e4',
  'fa-fire-flame-simple': '\uf46a',
  'fa-fire-hydrant': '\ue17f',
  'fa-fire-smoke': '\uf74b',
  'fa-fireplace': '\uf79a',
  'fa-fish': '\uf578',
  'fa-fish-bones': '\ue304',
  'fa-fish-cooked': '\uf7fe',
  'fa-fish-fins': '\ue4f2',
  'fa-fishing-rod': '\ue3a8',
  'fa-flag': '\uf024',
  'fa-flag-checkered': '\uf11e',
  'fa-flag-pennant': '\uf456',
  'fa-flag-swallowtail': '\uf74c',
  'fa-flag-usa': '\uf74d',
  'fa-flashlight': '\uf8b8',
  'fa-flask': '\uf0c3',
  'fa-flask-round-poison': '\uf6e0',
  'fa-flask-round-potion': '\uf6e1',
  'fa-flask-vial': '\ue4f3',
  'fa-flatbread': '\ue40b',
  'fa-flatbread-stuffed': '\ue40c',
  'fa-floppy-disk': '\uf0c7',
  'fa-floppy-disk-circle-arrow-right': '\ue180',
  'fa-floppy-disk-circle-xmark': '\ue181',
  'fa-floppy-disk-pen': '\ue182',
  'fa-floppy-disks': '\ue183',
  'fa-florin-sign': '\ue184',
  'fa-flower': '\uf7ff',
  'fa-flower-daffodil': '\uf800',
  'fa-flower-tulip': '\uf801',
  'fa-flute': '\uf8b9',
  'fa-flux-capacitor': '\uf8ba',
  'fa-flying-disc': '\ue3a9',
  'fa-folder': '\uf07b',
  'fa-folder-arrow-down': '\ue053',
  'fa-folder-arrow-up': '\ue054',
  'fa-folder-bookmark': '\ue186',
  'fa-folder-closed': '\ue185',
  'fa-folder-gear': '\ue187',
  'fa-folder-grid': '\ue188',
  'fa-folder-heart': '\ue189',
  'fa-folder-image': '\ue18a',
  'fa-folder-magnifying-glass': '\ue18b',
  'fa-folder-medical': '\ue18c',
  'fa-folder-minus': '\uf65d',
  'fa-folder-music': '\ue18d',
  'fa-folder-open': '\uf07c',
  'fa-folder-plus': '\uf65e',
  'fa-folder-tree': '\uf802',
  'fa-folder-user': '\ue18e',
  'fa-folder-xmark': '\uf65f',
  'fa-folders': '\uf660',
  'fa-fondue-pot': '\ue40d',
  'fa-font': '\uf031',
  'fa-font-case': '\uf866',
  'fa-football': '\uf44e',
  'fa-football-helmet': '\uf44f',
  'fa-fork': '\uf2e3',
  'fa-fork-knife': '\uf2e6',
  'fa-forklift': '\uf47a',
  'fa-fort': '\ue486',
  'fa-forward': '\uf04e',
  'fa-forward-fast': '\uf050',
  'fa-forward-step': '\uf051',
  'fa-frame': '\ue495',
  'fa-franc-sign': '\ue18f',
  'fa-french-fries': '\uf803',
  'fa-frog': '\uf52e',
  'fa-function': '\uf661',
  'fa-futbol': '\uf1e3',
  'fa-g': '\u0047',
  'fa-galaxy': '\ue008',
  'fa-gallery-thumbnails': '\ue3aa',
  'fa-game-board': '\uf867',
  'fa-game-board-simple': '\uf868',
  'fa-game-console-handheld': '\uf8bb',
  'fa-gamepad': '\uf11b',
  'fa-gamepad-modern': '\ue5a2',
  'fa-garage': '\ue009',
  'fa-garage-car': '\ue00a',
  'fa-garage-open': '\ue00b',
  'fa-garlic': '\ue40e',
  'fa-gas-pump': '\uf52f',
  'fa-gas-pump-slash': '\uf5f4',
  'fa-gauge': '\uf624',
  'fa-gauge-circle-bolt': '\ue496',
  'fa-gauge-circle-minus': '\ue497',
  'fa-gauge-circle-plus': '\ue498',
  'fa-gauge-high': '\uf625',
  'fa-gauge-low': '\uf627',
  'fa-gauge-max': '\uf626',
  'fa-gauge-min': '\uf628',
  'fa-gauge-simple': '\uf629',
  'fa-gauge-simple-high': '\uf62a',
  'fa-gauge-simple-low': '\uf62c',
  'fa-gauge-simple-max': '\uf62b',
  'fa-gauge-simple-min': '\uf62d',
  'fa-gavel': '\uf0e3',
  'fa-gear': '\uf013',
  'fa-gears': '\uf085',
  'fa-gem': '\uf3a5',
  'fa-genderless': '\uf22d',
  'fa-ghost': '\uf6e2',
  'fa-gif': '\ue190',
  'fa-gift': '\uf06b',
  'fa-gift-card': '\uf663',
  'fa-gifts': '\uf79c',
  'fa-gingerbread-man': '\uf79d',
  'fa-glass': '\uf804',
  'fa-glass-citrus': '\uf869',
  'fa-glass-empty': '\ue191',
  'fa-glass-half': '\ue192',
  'fa-glass-water': '\ue4f4',
  'fa-glass-water-droplet': '\ue4f5',
  'fa-glasses': '\uf530',
  'fa-glasses-round': '\uf5f5',
  'fa-globe': '\uf0ac',
  'fa-globe-snow': '\uf7a3',
  'fa-globe-stand': '\uf5f6',
  'fa-goal-net': '\ue3ab',
  'fa-golf-ball-tee': '\uf450',
  'fa-golf-club': '\uf451',
  'fa-golf-flag-hole': '\ue3ac',
  'fa-gopuram': '\uf664',
  'fa-graduation-cap': '\uf19d',
  'fa-gramophone': '\uf8bd',
  'fa-grapes': '\ue306',
  'fa-grate': '\ue193',
  'fa-grate-droplet': '\ue194',
  'fa-greater-than': '\u003e',
  'fa-greater-than-equal': '\uf532',
  'fa-grid': '\ue195',
  'fa-grid-2': '\ue196',
  'fa-grid-2-plus': '\ue197',
  'fa-grid-4': '\ue198',
  'fa-grid-5': '\ue199',
  'fa-grid-dividers': '\ue3ad',
  'fa-grid-horizontal': '\ue307',
  'fa-grill': '\ue5a3',
  'fa-grill-fire': '\ue5a4',
  'fa-grill-hot': '\ue5a5',
  'fa-grip': '\uf58d',
  'fa-grip-dots': '\ue410',
  'fa-grip-dots-vertical': '\ue411',
  'fa-grip-lines': '\uf7a4',
  'fa-grip-lines-vertical': '\uf7a5',
  'fa-grip-vertical': '\uf58e',
  'fa-group-arrows-rotate': '\ue4f6',
  'fa-guarani-sign': '\ue19a',
  'fa-guitar': '\uf7a6',
  'fa-guitar-electric': '\uf8be',
  'fa-guitars': '\uf8bf',
  'fa-gun': '\ue19b',
  'fa-gun-slash': '\ue19c',
  'fa-gun-squirt': '\ue19d',
  'fa-h': '\u0048',
  'fa-h1': '\uf313',
  'fa-h2': '\uf314',
  'fa-h3': '\uf315',
  'fa-h4': '\uf86a',
  'fa-h5': '\ue412',
  'fa-h6': '\ue413',
  'fa-hammer': '\uf6e3',
  'fa-hammer-crash': '\ue414',
  'fa-hammer-war': '\uf6e4',
  'fa-hamsa': '\uf665',
  'fa-hand': '\uf256',
  'fa-hand-back-fist': '\uf255',
  'fa-hand-back-point-down': '\ue19e',
  'fa-hand-back-point-left': '\ue19f',
  'fa-hand-back-point-ribbon': '\ue1a0',
  'fa-hand-back-point-right': '\ue1a1',
  'fa-hand-back-point-up': '\ue1a2',
  'fa-hand-dots': '\uf461',
  'fa-hand-fingers-crossed': '\ue1a3',
  'fa-hand-fist': '\uf6de',
  'fa-hand-heart': '\uf4bc',
  'fa-hand-holding': '\uf4bd',
  'fa-hand-holding-box': '\uf47b',
  'fa-hand-holding-dollar': '\uf4c0',
  'fa-hand-holding-droplet': '\uf4c1',
  'fa-hand-holding-hand': '\ue4f7',
  'fa-hand-holding-heart': '\uf4be',
  'fa-hand-holding-magic': '\uf6e5',
  'fa-hand-holding-medical': '\ue05c',
  'fa-hand-holding-seedling': '\uf4bf',
  'fa-hand-holding-skull': '\ue1a4',
  'fa-hand-horns': '\ue1a9',
  'fa-hand-lizard': '\uf258',
  'fa-hand-love': '\ue1a5',
  'fa-hand-middle-finger': '\uf806',
  'fa-hand-peace': '\uf25b',
  'fa-hand-point-down': '\uf0a7',
  'fa-hand-point-left': '\uf0a5',
  'fa-hand-point-ribbon': '\ue1a6',
  'fa-hand-point-right': '\uf0a4',
  'fa-hand-point-up': '\uf0a6',
  'fa-hand-pointer': '\uf25a',
  'fa-hand-scissors': '\uf257',
  'fa-hand-sparkles': '\ue05d',
  'fa-hand-spock': '\uf259',
  'fa-hand-wave': '\ue1a7',
  'fa-handcuffs': '\ue4f8',
  'fa-hands': '\uf2a7',
  'fa-hands-asl-interpreting': '\uf2a3',
  'fa-hands-bound': '\ue4f9',
  'fa-hands-bubbles': '\ue05e',
  'fa-hands-clapping': '\ue1a8',
  'fa-hands-holding': '\uf4c2',
  'fa-hands-holding-child': '\ue4fa',
  'fa-hands-holding-circle': '\ue4fb',
  'fa-hands-holding-diamond': '\uf47c',
  'fa-hands-holding-dollar': '\uf4c5',
  'fa-hands-holding-heart': '\uf4c3',
  'fa-hands-praying': '\uf684',
  'fa-handshake': '\uf2b5',
  'fa-handshake-angle': '\uf4c4',
  'fa-handshake-simple': '\uf4c6',
  'fa-handshake-simple-slash': '\ue05f',
  'fa-handshake-slash': '\ue060',
  'fa-hanukiah': '\uf6e6',
  'fa-hard-drive': '\uf0a0',
  'fa-hashtag': '\u0023',
  'fa-hashtag-lock': '\ue415',
  'fa-hat-chef': '\uf86b',
  'fa-hat-cowboy': '\uf8c0',
  'fa-hat-cowboy-side': '\uf8c1',
  'fa-hat-santa': '\uf7a7',
  'fa-hat-winter': '\uf7a8',
  'fa-hat-witch': '\uf6e7',
  'fa-hat-wizard': '\uf6e8',
  'fa-head-side': '\uf6e9',
  'fa-head-side-brain': '\uf808',
  'fa-head-side-cough': '\ue061',
  'fa-head-side-cough-slash': '\ue062',
  'fa-head-side-goggles': '\uf6ea',
  'fa-head-side-headphones': '\uf8c2',
  'fa-head-side-heart': '\ue1aa',
  'fa-head-side-mask': '\ue063',
  'fa-head-side-medical': '\uf809',
  'fa-head-side-virus': '\ue064',
  'fa-heading': '\uf1dc',
  'fa-headphones': '\uf025',
  'fa-headphones-simple': '\uf58f',
  'fa-headset': '\uf590',
  'fa-heart': '\uf004',
  'fa-heart-circle-bolt': '\ue4fc',
  'fa-heart-circle-check': '\ue4fd',
  'fa-heart-circle-exclamation': '\ue4fe',
  'fa-heart-circle-minus': '\ue4ff',
  'fa-heart-circle-plus': '\ue500',
  'fa-heart-circle-xmark': '\ue501',
  'fa-heart-crack': '\uf7a9',
  'fa-heart-half': '\ue1ab',
  'fa-heart-half-stroke': '\ue1ac',
  'fa-heart-pulse': '\uf21e',
  'fa-heat': '\ue00c',
  'fa-helicopter': '\uf533',
  'fa-helicopter-symbol': '\ue502',
  'fa-helmet-battle': '\uf6eb',
  'fa-helmet-safety': '\uf807',
  'fa-helmet-un': '\ue503',
  'fa-hexagon': '\uf312',
  'fa-hexagon-check': '\ue416',
  'fa-hexagon-divide': '\ue1ad',
  'fa-hexagon-exclamation': '\ue417',
  'fa-hexagon-image': '\ue504',
  'fa-hexagon-minus': '\uf307',
  'fa-hexagon-plus': '\uf300',
  'fa-hexagon-vertical-nft': '\ue505',
  'fa-hexagon-vertical-nft-slanted': '\ue506',
  'fa-hexagon-xmark': '\uf2ee',
  'fa-high-definition': '\ue1ae',
  'fa-highlighter': '\uf591',
  'fa-highlighter-line': '\ue1af',
  'fa-hill-avalanche': '\ue507',
  'fa-hill-rockslide': '\ue508',
  'fa-hippo': '\uf6ed',
  'fa-hockey-mask': '\uf6ee',
  'fa-hockey-puck': '\uf453',
  'fa-hockey-stick-puck': '\ue3ae',
  'fa-hockey-sticks': '\uf454',
  'fa-holly-berry': '\uf7aa',
  'fa-honey-pot': '\ue418',
  'fa-hood-cloak': '\uf6ef',
  'fa-horizontal-rule': '\uf86c',
  'fa-horse': '\uf6f0',
  'fa-horse-head': '\uf7ab',
  'fa-horse-saddle': '\uf8c3',
  'fa-hose': '\ue419',
  'fa-hose-reel': '\ue41a',
  'fa-hospital': '\uf0f8',
  'fa-hospital-user': '\uf80d',
  'fa-hospitals': '\uf80e',
  'fa-hot-tub-person': '\uf593',
  'fa-hotdog': '\uf80f',
  'fa-hotel': '\uf594',
  'fa-hourglass': '\uf254',
  'fa-hourglass-clock': '\ue41b',
  'fa-hourglass-end': '\uf253',
  'fa-hourglass-half': '\uf252',
  'fa-hourglass-start': '\uf251',
  'fa-house': '\uf015',
  'fa-house-blank': '\ue487',
  'fa-house-building': '\ue1b1',
  'fa-house-chimney': '\ue3af',
  'fa-house-chimney-blank': '\ue3b0',
  'fa-house-chimney-crack': '\uf6f1',
  'fa-house-chimney-heart': '\ue1b2',
  'fa-house-chimney-medical': '\uf7f2',
  'fa-house-chimney-user': '\ue065',
  'fa-house-chimney-window': '\ue00d',
  'fa-house-circle-check': '\ue509',
  'fa-house-circle-exclamation': '\ue50a',
  'fa-house-circle-xmark': '\ue50b',
  'fa-house-crack': '\ue3b1',
  'fa-house-day': '\ue00e',
  'fa-house-fire': '\ue50c',
  'fa-house-flag': '\ue50d',
  'fa-house-flood-water': '\ue50e',
  'fa-house-flood-water-circle-arrow-right': '\ue50f',
  'fa-house-heart': '\uf4c9',
  'fa-house-laptop': '\ue066',
  'fa-house-lock': '\ue510',
  'fa-house-medical': '\ue3b2',
  'fa-house-medical-circle-check': '\ue511',
  'fa-house-medical-circle-exclamation': '\ue512',
  'fa-house-medical-circle-xmark': '\ue513',
  'fa-house-medical-flag': '\ue514',
  'fa-house-night': '\ue010',
  'fa-house-person-leave': '\ue00f',
  'fa-house-person-return': '\ue011',
  'fa-house-signal': '\ue012',
  'fa-house-tree': '\ue1b3',
  'fa-house-tsunami': '\ue515',
  'fa-house-turret': '\ue1b4',
  'fa-house-user': '\ue1b0',
  'fa-house-water': '\uf74f',
  'fa-house-window': '\ue3b3',
  'fa-hryvnia-sign': '\uf6f2',
  'fa-hundred-points': '\ue41c',
  'fa-hurricane': '\uf751',
  'fa-hyphen': '\u002d',
  'fa-i': '\u0049',
  'fa-i-cursor': '\uf246',
  'fa-ice-cream': '\uf810',
  'fa-ice-skate': '\uf7ac',
  'fa-icicles': '\uf7ad',
  'fa-icons': '\uf86d',
  'fa-id-badge': '\uf2c1',
  'fa-id-card': '\uf2c2',
  'fa-id-card-clip': '\uf47f',
  'fa-igloo': '\uf7ae',
  'fa-image': '\uf03e',
  'fa-image-landscape': '\ue1b5',
  'fa-image-polaroid': '\uf8c4',
  'fa-image-polaroid-user': '\ue1b6',
  'fa-image-portrait': '\uf3e0',
  'fa-image-slash': '\ue1b7',
  'fa-image-user': '\ue1b8',
  'fa-images': '\uf302',
  'fa-images-user': '\ue1b9',
  'fa-inbox': '\uf01c',
  'fa-inbox-full': '\ue1ba',
  'fa-inbox-in': '\uf310',
  'fa-inbox-out': '\uf311',
  'fa-inboxes': '\ue1bb',
  'fa-indent': '\uf03c',
  'fa-indian-rupee-sign': '\ue1bc',
  'fa-industry': '\uf275',
  'fa-industry-windows': '\uf3b3',
  'fa-infinity': '\uf534',
  'fa-info': '\uf129',
  'fa-inhaler': '\uf5f9',
  'fa-input-numeric': '\ue1bd',
  'fa-input-pipe': '\ue1be',
  'fa-input-text': '\ue1bf',
  'fa-integral': '\uf667',
  'fa-intersection': '\uf668',
  'fa-island-tropical': '\uf811',
  'fa-italic': '\uf033',
  'fa-j': '\u004a',
  'fa-jack-o-lantern': '\uf30e',
  'fa-jar': '\ue516',
  'fa-jar-wheat': '\ue517',
  'fa-jedi': '\uf669',
  'fa-jet-fighter': '\uf0fb',
  'fa-jet-fighter-up': '\ue518',
  'fa-joint': '\uf595',
  'fa-joystick': '\uf8c5',
  'fa-jug': '\uf8c6',
  'fa-jug-detergent': '\ue519',
  'fa-k': '\u004b',
  'fa-kaaba': '\uf66b',
  'fa-kazoo': '\uf8c7',
  'fa-kerning': '\uf86f',
  'fa-key': '\uf084',
  'fa-key-skeleton': '\uf6f3',
  'fa-key-skeleton-left-right': '\ue3b4',
  'fa-keyboard': '\uf11c',
  'fa-keyboard-brightness': '\ue1c0',
  'fa-keyboard-brightness-low': '\ue1c1',
  'fa-keyboard-down': '\ue1c2',
  'fa-keyboard-left': '\ue1c3',
  'fa-keynote': '\uf66c',
  'fa-khanda': '\uf66d',
  'fa-kidneys': '\uf5fb',
  'fa-kip-sign': '\ue1c4',
  'fa-kit-medical': '\uf479',
  'fa-kitchen-set': '\ue51a',
  'fa-kite': '\uf6f4',
  'fa-kiwi-bird': '\uf535',
  'fa-kiwi-fruit': '\ue30c',
  'fa-knife': '\uf2e4',
  'fa-knife-kitchen': '\uf6f5',
  'fa-l': '\u004c',
  'fa-lacrosse-stick': '\ue3b5',
  'fa-lacrosse-stick-ball': '\ue3b6',
  'fa-lambda': '\uf66e',
  'fa-lamp': '\uf4ca',
  'fa-lamp-desk': '\ue014',
  'fa-lamp-floor': '\ue015',
  'fa-lamp-street': '\ue1c5',
  'fa-land-mine-on': '\ue51b',
  'fa-landmark': '\uf66f',
  'fa-landmark-dome': '\uf752',
  'fa-landmark-flag': '\ue51c',
  'fa-language': '\uf1ab',
  'fa-laptop': '\uf109',
  'fa-laptop-arrow-down': '\ue1c6',
  'fa-laptop-code': '\uf5fc',
  'fa-laptop-file': '\ue51d',
  'fa-laptop-medical': '\uf812',
  'fa-laptop-mobile': '\uf87a',
  'fa-laptop-slash': '\ue1c7',
  'fa-lari-sign': '\ue1c8',
  'fa-lasso': '\uf8c8',
  'fa-lasso-sparkles': '\ue1c9',
  'fa-layer-group': '\uf5fd',
  'fa-layer-minus': '\uf5fe',
  'fa-layer-plus': '\uf5ff',
  'fa-leaf': '\uf06c',
  'fa-leaf-heart': '\uf4cb',
  'fa-leaf-maple': '\uf6f6',
  'fa-leaf-oak': '\uf6f7',
  'fa-leafy-green': '\ue41d',
  'fa-left': '\uf355',
  'fa-left-from-line': '\uf348',
  'fa-left-long': '\uf30a',
  'fa-left-long-to-line': '\ue41e',
  'fa-left-right': '\uf337',
  'fa-left-to-line': '\uf34b',
  'fa-lemon': '\uf094',
  'fa-less-than': '\u003c',
  'fa-less-than-equal': '\uf537',
  'fa-life-ring': '\uf1cd',
  'fa-light-ceiling': '\ue016',
  'fa-light-emergency': '\ue41f',
  'fa-light-emergency-on': '\ue420',
  'fa-light-switch': '\ue017',
  'fa-light-switch-off': '\ue018',
  'fa-light-switch-on': '\ue019',
  'fa-lightbulb': '\uf0eb',
  'fa-lightbulb-cfl': '\ue5a6',
  'fa-lightbulb-cfl-on': '\ue5a7',
  'fa-lightbulb-dollar': '\uf670',
  'fa-lightbulb-exclamation': '\uf671',
  'fa-lightbulb-exclamation-on': '\ue1ca',
  'fa-lightbulb-on': '\uf672',
  'fa-lightbulb-slash': '\uf673',
  'fa-lights-holiday': '\uf7b2',
  'fa-line-columns': '\uf870',
  'fa-line-height': '\uf871',
  'fa-lines-leaning': '\ue51e',
  'fa-link': '\uf0c1',
  'fa-link-horizontal': '\ue1cb',
  'fa-link-horizontal-slash': '\ue1cc',
  'fa-link-simple': '\ue1cd',
  'fa-link-simple-slash': '\ue1ce',
  'fa-link-slash': '\uf127',
  'fa-lips': '\uf600',
  'fa-lira-sign': '\uf195',
  'fa-list': '\uf03a',
  'fa-list-check': '\uf0ae',
  'fa-list-dropdown': '\ue1cf',
  'fa-list-music': '\uf8c9',
  'fa-list-ol': '\uf0cb',
  'fa-list-radio': '\ue1d0',
  'fa-list-timeline': '\ue1d1',
  'fa-list-tree': '\ue1d2',
  'fa-list-ul': '\uf0ca',
  'fa-litecoin-sign': '\ue1d3',
  'fa-loader': '\ue1d4',
  'fa-lobster': '\ue421',
  'fa-location-arrow': '\uf124',
  'fa-location-check': '\uf606',
  'fa-location-crosshairs': '\uf601',
  'fa-location-crosshairs-slash': '\uf603',
  'fa-location-dot': '\uf3c5',
  'fa-location-dot-slash': '\uf605',
  'fa-location-exclamation': '\uf608',
  'fa-location-minus': '\uf609',
  'fa-location-pen': '\uf607',
  'fa-location-pin': '\uf041',
  'fa-location-pin-lock': '\ue51f',
  'fa-location-pin-slash': '\uf60c',
  'fa-location-plus': '\uf60a',
  'fa-location-question': '\uf60b',
  'fa-location-smile': '\uf60d',
  'fa-location-xmark': '\uf60e',
  'fa-lock': '\uf023',
  'fa-lock-a': '\ue422',
  'fa-lock-hashtag': '\ue423',
  'fa-lock-keyhole': '\uf30d',
  'fa-lock-keyhole-open': '\uf3c2',
  'fa-lock-open': '\uf3c1',
  'fa-locust': '\ue520',
  'fa-lollipop': '\ue424',
  'fa-loveseat': '\uf4cc',
  'fa-luchador-mask': '\uf455',
  'fa-lungs': '\uf604',
  'fa-lungs-virus': '\ue067',
  'fa-m': '\u004d',
  'fa-mace': '\uf6f8',
  'fa-magnet': '\uf076',
  'fa-magnifying-glass': '\uf002',
  'fa-magnifying-glass-arrow-right': '\ue521',
  'fa-magnifying-glass-chart': '\ue522',
  'fa-magnifying-glass-dollar': '\uf688',
  'fa-magnifying-glass-location': '\uf689',
  'fa-magnifying-glass-minus': '\uf010',
  'fa-magnifying-glass-plus': '\uf00e',
  'fa-mailbox': '\uf813',
  'fa-manat-sign': '\ue1d5',
  'fa-mandolin': '\uf6f9',
  'fa-mango': '\ue30f',
  'fa-manhole': '\ue1d6',
  'fa-map': '\uf279',
  'fa-map-location': '\uf59f',
  'fa-map-location-dot': '\uf5a0',
  'fa-map-pin': '\uf276',
  'fa-marker': '\uf5a1',
  'fa-mars': '\uf222',
  'fa-mars-and-venus': '\uf224',
  'fa-mars-and-venus-burst': '\ue523',
  'fa-mars-double': '\uf227',
  'fa-mars-stroke': '\uf229',
  'fa-mars-stroke-right': '\uf22b',
  'fa-mars-stroke-up': '\uf22a',
  'fa-martini-glass': '\uf57b',
  'fa-martini-glass-citrus': '\uf561',
  'fa-martini-glass-empty': '\uf000',
  'fa-mask': '\uf6fa',
  'fa-mask-face': '\ue1d7',
  'fa-mask-snorkel': '\ue3b7',
  'fa-mask-ventilator': '\ue524',
  'fa-masks-theater': '\uf630',
  'fa-mattress-pillow': '\ue525',
  'fa-maximize': '\uf31e',
  'fa-meat': '\uf814',
  'fa-medal': '\uf5a2',
  'fa-megaphone': '\uf675',
  'fa-melon': '\ue310',
  'fa-melon-slice': '\ue311',
  'fa-memo': '\ue1d8',
  'fa-memo-circle-check': '\ue1d9',
  'fa-memo-circle-info': '\ue49a',
  'fa-memo-pad': '\ue1da',
  'fa-memory': '\uf538',
  'fa-menorah': '\uf676',
  'fa-mercury': '\uf223',
  'fa-merge': '\ue526',
  'fa-message': '\uf27a',
  'fa-message-arrow-down': '\ue1db',
  'fa-message-arrow-up': '\ue1dc',
  'fa-message-arrow-up-right': '\ue1dd',
  'fa-message-bot': '\ue3b8',
  'fa-message-captions': '\ue1de',
  'fa-message-check': '\uf4a2',
  'fa-message-code': '\ue1df',
  'fa-message-dollar': '\uf650',
  'fa-message-dots': '\uf4a3',
  'fa-message-exclamation': '\uf4a5',
  'fa-message-image': '\ue1e0',
  'fa-message-lines': '\uf4a6',
  'fa-message-medical': '\uf7f4',
  'fa-message-middle': '\ue1e1',
  'fa-message-middle-top': '\ue1e2',
  'fa-message-minus': '\uf4a7',
  'fa-message-music': '\uf8af',
  'fa-message-pen': '\uf4a4',
  'fa-message-plus': '\uf4a8',
  'fa-message-question': '\ue1e3',
  'fa-message-quote': '\ue1e4',
  'fa-message-slash': '\uf4a9',
  'fa-message-smile': '\uf4aa',
  'fa-message-sms': '\ue1e5',
  'fa-message-text': '\ue1e6',
  'fa-message-xmark': '\uf4ab',
  'fa-messages': '\uf4b6',
  'fa-messages-dollar': '\uf652',
  'fa-messages-question': '\ue1e7',
  'fa-meteor': '\uf753',
  'fa-meter': '\ue1e8',
  'fa-meter-bolt': '\ue1e9',
  'fa-meter-droplet': '\ue1ea',
  'fa-meter-fire': '\ue1eb',
  'fa-microchip': '\uf2db',
  'fa-microchip-ai': '\ue1ec',
  'fa-microphone': '\uf130',
  'fa-microphone-lines': '\uf3c9',
  'fa-microphone-lines-slash': '\uf539',
  'fa-microphone-slash': '\uf131',
  'fa-microphone-stand': '\uf8cb',
  'fa-microscope': '\uf610',
  'fa-microwave': '\ue01b',
  'fa-mill-sign': '\ue1ed',
  'fa-minimize': '\uf78c',
  'fa-minus': '\uf068',
  'fa-mistletoe': '\uf7b4',
  'fa-mitten': '\uf7b5',
  'fa-mobile': '\uf3ce',
  'fa-mobile-button': '\uf10b',
  'fa-mobile-notch': '\ue1ee',
  'fa-mobile-retro': '\ue527',
  'fa-mobile-screen': '\uf3cf',
  'fa-mobile-screen-button': '\uf3cd',
  'fa-mobile-signal': '\ue1ef',
  'fa-mobile-signal-out': '\ue1f0',
  'fa-money-bill': '\uf0d6',
  'fa-money-bill-1': '\uf3d1',
  'fa-money-bill-1-wave': '\uf53b',
  'fa-money-bill-simple': '\ue1f1',
  'fa-money-bill-simple-wave': '\ue1f2',
  'fa-money-bill-transfer': '\ue528',
  'fa-money-bill-trend-up': '\ue529',
  'fa-money-bill-wave': '\uf53a',
  'fa-money-bill-wheat': '\ue52a',
  'fa-money-bills': '\ue1f3',
  'fa-money-bills-simple': '\ue1f4',
  'fa-money-check': '\uf53c',
  'fa-money-check-dollar': '\uf53d',
  'fa-money-check-dollar-pen': '\uf873',
  'fa-money-check-pen': '\uf872',
  'fa-money-from-bracket': '\ue312',
  'fa-money-simple-from-bracket': '\ue313',
  'fa-monitor-waveform': '\uf611',
  'fa-monkey': '\uf6fb',
  'fa-monument': '\uf5a6',
  'fa-moon': '\uf186',
  'fa-moon-cloud': '\uf754',
  'fa-moon-over-sun': '\uf74a',
  'fa-moon-stars': '\uf755',
  'fa-moped': '\ue3b9',
  'fa-mortar-pestle': '\uf5a7',
  'fa-mosque': '\uf678',
  'fa-mosquito': '\ue52b',
  'fa-mosquito-net': '\ue52c',
  'fa-motorcycle': '\uf21c',
  'fa-mound': '\ue52d',
  'fa-mountain': '\uf6fc',
  'fa-mountain-city': '\ue52e',
  'fa-mountain-sun': '\ue52f',
  'fa-mountains': '\uf6fd',
  'fa-mouse-field': '\ue5a8',
  'fa-mp3-player': '\uf8ce',
  'fa-mug': '\uf874',
  'fa-mug-hot': '\uf7b6',
  'fa-mug-marshmallows': '\uf7b7',
  'fa-mug-saucer': '\uf0f4',
  'fa-mug-tea': '\uf875',
  'fa-mug-tea-saucer': '\ue1f5',
  'fa-mushroom': '\ue425',
  'fa-music': '\uf001',
  'fa-music-note': '\uf8cf',
  'fa-music-note-slash': '\uf8d0',
  'fa-music-slash': '\uf8d1',
  'fa-n': '\u004e',
  'fa-naira-sign': '\ue1f6',
  'fa-narwhal': '\uf6fe',
  'fa-nesting-dolls': '\ue3ba',
  'fa-network-wired': '\uf6ff',
  'fa-neuter': '\uf22c',
  'fa-newspaper': '\uf1ea',
  'fa-nfc': '\ue1f7',
  'fa-nfc-lock': '\ue1f8',
  'fa-nfc-magnifying-glass': '\ue1f9',
  'fa-nfc-pen': '\ue1fa',
  'fa-nfc-signal': '\ue1fb',
  'fa-nfc-slash': '\ue1fc',
  'fa-nfc-trash': '\ue1fd',
  'fa-not-equal': '\uf53e',
  'fa-notdef': '\ue1fe',
  'fa-note': '\ue1ff',
  'fa-note-medical': '\ue200',
  'fa-note-sticky': '\uf249',
  'fa-notebook': '\ue201',
  'fa-notes': '\ue202',
  'fa-notes-medical': '\uf481',
  'fa-o': '\u004f',
  'fa-object-exclude': '\ue49c',
  'fa-object-group': '\uf247',
  'fa-object-intersect': '\ue49d',
  'fa-object-subtract': '\ue49e',
  'fa-object-ungroup': '\uf248',
  'fa-object-union': '\ue49f',
  'fa-objects-align-bottom': '\ue3bb',
  'fa-objects-align-center-horizontal': '\ue3bc',
  'fa-objects-align-center-vertical': '\ue3bd',
  'fa-objects-align-left': '\ue3be',
  'fa-objects-align-right': '\ue3bf',
  'fa-objects-align-top': '\ue3c0',
  'fa-objects-column': '\ue3c1',
  'fa-octagon': '\uf306',
  'fa-octagon-check': '\ue426',
  'fa-octagon-divide': '\ue203',
  'fa-octagon-exclamation': '\ue204',
  'fa-octagon-minus': '\uf308',
  'fa-octagon-plus': '\uf301',
  'fa-octagon-xmark': '\uf2f0',
  'fa-oil-can': '\uf613',
  'fa-oil-can-drip': '\ue205',
  'fa-oil-temperature': '\uf614',
  'fa-oil-well': '\ue532',
  'fa-olive': '\ue316',
  'fa-olive-branch': '\ue317',
  'fa-om': '\uf679',
  'fa-omega': '\uf67a',
  'fa-onion': '\ue427',
  'fa-option': '\ue318',
  'fa-ornament': '\uf7b8',
  'fa-otter': '\uf700',
  'fa-outdent': '\uf03b',
  'fa-outlet': '\ue01c',
  'fa-oven': '\ue01d',
  'fa-overline': '\uf876',
  'fa-p': '\u0050',
  'fa-page': '\ue428',
  'fa-page-caret-down': '\ue429',
  'fa-page-caret-up': '\ue42a',
  'fa-pager': '\uf815',
  'fa-paint-roller': '\uf5aa',
  'fa-paintbrush': '\uf1fc',
  'fa-paintbrush-fine': '\uf5a9',
  'fa-paintbrush-pencil': '\ue206',
  'fa-palette': '\uf53f',
  'fa-pallet': '\uf482',
  'fa-pallet-box': '\ue208',
  'fa-pallet-boxes': '\uf483',
  'fa-pan-food': '\ue42b',
  'fa-pan-frying': '\ue42c',
  'fa-pancakes': '\ue42d',
  'fa-panel-ews': '\ue42e',
  'fa-panel-fire': '\ue42f',
  'fa-panorama': '\ue209',
  'fa-paper-plane': '\uf1d8',
  'fa-paper-plane-top': '\ue20a',
  'fa-paperclip': '\uf0c6',
  'fa-paperclip-vertical': '\ue3c2',
  'fa-parachute-box': '\uf4cd',
  'fa-paragraph': '\uf1dd',
  'fa-paragraph-left': '\uf878',
  'fa-party-bell': '\ue31a',
  'fa-party-horn': '\ue31b',
  'fa-passport': '\uf5ab',
  'fa-paste': '\uf0ea',
  'fa-pause': '\uf04c',
  'fa-paw': '\uf1b0',
  'fa-paw-claws': '\uf702',
  'fa-paw-simple': '\uf701',
  'fa-peace': '\uf67c',
  'fa-peach': '\ue20b',
  'fa-peanut': '\ue430',
  'fa-peanuts': '\ue431',
  'fa-peapod': '\ue31c',
  'fa-pear': '\ue20c',
  'fa-pedestal': '\ue20d',
  'fa-pegasus': '\uf703',
  'fa-pen': '\uf304',
  'fa-pen-circle': '\ue20e',
  'fa-pen-clip': '\uf305',
  'fa-pen-clip-slash': '\ue20f',
  'fa-pen-fancy': '\uf5ac',
  'fa-pen-fancy-slash': '\ue210',
  'fa-pen-field': '\ue211',
  'fa-pen-line': '\ue212',
  'fa-pen-nib': '\uf5ad',
  'fa-pen-nib-slash': '\ue4a1',
  'fa-pen-paintbrush': '\uf618',
  'fa-pen-ruler': '\uf5ae',
  'fa-pen-slash': '\ue213',
  'fa-pen-swirl': '\ue214',
  'fa-pen-to-square': '\uf044',
  'fa-pencil': '\uf303',
  'fa-pencil-slash': '\ue215',
  'fa-people': '\ue216',
  'fa-people-arrows': '\ue068',
  'fa-people-carry-box': '\uf4ce',
  'fa-people-dress': '\ue217',
  'fa-people-dress-simple': '\ue218',
  'fa-people-group': '\ue533',
  'fa-people-line': '\ue534',
  'fa-people-pants': '\ue219',
  'fa-people-pants-simple': '\ue21a',
  'fa-people-pulling': '\ue535',
  'fa-people-robbery': '\ue536',
  'fa-people-roof': '\ue537',
  'fa-people-simple': '\ue21b',
  'fa-pepper': '\ue432',
  'fa-pepper-hot': '\uf816',
  'fa-percent': '\u0025',
  'fa-period': '\u002e',
  'fa-person': '\uf183',
  'fa-person-arrow-down-to-line': '\ue538',
  'fa-person-arrow-up-from-line': '\ue539',
  'fa-person-biking': '\uf84a',
  'fa-person-biking-mountain': '\uf84b',
  'fa-person-booth': '\uf756',
  'fa-person-breastfeeding': '\ue53a',
  'fa-person-burst': '\ue53b',
  'fa-person-cane': '\ue53c',
  'fa-person-carry-box': '\uf4cf',
  'fa-person-chalkboard': '\ue53d',
  'fa-person-circle-check': '\ue53e',
  'fa-person-circle-exclamation': '\ue53f',
  'fa-person-circle-minus': '\ue540',
  'fa-person-circle-plus': '\ue541',
  'fa-person-circle-question': '\ue542',
  'fa-person-circle-xmark': '\ue543',
  'fa-person-digging': '\uf85e',
  'fa-person-dolly': '\uf4d0',
  'fa-person-dolly-empty': '\uf4d1',
  'fa-person-dots-from-line': '\uf470',
  'fa-person-dress': '\uf182',
  'fa-person-dress-burst': '\ue544',
  'fa-person-dress-simple': '\ue21c',
  'fa-person-drowning': '\ue545',
  'fa-person-falling': '\ue546',
  'fa-person-falling-burst': '\ue547',
  'fa-person-from-portal': '\ue023',
  'fa-person-half-dress': '\ue548',
  'fa-person-harassing': '\ue549',
  'fa-person-hiking': '\uf6ec',
  'fa-person-military-pointing': '\ue54a',
  'fa-person-military-rifle': '\ue54b',
  'fa-person-military-to-person': '\ue54c',
  'fa-person-pinball': '\ue21d',
  'fa-person-praying': '\uf683',
  'fa-person-pregnant': '\ue31e',
  'fa-person-rays': '\ue54d',
  'fa-person-rifle': '\ue54e',
  'fa-person-running': '\uf70c',
  'fa-person-seat': '\ue21e',
  'fa-person-seat-reclined': '\ue21f',
  'fa-person-shelter': '\ue54f',
  'fa-person-sign': '\uf757',
  'fa-person-simple': '\ue220',
  'fa-person-skating': '\uf7c5',
  'fa-person-ski-jumping': '\uf7c7',
  'fa-person-ski-lift': '\uf7c8',
  'fa-person-skiing': '\uf7c9',
  'fa-person-skiing-nordic': '\uf7ca',
  'fa-person-sledding': '\uf7cb',
  'fa-person-snowboarding': '\uf7ce',
  'fa-person-snowmobiling': '\uf7d1',
  'fa-person-swimming': '\uf5c4',
  'fa-person-through-window': '\ue5a9',
  'fa-person-to-door': '\ue433',
  'fa-person-to-portal': '\ue022',
  'fa-person-walking': '\uf554',
  'fa-person-walking-arrow-loop-left': '\ue551',
  'fa-person-walking-arrow-right': '\ue552',
  'fa-person-walking-dashed-line-arrow-right': '\ue553',
  'fa-person-walking-luggage': '\ue554',
  'fa-person-walking-with-cane': '\uf29d',
  'fa-peseta-sign': '\ue221',
  'fa-peso-sign': '\ue222',
  'fa-phone': '\uf095',
  'fa-phone-arrow-down-left': '\ue223',
  'fa-phone-arrow-up-right': '\ue224',
  'fa-phone-flip': '\uf879',
  'fa-phone-hangup': '\ue225',
  'fa-phone-intercom': '\ue434',
  'fa-phone-missed': '\ue226',
  'fa-phone-office': '\uf67d',
  'fa-phone-plus': '\uf4d2',
  'fa-phone-rotary': '\uf8d3',
  'fa-phone-slash': '\uf3dd',
  'fa-phone-volume': '\uf2a0',
  'fa-phone-xmark': '\ue227',
  'fa-photo-film': '\uf87c',
  'fa-photo-film-music': '\ue228',
  'fa-pi': '\uf67e',
  'fa-piano': '\uf8d4',
  'fa-piano-keyboard': '\uf8d5',
  'fa-pickleball': '\ue435',
  'fa-pie': '\uf705',
  'fa-pig': '\uf706',
  'fa-piggy-bank': '\uf4d3',
  'fa-pills': '\uf484',
  'fa-pinata': '\ue3c3',
  'fa-pinball': '\ue229',
  'fa-pineapple': '\ue31f',
  'fa-pipe': '\u007c',
  'fa-pipe-circle-check': '\ue436',
  'fa-pipe-collar': '\ue437',
  'fa-pipe-section': '\ue438',
  'fa-pipe-smoking': '\ue3c4',
  'fa-pipe-valve': '\ue439',
  'fa-pizza': '\uf817',
  'fa-pizza-slice': '\uf818',
  'fa-place-of-worship': '\uf67f',
  'fa-plane': '\uf072',
  'fa-plane-arrival': '\uf5af',
  'fa-plane-circle-check': '\ue555',
  'fa-plane-circle-exclamation': '\ue556',
  'fa-plane-circle-xmark': '\ue557',
  'fa-plane-departure': '\uf5b0',
  'fa-plane-engines': '\uf3de',
  'fa-plane-lock': '\ue558',
  'fa-plane-prop': '\ue22b',
  'fa-plane-slash': '\ue069',
  'fa-plane-tail': '\ue22c',
  'fa-plane-up': '\ue22d',
  'fa-plane-up-slash': '\ue22e',
  'fa-planet-moon': '\ue01f',
  'fa-planet-ringed': '\ue020',
  'fa-plant-wilt': '\ue5aa',
  'fa-plate-utensils': '\ue43b',
  'fa-plate-wheat': '\ue55a',
  'fa-play': '\uf04b',
  'fa-play-pause': '\ue22f',
  'fa-plug': '\uf1e6',
  'fa-plug-circle-bolt': '\ue55b',
  'fa-plug-circle-check': '\ue55c',
  'fa-plug-circle-exclamation': '\ue55d',
  'fa-plug-circle-minus': '\ue55e',
  'fa-plug-circle-plus': '\ue55f',
  'fa-plug-circle-xmark': '\ue560',
  'fa-plus': '\u002b',
  'fa-plus-large': '\ue59e',
  'fa-plus-minus': '\ue43c',
  'fa-podcast': '\uf2ce',
  'fa-podium': '\uf680',
  'fa-podium-star': '\uf758',
  'fa-police-box': '\ue021',
  'fa-poll-people': '\uf759',
  'fa-pompebled': '\ue43d',
  'fa-poo': '\uf2fe',
  'fa-poo-storm': '\uf75a',
  'fa-pool-8-ball': '\ue3c5',
  'fa-poop': '\uf619',
  'fa-popcorn': '\uf819',
  'fa-popsicle': '\ue43e',
  'fa-pot-food': '\ue43f',
  'fa-potato': '\ue440',
  'fa-power-off': '\uf011',
  'fa-prescription': '\uf5b1',
  'fa-prescription-bottle': '\uf485',
  'fa-prescription-bottle-medical': '\uf486',
  'fa-presentation-screen': '\uf685',
  'fa-pretzel': '\ue441',
  'fa-print': '\uf02f',
  'fa-print-magnifying-glass': '\uf81a',
  'fa-print-slash': '\uf686',
  'fa-projector': '\uf8d6',
  'fa-pump': '\ue442',
  'fa-pump-medical': '\ue06a',
  'fa-pump-soap': '\ue06b',
  'fa-pumpkin': '\uf707',
  'fa-puzzle': '\ue443',
  'fa-puzzle-piece': '\uf12e',
  'fa-puzzle-piece-simple': '\ue231',
  'fa-q': '\u0051',
  'fa-qrcode': '\uf029',
  'fa-question': '\u003f',
  'fa-quote-left': '\uf10d',
  'fa-quote-right': '\uf10e',
  'fa-quotes': '\ue234',
  'fa-r': '\u0052',
  'fa-rabbit': '\uf708',
  'fa-rabbit-running': '\uf709',
  'fa-racquet': '\uf45a',
  'fa-radar': '\ue024',
  'fa-radiation': '\uf7b9',
  'fa-radio': '\uf8d7',
  'fa-radio-tuner': '\uf8d8',
  'fa-rainbow': '\uf75b',
  'fa-raindrops': '\uf75c',
  'fa-ram': '\uf70a',
  'fa-ramp-loading': '\uf4d4',
  'fa-ranking-star': '\ue561',
  'fa-raygun': '\ue025',
  'fa-receipt': '\uf543',
  'fa-record-vinyl': '\uf8d9',
  'fa-rectangle': '\uf2fa',
  'fa-rectangle-ad': '\uf641',
  'fa-rectangle-barcode': '\uf463',
  'fa-rectangle-code': '\ue322',
  'fa-rectangle-history': '\ue4a2',
  'fa-rectangle-history-circle-plus': '\ue4a3',
  'fa-rectangle-history-circle-user': '\ue4a4',
  'fa-rectangle-list': '\uf022',
  'fa-rectangle-pro': '\ue235',
  'fa-rectangle-terminal': '\ue236',
  'fa-rectangle-vertical': '\uf2fb',
  'fa-rectangle-vertical-history': '\ue237',
  'fa-rectangle-wide': '\uf2fc',
  'fa-rectangle-xmark': '\uf410',
  'fa-rectangles-mixed': '\ue323',
  'fa-recycle': '\uf1b8',
  'fa-reel': '\ue238',
  'fa-refrigerator': '\ue026',
  'fa-registered': '\uf25d',
  'fa-repeat': '\uf363',
  'fa-repeat-1': '\uf365',
  'fa-reply': '\uf3e5',
  'fa-reply-all': '\uf122',
  'fa-reply-clock': '\ue239',
  'fa-republican': '\uf75e',
  'fa-restroom': '\uf7bd',
  'fa-restroom-simple': '\ue23a',
  'fa-retweet': '\uf079',
  'fa-rhombus': '\ue23b',
  'fa-ribbon': '\uf4d6',
  'fa-right': '\uf356',
  'fa-right-from-bracket': '\uf2f5',
  'fa-right-from-line': '\uf347',
  'fa-right-left': '\uf362',
  'fa-right-long': '\uf30b',
  'fa-right-long-to-line': '\ue444',
  'fa-right-to-bracket': '\uf2f6',
  'fa-right-to-line': '\uf34c',
  'fa-ring': '\uf70b',
  'fa-ring-diamond': '\ue5ab',
  'fa-rings-wedding': '\uf81b',
  'fa-road': '\uf018',
  'fa-road-barrier': '\ue562',
  'fa-road-bridge': '\ue563',
  'fa-road-circle-check': '\ue564',
  'fa-road-circle-exclamation': '\ue565',
  'fa-road-circle-xmark': '\ue566',
  'fa-road-lock': '\ue567',
  'fa-road-spikes': '\ue568',
  'fa-robot': '\uf544',
  'fa-robot-astromech': '\ue2d2',
  'fa-rocket': '\uf135',
  'fa-rocket-launch': '\ue027',
  'fa-roller-coaster': '\ue324',
  'fa-rotate': '\uf2f1',
  'fa-rotate-exclamation': '\ue23c',
  'fa-rotate-left': '\uf2ea',
  'fa-rotate-right': '\uf2f9',
  'fa-route': '\uf4d7',
  'fa-route-highway': '\uf61a',
  'fa-route-interstate': '\uf61b',
  'fa-router': '\uf8da',
  'fa-rss': '\uf09e',
  'fa-ruble-sign': '\uf158',
  'fa-rug': '\ue569',
  'fa-rugby-ball': '\ue3c6',
  'fa-ruler': '\uf545',
  'fa-ruler-combined': '\uf546',
  'fa-ruler-horizontal': '\uf547',
  'fa-ruler-triangle': '\uf61c',
  'fa-ruler-vertical': '\uf548',
  'fa-rupee-sign': '\uf156',
  'fa-rupiah-sign': '\ue23d',
  'fa-rv': '\uf7be',
  'fa-s': '\u0053',
  'fa-sack': '\uf81c',
  'fa-sack-dollar': '\uf81d',
  'fa-sack-xmark': '\ue56a',
  'fa-sailboat': '\ue445',
  'fa-salad': '\uf81e',
  'fa-salt-shaker': '\ue446',
  'fa-sandwich': '\uf81f',
  'fa-satellite': '\uf7bf',
  'fa-satellite-dish': '\uf7c0',
  'fa-sausage': '\uf820',
  'fa-saxophone': '\uf8dc',
  'fa-saxophone-fire': '\uf8db',
  'fa-scale-balanced': '\uf24e',
  'fa-scale-unbalanced': '\uf515',
  'fa-scale-unbalanced-flip': '\uf516',
  'fa-scalpel': '\uf61d',
  'fa-scalpel-line-dashed': '\uf61e',
  'fa-scanner-gun': '\uf488',
  'fa-scanner-image': '\uf8f3',
  'fa-scanner-keyboard': '\uf489',
  'fa-scanner-touchscreen': '\uf48a',
  'fa-scarecrow': '\uf70d',
  'fa-scarf': '\uf7c1',
  'fa-school': '\uf549',
  'fa-school-circle-check': '\ue56b',
  'fa-school-circle-exclamation': '\ue56c',
  'fa-school-circle-xmark': '\ue56d',
  'fa-school-flag': '\ue56e',
  'fa-school-lock': '\ue56f',
  'fa-scissors': '\uf0c4',
  'fa-screen-users': '\uf63d',
  'fa-screencast': '\ue23e',
  'fa-screwdriver': '\uf54a',
  'fa-screwdriver-wrench': '\uf7d9',
  'fa-scribble': '\ue23f',
  'fa-scroll': '\uf70e',
  'fa-scroll-old': '\uf70f',
  'fa-scroll-torah': '\uf6a0',
  'fa-scrubber': '\uf2f8',
  'fa-scythe': '\uf710',
  'fa-sd-card': '\uf7c2',
  'fa-sd-cards': '\ue240',
  'fa-seal': '\ue241',
  'fa-seal-exclamation': '\ue242',
  'fa-seal-question': '\ue243',
  'fa-seat-airline': '\ue244',
  'fa-section': '\ue447',
  'fa-seedling': '\uf4d8',
  'fa-semicolon': '\u003b',
  'fa-send-back': '\uf87e',
  'fa-send-backward': '\uf87f',
  'fa-sensor': '\ue028',
  'fa-sensor-cloud': '\ue02c',
  'fa-sensor-fire': '\ue02a',
  'fa-sensor-on': '\ue02b',
  'fa-sensor-triangle-exclamation': '\ue029',
  'fa-server': '\uf233',
  'fa-shapes': '\uf61f',
  'fa-share': '\uf064',
  'fa-share-all': '\uf367',
  'fa-share-from-square': '\uf14d',
  'fa-share-nodes': '\uf1e0',
  'fa-sheep': '\uf711',
  'fa-sheet-plastic': '\ue571',
  'fa-shekel-sign': '\uf20b',
  'fa-shelves': '\uf480',
  'fa-shelves-empty': '\ue246',
  'fa-shield': '\uf132',
  'fa-shield-cat': '\ue572',
  'fa-shield-check': '\uf2f7',
  'fa-shield-cross': '\uf712',
  'fa-shield-dog': '\ue573',
  'fa-shield-exclamation': '\ue247',
  'fa-shield-halved': '\uf3ed',
  'fa-shield-heart': '\ue574',
  'fa-shield-keyhole': '\ue248',
  'fa-shield-minus': '\ue249',
  'fa-shield-plus': '\ue24a',
  'fa-shield-quartered': '\ue575',
  'fa-shield-slash': '\ue24b',
  'fa-shield-virus': '\ue06c',
  'fa-shield-xmark': '\ue24c',
  'fa-ship': '\uf21a',
  'fa-shirt': '\uf553',
  'fa-shirt-long-sleeve': '\ue3c7',
  'fa-shirt-running': '\ue3c8',
  'fa-shirt-tank-top': '\ue3c9',
  'fa-shish-kebab': '\uf821',
  'fa-shoe-prints': '\uf54b',
  'fa-shop': '\uf54f',
  'fa-shop-lock': '\ue4a5',
  'fa-shop-slash': '\ue070',
  'fa-shovel': '\uf713',
  'fa-shovel-snow': '\uf7c3',
  'fa-shower': '\uf2cc',
  'fa-shower-down': '\ue24d',
  'fa-shredder': '\uf68a',
  'fa-shrimp': '\ue448',
  'fa-shuffle': '\uf074',
  'fa-shutters': '\ue449',
  'fa-shuttle-space': '\uf197',
  'fa-shuttlecock': '\uf45b',
  'fa-sickle': '\uf822',
  'fa-sidebar': '\ue24e',
  'fa-sidebar-flip': '\ue24f',
  'fa-sigma': '\uf68b',
  'fa-sign-hanging': '\uf4d9',
  'fa-signal': '\uf012',
  'fa-signal-bars': '\uf690',
  'fa-signal-bars-fair': '\uf692',
  'fa-signal-bars-good': '\uf693',
  'fa-signal-bars-slash': '\uf694',
  'fa-signal-bars-weak': '\uf691',
  'fa-signal-fair': '\uf68d',
  'fa-signal-good': '\uf68e',
  'fa-signal-slash': '\uf695',
  'fa-signal-stream': '\uf8dd',
  'fa-signal-stream-slash': '\ue250',
  'fa-signal-strong': '\uf68f',
  'fa-signal-weak': '\uf68c',
  'fa-signature': '\uf5b7',
  'fa-signature-lock': '\ue3ca',
  'fa-signature-slash': '\ue3cb',
  'fa-signs-post': '\uf277',
  'fa-sim-card': '\uf7c4',
  'fa-sim-cards': '\ue251',
  'fa-sink': '\ue06d',
  'fa-siren': '\ue02d',
  'fa-siren-on': '\ue02e',
  'fa-sitemap': '\uf0e8',
  'fa-skeleton': '\uf620',
  'fa-ski-boot': '\ue3cc',
  'fa-ski-boot-ski': '\ue3cd',
  'fa-skull': '\uf54c',
  'fa-skull-cow': '\uf8de',
  'fa-skull-crossbones': '\uf714',
  'fa-slash': '\uf715',
  'fa-slash-back': '\u005c',
  'fa-slash-forward': '\u002f',
  'fa-sleigh': '\uf7cc',
  'fa-slider': '\ue252',
  'fa-sliders': '\uf1de',
  'fa-sliders-simple': '\ue253',
  'fa-sliders-up': '\uf3f1',
  'fa-slot-machine': '\ue3ce',
  'fa-smog': '\uf75f',
  'fa-smoke': '\uf760',
  'fa-smoking': '\uf48d',
  'fa-snake': '\uf716',
  'fa-snooze': '\uf880',
  'fa-snow-blowing': '\uf761',
  'fa-snowflake': '\uf2dc',
  'fa-snowflakes': '\uf7cf',
  'fa-snowman': '\uf7d0',
  'fa-snowman-head': '\uf79b',
  'fa-snowplow': '\uf7d2',
  'fa-soap': '\ue06e',
  'fa-socks': '\uf696',
  'fa-soft-serve': '\ue400',
  'fa-solar-panel': '\uf5ba',
  'fa-solar-system': '\ue02f',
  'fa-sort': '\uf0dc',
  'fa-sort-down': '\uf0dd',
  'fa-sort-up': '\uf0de',
  'fa-spa': '\uf5bb',
  'fa-space-station-moon': '\ue033',
  'fa-space-station-moon-construction': '\ue034',
  'fa-spade': '\uf2f4',
  'fa-spaghetti-monster-flying': '\uf67b',
  'fa-sparkles': '\uf890',
  'fa-speaker': '\uf8df',
  'fa-speakers': '\uf8e0',
  'fa-spell-check': '\uf891',
  'fa-spider': '\uf717',
  'fa-spider-black-widow': '\uf718',
  'fa-spider-web': '\uf719',
  'fa-spinner': '\uf110',
  'fa-spinner-third': '\uf3f4',
  'fa-split': '\ue254',
  'fa-splotch': '\uf5bc',
  'fa-spoon': '\uf2e5',
  'fa-sportsball': '\ue44b',
  'fa-spray-can': '\uf5bd',
  'fa-spray-can-sparkles': '\uf5d0',
  'fa-sprinkler': '\ue035',
  'fa-sprinkler-ceiling': '\ue44c',
  'fa-square': '\uf0c8',
  'fa-square-0': '\ue255',
  'fa-square-1': '\ue256',
  'fa-square-2': '\ue257',
  'fa-square-3': '\ue258',
  'fa-square-4': '\ue259',
  'fa-square-5': '\ue25a',
  'fa-square-6': '\ue25b',
  'fa-square-7': '\ue25c',
  'fa-square-8': '\ue25d',
  'fa-square-9': '\ue25e',
  'fa-square-a': '\ue25f',
  'fa-square-a-lock': '\ue44d',
  'fa-square-ampersand': '\ue260',
  'fa-square-arrow-down': '\uf339',
  'fa-square-arrow-down-left': '\ue261',
  'fa-square-arrow-down-right': '\ue262',
  'fa-square-arrow-left': '\uf33a',
  'fa-square-arrow-right': '\uf33b',
  'fa-square-arrow-up': '\uf33c',
  'fa-square-arrow-up-left': '\ue263',
  'fa-square-arrow-up-right': '\uf14c',
  'fa-square-b': '\ue264',
  'fa-square-bolt': '\ue265',
  'fa-square-c': '\ue266',
  'fa-square-caret-down': '\uf150',
  'fa-square-caret-left': '\uf191',
  'fa-square-caret-right': '\uf152',
  'fa-square-caret-up': '\uf151',
  'fa-square-check': '\uf14a',
  'fa-square-chevron-down': '\uf329',
  'fa-square-chevron-left': '\uf32a',
  'fa-square-chevron-right': '\uf32b',
  'fa-square-chevron-up': '\uf32c',
  'fa-square-code': '\ue267',
  'fa-square-d': '\ue268',
  'fa-square-dashed': '\ue269',
  'fa-square-divide': '\ue26a',
  'fa-square-dollar': '\uf2e9',
  'fa-square-down': '\uf350',
  'fa-square-down-left': '\ue26b',
  'fa-square-down-right': '\ue26c',
  'fa-square-e': '\ue26d',
  'fa-square-ellipsis': '\ue26e',
  'fa-square-ellipsis-vertical': '\ue26f',
  'fa-square-envelope': '\uf199',
  'fa-square-exclamation': '\uf321',
  'fa-square-f': '\ue270',
  'fa-square-fragile': '\uf49b',
  'fa-square-full': '\uf45c',
  'fa-square-g': '\ue271',
  'fa-square-h': '\uf0fd',
  'fa-square-heart': '\uf4c8',
  'fa-square-i': '\ue272',
  'fa-square-info': '\uf30f',
  'fa-square-j': '\ue273',
  'fa-square-k': '\ue274',
  'fa-square-kanban': '\ue488',
  'fa-square-l': '\ue275',
  'fa-square-left': '\uf351',
  'fa-square-list': '\ue489',
  'fa-square-m': '\ue276',
  'fa-square-minus': '\uf146',
  'fa-square-n': '\ue277',
  'fa-square-nfi': '\ue576',
  'fa-square-o': '\ue278',
  'fa-square-p': '\ue279',
  'fa-square-parking': '\uf540',
  'fa-square-parking-slash': '\uf617',
  'fa-square-pen': '\uf14b',
  'fa-square-person-confined': '\ue577',
  'fa-square-phone': '\uf098',
  'fa-square-phone-flip': '\uf87b',
  'fa-square-phone-hangup': '\ue27a',
  'fa-square-plus': '\uf0fe',
  'fa-square-poll-horizontal': '\uf682',
  'fa-square-poll-vertical': '\uf681',
  'fa-square-q': '\ue27b',
  'fa-square-quarters': '\ue44e',
  'fa-square-question': '\uf2fd',
  'fa-square-quote': '\ue329',
  'fa-square-r': '\ue27c',
  'fa-square-right': '\uf352',
  'fa-square-ring': '\ue44f',
  'fa-square-root': '\uf697',
  'fa-square-root-variable': '\uf698',
  'fa-square-rss': '\uf143',
  'fa-square-s': '\ue27d',
  'fa-square-share-nodes': '\uf1e1',
  'fa-square-sliders': '\uf3f0',
  'fa-square-sliders-vertical': '\uf3f2',
  'fa-square-small': '\ue27e',
  'fa-square-star': '\ue27f',
  'fa-square-t': '\ue280',
  'fa-square-terminal': '\ue32a',
  'fa-square-this-way-up': '\uf49f',
  'fa-square-u': '\ue281',
  'fa-square-up': '\uf353',
  'fa-square-up-left': '\ue282',
  'fa-square-up-right': '\uf360',
  'fa-square-user': '\ue283',
  'fa-square-v': '\ue284',
  'fa-square-virus': '\ue578',
  'fa-square-w': '\ue285',
  'fa-square-x': '\ue286',
  'fa-square-xmark': '\uf2d3',
  'fa-square-y': '\ue287',
  'fa-square-z': '\ue288',
  'fa-squid': '\ue450',
  'fa-squirrel': '\uf71a',
  'fa-staff': '\uf71b',
  'fa-staff-snake': '\ue579',
  'fa-stairs': '\ue289',
  'fa-stamp': '\uf5bf',
  'fa-standard-definition': '\ue28a',
  'fa-stapler': '\ue5af',
  'fa-star': '\uf005',
  'fa-star-and-crescent': '\uf699',
  'fa-star-christmas': '\uf7d4',
  'fa-star-exclamation': '\uf2f3',
  'fa-star-half': '\uf089',
  'fa-star-half-stroke': '\uf5c0',
  'fa-star-of-david': '\uf69a',
  'fa-star-of-life': '\uf621',
  'fa-star-sharp': '\ue28b',
  'fa-star-sharp-half': '\ue28c',
  'fa-star-sharp-half-stroke': '\ue28d',
  'fa-star-shooting': '\ue036',
  'fa-starfighter': '\ue037',
  'fa-starfighter-twin-ion-engine': '\ue038',
  'fa-starfighter-twin-ion-engine-advanced': '\ue28e',
  'fa-stars': '\uf762',
  'fa-starship': '\ue039',
  'fa-starship-freighter': '\ue03a',
  'fa-steak': '\uf824',
  'fa-steering-wheel': '\uf622',
  'fa-sterling-sign': '\uf154',
  'fa-stethoscope': '\uf0f1',
  'fa-stocking': '\uf7d5',
  'fa-stomach': '\uf623',
  'fa-stop': '\uf04d',
  'fa-stopwatch': '\uf2f2',
  'fa-stopwatch-20': '\ue06f',
  'fa-store': '\uf54e',
  'fa-store-lock': '\ue4a6',
  'fa-store-slash': '\ue071',
  'fa-strawberry': '\ue32b',
  'fa-street-view': '\uf21d',
  'fa-stretcher': '\uf825',
  'fa-strikethrough': '\uf0cc',
  'fa-stroopwafel': '\uf551',
  'fa-subscript': '\uf12c',
  'fa-suitcase': '\uf0f2',
  'fa-suitcase-medical': '\uf0fa',
  'fa-suitcase-rolling': '\uf5c1',
  'fa-sun': '\uf185',
  'fa-sun-bright': '\ue28f',
  'fa-sun-cloud': '\uf763',
  'fa-sun-dust': '\uf764',
  'fa-sun-haze': '\uf765',
  'fa-sun-plant-wilt': '\ue57a',
  'fa-sunglasses': '\uf892',
  'fa-sunrise': '\uf766',
  'fa-sunset': '\uf767',
  'fa-superscript': '\uf12b',
  'fa-sushi': '\ue48a',
  'fa-sushi-roll': '\ue48b',
  'fa-swatchbook': '\uf5c3',
  'fa-sword': '\uf71c',
  'fa-sword-laser': '\ue03b',
  'fa-sword-laser-alt': '\ue03c',
  'fa-swords': '\uf71d',
  'fa-swords-laser': '\ue03d',
  'fa-symbols': '\uf86e',
  'fa-synagogue': '\uf69b',
  'fa-syringe': '\uf48e',
  'fa-t': '\u0054',
  'fa-table': '\uf0ce',
  'fa-table-cells': '\uf00a',
  'fa-table-cells-large': '\uf009',
  'fa-table-columns': '\uf0db',
  'fa-table-layout': '\ue290',
  'fa-table-list': '\uf00b',
  'fa-table-picnic': '\ue32d',
  'fa-table-pivot': '\ue291',
  'fa-table-rows': '\ue292',
  'fa-table-tennis-paddle-ball': '\uf45d',
  'fa-table-tree': '\ue293',
  'fa-tablet': '\uf3fb',
  'fa-tablet-button': '\uf10a',
  'fa-tablet-rugged': '\uf48f',
  'fa-tablet-screen': '\uf3fc',
  'fa-tablet-screen-button': '\uf3fa',
  'fa-tablets': '\uf490',
  'fa-tachograph-digital': '\uf566',
  'fa-taco': '\uf826',
  'fa-tag': '\uf02b',
  'fa-tags': '\uf02c',
  'fa-tally': '\uf69c',
  'fa-tally-1': '\ue294',
  'fa-tally-2': '\ue295',
  'fa-tally-3': '\ue296',
  'fa-tally-4': '\ue297',
  'fa-tamale': '\ue451',
  'fa-tank-water': '\ue452',
  'fa-tape': '\uf4db',
  'fa-tarp': '\ue57b',
  'fa-tarp-droplet': '\ue57c',
  'fa-taxi': '\uf1ba',
  'fa-taxi-bus': '\ue298',
  'fa-teddy-bear': '\ue3cf',
  'fa-teeth': '\uf62e',
  'fa-teeth-open': '\uf62f',
  'fa-telescope': '\ue03e',
  'fa-temperature-arrow-down': '\ue03f',
  'fa-temperature-arrow-up': '\ue040',
  'fa-temperature-empty': '\uf2cb',
  'fa-temperature-full': '\uf2c7',
  'fa-temperature-half': '\uf2c9',
  'fa-temperature-high': '\uf769',
  'fa-temperature-list': '\ue299',
  'fa-temperature-low': '\uf76b',
  'fa-temperature-quarter': '\uf2ca',
  'fa-temperature-snow': '\uf768',
  'fa-temperature-sun': '\uf76a',
  'fa-temperature-three-quarters': '\uf2c8',
  'fa-tenge-sign': '\uf7d7',
  'fa-tennis-ball': '\uf45e',
  'fa-tent': '\ue57d',
  'fa-tent-arrow-down-to-line': '\ue57e',
  'fa-tent-arrow-left-right': '\ue57f',
  'fa-tent-arrow-turn-left': '\ue580',
  'fa-tent-arrows-down': '\ue581',
  'fa-tents': '\ue582',
  'fa-terminal': '\uf120',
  'fa-text': '\uf893',
  'fa-text-height': '\uf034',
  'fa-text-size': '\uf894',
  'fa-text-slash': '\uf87d',
  'fa-text-width': '\uf035',
  'fa-thermometer': '\uf491',
  'fa-theta': '\uf69e',
  'fa-thought-bubble': '\ue32e',
  'fa-thumbs-down': '\uf165',
  'fa-thumbs-up': '\uf164',
  'fa-thumbtack': '\uf08d',
  'fa-tick': '\ue32f',
  'fa-ticket': '\uf145',
  'fa-ticket-airline': '\ue29a',
  'fa-ticket-simple': '\uf3ff',
  'fa-tickets-airline': '\ue29b',
  'fa-tilde': '\u007e',
  'fa-timeline': '\ue29c',
  'fa-timeline-arrow': '\ue29d',
  'fa-timer': '\ue29e',
  'fa-tire': '\uf631',
  'fa-tire-flat': '\uf632',
  'fa-tire-pressure-warning': '\uf633',
  'fa-tire-rugged': '\uf634',
  'fa-toggle-large-off': '\ue5b0',
  'fa-toggle-large-on': '\ue5b1',
  'fa-toggle-off': '\uf204',
  'fa-toggle-on': '\uf205',
  'fa-toilet': '\uf7d8',
  'fa-toilet-paper': '\uf71e',
  'fa-toilet-paper-blank': '\uf71f',
  'fa-toilet-paper-blank-under': '\ue29f',
  'fa-toilet-paper-check': '\ue5b2',
  'fa-toilet-paper-slash': '\ue072',
  'fa-toilet-paper-under': '\ue2a0',
  'fa-toilet-paper-under-slash': '\ue2a1',
  'fa-toilet-paper-xmark': '\ue5b3',
  'fa-toilet-portable': '\ue583',
  'fa-toilets-portable': '\ue584',
  'fa-tomato': '\ue330',
  'fa-tombstone': '\uf720',
  'fa-tombstone-blank': '\uf721',
  'fa-toolbox': '\uf552',
  'fa-tooth': '\uf5c9',
  'fa-toothbrush': '\uf635',
  'fa-torii-gate': '\uf6a1',
  'fa-tornado': '\uf76f',
  'fa-tower-broadcast': '\uf519',
  'fa-tower-cell': '\ue585',
  'fa-tower-control': '\ue2a2',
  'fa-tower-observation': '\ue586',
  'fa-tractor': '\uf722',
  'fa-trademark': '\uf25c',
  'fa-traffic-cone': '\uf636',
  'fa-traffic-light': '\uf637',
  'fa-traffic-light-go': '\uf638',
  'fa-traffic-light-slow': '\uf639',
  'fa-traffic-light-stop': '\uf63a',
  'fa-trailer': '\ue041',
  'fa-train': '\uf238',
  'fa-train-subway': '\uf239',
  'fa-train-subway-tunnel': '\ue2a3',
  'fa-train-track': '\ue453',
  'fa-train-tram': '\ue5b4',
  'fa-train-tunnel': '\ue454',
  'fa-transformer-bolt': '\ue2a4',
  'fa-transgender': '\uf225',
  'fa-transporter': '\ue042',
  'fa-transporter-1': '\ue043',
  'fa-transporter-2': '\ue044',
  'fa-transporter-3': '\ue045',
  'fa-transporter-4': '\ue2a5',
  'fa-transporter-5': '\ue2a6',
  'fa-transporter-6': '\ue2a7',
  'fa-transporter-7': '\ue2a8',
  'fa-transporter-empty': '\ue046',
  'fa-trash': '\uf1f8',
  'fa-trash-arrow-up': '\uf829',
  'fa-trash-can': '\uf2ed',
  'fa-trash-can-arrow-up': '\uf82a',
  'fa-trash-can-check': '\ue2a9',
  'fa-trash-can-clock': '\ue2aa',
  'fa-trash-can-list': '\ue2ab',
  'fa-trash-can-plus': '\ue2ac',
  'fa-trash-can-slash': '\ue2ad',
  'fa-trash-can-undo': '\uf896',
  'fa-trash-can-xmark': '\ue2ae',
  'fa-trash-check': '\ue2af',
  'fa-trash-clock': '\ue2b0',
  'fa-trash-list': '\ue2b1',
  'fa-trash-plus': '\ue2b2',
  'fa-trash-slash': '\ue2b3',
  'fa-trash-undo': '\uf895',
  'fa-trash-xmark': '\ue2b4',
  'fa-treasure-chest': '\uf723',
  'fa-tree': '\uf1bb',
  'fa-tree-christmas': '\uf7db',
  'fa-tree-city': '\ue587',
  'fa-tree-deciduous': '\uf400',
  'fa-tree-decorated': '\uf7dc',
  'fa-tree-large': '\uf7dd',
  'fa-tree-palm': '\uf82b',
  'fa-trees': '\uf724',
  'fa-triangle': '\uf2ec',
  'fa-triangle-exclamation': '\uf071',
  'fa-triangle-instrument': '\uf8e2',
  'fa-triangle-person-digging': '\uf85d',
  'fa-trillium': '\ue588',
  'fa-trophy': '\uf091',
  'fa-trophy-star': '\uf2eb',
  'fa-trowel': '\ue589',
  'fa-trowel-bricks': '\ue58a',
  'fa-truck': '\uf0d1',
  'fa-truck-arrow-right': '\ue58b',
  'fa-truck-bolt': '\ue3d0',
  'fa-truck-clock': '\uf48c',
  'fa-truck-container': '\uf4dc',
  'fa-truck-container-empty': '\ue2b5',
  'fa-truck-droplet': '\ue58c',
  'fa-truck-fast': '\uf48b',
  'fa-truck-field': '\ue58d',
  'fa-truck-field-un': '\ue58e',
  'fa-truck-flatbed': '\ue2b6',
  'fa-truck-front': '\ue2b7',
  'fa-truck-medical': '\uf0f9',
  'fa-truck-monster': '\uf63b',
  'fa-truck-moving': '\uf4df',
  'fa-truck-pickup': '\uf63c',
  'fa-truck-plane': '\ue58f',
  'fa-truck-plow': '\uf7de',
  'fa-truck-ramp': '\uf4e0',
  'fa-truck-ramp-box': '\uf4de',
  'fa-truck-ramp-couch': '\uf4dd',
  'fa-truck-tow': '\ue2b8',
  'fa-trumpet': '\uf8e3',
  'fa-tty': '\uf1e4',
  'fa-tty-answer': '\ue2b9',
  'fa-tugrik-sign': '\ue2ba',
  'fa-turkey': '\uf725',
  'fa-turkish-lira-sign': '\ue2bb',
  'fa-turn-down': '\uf3be',
  'fa-turn-down-left': '\ue331',
  'fa-turn-down-right': '\ue455',
  'fa-turn-up': '\uf3bf',
  'fa-turntable': '\uf8e4',
  'fa-turtle': '\uf726',
  'fa-tv': '\uf26c',
  'fa-tv-music': '\uf8e6',
  'fa-tv-retro': '\uf401',
  'fa-typewriter': '\uf8e7',
  'fa-u': '\u0055',
  'fa-ufo': '\ue047',
  'fa-ufo-beam': '\ue048',
  'fa-umbrella': '\uf0e9',
  'fa-umbrella-beach': '\uf5ca',
  'fa-umbrella-simple': '\ue2bc',
  'fa-underline': '\uf0cd',
  'fa-unicorn': '\uf727',
  'fa-uniform-martial-arts': '\ue3d1',
  'fa-union': '\uf6a2',
  'fa-universal-access': '\uf29a',
  'fa-unlock': '\uf09c',
  'fa-unlock-keyhole': '\uf13e',
  'fa-up': '\uf357',
  'fa-up-down': '\uf338',
  'fa-up-down-left-right': '\uf0b2',
  'fa-up-from-bracket': '\ue590',
  'fa-up-from-dotted-line': '\ue456',
  'fa-up-from-line': '\uf346',
  'fa-up-left': '\ue2bd',
  'fa-up-long': '\uf30c',
  'fa-up-right': '\ue2be',
  'fa-up-right-and-down-left-from-center': '\uf424',
  'fa-up-right-from-square': '\uf35d',
  'fa-up-to-dotted-line': '\ue457',
  'fa-up-to-line': '\uf34d',
  'fa-upload': '\uf093',
  'fa-usb-drive': '\uf8e9',
  'fa-user': '\uf007',
  'fa-user-alien': '\ue04a',
  'fa-user-astronaut': '\uf4fb',
  'fa-user-bounty-hunter': '\ue2bf',
  'fa-user-check': '\uf4fc',
  'fa-user-chef': '\ue3d2',
  'fa-user-clock': '\uf4fd',
  'fa-user-cowboy': '\uf8ea',
  'fa-user-crown': '\uf6a4',
  'fa-user-doctor': '\uf0f0',
  'fa-user-doctor-hair': '\ue458',
  'fa-user-doctor-hair-long': '\ue459',
  'fa-user-doctor-message': '\uf82e',
  'fa-user-gear': '\uf4fe',
  'fa-user-graduate': '\uf501',
  'fa-user-group': '\uf500',
  'fa-user-group-crown': '\uf6a5',
  'fa-user-hair': '\ue45a',
  'fa-user-hair-buns': '\ue3d3',
  'fa-user-hair-long': '\ue45b',
  'fa-user-hair-mullet': '\ue45c',
  'fa-user-headset': '\uf82d',
  'fa-user-helmet-safety': '\uf82c',
  'fa-user-injured': '\uf728',
  'fa-user-large': '\uf406',
  'fa-user-large-slash': '\uf4fa',
  'fa-user-lock': '\uf502',
  'fa-user-minus': '\uf503',
  'fa-user-music': '\uf8eb',
  'fa-user-ninja': '\uf504',
  'fa-user-nurse': '\uf82f',
  'fa-user-nurse-hair': '\ue45d',
  'fa-user-nurse-hair-long': '\ue45e',
  'fa-user-pen': '\uf4ff',
  'fa-user-pilot': '\ue2c0',
  'fa-user-pilot-tie': '\ue2c1',
  'fa-user-plus': '\uf234',
  'fa-user-police': '\ue333',
  'fa-user-police-tie': '\ue334',
  'fa-user-robot': '\ue04b',
  'fa-user-robot-xmarks': '\ue4a7',
  'fa-user-secret': '\uf21b',
  'fa-user-shakespeare': '\ue2c2',
  'fa-user-shield': '\uf505',
  'fa-user-slash': '\uf506',
  'fa-user-tag': '\uf507',
  'fa-user-tie': '\uf508',
  'fa-user-tie-hair': '\ue45f',
  'fa-user-tie-hair-long': '\ue460',
  'fa-user-unlock': '\ue058',
  'fa-user-visor': '\ue04c',
  'fa-user-vneck': '\ue461',
  'fa-user-vneck-hair': '\ue462',
  'fa-user-vneck-hair-long': '\ue463',
  'fa-user-xmark': '\uf235',
  'fa-users': '\uf0c0',
  'fa-users-between-lines': '\ue591',
  'fa-users-gear': '\uf509',
  'fa-users-line': '\ue592',
  'fa-users-medical': '\uf830',
  'fa-users-rays': '\ue593',
  'fa-users-rectangle': '\ue594',
  'fa-users-slash': '\ue073',
  'fa-users-viewfinder': '\ue595',
  'fa-utensils': '\uf2e7',
  'fa-utensils-slash': '\ue464',
  'fa-utility-pole': '\ue2c3',
  'fa-utility-pole-double': '\ue2c4',
  'fa-v': '\u0056',
  'fa-vacuum': '\ue04d',
  'fa-vacuum-robot': '\ue04e',
  'fa-value-absolute': '\uf6a6',
  'fa-van-shuttle': '\uf5b6',
  'fa-vault': '\ue2c5',
  'fa-vector-circle': '\ue2c6',
  'fa-vector-polygon': '\ue2c7',
  'fa-vector-square': '\uf5cb',
  'fa-vent-damper': '\ue465',
  'fa-venus': '\uf221',
  'fa-venus-double': '\uf226',
  'fa-venus-mars': '\uf228',
  'fa-vest': '\ue085',
  'fa-vest-patches': '\ue086',
  'fa-vial': '\uf492',
  'fa-vial-circle-check': '\ue596',
  'fa-vial-virus': '\ue597',
  'fa-vials': '\uf493',
  'fa-video': '\uf03d',
  'fa-video-arrow-down-left': '\ue2c8',
  'fa-video-arrow-up-right': '\ue2c9',
  'fa-video-plus': '\uf4e1',
  'fa-video-slash': '\uf4e2',
  'fa-vihara': '\uf6a7',
  'fa-violin': '\uf8ed',
  'fa-virus': '\ue074',
  'fa-virus-covid': '\ue4a8',
  'fa-virus-covid-slash': '\ue4a9',
  'fa-virus-slash': '\ue075',
  'fa-viruses': '\ue076',
  'fa-voicemail': '\uf897',
  'fa-volcano': '\uf770',
  'fa-volleyball': '\uf45f',
  'fa-volume': '\uf6a8',
  'fa-volume-high': '\uf028',
  'fa-volume-low': '\uf027',
  'fa-volume-off': '\uf026',
  'fa-volume-slash': '\uf2e2',
  'fa-volume-xmark': '\uf6a9',
  'fa-vr-cardboard': '\uf729',
  'fa-w': '\u0057',
  'fa-waffle': '\ue466',
  'fa-wagon-covered': '\uf8ee',
  'fa-walker': '\uf831',
  'fa-walkie-talkie': '\uf8ef',
  'fa-wallet': '\uf555',
  'fa-wand': '\uf72a',
  'fa-wand-magic': '\uf0d0',
  'fa-wand-magic-sparkles': '\ue2ca',
  'fa-wand-sparkles': '\uf72b',
  'fa-warehouse': '\uf494',
  'fa-warehouse-full': '\uf495',
  'fa-washing-machine': '\uf898',
  'fa-watch': '\uf2e1',
  'fa-watch-apple': '\ue2cb',
  'fa-watch-calculator': '\uf8f0',
  'fa-watch-fitness': '\uf63e',
  'fa-watch-smart': '\ue2cc',
  'fa-water': '\uf773',
  'fa-water-arrow-down': '\uf774',
  'fa-water-arrow-up': '\uf775',
  'fa-water-ladder': '\uf5c5',
  'fa-watermelon-slice': '\ue337',
  'fa-wave-pulse': '\uf5f8',
  'fa-wave-sine': '\uf899',
  'fa-wave-square': '\uf83e',
  'fa-wave-triangle': '\uf89a',
  'fa-waveform': '\uf8f1',
  'fa-waveform-lines': '\uf8f2',
  'fa-weight-hanging': '\uf5cd',
  'fa-weight-scale': '\uf496',
  'fa-whale': '\uf72c',
  'fa-wheat': '\uf72d',
  'fa-wheat-awn': '\ue2cd',
  'fa-wheat-awn-circle-exclamation': '\ue598',
  'fa-wheat-awn-slash': '\ue338',
  'fa-wheat-slash': '\ue339',
  'fa-wheelchair': '\uf193',
  'fa-wheelchair-move': '\ue2ce',
  'fa-whiskey-glass': '\uf7a0',
  'fa-whiskey-glass-ice': '\uf7a1',
  'fa-whistle': '\uf460',
  'fa-wifi': '\uf1eb',
  'fa-wifi-exclamation': '\ue2cf',
  'fa-wifi-fair': '\uf6ab',
  'fa-wifi-slash': '\uf6ac',
  'fa-wifi-weak': '\uf6aa',
  'fa-wind': '\uf72e',
  'fa-wind-turbine': '\uf89b',
  'fa-wind-warning': '\uf776',
  'fa-window': '\uf40e',
  'fa-window-flip': '\uf40f',
  'fa-window-frame': '\ue04f',
  'fa-window-frame-open': '\ue050',
  'fa-window-maximize': '\uf2d0',
  'fa-window-minimize': '\uf2d1',
  'fa-window-restore': '\uf2d2',
  'fa-windsock': '\uf777',
  'fa-wine-bottle': '\uf72f',
  'fa-wine-glass': '\uf4e3',
  'fa-wine-glass-crack': '\uf4bb',
  'fa-wine-glass-empty': '\uf5ce',
  'fa-won-sign': '\uf159',
  'fa-worm': '\ue599',
  'fa-wreath': '\uf7e2',
  'fa-wrench': '\uf0ad',
  'fa-wrench-simple': '\ue2d1',
  'fa-x': '\u0058',
  'fa-x-ray': '\uf497',
  'fa-xmark': '\uf00d',
  'fa-xmark-large': '\ue59b',
  'fa-xmark-to-slot': '\uf771',
  'fa-xmarks-lines': '\ue59a',
  'fa-y': '\u0059',
  'fa-yen-sign': '\uf157',
  'fa-yin-yang': '\uf6ad',
  'fa-z': '\u005a',
};

export const icons: string[] = Object.keys(iconsMap);

export const solid: string[] = icons.map(icon => `fas ${icon}`);

export const regular: string[] = icons.map(icon => `far ${icon}`);

export const light: string[] = icons.map(icon => `fal ${icon}`);

export const brandMap: Record<string, string> = {
  'fa-42-group': '\ue080',
  'fa-500px': '\uf26e',
  'fa-accessible-icon': '\uf368',
  'fa-accusoft': '\uf369',
  'fa-adn': '\uf170',
  'fa-adversal': '\uf36a',
  'fa-affiliatetheme': '\uf36b',
  'fa-airbnb': '\uf834',
  'fa-algolia': '\uf36c',
  'fa-alipay': '\uf642',
  'fa-amazon': '\uf270',
  'fa-amazon-pay': '\uf42c',
  'fa-amilia': '\uf36d',
  'fa-android': '\uf17b',
  'fa-angellist': '\uf209',
  'fa-angrycreative': '\uf36e',
  'fa-angular': '\uf420',
  'fa-app-store': '\uf36f',
  'fa-app-store-ios': '\uf370',
  'fa-apper': '\uf371',
  'fa-apple': '\uf179',
  'fa-apple-pay': '\uf415',
  'fa-artstation': '\uf77a',
  'fa-asymmetrik': '\uf372',
  'fa-atlassian': '\uf77b',
  'fa-audible': '\uf373',
  'fa-autoprefixer': '\uf41c',
  'fa-avianex': '\uf374',
  'fa-aviato': '\uf421',
  'fa-aws': '\uf375',
  'fa-bandcamp': '\uf2d5',
  'fa-battle-net': '\uf835',
  'fa-behance': '\uf1b4',
  'fa-bilibili': '\ue3d9',
  'fa-bimobject': '\uf378',
  'fa-bitbucket': '\uf171',
  'fa-bitcoin': '\uf379',
  'fa-bity': '\uf37a',
  'fa-black-tie': '\uf27e',
  'fa-blackberry': '\uf37b',
  'fa-blogger': '\uf37c',
  'fa-blogger-b': '\uf37d',
  'fa-bluetooth': '\uf293',
  'fa-bluetooth-b': '\uf294',
  'fa-bootstrap': '\uf836',
  'fa-bots': '\ue340',
  'fa-btc': '\uf15a',
  'fa-buffer': '\uf837',
  'fa-buromobelexperte': '\uf37f',
  'fa-buy-n-large': '\uf8a6',
  'fa-buysellads': '\uf20d',
  'fa-canadian-maple-leaf': '\uf785',
  'fa-cc-amazon-pay': '\uf42d',
  'fa-cc-amex': '\uf1f3',
  'fa-cc-apple-pay': '\uf416',
  'fa-cc-diners-club': '\uf24c',
  'fa-cc-discover': '\uf1f2',
  'fa-cc-jcb': '\uf24b',
  'fa-cc-mastercard': '\uf1f1',
  'fa-cc-paypal': '\uf1f4',
  'fa-cc-stripe': '\uf1f5',
  'fa-cc-visa': '\uf1f0',
  'fa-centercode': '\uf380',
  'fa-centos': '\uf789',
  'fa-chrome': '\uf268',
  'fa-chromecast': '\uf838',
  'fa-cloudflare': '\ue07d',
  'fa-cloudscale': '\uf383',
  'fa-cloudsmith': '\uf384',
  'fa-cloudversify': '\uf385',
  'fa-cmplid': '\ue360',
  'fa-codepen': '\uf1cb',
  'fa-codiepie': '\uf284',
  'fa-confluence': '\uf78d',
  'fa-connectdevelop': '\uf20e',
  'fa-contao': '\uf26d',
  'fa-cotton-bureau': '\uf89e',
  'fa-cpanel': '\uf388',
  'fa-creative-commons': '\uf25e',
  'fa-creative-commons-by': '\uf4e7',
  'fa-creative-commons-nc': '\uf4e8',
  'fa-creative-commons-nc-eu': '\uf4e9',
  'fa-creative-commons-nc-jp': '\uf4ea',
  'fa-creative-commons-nd': '\uf4eb',
  'fa-creative-commons-pd': '\uf4ec',
  'fa-creative-commons-pd-alt': '\uf4ed',
  'fa-creative-commons-remix': '\uf4ee',
  'fa-creative-commons-sa': '\uf4ef',
  'fa-creative-commons-sampling': '\uf4f0',
  'fa-creative-commons-sampling-plus': '\uf4f1',
  'fa-creative-commons-share': '\uf4f2',
  'fa-creative-commons-zero': '\uf4f3',
  'fa-critical-role': '\uf6c9',
  'fa-css3': '\uf13c',
  'fa-css3-alt': '\uf38b',
  'fa-cuttlefish': '\uf38c',
  'fa-d-and-d': '\uf38d',
  'fa-d-and-d-beyond': '\uf6ca',
  'fa-dailymotion': '\ue052',
  'fa-dashcube': '\uf210',
  'fa-deezer': '\ue077',
  'fa-delicious': '\uf1a5',
  'fa-deploydog': '\uf38e',
  'fa-deskpro': '\uf38f',
  'fa-dev': '\uf6cc',
  'fa-deviantart': '\uf1bd',
  'fa-dhl': '\uf790',
  'fa-diaspora': '\uf791',
  'fa-digg': '\uf1a6',
  'fa-digital-ocean': '\uf391',
  'fa-discord': '\uf392',
  'fa-discourse': '\uf393',
  'fa-dochub': '\uf394',
  'fa-docker': '\uf395',
  'fa-draft2digital': '\uf396',
  'fa-dribbble': '\uf17d',
  'fa-dropbox': '\uf16b',
  'fa-drupal': '\uf1a9',
  'fa-dyalog': '\uf399',
  'fa-earlybirds': '\uf39a',
  'fa-ebay': '\uf4f4',
  'fa-edge': '\uf282',
  'fa-edge-legacy': '\ue078',
  'fa-elementor': '\uf430',
  'fa-ello': '\uf5f1',
  'fa-ember': '\uf423',
  'fa-empire': '\uf1d1',
  'fa-envira': '\uf299',
  'fa-erlang': '\uf39d',
  'fa-ethereum': '\uf42e',
  'fa-etsy': '\uf2d7',
  'fa-evernote': '\uf839',
  'fa-expeditedssl': '\uf23e',
  'fa-facebook': '\uf09a',
  'fa-facebook-f': '\uf39e',
  'fa-facebook-messenger': '\uf39f',
  'fa-fantasy-flight-games': '\uf6dc',
  'fa-fedex': '\uf797',
  'fa-fedora': '\uf798',
  'fa-figma': '\uf799',
  'fa-firefox': '\uf269',
  'fa-firefox-browser': '\ue007',
  'fa-first-order': '\uf2b0',
  'fa-first-order-alt': '\uf50a',
  'fa-firstdraft': '\uf3a1',
  'fa-flickr': '\uf16e',
  'fa-flipboard': '\uf44d',
  'fa-fly': '\uf417',
  'fa-font-awesome': '\uf2b4',
  'fa-fonticons': '\uf280',
  'fa-fonticons-fi': '\uf3a2',
  'fa-fort-awesome': '\uf286',
  'fa-fort-awesome-alt': '\uf3a3',
  'fa-forumbee': '\uf211',
  'fa-foursquare': '\uf180',
  'fa-free-code-camp': '\uf2c5',
  'fa-freebsd': '\uf3a4',
  'fa-fulcrum': '\uf50b',
  'fa-galactic-republic': '\uf50c',
  'fa-galactic-senate': '\uf50d',
  'fa-get-pocket': '\uf265',
  'fa-gg': '\uf260',
  'fa-gg-circle': '\uf261',
  'fa-git': '\uf1d3',
  'fa-git-alt': '\uf841',
  'fa-github': '\uf09b',
  'fa-github-alt': '\uf113',
  'fa-gitkraken': '\uf3a6',
  'fa-gitlab': '\uf296',
  'fa-gitter': '\uf426',
  'fa-glide': '\uf2a5',
  'fa-glide-g': '\uf2a6',
  'fa-gofore': '\uf3a7',
  'fa-golang': '\ue40f',
  'fa-goodreads': '\uf3a8',
  'fa-goodreads-g': '\uf3a9',
  'fa-google': '\uf1a0',
  'fa-google-drive': '\uf3aa',
  'fa-google-pay': '\ue079',
  'fa-google-play': '\uf3ab',
  'fa-google-plus': '\uf2b3',
  'fa-google-plus-g': '\uf0d5',
  'fa-google-wallet': '\uf1ee',
  'fa-gratipay': '\uf184',
  'fa-grav': '\uf2d6',
  'fa-gripfire': '\uf3ac',
  'fa-grunt': '\uf3ad',
  'fa-guilded': '\ue07e',
  'fa-gulp': '\uf3ae',
  'fa-hacker-news': '\uf1d4',
  'fa-hackerrank': '\uf5f7',
  'fa-hashnode': '\ue499',
  'fa-hips': '\uf452',
  'fa-hire-a-helper': '\uf3b0',
  'fa-hive': '\ue07f',
  'fa-hooli': '\uf427',
  'fa-hornbill': '\uf592',
  'fa-hotjar': '\uf3b1',
  'fa-houzz': '\uf27c',
  'fa-html5': '\uf13b',
  'fa-hubspot': '\uf3b2',
  'fa-ideal': '\ue013',
  'fa-imdb': '\uf2d8',
  'fa-instagram': '\uf16d',
  'fa-instalod': '\ue081',
  'fa-intercom': '\uf7af',
  'fa-internet-explorer': '\uf26b',
  'fa-invision': '\uf7b0',
  'fa-ioxhost': '\uf208',
  'fa-itch-io': '\uf83a',
  'fa-itunes': '\uf3b4',
  'fa-itunes-note': '\uf3b5',
  'fa-java': '\uf4e4',
  'fa-jedi-order': '\uf50e',
  'fa-jenkins': '\uf3b6',
  'fa-jira': '\uf7b1',
  'fa-joget': '\uf3b7',
  'fa-joomla': '\uf1aa',
  'fa-js': '\uf3b8',
  'fa-jsfiddle': '\uf1cc',
  'fa-kaggle': '\uf5fa',
  'fa-keybase': '\uf4f5',
  'fa-keycdn': '\uf3ba',
  'fa-kickstarter': '\uf3bb',
  'fa-kickstarter-k': '\uf3bc',
  'fa-korvue': '\uf42f',
  'fa-laravel': '\uf3bd',
  'fa-lastfm': '\uf202',
  'fa-leanpub': '\uf212',
  'fa-less': '\uf41d',
  'fa-line': '\uf3c0',
  'fa-linkedin': '\uf08c',
  'fa-linkedin-in': '\uf0e1',
  'fa-linode': '\uf2b8',
  'fa-linux': '\uf17c',
  'fa-lyft': '\uf3c3',
  'fa-magento': '\uf3c4',
  'fa-mailchimp': '\uf59e',
  'fa-mandalorian': '\uf50f',
  'fa-markdown': '\uf60f',
  'fa-mastodon': '\uf4f6',
  'fa-maxcdn': '\uf136',
  'fa-mdb': '\uf8ca',
  'fa-medapps': '\uf3c6',
  'fa-medium': '\uf23a',
  'fa-medrt': '\uf3c8',
  'fa-meetup': '\uf2e0',
  'fa-megaport': '\uf5a3',
  'fa-mendeley': '\uf7b3',
  'fa-meta': '\ue49b',
  'fa-microblog': '\ue01a',
  'fa-microsoft': '\uf3ca',
  'fa-mix': '\uf3cb',
  'fa-mixcloud': '\uf289',
  'fa-mixer': '\ue056',
  'fa-mizuni': '\uf3cc',
  'fa-modx': '\uf285',
  'fa-monero': '\uf3d0',
  'fa-napster': '\uf3d2',
  'fa-neos': '\uf612',
  'fa-nfc-directional': '\ue530',
  'fa-nfc-symbol': '\ue531',
  'fa-nimblr': '\uf5a8',
  'fa-node': '\uf419',
  'fa-node-js': '\uf3d3',
  'fa-npm': '\uf3d4',
  'fa-ns8': '\uf3d5',
  'fa-nutritionix': '\uf3d6',
  'fa-octopus-deploy': '\ue082',
  'fa-odnoklassniki': '\uf263',
  'fa-old-republic': '\uf510',
  'fa-opencart': '\uf23d',
  'fa-openid': '\uf19b',
  'fa-opera': '\uf26a',
  'fa-optin-monster': '\uf23c',
  'fa-orcid': '\uf8d2',
  'fa-osi': '\uf41a',
  'fa-padlet': '\ue4a0',
  'fa-page4': '\uf3d7',
  'fa-pagelines': '\uf18c',
  'fa-palfed': '\uf3d8',
  'fa-patreon': '\uf3d9',
  'fa-paypal': '\uf1ed',
  'fa-perbyte': '\ue083',
  'fa-periscope': '\uf3da',
  'fa-phabricator': '\uf3db',
  'fa-phoenix-framework': '\uf3dc',
  'fa-phoenix-squadron': '\uf511',
  'fa-php': '\uf457',
  'fa-pied-piper': '\uf2ae',
  'fa-pied-piper-alt': '\uf1a8',
  'fa-pied-piper-hat': '\uf4e5',
  'fa-pied-piper-pp': '\uf1a7',
  'fa-pinterest': '\uf0d2',
  'fa-pinterest-p': '\uf231',
  'fa-pix': '\ue43a',
  'fa-playstation': '\uf3df',
  'fa-product-hunt': '\uf288',
  'fa-pushed': '\uf3e1',
  'fa-python': '\uf3e2',
  'fa-qq': '\uf1d6',
  'fa-quinscape': '\uf459',
  'fa-quora': '\uf2c4',
  'fa-r-project': '\uf4f7',
  'fa-raspberry-pi': '\uf7bb',
  'fa-ravelry': '\uf2d9',
  'fa-react': '\uf41b',
  'fa-reacteurope': '\uf75d',
  'fa-readme': '\uf4d5',
  'fa-rebel': '\uf1d0',
  'fa-red-river': '\uf3e3',
  'fa-reddit': '\uf1a1',
  'fa-reddit-alien': '\uf281',
  'fa-redhat': '\uf7bc',
  'fa-renren': '\uf18b',
  'fa-replyd': '\uf3e6',
  'fa-researchgate': '\uf4f8',
  'fa-resolving': '\uf3e7',
  'fa-rev': '\uf5b2',
  'fa-rocketchat': '\uf3e8',
  'fa-rockrms': '\uf3e9',
  'fa-rust': '\ue07a',
  'fa-safari': '\uf267',
  'fa-salesforce': '\uf83b',
  'fa-sass': '\uf41e',
  'fa-schlix': '\uf3ea',
  'fa-screenpal': '\ue570',
  'fa-scribd': '\uf28a',
  'fa-searchengin': '\uf3eb',
  'fa-sellcast': '\uf2da',
  'fa-sellsy': '\uf213',
  'fa-servicestack': '\uf3ec',
  'fa-shirtsinbulk': '\uf214',
  'fa-shopify': '\ue057',
  'fa-shopware': '\uf5b5',
  'fa-simplybuilt': '\uf215',
  'fa-sistrix': '\uf3ee',
  'fa-sith': '\uf512',
  'fa-sitrox': '\ue44a',
  'fa-sketch': '\uf7c6',
  'fa-skyatlas': '\uf216',
  'fa-skype': '\uf17e',
  'fa-slack': '\uf198',
  'fa-slideshare': '\uf1e7',
  'fa-snapchat': '\uf2ab',
  'fa-soundcloud': '\uf1be',
  'fa-sourcetree': '\uf7d3',
  'fa-space-awesome': '\ue5ac',
  'fa-speakap': '\uf3f3',
  'fa-speaker-deck': '\uf83c',
  'fa-spotify': '\uf1bc',
  'fa-square-behance': '\uf1b5',
  'fa-square-dribbble': '\uf397',
  'fa-square-facebook': '\uf082',
  'fa-square-font-awesome': '\ue5ad',
  'fa-square-font-awesome-stroke': '\uf35c',
  'fa-square-git': '\uf1d2',
  'fa-square-github': '\uf092',
  'fa-square-gitlab': '\ue5ae',
  'fa-square-google-plus': '\uf0d4',
  'fa-square-hacker-news': '\uf3af',
  'fa-square-instagram': '\ue055',
  'fa-square-js': '\uf3b9',
  'fa-square-lastfm': '\uf203',
  'fa-square-odnoklassniki': '\uf264',
  'fa-square-pied-piper': '\ue01e',
  'fa-square-pinterest': '\uf0d3',
  'fa-square-reddit': '\uf1a2',
  'fa-square-snapchat': '\uf2ad',
  'fa-square-steam': '\uf1b7',
  'fa-square-tumblr': '\uf174',
  'fa-square-twitter': '\uf081',
  'fa-square-viadeo': '\uf2aa',
  'fa-square-vimeo': '\uf194',
  'fa-square-whatsapp': '\uf40c',
  'fa-square-xing': '\uf169',
  'fa-square-youtube': '\uf431',
  'fa-squarespace': '\uf5be',
  'fa-stack-exchange': '\uf18d',
  'fa-stack-overflow': '\uf16c',
  'fa-stackpath': '\uf842',
  'fa-staylinked': '\uf3f5',
  'fa-steam': '\uf1b6',
  'fa-steam-symbol': '\uf3f6',
  'fa-sticker-mule': '\uf3f7',
  'fa-strava': '\uf428',
  'fa-stripe': '\uf429',
  'fa-stripe-s': '\uf42a',
  'fa-studiovinari': '\uf3f8',
  'fa-stumbleupon': '\uf1a4',
  'fa-stumbleupon-circle': '\uf1a3',
  'fa-superpowers': '\uf2dd',
  'fa-supple': '\uf3f9',
  'fa-suse': '\uf7d6',
  'fa-swift': '\uf8e1',
  'fa-symfony': '\uf83d',
  'fa-teamspeak': '\uf4f9',
  'fa-telegram': '\uf2c6',
  'fa-tencent-weibo': '\uf1d5',
  'fa-the-red-yeti': '\uf69d',
  'fa-themeco': '\uf5c6',
  'fa-themeisle': '\uf2b2',
  'fa-think-peaks': '\uf731',
  'fa-tiktok': '\ue07b',
  'fa-trade-federation': '\uf513',
  'fa-trello': '\uf181',
  'fa-tumblr': '\uf173',
  'fa-twitch': '\uf1e8',
  'fa-twitter': '\uf099',
  'fa-typo3': '\uf42b',
  'fa-uber': '\uf402',
  'fa-ubuntu': '\uf7df',
  'fa-uikit': '\uf403',
  'fa-umbraco': '\uf8e8',
  'fa-uncharted': '\ue084',
  'fa-uniregistry': '\uf404',
  'fa-unity': '\ue049',
  'fa-unsplash': '\ue07c',
  'fa-untappd': '\uf405',
  'fa-ups': '\uf7e0',
  'fa-usb': '\uf287',
  'fa-usps': '\uf7e1',
  'fa-ussunnah': '\uf407',
  'fa-vaadin': '\uf408',
  'fa-viacoin': '\uf237',
  'fa-viadeo': '\uf2a9',
  'fa-viber': '\uf409',
  'fa-vimeo': '\uf40a',
  'fa-vimeo-v': '\uf27d',
  'fa-vine': '\uf1ca',
  'fa-vk': '\uf189',
  'fa-vnv': '\uf40b',
  'fa-vuejs': '\uf41f',
  'fa-watchman-monitoring': '\ue087',
  'fa-waze': '\uf83f',
  'fa-weebly': '\uf5cc',
  'fa-weibo': '\uf18a',
  'fa-weixin': '\uf1d7',
  'fa-whatsapp': '\uf232',
  'fa-whmcs': '\uf40d',
  'fa-wikipedia-w': '\uf266',
  'fa-windows': '\uf17a',
  'fa-wirsindhandwerk': '\ue2d0',
  'fa-wix': '\uf5cf',
  'fa-wizards-of-the-coast': '\uf730',
  'fa-wodu': '\ue088',
  'fa-wolf-pack-battalion': '\uf514',
  'fa-wordpress': '\uf19a',
  'fa-wordpress-simple': '\uf411',
  'fa-wpbeginner': '\uf297',
  'fa-wpexplorer': '\uf2de',
  'fa-wpforms': '\uf298',
  'fa-wpressr': '\uf3e4',
  'fa-xbox': '\uf412',
  'fa-xing': '\uf168',
  'fa-y-combinator': '\uf23b',
  'fa-yahoo': '\uf19e',
  'fa-yammer': '\uf840',
  'fa-yandex': '\uf413',
  'fa-yandex-international': '\uf414',
  'fa-yarn': '\uf7e3',
  'fa-yelp': '\uf1e9',
  'fa-yoast': '\uf2b1',
  'fa-youtube': '\uf167',
  'fa-zhihu': '\uf63f',
};

export const brand = Object.keys(brandMap).map(icon => `fab ${icon}`);

export const iconsMeta = new Map<string, string[]>([
  ['0', ['0']],
  ['1', ['1']],
  ['2', ['2']],
  ['3', ['3']],
  ['4', ['4']],
  ['5', ['5']],
  ['6', ['6']],
  ['7', ['7']],
  ['8', ['8']],
  ['9', ['9']],
  ['00', ['00']],
  ['360-degrees', ['360-degrees']],
  ['42-group', ['42-group']],
  ['innosoft', ['42-group']],
  ['500px', ['500px']],
  ['a', ['a']],
  ['abacus', ['abacus']],
  ['accent-grave', ['accent-grave']],
  ['accessible-icon', ['accessible-icon']],
  ['accusoft', ['accusoft']],
  ['acorn', ['acorn']],
  ['address-book', ['address-book']],
  ['contact-book', ['address-book']],
  ['address-card', ['address-card']],
  ['contact-card', ['address-card']],
  ['vcard', ['address-card']],
  ['adn', ['adn']],
  ['adversal', ['adversal']],
  ['affiliatetheme', ['affiliatetheme']],
  ['air-conditioner', ['air-conditioner']],
  ['airbnb', ['airbnb']],
  ['airplay', ['airplay']],
  ['alarm-clock', ['alarm-clock']],
  ['alarm-exclamation', ['alarm-exclamation']],
  ['alarm-plus', ['alarm-plus']],
  ['alarm-snooze', ['alarm-snooze']],
  ['album', ['album']],
  ['album-circle-plus', ['album-circle-plus']],
  ['album-circle-user', ['album-circle-user']],
  ['album-collection', ['album-collection']],
  ['album-collection-circle-plus', ['album-collection-circle-plus']],
  ['album-collection-circle-user', ['album-collection-circle-user']],
  ['algolia', ['algolia']],
  ['alicorn', ['alicorn']],
  ['alien', ['alien']],
  ['alien-8bit', ['alien-8bit']],
  ['alien-monster', ['alien-8bit']],
  ['align-center', ['align-center']],
  ['align-justify', ['align-justify']],
  ['align-left', ['align-left']],
  ['align-right', ['align-right']],
  ['align-slash', ['align-slash']],
  ['alipay', ['alipay']],
  ['alt', ['alt']],
  ['amazon', ['amazon']],
  ['amazon-pay', ['amazon-pay']],
  ['amilia', ['amilia']],
  ['amp-guitar', ['amp-guitar']],
  ['ampersand', ['ampersand']],
  ['anchor', ['anchor']],
  ['anchor-circle-check', ['anchor-circle-check']],
  ['anchor-circle-exclamation', ['anchor-circle-exclamation']],
  ['anchor-circle-xmark', ['anchor-circle-xmark']],
  ['anchor-lock', ['anchor-lock']],
  ['android', ['android']],
  ['angel', ['angel']],
  ['angellist', ['angellist']],
  ['angle', ['angle']],
  ['angle-90', ['angle-90']],
  ['angle-down', ['angle-down']],
  ['angle-left', ['angle-left']],
  ['angle-right', ['angle-right']],
  ['angle-up', ['angle-up']],
  ['angles-down', ['angles-down']],
  ['angle-double-down', ['angles-down']],
  ['angles-left', ['angles-left']],
  ['angle-double-left', ['angles-left']],
  ['angles-right', ['angles-right']],
  ['angle-double-right', ['angles-right']],
  ['angles-up', ['angles-up']],
  ['angle-double-up', ['angles-up']],
  ['angrycreative', ['angrycreative']],
  ['angular', ['angular']],
  ['ankh', ['ankh']],
  ['apartment', ['apartment']],
  ['aperture', ['aperture']],
  ['apostrophe', ['apostrophe']],
  ['app-store', ['app-store']],
  ['app-store-ios', ['app-store-ios']],
  ['apper', ['apper']],
  ['apple', ['apple']],
  ['apple-core', ['apple-core']],
  ['apple-pay', ['apple-pay']],
  ['apple-whole', ['apple-whole']],
  ['apple-alt', ['apple-whole']],
  ['archway', ['archway']],
  ['arrow-down', ['arrow-down']],
  ['arrow-down-1-9', ['arrow-down-1-9']],
  ['sort-numeric-asc', ['arrow-down-1-9']],
  ['sort-numeric-down', ['arrow-down-1-9']],
  ['arrow-down-9-1', ['arrow-down-9-1']],
  ['sort-numeric-desc', ['arrow-down-9-1']],
  ['sort-numeric-down-alt', ['arrow-down-9-1']],
  ['arrow-down-a-z', ['arrow-down-a-z']],
  ['sort-alpha-asc', ['arrow-down-a-z']],
  ['sort-alpha-down', ['arrow-down-a-z']],
  ['arrow-down-arrow-up', ['arrow-down-arrow-up']],
  ['sort-alt', ['arrow-down-arrow-up']],
  ['arrow-down-big-small', ['arrow-down-big-small']],
  ['sort-size-down', ['arrow-down-big-small']],
  ['arrow-down-from-dotted-line', ['arrow-down-from-dotted-line']],
  ['arrow-down-from-line', ['arrow-down-from-line']],
  ['arrow-from-top', ['arrow-down-from-line']],
  ['arrow-down-left', ['arrow-down-left']],
  ['arrow-down-left-and-arrow-up-right-to-center', ['arrow-down-left-and-arrow-up-right-to-center']],
  ['arrow-down-long', ['arrow-down-long']],
  ['long-arrow-down', ['arrow-down-long']],
  ['arrow-down-right', ['arrow-down-right']],
  ['arrow-down-short-wide', ['arrow-down-short-wide']],
  ['sort-amount-desc', ['arrow-down-short-wide']],
  ['sort-amount-down-alt', ['arrow-down-short-wide']],
  ['arrow-down-small-big', ['arrow-down-small-big']],
  ['sort-size-down-alt', ['arrow-down-small-big']],
  ['arrow-down-square-triangle', ['arrow-down-square-triangle']],
  ['sort-shapes-down-alt', ['arrow-down-square-triangle']],
  ['arrow-down-to-arc', ['arrow-down-to-arc']],
  ['arrow-down-to-bracket', ['arrow-down-to-bracket']],
  ['arrow-down-to-dotted-line', ['arrow-down-to-dotted-line']],
  ['arrow-down-to-line', ['arrow-down-to-line']],
  ['arrow-to-bottom', ['arrow-down-to-line']],
  ['arrow-down-to-square', ['arrow-down-to-square']],
  ['arrow-down-triangle-square', ['arrow-down-triangle-square']],
  ['sort-shapes-down', ['arrow-down-triangle-square']],
  ['arrow-down-up-across-line', ['arrow-down-up-across-line']],
  ['arrow-down-up-lock', ['arrow-down-up-lock']],
  ['arrow-down-wide-short', ['arrow-down-wide-short']],
  ['sort-amount-asc', ['arrow-down-wide-short']],
  ['sort-amount-down', ['arrow-down-wide-short']],
  ['arrow-down-z-a', ['arrow-down-z-a']],
  ['sort-alpha-desc', ['arrow-down-z-a']],
  ['sort-alpha-down-alt', ['arrow-down-z-a']],
  ['arrow-left', ['arrow-left']],
  ['arrow-left-from-line', ['arrow-left-from-line']],
  ['arrow-from-right', ['arrow-left-from-line']],
  ['arrow-left-long', ['arrow-left-long']],
  ['long-arrow-left', ['arrow-left-long']],
  ['arrow-left-long-to-line', ['arrow-left-long-to-line']],
  ['arrow-left-to-line', ['arrow-left-to-line']],
  ['arrow-to-left', ['arrow-left-to-line']],
  ['arrow-pointer', ['arrow-pointer']],
  ['mouse-pointer', ['arrow-pointer']],
  ['arrow-right', ['arrow-right']],
  ['arrow-right-arrow-left', ['arrow-right-arrow-left']],
  ['exchange', ['arrow-right-arrow-left']],
  ['arrow-right-from-arc', ['arrow-right-from-arc']],
  ['arrow-right-from-bracket', ['arrow-right-from-bracket']],
  ['sign-out', ['arrow-right-from-bracket']],
  ['arrow-right-from-line', ['arrow-right-from-line']],
  ['arrow-from-left', ['arrow-right-from-line']],
  ['arrow-right-long', ['arrow-right-long']],
  ['long-arrow-right', ['arrow-right-long']],
  ['arrow-right-long-to-line', ['arrow-right-long-to-line']],
  ['arrow-right-to-arc', ['arrow-right-to-arc']],
  ['arrow-right-to-bracket', ['arrow-right-to-bracket']],
  ['sign-in', ['arrow-right-to-bracket']],
  ['arrow-right-to-city', ['arrow-right-to-city']],
  ['arrow-right-to-line', ['arrow-right-to-line']],
  ['arrow-to-right', ['arrow-right-to-line']],
  ['arrow-rotate-left', ['arrow-rotate-left']],
  ['arrow-left-rotate', ['arrow-rotate-left']],
  ['arrow-rotate-back', ['arrow-rotate-left']],
  ['arrow-rotate-backward', ['arrow-rotate-left']],
  ['undo', ['arrow-rotate-left']],
  ['arrow-rotate-right', ['arrow-rotate-right']],
  ['arrow-right-rotate', ['arrow-rotate-right']],
  ['arrow-rotate-forward', ['arrow-rotate-right']],
  ['redo', ['arrow-rotate-right']],
  ['arrow-trend-down', ['arrow-trend-down']],
  ['arrow-trend-up', ['arrow-trend-up']],
  ['arrow-turn-down', ['arrow-turn-down']],
  ['level-down', ['arrow-turn-down']],
  ['arrow-turn-down-left', ['arrow-turn-down-left']],
  ['arrow-turn-down-right', ['arrow-turn-down-right']],
  ['arrow-turn-up', ['arrow-turn-up']],
  ['level-up', ['arrow-turn-up']],
  ['arrow-up', ['arrow-up']],
  ['arrow-up-1-9', ['arrow-up-1-9']],
  ['sort-numeric-up', ['arrow-up-1-9']],
  ['arrow-up-9-1', ['arrow-up-9-1']],
  ['sort-numeric-up-alt', ['arrow-up-9-1']],
  ['arrow-up-a-z', ['arrow-up-a-z']],
  ['sort-alpha-up', ['arrow-up-a-z']],
  ['arrow-up-arrow-down', ['arrow-up-arrow-down']],
  ['sort-up-down', ['arrow-up-arrow-down']],
  ['arrow-up-big-small', ['arrow-up-big-small']],
  ['sort-size-up', ['arrow-up-big-small']],
  ['arrow-up-from-arc', ['arrow-up-from-arc']],
  ['arrow-up-from-bracket', ['arrow-up-from-bracket']],
  ['arrow-up-from-dotted-line', ['arrow-up-from-dotted-line']],
  ['arrow-up-from-ground-water', ['arrow-up-from-ground-water']],
  ['arrow-up-from-line', ['arrow-up-from-line']],
  ['arrow-from-bottom', ['arrow-up-from-line']],
  ['arrow-up-from-square', ['arrow-up-from-square']],
  ['arrow-up-from-water-pump', ['arrow-up-from-water-pump']],
  ['arrow-up-left', ['arrow-up-left']],
  ['arrow-up-left-from-circle', ['arrow-up-left-from-circle']],
  ['arrow-up-long', ['arrow-up-long']],
  ['long-arrow-up', ['arrow-up-long']],
  ['arrow-up-right', ['arrow-up-right']],
  ['arrow-up-right-and-arrow-down-left-from-center', ['arrow-up-right-and-arrow-down-left-from-center']],
  ['arrow-up-right-dots', ['arrow-up-right-dots']],
  ['arrow-up-right-from-square', ['arrow-up-right-from-square']],
  ['external-link', ['arrow-up-right-from-square']],
  ['arrow-up-short-wide', ['arrow-up-short-wide']],
  ['sort-amount-up-alt', ['arrow-up-short-wide']],
  ['arrow-up-small-big', ['arrow-up-small-big']],
  ['sort-size-up-alt', ['arrow-up-small-big']],
  ['arrow-up-square-triangle', ['arrow-up-square-triangle']],
  ['sort-shapes-up-alt', ['arrow-up-square-triangle']],
  ['arrow-up-to-dotted-line', ['arrow-up-to-dotted-line']],
  ['arrow-up-to-line', ['arrow-up-to-line']],
  ['arrow-to-top', ['arrow-up-to-line']],
  ['arrow-up-triangle-square', ['arrow-up-triangle-square']],
  ['sort-shapes-up', ['arrow-up-triangle-square']],
  ['arrow-up-wide-short', ['arrow-up-wide-short']],
  ['sort-amount-up', ['arrow-up-wide-short']],
  ['arrow-up-z-a', ['arrow-up-z-a']],
  ['sort-alpha-up-alt', ['arrow-up-z-a']],
  ['arrows-cross', ['arrows-cross']],
  ['arrows-down-to-line', ['arrows-down-to-line']],
  ['arrows-down-to-people', ['arrows-down-to-people']],
  ['arrows-from-dotted-line', ['arrows-from-dotted-line']],
  ['arrows-from-line', ['arrows-from-line']],
  ['arrows-left-right', ['arrows-left-right']],
  ['arrows-h', ['arrows-left-right']],
  ['arrows-left-right-to-line', ['arrows-left-right-to-line']],
  ['arrows-maximize', ['arrows-maximize']],
  ['expand-arrows', ['arrows-maximize']],
  ['arrows-minimize', ['arrows-minimize']],
  ['compress-arrows', ['arrows-minimize']],
  ['arrows-repeat', ['arrows-repeat']],
  ['repeat-alt', ['arrows-repeat']],
  ['arrows-repeat-1', ['arrows-repeat-1']],
  ['repeat-1-alt', ['arrows-repeat-1']],
  ['arrows-retweet', ['arrows-retweet']],
  ['retweet-alt', ['arrows-retweet']],
  ['arrows-rotate', ['arrows-rotate']],
  ['refresh', ['arrows-rotate']],
  ['sync', ['arrows-rotate']],
  ['arrows-spin', ['arrows-spin']],
  ['arrows-split-up-and-left', ['arrows-split-up-and-left']],
  ['arrows-to-circle', ['arrows-to-circle']],
  ['arrows-to-dot', ['arrows-to-dot']],
  ['arrows-to-dotted-line', ['arrows-to-dotted-line']],
  ['arrows-to-eye', ['arrows-to-eye']],
  ['arrows-to-line', ['arrows-to-line']],
  ['arrows-turn-right', ['arrows-turn-right']],
  ['arrows-turn-to-dots', ['arrows-turn-to-dots']],
  ['arrows-up-down', ['arrows-up-down']],
  ['arrows-v', ['arrows-up-down']],
  ['arrows-up-down-left-right', ['arrows-up-down-left-right']],
  ['arrows', ['arrows-up-down-left-right']],
  ['arrows-up-to-line', ['arrows-up-to-line']],
  ['artstation', ['artstation']],
  ['asterisk', ['asterisk']],
  ['asymmetrik', ['asymmetrik']],
  ['at', ['at']],
  ['atlassian', ['atlassian']],
  ['atom', ['atom']],
  ['atom-simple', ['atom-simple']],
  ['atom-alt', ['atom-simple']],
  ['audible', ['audible']],
  ['audio-description', ['audio-description']],
  ['audio-description-slash', ['audio-description-slash']],
  ['austral-sign', ['austral-sign']],
  ['autoprefixer', ['autoprefixer']],
  ['avianex', ['avianex']],
  ['aviato', ['aviato']],
  ['avocado', ['avocado']],
  ['award', ['award']],
  ['award-simple', ['award-simple']],
  ['aws', ['aws']],
  ['axe', ['axe']],
  ['axe-battle', ['axe-battle']],
  ['b', ['b']],
  ['baby', ['baby']],
  ['baby-carriage', ['baby-carriage']],
  ['carriage-baby', ['baby-carriage']],
  ['backpack', ['backpack']],
  ['backward', ['backward']],
  ['backward-fast', ['backward-fast']],
  ['fast-backward', ['backward-fast']],
  ['backward-step', ['backward-step']],
  ['step-backward', ['backward-step']],
  ['bacon', ['bacon']],
  ['bacteria', ['bacteria']],
  ['bacterium', ['bacterium']],
  ['badge', ['badge']],
  ['badge-check', ['badge-check']],
  ['badge-dollar', ['badge-dollar']],
  ['badge-percent', ['badge-percent']],
  ['badge-sheriff', ['badge-sheriff']],
  ['badger-honey', ['badger-honey']],
  ['badminton', ['badminton']],
  ['bag-shopping', ['bag-shopping']],
  ['shopping-bag', ['bag-shopping']],
  ['bagel', ['bagel']],
  ['bags-shopping', ['bags-shopping']],
  ['baguette', ['baguette']],
  ['bahai', ['bahai']],
  ['haykal', ['bahai']],
  ['baht-sign', ['baht-sign']],
  ['ball-pile', ['ball-pile']],
  ['balloon', ['balloon']],
  ['balloons', ['balloons']],
  ['ballot', ['ballot']],
  ['ballot-check', ['ballot-check']],
  ['ban', ['ban']],
  ['cancel', ['ban']],
  ['ban-bug', ['ban-bug']],
  ['debug', ['ban-bug']],
  ['ban-parking', ['ban-parking']],
  ['parking-circle-slash', ['ban-parking']],
  ['ban-smoking', ['ban-smoking']],
  ['smoking-ban', ['ban-smoking']],
  ['banana', ['banana']],
  ['bandage', ['bandage']],
  ['band-aid', ['bandage']],
  ['bandcamp', ['bandcamp']],
  ['bangladeshi-taka-sign', ['bangladeshi-taka-sign']],
  ['banjo', ['banjo']],
  ['barcode', ['barcode']],
  ['barcode-read', ['barcode-read']],
  ['barcode-scan', ['barcode-scan']],
  ['bars', ['bars']],
  ['navicon', ['bars']],
  ['bars-filter', ['bars-filter']],
  ['bars-progress', ['bars-progress']],
  ['tasks-alt', ['bars-progress']],
  ['bars-sort', ['bars-sort']],
  ['bars-staggered', ['bars-staggered']],
  ['reorder', ['bars-staggered']],
  ['stream', ['bars-staggered']],
  ['baseball', ['baseball']],
  ['baseball-ball', ['baseball']],
  ['baseball-bat-ball', ['baseball-bat-ball']],
  ['basket-shopping', ['basket-shopping']],
  ['shopping-basket', ['basket-shopping']],
  ['basket-shopping-simple', ['basket-shopping-simple']],
  ['shopping-basket-alt', ['basket-shopping-simple']],
  ['basketball', ['basketball']],
  ['basketball-ball', ['basketball']],
  ['basketball-hoop', ['basketball-hoop']],
  ['bat', ['bat']],
  ['bath', ['bath']],
  ['bathtub', ['bath']],
  ['battery-bolt', ['battery-bolt']],
  ['battery-empty', ['battery-empty']],
  ['battery-0', ['battery-empty']],
  ['battery-exclamation', ['battery-exclamation']],
  ['battery-full', ['battery-full']],
  ['battery', ['battery-full']],
  ['battery-5', ['battery-full']],
  ['battery-half', ['battery-half']],
  ['battery-3', ['battery-half']],
  ['battery-low', ['battery-low']],
  ['battery-1', ['battery-low']],
  ['battery-quarter', ['battery-quarter']],
  ['battery-2', ['battery-quarter']],
  ['battery-slash', ['battery-slash']],
  ['battery-three-quarters', ['battery-three-quarters']],
  ['battery-4', ['battery-three-quarters']],
  ['battle-net', ['battle-net']],
  ['bed', ['bed']],
  ['bed-bunk', ['bed-bunk']],
  ['bed-empty', ['bed-empty']],
  ['bed-front', ['bed-front']],
  ['bed-alt', ['bed-front']],
  ['bed-pulse', ['bed-pulse']],
  ['procedures', ['bed-pulse']],
  ['bee', ['bee']],
  ['beer-mug', ['beer-mug']],
  ['beer-foam', ['beer-mug']],
  ['beer-mug-empty', ['beer-mug-empty']],
  ['beer', ['beer-mug-empty']],
  ['behance', ['behance']],
  ['bell', ['bell']],
  ['bell-concierge', ['bell-concierge']],
  ['concierge-bell', ['bell-concierge']],
  ['bell-exclamation', ['bell-exclamation']],
  ['bell-on', ['bell-on']],
  ['bell-plus', ['bell-plus']],
  ['bell-school', ['bell-school']],
  ['bell-school-slash', ['bell-school-slash']],
  ['bell-slash', ['bell-slash']],
  ['bells', ['bells']],
  ['bench-tree', ['bench-tree']],
  ['bezier-curve', ['bezier-curve']],
  ['bicycle', ['bicycle']],
  ['bilibili', ['bilibili']],
  ['bimobject', ['bimobject']],
  ['binary', ['binary']],
  ['binary-circle-check', ['binary-circle-check']],
  ['binary-lock', ['binary-lock']],
  ['binary-slash', ['binary-slash']],
  ['binoculars', ['binoculars']],
  ['biohazard', ['biohazard']],
  ['bird', ['bird']],
  ['bitbucket', ['bitbucket']],
  ['bitcoin', ['bitcoin']],
  ['bitcoin-sign', ['bitcoin-sign']],
  ['bity', ['bity']],
  ['black-tie', ['black-tie']],
  ['blackberry', ['blackberry']],
  ['blanket', ['blanket']],
  ['blanket-fire', ['blanket-fire']],
  ['blender', ['blender']],
  ['blender-phone', ['blender-phone']],
  ['blinds', ['blinds']],
  ['blinds-open', ['blinds-open']],
  ['blinds-raised', ['blinds-raised']],
  ['block', ['block']],
  ['block-brick', ['block-brick']],
  ['wall-brick', ['block-brick']],
  ['block-brick-fire', ['block-brick-fire']],
  ['firewall', ['block-brick-fire']],
  ['block-question', ['block-question']],
  ['block-quote', ['block-quote']],
  ['blog', ['blog']],
  ['blogger', ['blogger']],
  ['blogger-b', ['blogger-b']],
  ['blueberries', ['blueberries']],
  ['bluetooth', ['bluetooth']],
  ['bluetooth-b', ['bluetooth-b']],
  ['bold', ['bold']],
  ['bolt', ['bolt']],
  ['zap', ['bolt']],
  ['bolt-auto', ['bolt-auto']],
  ['bolt-lightning', ['bolt-lightning']],
  ['bolt-slash', ['bolt-slash']],
  ['bomb', ['bomb']],
  ['bone', ['bone']],
  ['bone-break', ['bone-break']],
  ['bong', ['bong']],
  ['book', ['book']],
  ['book-arrow-right', ['book-arrow-right']],
  ['book-arrow-up', ['book-arrow-up']],
  ['book-atlas', ['book-atlas']],
  ['atlas', ['book-atlas']],
  ['book-bible', ['book-bible']],
  ['bible', ['book-bible']],
  ['book-blank', ['book-blank']],
  ['book-alt', ['book-blank']],
  ['book-bookmark', ['book-bookmark']],
  ['book-circle-arrow-right', ['book-circle-arrow-right']],
  ['book-circle-arrow-up', ['book-circle-arrow-up']],
  ['book-copy', ['book-copy']],
  ['book-font', ['book-font']],
  ['book-heart', ['book-heart']],
  ['book-journal-whills', ['book-journal-whills']],
  ['journal-whills', ['book-journal-whills']],
  ['book-medical', ['book-medical']],
  ['book-open', ['book-open']],
  ['book-open-cover', ['book-open-cover']],
  ['book-open-alt', ['book-open-cover']],
  ['book-open-reader', ['book-open-reader']],
  ['book-reader', ['book-open-reader']],
  ['book-quran', ['book-quran']],
  ['quran', ['book-quran']],
  ['book-section', ['book-section']],
  ['book-law', ['book-section']],
  ['book-skull', ['book-skull']],
  ['book-dead', ['book-skull']],
  ['book-sparkles', ['book-sparkles']],
  ['book-spells', ['book-sparkles']],
  ['book-tanakh', ['book-tanakh']],
  ['tanakh', ['book-tanakh']],
  ['book-user', ['book-user']],
  ['bookmark', ['bookmark']],
  ['bookmark-slash', ['bookmark-slash']],
  ['books', ['books']],
  ['books-medical', ['books-medical']],
  ['boombox', ['boombox']],
  ['boot', ['boot']],
  ['boot-heeled', ['boot-heeled']],
  ['booth-curtain', ['booth-curtain']],
  ['bootstrap', ['bootstrap']],
  ['border-all', ['border-all']],
  ['border-bottom', ['border-bottom']],
  ['border-bottom-right', ['border-bottom-right']],
  ['border-style-alt', ['border-bottom-right']],
  ['border-center-h', ['border-center-h']],
  ['border-center-v', ['border-center-v']],
  ['border-inner', ['border-inner']],
  ['border-left', ['border-left']],
  ['border-none', ['border-none']],
  ['border-outer', ['border-outer']],
  ['border-right', ['border-right']],
  ['border-top', ['border-top']],
  ['border-top-left', ['border-top-left']],
  ['border-style', ['border-top-left']],
  ['bore-hole', ['bore-hole']],
  ['bots', ['bots']],
  ['bottle-droplet', ['bottle-droplet']],
  ['bottle-water', ['bottle-water']],
  ['bow-arrow', ['bow-arrow']],
  ['bowl-chopsticks', ['bowl-chopsticks']],
  ['bowl-chopsticks-noodles', ['bowl-chopsticks-noodles']],
  ['bowl-food', ['bowl-food']],
  ['bowl-hot', ['bowl-hot']],
  ['soup', ['bowl-hot']],
  ['bowl-rice', ['bowl-rice']],
  ['bowl-scoop', ['bowl-scoop']],
  ['bowl-shaved-ice', ['bowl-scoop']],
  ['bowl-scoops', ['bowl-scoops']],
  ['bowl-soft-serve', ['bowl-soft-serve']],
  ['bowl-spoon', ['bowl-spoon']],
  ['bowling-ball', ['bowling-ball']],
  ['bowling-ball-pin', ['bowling-ball-pin']],
  ['bowling-pins', ['bowling-pins']],
  ['box', ['box']],
  ['box-archive', ['box-archive']],
  ['archive', ['box-archive']],
  ['box-ballot', ['box-ballot']],
  ['box-check', ['box-check']],
  ['box-circle-check', ['box-circle-check']],
  ['box-dollar', ['box-dollar']],
  ['box-usd', ['box-dollar']],
  ['box-heart', ['box-heart']],
  ['box-open', ['box-open']],
  ['box-open-full', ['box-open-full']],
  ['box-full', ['box-open-full']],
  ['box-taped', ['box-taped']],
  ['box-alt', ['box-taped']],
  ['box-tissue', ['box-tissue']],
  ['boxes-packing', ['boxes-packing']],
  ['boxes-stacked', ['boxes-stacked']],
  ['boxes', ['boxes-stacked']],
  ['boxes-alt', ['boxes-stacked']],
  ['boxing-glove', ['boxing-glove']],
  ['glove-boxing', ['boxing-glove']],
  ['bracket-curly', ['bracket-curly']],
  ['bracket-curly-left', ['bracket-curly']],
  ['bracket-curly-right', ['bracket-curly-right']],
  ['bracket-round', ['bracket-round']],
  ['parenthesis', ['bracket-round']],
  ['bracket-round-right', ['bracket-round-right']],
  ['bracket-square', ['bracket-square']],
  ['bracket', ['bracket-square']],
  ['bracket-left', ['bracket-square']],
  ['bracket-square-right', ['bracket-square-right']],
  ['brackets-curly', ['brackets-curly']],
  ['brackets-round', ['brackets-round']],
  ['parentheses', ['brackets-round']],
  ['brackets-square', ['brackets-square']],
  ['brackets', ['brackets-square']],
  ['braille', ['braille']],
  ['brain', ['brain']],
  ['brain-arrow-curved-right', ['brain-arrow-curved-right']],
  ['mind-share', ['brain-arrow-curved-right']],
  ['brain-circuit', ['brain-circuit']],
  ['brake-warning', ['brake-warning']],
  ['brazilian-real-sign', ['brazilian-real-sign']],
  ['bread-loaf', ['bread-loaf']],
  ['bread-slice', ['bread-slice']],
  ['bread-slice-butter', ['bread-slice-butter']],
  ['bridge', ['bridge']],
  ['bridge-circle-check', ['bridge-circle-check']],
  ['bridge-circle-exclamation', ['bridge-circle-exclamation']],
  ['bridge-circle-xmark', ['bridge-circle-xmark']],
  ['bridge-lock', ['bridge-lock']],
  ['bridge-suspension', ['bridge-suspension']],
  ['bridge-water', ['bridge-water']],
  ['briefcase', ['briefcase']],
  ['briefcase-arrow-right', ['briefcase-arrow-right']],
  ['briefcase-blank', ['briefcase-blank']],
  ['briefcase-medical', ['briefcase-medical']],
  ['brightness', ['brightness']],
  ['brightness-low', ['brightness-low']],
  ['bring-forward', ['bring-forward']],
  ['bring-front', ['bring-front']],
  ['broccoli', ['broccoli']],
  ['broom', ['broom']],
  ['broom-ball', ['broom-ball']],
  ['quidditch', ['broom-ball']],
  ['quidditch-broom-ball', ['broom-ball']],
  ['browser', ['browser']],
  ['browsers', ['browsers']],
  ['brush', ['brush']],
  ['btc', ['btc']],
  ['bucket', ['bucket']],
  ['buffer', ['buffer']],
  ['bug', ['bug']],
  ['bug-slash', ['bug-slash']],
  ['bugs', ['bugs']],
  ['building', ['building']],
  ['building-circle-arrow-right', ['building-circle-arrow-right']],
  ['building-circle-check', ['building-circle-check']],
  ['building-circle-exclamation', ['building-circle-exclamation']],
  ['building-circle-xmark', ['building-circle-xmark']],
  ['building-columns', ['building-columns']],
  ['bank', ['building-columns']],
  ['institution', ['building-columns']],
  ['museum', ['building-columns']],
  ['university', ['building-columns']],
  ['building-flag', ['building-flag']],
  ['building-lock', ['building-lock']],
  ['building-ngo', ['building-ngo']],
  ['building-shield', ['building-shield']],
  ['building-un', ['building-un']],
  ['building-user', ['building-user']],
  ['building-wheat', ['building-wheat']],
  ['buildings', ['buildings']],
  ['bullhorn', ['bullhorn']],
  ['bullseye', ['bullseye']],
  ['bullseye-arrow', ['bullseye-arrow']],
  ['bullseye-pointer', ['bullseye-pointer']],
  ['burger', ['burger']],
  ['hamburger', ['burger']],
  ['burger-cheese', ['burger-cheese']],
  ['cheeseburger', ['burger-cheese']],
  ['burger-fries', ['burger-fries']],
  ['burger-glass', ['burger-glass']],
  ['burger-lettuce', ['burger-lettuce']],
  ['burger-soda', ['burger-soda']],
  ['buromobelexperte', ['buromobelexperte']],
  ['burrito', ['burrito']],
  ['burst', ['burst']],
  ['bus', ['bus']],
  ['bus-school', ['bus-school']],
  ['bus-simple', ['bus-simple']],
  ['bus-alt', ['bus-simple']],
  ['business-time', ['business-time']],
  ['briefcase-clock', ['business-time']],
  ['butter', ['butter']],
  ['buy-n-large', ['buy-n-large']],
  ['buysellads', ['buysellads']],
  ['c', ['c']],
  ['cabin', ['cabin']],
  ['cabinet-filing', ['cabinet-filing']],
  ['cable-car', ['cable-car']],
  ['tram', ['cable-car']],
  ['cactus', ['cactus']],
  ['cake-candles', ['cake-candles']],
  ['birthday-cake', ['cake-candles']],
  ['cake', ['cake-candles']],
  ['cake-slice', ['cake-slice']],
  ['shortcake', ['cake-slice']],
  ['calculator', ['calculator']],
  ['calculator-simple', ['calculator-simple']],
  ['calculator-alt', ['calculator-simple']],
  ['calendar', ['calendar']],
  ['calendar-arrow-down', ['calendar-arrow-down']],
  ['calendar-download', ['calendar-arrow-down']],
  ['calendar-arrow-up', ['calendar-arrow-up']],
  ['calendar-upload', ['calendar-arrow-up']],
  ['calendar-check', ['calendar-check']],
  ['calendar-circle-exclamation', ['calendar-circle-exclamation']],
  ['calendar-circle-minus', ['calendar-circle-minus']],
  ['calendar-circle-plus', ['calendar-circle-plus']],
  ['calendar-circle-user', ['calendar-circle-user']],
  ['calendar-clock', ['calendar-clock']],
  ['calendar-time', ['calendar-clock']],
  ['calendar-day', ['calendar-day']],
  ['calendar-days', ['calendar-days']],
  ['calendar-alt', ['calendar-days']],
  ['calendar-exclamation', ['calendar-exclamation']],
  ['calendar-heart', ['calendar-heart']],
  ['calendar-image', ['calendar-image']],
  ['calendar-lines', ['calendar-lines']],
  ['calendar-note', ['calendar-lines']],
  ['calendar-lines-pen', ['calendar-lines-pen']],
  ['calendar-minus', ['calendar-minus']],
  ['calendar-pen', ['calendar-pen']],
  ['calendar-edit', ['calendar-pen']],
  ['calendar-plus', ['calendar-plus']],
  ['calendar-range', ['calendar-range']],
  ['calendar-star', ['calendar-star']],
  ['calendar-week', ['calendar-week']],
  ['calendar-xmark', ['calendar-xmark']],
  ['calendar-times', ['calendar-xmark']],
  ['calendars', ['calendars']],
  ['camcorder', ['camcorder']],
  ['video-handheld', ['camcorder']],
  ['camera', ['camera']],
  ['camera-alt', ['camera']],
  ['camera-cctv', ['camera-cctv']],
  ['cctv', ['camera-cctv']],
  ['camera-movie', ['camera-movie']],
  ['camera-polaroid', ['camera-polaroid']],
  ['camera-retro', ['camera-retro']],
  ['camera-rotate', ['camera-rotate']],
  ['camera-security', ['camera-security']],
  ['camera-home', ['camera-security']],
  ['camera-slash', ['camera-slash']],
  ['camera-viewfinder', ['camera-viewfinder']],
  ['screenshot', ['camera-viewfinder']],
  ['camera-web', ['camera-web']],
  ['webcam', ['camera-web']],
  ['camera-web-slash', ['camera-web-slash']],
  ['webcam-slash', ['camera-web-slash']],
  ['campfire', ['campfire']],
  ['campground', ['campground']],
  ['can-food', ['can-food']],
  ['canadian-maple-leaf', ['canadian-maple-leaf']],
  ['candle-holder', ['candle-holder']],
  ['candy', ['candy']],
  ['candy-bar', ['candy-bar']],
  ['chocolate-bar', ['candy-bar']],
  ['candy-cane', ['candy-cane']],
  ['candy-corn', ['candy-corn']],
  ['cannabis', ['cannabis']],
  ['capsules', ['capsules']],
  ['car', ['car']],
  ['automobile', ['car']],
  ['car-battery', ['car-battery']],
  ['battery-car', ['car-battery']],
  ['car-bolt', ['car-bolt']],
  ['car-building', ['car-building']],
  ['car-bump', ['car-bump']],
  ['car-burst', ['car-burst']],
  ['car-crash', ['car-burst']],
  ['car-bus', ['car-bus']],
  ['car-circle-bolt', ['car-circle-bolt']],
  ['car-garage', ['car-garage']],
  ['car-mirrors', ['car-mirrors']],
  ['car-on', ['car-on']],
  ['car-rear', ['car-rear']],
  ['car-alt', ['car-rear']],
  ['car-side', ['car-side']],
  ['car-side-bolt', ['car-side-bolt']],
  ['car-tilt', ['car-tilt']],
  ['car-tunnel', ['car-tunnel']],
  ['car-wash', ['car-wash']],
  ['car-wrench', ['car-wrench']],
  ['car-mechanic', ['car-wrench']],
  ['caravan', ['caravan']],
  ['caravan-simple', ['caravan-simple']],
  ['caravan-alt', ['caravan-simple']],
  ['card-club', ['card-club']],
  ['card-diamond', ['card-diamond']],
  ['card-heart', ['card-heart']],
  ['card-spade', ['card-spade']],
  ['cards', ['cards']],
  ['cards-blank', ['cards-blank']],
  ['caret-down', ['caret-down']],
  ['caret-left', ['caret-left']],
  ['caret-right', ['caret-right']],
  ['caret-up', ['caret-up']],
  ['carrot', ['carrot']],
  ['cars', ['cars']],
  ['cart-arrow-down', ['cart-arrow-down']],
  ['cart-arrow-up', ['cart-arrow-up']],
  ['cart-circle-arrow-down', ['cart-circle-arrow-down']],
  ['cart-circle-arrow-up', ['cart-circle-arrow-up']],
  ['cart-circle-check', ['cart-circle-check']],
  ['cart-circle-exclamation', ['cart-circle-exclamation']],
  ['cart-circle-plus', ['cart-circle-plus']],
  ['cart-circle-xmark', ['cart-circle-xmark']],
  ['cart-flatbed', ['cart-flatbed']],
  ['dolly-flatbed', ['cart-flatbed']],
  ['cart-flatbed-boxes', ['cart-flatbed-boxes']],
  ['dolly-flatbed-alt', ['cart-flatbed-boxes']],
  ['cart-flatbed-empty', ['cart-flatbed-empty']],
  ['dolly-flatbed-empty', ['cart-flatbed-empty']],
  ['cart-flatbed-suitcase', ['cart-flatbed-suitcase']],
  ['luggage-cart', ['cart-flatbed-suitcase']],
  ['cart-minus', ['cart-minus']],
  ['cart-plus', ['cart-plus']],
  ['cart-shopping', ['cart-shopping']],
  ['shopping-cart', ['cart-shopping']],
  ['cart-shopping-fast', ['cart-shopping-fast']],
  ['cart-xmark', ['cart-xmark']],
  ['cash-register', ['cash-register']],
  ['cassette-betamax', ['cassette-betamax']],
  ['betamax', ['cassette-betamax']],
  ['cassette-tape', ['cassette-tape']],
  ['cassette-vhs', ['cassette-vhs']],
  ['vhs', ['cassette-vhs']],
  ['castle', ['castle']],
  ['cat', ['cat']],
  ['cat-space', ['cat-space']],
  ['cauldron', ['cauldron']],
  ['cc-amazon-pay', ['cc-amazon-pay']],
  ['cc-amex', ['cc-amex']],
  ['cc-apple-pay', ['cc-apple-pay']],
  ['cc-diners-club', ['cc-diners-club']],
  ['cc-discover', ['cc-discover']],
  ['cc-jcb', ['cc-jcb']],
  ['cc-mastercard', ['cc-mastercard']],
  ['cc-paypal', ['cc-paypal']],
  ['cc-stripe', ['cc-stripe']],
  ['cc-visa', ['cc-visa']],
  ['cedi-sign', ['cedi-sign']],
  ['cent-sign', ['cent-sign']],
  ['centercode', ['centercode']],
  ['centos', ['centos']],
  ['certificate', ['certificate']],
  ['chair', ['chair']],
  ['chair-office', ['chair-office']],
  ['chalkboard', ['chalkboard']],
  ['blackboard', ['chalkboard']],
  ['chalkboard-user', ['chalkboard-user']],
  ['chalkboard-teacher', ['chalkboard-user']],
  ['champagne-glass', ['champagne-glass']],
  ['glass-champagne', ['champagne-glass']],
  ['champagne-glasses', ['champagne-glasses']],
  ['glass-cheers', ['champagne-glasses']],
  ['charging-station', ['charging-station']],
  ['chart-area', ['chart-area']],
  ['area-chart', ['chart-area']],
  ['chart-bar', ['chart-bar']],
  ['bar-chart', ['chart-bar']],
  ['chart-bullet', ['chart-bullet']],
  ['chart-candlestick', ['chart-candlestick']],
  ['chart-column', ['chart-column']],
  ['chart-gantt', ['chart-gantt']],
  ['chart-line', ['chart-line']],
  ['line-chart', ['chart-line']],
  ['chart-line-down', ['chart-line-down']],
  ['chart-line-up', ['chart-line-up']],
  ['chart-mixed', ['chart-mixed']],
  ['analytics', ['chart-mixed']],
  ['chart-network', ['chart-network']],
  ['chart-pie', ['chart-pie']],
  ['pie-chart', ['chart-pie']],
  ['chart-pie-simple', ['chart-pie-simple']],
  ['chart-pie-alt', ['chart-pie-simple']],
  ['chart-pyramid', ['chart-pyramid']],
  ['chart-radar', ['chart-radar']],
  ['chart-scatter', ['chart-scatter']],
  ['chart-scatter-3d', ['chart-scatter-3d']],
  ['chart-scatter-bubble', ['chart-scatter-bubble']],
  ['chart-simple', ['chart-simple']],
  ['chart-simple-horizontal', ['chart-simple-horizontal']],
  ['chart-tree-map', ['chart-tree-map']],
  ['chart-user', ['chart-user']],
  ['user-chart', ['chart-user']],
  ['chart-waterfall', ['chart-waterfall']],
  ['check', ['check']],
  ['check-double', ['check-double']],
  ['check-to-slot', ['check-to-slot']],
  ['vote-yea', ['check-to-slot']],
  ['cheese', ['cheese']],
  ['cheese-swiss', ['cheese-swiss']],
  ['cherries', ['cherries']],
  ['chess', ['chess']],
  ['chess-bishop', ['chess-bishop']],
  ['chess-bishop-piece', ['chess-bishop-piece']],
  ['chess-bishop-alt', ['chess-bishop-piece']],
  ['chess-board', ['chess-board']],
  ['chess-clock', ['chess-clock']],
  ['chess-clock-flip', ['chess-clock-flip']],
  ['chess-clock-alt', ['chess-clock-flip']],
  ['chess-king', ['chess-king']],
  ['chess-king-piece', ['chess-king-piece']],
  ['chess-king-alt', ['chess-king-piece']],
  ['chess-knight', ['chess-knight']],
  ['chess-knight-piece', ['chess-knight-piece']],
  ['chess-knight-alt', ['chess-knight-piece']],
  ['chess-pawn', ['chess-pawn']],
  ['chess-pawn-piece', ['chess-pawn-piece']],
  ['chess-pawn-alt', ['chess-pawn-piece']],
  ['chess-queen', ['chess-queen']],
  ['chess-queen-piece', ['chess-queen-piece']],
  ['chess-queen-alt', ['chess-queen-piece']],
  ['chess-rook', ['chess-rook']],
  ['chess-rook-piece', ['chess-rook-piece']],
  ['chess-rook-alt', ['chess-rook-piece']],
  ['chestnut', ['chestnut']],
  ['chevron-down', ['chevron-down']],
  ['chevron-left', ['chevron-left']],
  ['chevron-right', ['chevron-right']],
  ['chevron-up', ['chevron-up']],
  ['chevrons-down', ['chevrons-down']],
  ['chevron-double-down', ['chevrons-down']],
  ['chevrons-left', ['chevrons-left']],
  ['chevron-double-left', ['chevrons-left']],
  ['chevrons-right', ['chevrons-right']],
  ['chevron-double-right', ['chevrons-right']],
  ['chevrons-up', ['chevrons-up']],
  ['chevron-double-up', ['chevrons-up']],
  ['child', ['child']],
  ['child-dress', ['child-dress']],
  ['child-reaching', ['child-reaching']],
  ['child-rifle', ['child-rifle']],
  ['children', ['children']],
  ['chimney', ['chimney']],
  ['chopsticks', ['chopsticks']],
  ['chrome', ['chrome']],
  ['chromecast', ['chromecast']],
  ['church', ['church']],
  ['circle', ['circle']],
  ['circle-0', ['circle-0']],
  ['circle-1', ['circle-1']],
  ['circle-2', ['circle-2']],
  ['circle-3', ['circle-3']],
  ['circle-4', ['circle-4']],
  ['circle-5', ['circle-5']],
  ['circle-6', ['circle-6']],
  ['circle-7', ['circle-7']],
  ['circle-8', ['circle-8']],
  ['circle-9', ['circle-9']],
  ['circle-a', ['circle-a']],
  ['circle-ampersand', ['circle-ampersand']],
  ['circle-arrow-down', ['circle-arrow-down']],
  ['arrow-circle-down', ['circle-arrow-down']],
  ['circle-arrow-down-left', ['circle-arrow-down-left']],
  ['circle-arrow-down-right', ['circle-arrow-down-right']],
  ['circle-arrow-left', ['circle-arrow-left']],
  ['arrow-circle-left', ['circle-arrow-left']],
  ['circle-arrow-right', ['circle-arrow-right']],
  ['arrow-circle-right', ['circle-arrow-right']],
  ['circle-arrow-up', ['circle-arrow-up']],
  ['arrow-circle-up', ['circle-arrow-up']],
  ['circle-arrow-up-left', ['circle-arrow-up-left']],
  ['circle-arrow-up-right', ['circle-arrow-up-right']],
  ['circle-b', ['circle-b']],
  ['circle-bolt', ['circle-bolt']],
  ['circle-book-open', ['circle-book-open']],
  ['book-circle', ['circle-book-open']],
  ['circle-bookmark', ['circle-bookmark']],
  ['bookmark-circle', ['circle-bookmark']],
  ['circle-c', ['circle-c']],
  ['circle-calendar', ['circle-calendar']],
  ['calendar-circle', ['circle-calendar']],
  ['circle-camera', ['circle-camera']],
  ['camera-circle', ['circle-camera']],
  ['circle-caret-down', ['circle-caret-down']],
  ['caret-circle-down', ['circle-caret-down']],
  ['circle-caret-left', ['circle-caret-left']],
  ['caret-circle-left', ['circle-caret-left']],
  ['circle-caret-right', ['circle-caret-right']],
  ['caret-circle-right', ['circle-caret-right']],
  ['circle-caret-up', ['circle-caret-up']],
  ['caret-circle-up', ['circle-caret-up']],
  ['circle-check', ['circle-check']],
  ['check-circle', ['circle-check']],
  ['circle-chevron-down', ['circle-chevron-down']],
  ['chevron-circle-down', ['circle-chevron-down']],
  ['circle-chevron-left', ['circle-chevron-left']],
  ['chevron-circle-left', ['circle-chevron-left']],
  ['circle-chevron-right', ['circle-chevron-right']],
  ['chevron-circle-right', ['circle-chevron-right']],
  ['circle-chevron-up', ['circle-chevron-up']],
  ['chevron-circle-up', ['circle-chevron-up']],
  ['circle-d', ['circle-d']],
  ['circle-dashed', ['circle-dashed']],
  ['circle-divide', ['circle-divide']],
  ['circle-dollar', ['circle-dollar']],
  ['dollar-circle', ['circle-dollar']],
  ['usd-circle', ['circle-dollar']],
  ['circle-dollar-to-slot', ['circle-dollar-to-slot']],
  ['donate', ['circle-dollar-to-slot']],
  ['circle-dot', ['circle-dot']],
  ['dot-circle', ['circle-dot']],
  ['circle-down', ['circle-down']],
  ['arrow-alt-circle-down', ['circle-down']],
  ['circle-down-left', ['circle-down-left']],
  ['circle-down-right', ['circle-down-right']],
  ['circle-e', ['circle-e']],
  ['circle-ellipsis', ['circle-ellipsis']],
  ['circle-ellipsis-vertical', ['circle-ellipsis-vertical']],
  ['circle-envelope', ['circle-envelope']],
  ['envelope-circle', ['circle-envelope']],
  ['circle-exclamation', ['circle-exclamation']],
  ['exclamation-circle', ['circle-exclamation']],
  ['circle-exclamation-check', ['circle-exclamation-check']],
  ['circle-f', ['circle-f']],
  ['circle-g', ['circle-g']],
  ['circle-h', ['circle-h']],
  ['hospital-symbol', ['circle-h']],
  ['circle-half', ['circle-half']],
  ['circle-half-stroke', ['circle-half-stroke']],
  ['adjust', ['circle-half-stroke']],
  ['circle-heart', ['circle-heart']],
  ['heart-circle', ['circle-heart']],
  ['circle-i', ['circle-i']],
  ['circle-info', ['circle-info']],
  ['info-circle', ['circle-info']],
  ['circle-j', ['circle-j']],
  ['circle-k', ['circle-k']],
  ['circle-l', ['circle-l']],
  ['circle-left', ['circle-left']],
  ['arrow-alt-circle-left', ['circle-left']],
  ['circle-location-arrow', ['circle-location-arrow']],
  ['location-circle', ['circle-location-arrow']],
  ['circle-m', ['circle-m']],
  ['circle-microphone', ['circle-microphone']],
  ['microphone-circle', ['circle-microphone']],
  ['circle-microphone-lines', ['circle-microphone-lines']],
  ['microphone-circle-alt', ['circle-microphone-lines']],
  ['circle-minus', ['circle-minus']],
  ['minus-circle', ['circle-minus']],
  ['circle-n', ['circle-n']],
  ['circle-nodes', ['circle-nodes']],
  ['circle-notch', ['circle-notch']],
  ['circle-o', ['circle-o']],
  ['circle-p', ['circle-p']],
  ['circle-parking', ['circle-parking']],
  ['parking-circle', ['circle-parking']],
  ['circle-pause', ['circle-pause']],
  ['pause-circle', ['circle-pause']],
  ['circle-phone', ['circle-phone']],
  ['phone-circle', ['circle-phone']],
  ['circle-phone-flip', ['circle-phone-flip']],
  ['phone-circle-alt', ['circle-phone-flip']],
  ['circle-phone-hangup', ['circle-phone-hangup']],
  ['phone-circle-down', ['circle-phone-hangup']],
  ['circle-play', ['circle-play']],
  ['play-circle', ['circle-play']],
  ['circle-plus', ['circle-plus']],
  ['plus-circle', ['circle-plus']],
  ['circle-q', ['circle-q']],
  ['circle-quarter', ['circle-quarter']],
  ['circle-quarters', ['circle-quarters']],
  ['circle-question', ['circle-question']],
  ['question-circle', ['circle-question']],
  ['circle-r', ['circle-r']],
  ['circle-radiation', ['circle-radiation']],
  ['radiation-alt', ['circle-radiation']],
  ['circle-right', ['circle-right']],
  ['arrow-alt-circle-right', ['circle-right']],
  ['circle-s', ['circle-s']],
  ['circle-small', ['circle-small']],
  ['circle-sort', ['circle-sort']],
  ['sort-circle', ['circle-sort']],
  ['circle-sort-down', ['circle-sort-down']],
  ['sort-circle-down', ['circle-sort-down']],
  ['circle-sort-up', ['circle-sort-up']],
  ['sort-circle-up', ['circle-sort-up']],
  ['circle-star', ['circle-star']],
  ['star-circle', ['circle-star']],
  ['circle-stop', ['circle-stop']],
  ['stop-circle', ['circle-stop']],
  ['circle-t', ['circle-t']],
  ['circle-three-quarters', ['circle-three-quarters']],
  ['circle-trash', ['circle-trash']],
  ['trash-circle', ['circle-trash']],
  ['circle-u', ['circle-u']],
  ['circle-up', ['circle-up']],
  ['arrow-alt-circle-up', ['circle-up']],
  ['circle-up-left', ['circle-up-left']],
  ['circle-up-right', ['circle-up-right']],
  ['circle-user', ['circle-user']],
  ['user-circle', ['circle-user']],
  ['circle-v', ['circle-v']],
  ['circle-video', ['circle-video']],
  ['video-circle', ['circle-video']],
  ['circle-w', ['circle-w']],
  ['circle-waveform-lines', ['circle-waveform-lines']],
  ['waveform-circle', ['circle-waveform-lines']],
  ['circle-x', ['circle-x']],
  ['circle-xmark', ['circle-xmark']],
  ['times-circle', ['circle-xmark']],
  ['xmark-circle', ['circle-xmark']],
  ['circle-y', ['circle-y']],
  ['circle-z', ['circle-z']],
  ['citrus', ['citrus']],
  ['citrus-slice', ['citrus-slice']],
  ['city', ['city']],
  ['clapperboard', ['clapperboard']],
  ['clapperboard-play', ['clapperboard-play']],
  ['clarinet', ['clarinet']],
  ['claw-marks', ['claw-marks']],
  ['clipboard', ['clipboard']],
  ['clipboard-check', ['clipboard-check']],
  ['clipboard-list', ['clipboard-list']],
  ['clipboard-list-check', ['clipboard-list-check']],
  ['clipboard-medical', ['clipboard-medical']],
  ['clipboard-prescription', ['clipboard-prescription']],
  ['clipboard-question', ['clipboard-question']],
  ['clipboard-user', ['clipboard-user']],
  ['clock', ['clock']],
  ['clock-four', ['clock']],
  ['clock-desk', ['clock-desk']],
  ['clock-eight', ['clock-eight']],
  ['clock-eight-thirty', ['clock-eight-thirty']],
  ['clock-eleven', ['clock-eleven']],
  ['clock-eleven-thirty', ['clock-eleven-thirty']],
  ['clock-five', ['clock-five']],
  ['clock-five-thirty', ['clock-five-thirty']],
  ['clock-four-thirty', ['clock-four-thirty']],
  ['clock-nine', ['clock-nine']],
  ['clock-nine-thirty', ['clock-nine-thirty']],
  ['clock-one', ['clock-one']],
  ['clock-one-thirty', ['clock-one-thirty']],
  ['clock-rotate-left', ['clock-rotate-left']],
  ['history', ['clock-rotate-left']],
  ['clock-seven', ['clock-seven']],
  ['clock-seven-thirty', ['clock-seven-thirty']],
  ['clock-six', ['clock-six']],
  ['clock-six-thirty', ['clock-six-thirty']],
  ['clock-ten', ['clock-ten']],
  ['clock-ten-thirty', ['clock-ten-thirty']],
  ['clock-three', ['clock-three']],
  ['clock-three-thirty', ['clock-three-thirty']],
  ['clock-twelve', ['clock-twelve']],
  ['clock-twelve-thirty', ['clock-twelve-thirty']],
  ['clock-two', ['clock-two']],
  ['clock-two-thirty', ['clock-two-thirty']],
  ['clone', ['clone']],
  ['closed-captioning', ['closed-captioning']],
  ['closed-captioning-slash', ['closed-captioning-slash']],
  ['clothes-hanger', ['clothes-hanger']],
  ['cloud', ['cloud']],
  ['cloud-arrow-down', ['cloud-arrow-down']],
  ['cloud-download', ['cloud-arrow-down']],
  ['cloud-download-alt', ['cloud-arrow-down']],
  ['cloud-arrow-up', ['cloud-arrow-up']],
  ['cloud-upload', ['cloud-arrow-up']],
  ['cloud-upload-alt', ['cloud-arrow-up']],
  ['cloud-bolt', ['cloud-bolt']],
  ['thunderstorm', ['cloud-bolt']],
  ['cloud-bolt-moon', ['cloud-bolt-moon']],
  ['thunderstorm-moon', ['cloud-bolt-moon']],
  ['cloud-bolt-sun', ['cloud-bolt-sun']],
  ['thunderstorm-sun', ['cloud-bolt-sun']],
  ['cloud-check', ['cloud-check']],
  ['cloud-drizzle', ['cloud-drizzle']],
  ['cloud-exclamation', ['cloud-exclamation']],
  ['cloud-fog', ['cloud-fog']],
  ['fog', ['cloud-fog']],
  ['cloud-hail', ['cloud-hail']],
  ['cloud-hail-mixed', ['cloud-hail-mixed']],
  ['cloud-meatball', ['cloud-meatball']],
  ['cloud-minus', ['cloud-minus']],
  ['cloud-moon', ['cloud-moon']],
  ['cloud-moon-rain', ['cloud-moon-rain']],
  ['cloud-music', ['cloud-music']],
  ['cloud-plus', ['cloud-plus']],
  ['cloud-question', ['cloud-question']],
  ['cloud-rain', ['cloud-rain']],
  ['cloud-rainbow', ['cloud-rainbow']],
  ['cloud-showers', ['cloud-showers']],
  ['cloud-showers-heavy', ['cloud-showers-heavy']],
  ['cloud-showers-water', ['cloud-showers-water']],
  ['cloud-slash', ['cloud-slash']],
  ['cloud-sleet', ['cloud-sleet']],
  ['cloud-snow', ['cloud-snow']],
  ['cloud-sun', ['cloud-sun']],
  ['cloud-sun-rain', ['cloud-sun-rain']],
  ['cloud-word', ['cloud-word']],
  ['cloud-xmark', ['cloud-xmark']],
  ['cloudflare', ['cloudflare']],
  ['clouds', ['clouds']],
  ['clouds-moon', ['clouds-moon']],
  ['clouds-sun', ['clouds-sun']],
  ['cloudscale', ['cloudscale']],
  ['cloudsmith', ['cloudsmith']],
  ['cloudversify', ['cloudversify']],
  ['clover', ['clover']],
  ['club', ['club']],
  ['cmplid', ['cmplid']],
  ['coconut', ['coconut']],
  ['code', ['code']],
  ['code-branch', ['code-branch']],
  ['code-commit', ['code-commit']],
  ['code-compare', ['code-compare']],
  ['code-fork', ['code-fork']],
  ['code-merge', ['code-merge']],
  ['code-pull-request', ['code-pull-request']],
  ['code-pull-request-closed', ['code-pull-request-closed']],
  ['code-pull-request-draft', ['code-pull-request-draft']],
  ['code-simple', ['code-simple']],
  ['codepen', ['codepen']],
  ['codiepie', ['codiepie']],
  ['coffee-bean', ['coffee-bean']],
  ['coffee-beans', ['coffee-beans']],
  ['coffee-pot', ['coffee-pot']],
  ['coffin', ['coffin']],
  ['coffin-cross', ['coffin-cross']],
  ['coin', ['coin']],
  ['coin-blank', ['coin-blank']],
  ['coin-front', ['coin-front']],
  ['coin-vertical', ['coin-vertical']],
  ['coins', ['coins']],
  ['colon', ['colon']],
  ['colon-sign', ['colon-sign']],
  ['columns-3', ['columns-3']],
  ['comet', ['comet']],
  ['comma', ['comma']],
  ['command', ['command']],
  ['comment', ['comment']],
  ['comment-arrow-down', ['comment-arrow-down']],
  ['comment-arrow-up', ['comment-arrow-up']],
  ['comment-arrow-up-right', ['comment-arrow-up-right']],
  ['comment-captions', ['comment-captions']],
  ['comment-check', ['comment-check']],
  ['comment-code', ['comment-code']],
  ['comment-dollar', ['comment-dollar']],
  ['comment-dots', ['comment-dots']],
  ['commenting', ['comment-dots']],
  ['comment-exclamation', ['comment-exclamation']],
  ['comment-image', ['comment-image']],
  ['comment-lines', ['comment-lines']],
  ['comment-medical', ['comment-medical']],
  ['comment-middle', ['comment-middle']],
  ['comment-middle-top', ['comment-middle-top']],
  ['comment-minus', ['comment-minus']],
  ['comment-music', ['comment-music']],
  ['comment-pen', ['comment-pen']],
  ['comment-edit', ['comment-pen']],
  ['comment-plus', ['comment-plus']],
  ['comment-question', ['comment-question']],
  ['comment-quote', ['comment-quote']],
  ['comment-slash', ['comment-slash']],
  ['comment-smile', ['comment-smile']],
  ['comment-sms', ['comment-sms']],
  ['sms', ['comment-sms']],
  ['comment-text', ['comment-text']],
  ['comment-xmark', ['comment-xmark']],
  ['comment-times', ['comment-xmark']],
  ['comments', ['comments']],
  ['comments-dollar', ['comments-dollar']],
  ['comments-question', ['comments-question']],
  ['comments-question-check', ['comments-question-check']],
  ['compact-disc', ['compact-disc']],
  ['compass', ['compass']],
  ['compass-drafting', ['compass-drafting']],
  ['drafting-compass', ['compass-drafting']],
  ['compass-slash', ['compass-slash']],
  ['compress', ['compress']],
  ['compress-wide', ['compress-wide']],
  ['computer', ['computer']],
  ['computer-classic', ['computer-classic']],
  ['computer-mouse', ['computer-mouse']],
  ['mouse', ['computer-mouse']],
  ['computer-mouse-scrollwheel', ['computer-mouse-scrollwheel']],
  ['mouse-alt', ['computer-mouse-scrollwheel']],
  ['computer-speaker', ['computer-speaker']],
  ['confluence', ['confluence']],
  ['connectdevelop', ['connectdevelop']],
  ['container-storage', ['container-storage']],
  ['contao', ['contao']],
  ['conveyor-belt', ['conveyor-belt']],
  ['conveyor-belt-boxes', ['conveyor-belt-boxes']],
  ['conveyor-belt-alt', ['conveyor-belt-boxes']],
  ['conveyor-belt-empty', ['conveyor-belt-empty']],
  ['cookie', ['cookie']],
  ['cookie-bite', ['cookie-bite']],
  ['copy', ['copy']],
  ['copyright', ['copyright']],
  ['corn', ['corn']],
  ['corner', ['corner']],
  ['cotton-bureau', ['cotton-bureau']],
  ['couch', ['couch']],
  ['cow', ['cow']],
  ['cowbell', ['cowbell']],
  ['cowbell-circle-plus', ['cowbell-circle-plus']],
  ['cowbell-more', ['cowbell-circle-plus']],
  ['cpanel', ['cpanel']],
  ['crab', ['crab']],
  ['crate-apple', ['crate-apple']],
  ['apple-crate', ['crate-apple']],
  ['crate-empty', ['crate-empty']],
  ['creative-commons', ['creative-commons']],
  ['creative-commons-by', ['creative-commons-by']],
  ['creative-commons-nc', ['creative-commons-nc']],
  ['creative-commons-nc-eu', ['creative-commons-nc-eu']],
  ['creative-commons-nc-jp', ['creative-commons-nc-jp']],
  ['creative-commons-nd', ['creative-commons-nd']],
  ['creative-commons-pd', ['creative-commons-pd']],
  ['creative-commons-pd-alt', ['creative-commons-pd-alt']],
  ['creative-commons-remix', ['creative-commons-remix']],
  ['creative-commons-sa', ['creative-commons-sa']],
  ['creative-commons-sampling', ['creative-commons-sampling']],
  ['creative-commons-sampling-plus', ['creative-commons-sampling-plus']],
  ['creative-commons-share', ['creative-commons-share']],
  ['creative-commons-zero', ['creative-commons-zero']],
  ['credit-card', ['credit-card']],
  ['credit-card-alt', ['credit-card']],
  ['credit-card-blank', ['credit-card-blank']],
  ['credit-card-front', ['credit-card-front']],
  ['cricket-bat-ball', ['cricket-bat-ball']],
  ['cricket', ['cricket-bat-ball']],
  ['critical-role', ['critical-role']],
  ['croissant', ['croissant']],
  ['crop', ['crop']],
  ['crop-simple', ['crop-simple']],
  ['crop-alt', ['crop-simple']],
  ['cross', ['cross']],
  ['crosshairs', ['crosshairs']],
  ['crosshairs-simple', ['crosshairs-simple']],
  ['crow', ['crow']],
  ['crown', ['crown']],
  ['crutch', ['crutch']],
  ['crutches', ['crutches']],
  ['cruzeiro-sign', ['cruzeiro-sign']],
  ['crystal-ball', ['crystal-ball']],
  ['css3', ['css3']],
  ['css3-alt', ['css3-alt']],
  ['cube', ['cube']],
  ['cubes', ['cubes']],
  ['cubes-stacked', ['cubes-stacked']],
  ['cucumber', ['cucumber']],
  ['cup-straw', ['cup-straw']],
  ['cup-straw-swoosh', ['cup-straw-swoosh']],
  ['cup-togo', ['cup-togo']],
  ['coffee-togo', ['cup-togo']],
  ['cupcake', ['cupcake']],
  ['curling-stone', ['curling-stone']],
  ['curling', ['curling-stone']],
  ['custard', ['custard']],
  ['cuttlefish', ['cuttlefish']],
  ['d', ['d']],
  ['d-and-d', ['d-and-d']],
  ['d-and-d-beyond', ['d-and-d-beyond']],
  ['dagger', ['dagger']],
  ['dailymotion', ['dailymotion']],
  ['dash', ['dash']],
  ['minus-large', ['dash']],
  ['dashcube', ['dashcube']],
  ['database', ['database']],
  ['deer', ['deer']],
  ['deer-rudolph', ['deer-rudolph']],
  ['deezer', ['deezer']],
  ['delete-left', ['delete-left']],
  ['backspace', ['delete-left']],
  ['delete-right', ['delete-right']],
  ['delicious', ['delicious']],
  ['democrat', ['democrat']],
  ['deploydog', ['deploydog']],
  ['deskpro', ['deskpro']],
  ['desktop', ['desktop']],
  ['desktop-alt', ['desktop']],
  ['desktop-arrow-down', ['desktop-arrow-down']],
  ['dev', ['dev']],
  ['deviantart', ['deviantart']],
  ['dharmachakra', ['dharmachakra']],
  ['dhl', ['dhl']],
  ['diagram-cells', ['diagram-cells']],
  ['diagram-lean-canvas', ['diagram-lean-canvas']],
  ['diagram-nested', ['diagram-nested']],
  ['diagram-next', ['diagram-next']],
  ['diagram-predecessor', ['diagram-predecessor']],
  ['diagram-previous', ['diagram-previous']],
  ['diagram-project', ['diagram-project']],
  ['project-diagram', ['diagram-project']],
  ['diagram-sankey', ['diagram-sankey']],
  ['diagram-subtask', ['diagram-subtask']],
  ['diagram-successor', ['diagram-successor']],
  ['diagram-venn', ['diagram-venn']],
  ['dial', ['dial']],
  ['dial-med-high', ['dial']],
  ['dial-high', ['dial-high']],
  ['dial-low', ['dial-low']],
  ['dial-max', ['dial-max']],
  ['dial-med', ['dial-med']],
  ['dial-med-low', ['dial-med-low']],
  ['dial-min', ['dial-min']],
  ['dial-off', ['dial-off']],
  ['diamond', ['diamond']],
  ['diamond-exclamation', ['diamond-exclamation']],
  ['diamond-turn-right', ['diamond-turn-right']],
  ['directions', ['diamond-turn-right']],
  ['diaspora', ['diaspora']],
  ['dice', ['dice']],
  ['dice-d10', ['dice-d10']],
  ['dice-d12', ['dice-d12']],
  ['dice-d20', ['dice-d20']],
  ['dice-d4', ['dice-d4']],
  ['dice-d6', ['dice-d6']],
  ['dice-d8', ['dice-d8']],
  ['dice-five', ['dice-five']],
  ['dice-four', ['dice-four']],
  ['dice-one', ['dice-one']],
  ['dice-six', ['dice-six']],
  ['dice-three', ['dice-three']],
  ['dice-two', ['dice-two']],
  ['digg', ['digg']],
  ['digital-ocean', ['digital-ocean']],
  ['diploma', ['diploma']],
  ['scroll-ribbon', ['diploma']],
  ['disc-drive', ['disc-drive']],
  ['discord', ['discord']],
  ['discourse', ['discourse']],
  ['disease', ['disease']],
  ['display', ['display']],
  ['display-arrow-down', ['display-arrow-down']],
  ['display-code', ['display-code']],
  ['desktop-code', ['display-code']],
  ['display-medical', ['display-medical']],
  ['desktop-medical', ['display-medical']],
  ['display-slash', ['display-slash']],
  ['desktop-slash', ['display-slash']],
  ['distribute-spacing-horizontal', ['distribute-spacing-horizontal']],
  ['distribute-spacing-vertical', ['distribute-spacing-vertical']],
  ['ditto', ['ditto']],
  ['divide', ['divide']],
  ['dna', ['dna']],
  ['do-not-enter', ['do-not-enter']],
  ['dochub', ['dochub']],
  ['docker', ['docker']],
  ['dog', ['dog']],
  ['dog-leashed', ['dog-leashed']],
  ['dollar-sign', ['dollar-sign']],
  ['dollar', ['dollar-sign']],
  ['usd', ['dollar-sign']],
  ['dolly', ['dolly']],
  ['dolly-box', ['dolly']],
  ['dolly-empty', ['dolly-empty']],
  ['dolphin', ['dolphin']],
  ['dong-sign', ['dong-sign']],
  ['donut', ['donut']],
  ['doughnut', ['donut']],
  ['door-closed', ['door-closed']],
  ['door-open', ['door-open']],
  ['dove', ['dove']],
  ['down', ['down']],
  ['arrow-alt-down', ['down']],
  ['down-from-dotted-line', ['down-from-dotted-line']],
  ['down-from-line', ['down-from-line']],
  ['arrow-alt-from-top', ['down-from-line']],
  ['down-left', ['down-left']],
  ['down-left-and-up-right-to-center', ['down-left-and-up-right-to-center']],
  ['compress-alt', ['down-left-and-up-right-to-center']],
  ['down-long', ['down-long']],
  ['long-arrow-alt-down', ['down-long']],
  ['down-right', ['down-right']],
  ['down-to-bracket', ['down-to-bracket']],
  ['down-to-dotted-line', ['down-to-dotted-line']],
  ['down-to-line', ['down-to-line']],
  ['arrow-alt-to-bottom', ['down-to-line']],
  ['download', ['download']],
  ['draft2digital', ['draft2digital']],
  ['dragon', ['dragon']],
  ['draw-circle', ['draw-circle']],
  ['draw-polygon', ['draw-polygon']],
  ['draw-square', ['draw-square']],
  ['dreidel', ['dreidel']],
  ['dribbble', ['dribbble']],
  ['drone', ['drone']],
  ['drone-front', ['drone-front']],
  ['drone-alt', ['drone-front']],
  ['dropbox', ['dropbox']],
  ['droplet', ['droplet']],
  ['tint', ['droplet']],
  ['droplet-degree', ['droplet-degree']],
  ['dewpoint', ['droplet-degree']],
  ['droplet-percent', ['droplet-percent']],
  ['humidity', ['droplet-percent']],
  ['droplet-slash', ['droplet-slash']],
  ['tint-slash', ['droplet-slash']],
  ['drum', ['drum']],
  ['drum-steelpan', ['drum-steelpan']],
  ['drumstick', ['drumstick']],
  ['drumstick-bite', ['drumstick-bite']],
  ['drupal', ['drupal']],
  ['dryer', ['dryer']],
  ['dryer-heat', ['dryer-heat']],
  ['dryer-alt', ['dryer-heat']],
  ['duck', ['duck']],
  ['dumbbell', ['dumbbell']],
  ['dumpster', ['dumpster']],
  ['dumpster-fire', ['dumpster-fire']],
  ['dungeon', ['dungeon']],
  ['dyalog', ['dyalog']],
  ['e', ['e']],
  ['ear', ['ear']],
  ['ear-deaf', ['ear-deaf']],
  ['deaf', ['ear-deaf']],
  ['deafness', ['ear-deaf']],
  ['hard-of-hearing', ['ear-deaf']],
  ['ear-listen', ['ear-listen']],
  ['assistive-listening-systems', ['ear-listen']],
  ['ear-muffs', ['ear-muffs']],
  ['earlybirds', ['earlybirds']],
  ['earth-africa', ['earth-africa']],
  ['globe-africa', ['earth-africa']],
  ['earth-americas', ['earth-americas']],
  ['earth', ['earth-americas']],
  ['earth-america', ['earth-americas']],
  ['globe-americas', ['earth-americas']],
  ['earth-asia', ['earth-asia']],
  ['globe-asia', ['earth-asia']],
  ['earth-europe', ['earth-europe']],
  ['globe-europe', ['earth-europe']],
  ['earth-oceania', ['earth-oceania']],
  ['globe-oceania', ['earth-oceania']],
  ['ebay', ['ebay']],
  ['eclipse', ['eclipse']],
  ['edge', ['edge']],
  ['edge-legacy', ['edge-legacy']],
  ['egg', ['egg']],
  ['egg-fried', ['egg-fried']],
  ['eggplant', ['eggplant']],
  ['eject', ['eject']],
  ['elementor', ['elementor']],
  ['elephant', ['elephant']],
  ['elevator', ['elevator']],
  ['ellipsis', ['ellipsis']],
  ['ellipsis-h', ['ellipsis']],
  ['ellipsis-stroke', ['ellipsis-stroke']],
  ['ellipsis-h-alt', ['ellipsis-stroke']],
  ['ellipsis-stroke-vertical', ['ellipsis-stroke-vertical']],
  ['ellipsis-v-alt', ['ellipsis-stroke-vertical']],
  ['ellipsis-vertical', ['ellipsis-vertical']],
  ['ellipsis-v', ['ellipsis-vertical']],
  ['ello', ['ello']],
  ['ember', ['ember']],
  ['empire', ['empire']],
  ['empty-set', ['empty-set']],
  ['engine', ['engine']],
  ['engine-warning', ['engine-warning']],
  ['engine-exclamation', ['engine-warning']],
  ['envelope', ['envelope']],
  ['envelope-circle-check', ['envelope-circle-check']],
  ['envelope-dot', ['envelope-dot']],
  ['envelope-badge', ['envelope-dot']],
  ['envelope-open', ['envelope-open']],
  ['envelope-open-dollar', ['envelope-open-dollar']],
  ['envelope-open-text', ['envelope-open-text']],
  ['envelopes', ['envelopes']],
  ['envelopes-bulk', ['envelopes-bulk']],
  ['mail-bulk', ['envelopes-bulk']],
  ['envira', ['envira']],
  ['equals', ['equals']],
  ['eraser', ['eraser']],
  ['erlang', ['erlang']],
  ['escalator', ['escalator']],
  ['ethereum', ['ethereum']],
  ['ethernet', ['ethernet']],
  ['etsy', ['etsy']],
  ['euro-sign', ['euro-sign']],
  ['eur', ['euro-sign']],
  ['euro', ['euro-sign']],
  ['evernote', ['evernote']],
  ['exclamation', ['exclamation']],
  ['expand', ['expand']],
  ['expand-wide', ['expand-wide']],
  ['expeditedssl', ['expeditedssl']],
  ['explosion', ['explosion']],
  ['eye', ['eye']],
  ['eye-dropper', ['eye-dropper']],
  ['eye-dropper-empty', ['eye-dropper']],
  ['eyedropper', ['eye-dropper']],
  ['eye-dropper-full', ['eye-dropper-full']],
  ['eye-dropper-half', ['eye-dropper-half']],
  ['eye-evil', ['eye-evil']],
  ['eye-low-vision', ['eye-low-vision']],
  ['low-vision', ['eye-low-vision']],
  ['eye-slash', ['eye-slash']],
  ['eyes', ['eyes']],
  ['f', ['f']],
  ['face-angry', ['face-angry']],
  ['angry', ['face-angry']],
  ['face-angry-horns', ['face-angry-horns']],
  ['face-anguished', ['face-anguished']],
  ['face-anxious-sweat', ['face-anxious-sweat']],
  ['face-astonished', ['face-astonished']],
  ['face-awesome', ['face-awesome']],
  ['gave-dandy', ['face-awesome']],
  ['face-beam-hand-over-mouth', ['face-beam-hand-over-mouth']],
  ['face-clouds', ['face-clouds']],
  ['face-confounded', ['face-confounded']],
  ['face-confused', ['face-confused']],
  ['face-cowboy-hat', ['face-cowboy-hat']],
  ['face-diagonal-mouth', ['face-diagonal-mouth']],
  ['face-disappointed', ['face-disappointed']],
  ['face-disguise', ['face-disguise']],
  ['face-dizzy', ['face-dizzy']],
  ['dizzy', ['face-dizzy']],
  ['face-dotted', ['face-dotted']],
  ['face-downcast-sweat', ['face-downcast-sweat']],
  ['face-drooling', ['face-drooling']],
  ['face-exhaling', ['face-exhaling']],
  ['face-explode', ['face-explode']],
  ['exploding-head', ['face-explode']],
  ['face-expressionless', ['face-expressionless']],
  ['face-eyes-xmarks', ['face-eyes-xmarks']],
  ['face-fearful', ['face-fearful']],
  ['face-flushed', ['face-flushed']],
  ['flushed', ['face-flushed']],
  ['face-frown', ['face-frown']],
  ['frown', ['face-frown']],
  ['face-frown-open', ['face-frown-open']],
  ['frown-open', ['face-frown-open']],
  ['face-frown-slight', ['face-frown-slight']],
  ['face-glasses', ['face-glasses']],
  ['face-grimace', ['face-grimace']],
  ['grimace', ['face-grimace']],
  ['face-grin', ['face-grin']],
  ['grin', ['face-grin']],
  ['face-grin-beam', ['face-grin-beam']],
  ['grin-beam', ['face-grin-beam']],
  ['face-grin-beam-sweat', ['face-grin-beam-sweat']],
  ['grin-beam-sweat', ['face-grin-beam-sweat']],
  ['face-grin-hearts', ['face-grin-hearts']],
  ['grin-hearts', ['face-grin-hearts']],
  ['face-grin-squint', ['face-grin-squint']],
  ['grin-squint', ['face-grin-squint']],
  ['face-grin-squint-tears', ['face-grin-squint-tears']],
  ['grin-squint-tears', ['face-grin-squint-tears']],
  ['face-grin-stars', ['face-grin-stars']],
  ['grin-stars', ['face-grin-stars']],
  ['face-grin-tears', ['face-grin-tears']],
  ['grin-tears', ['face-grin-tears']],
  ['face-grin-tongue', ['face-grin-tongue']],
  ['grin-tongue', ['face-grin-tongue']],
  ['face-grin-tongue-squint', ['face-grin-tongue-squint']],
  ['grin-tongue-squint', ['face-grin-tongue-squint']],
  ['face-grin-tongue-wink', ['face-grin-tongue-wink']],
  ['grin-tongue-wink', ['face-grin-tongue-wink']],
  ['face-grin-wide', ['face-grin-wide']],
  ['grin-alt', ['face-grin-wide']],
  ['face-grin-wink', ['face-grin-wink']],
  ['grin-wink', ['face-grin-wink']],
  ['face-hand-over-mouth', ['face-hand-over-mouth']],
  ['face-hand-peeking', ['face-hand-peeking']],
  ['face-hand-yawn', ['face-hand-yawn']],
  ['face-head-bandage', ['face-head-bandage']],
  ['face-holding-back-tears', ['face-holding-back-tears']],
  ['face-hushed', ['face-hushed']],
  ['face-icicles', ['face-icicles']],
  ['face-kiss', ['face-kiss']],
  ['kiss', ['face-kiss']],
  ['face-kiss-beam', ['face-kiss-beam']],
  ['kiss-beam', ['face-kiss-beam']],
  ['face-kiss-closed-eyes', ['face-kiss-closed-eyes']],
  ['face-kiss-wink-heart', ['face-kiss-wink-heart']],
  ['kiss-wink-heart', ['face-kiss-wink-heart']],
  ['face-laugh', ['face-laugh']],
  ['laugh', ['face-laugh']],
  ['face-laugh-beam', ['face-laugh-beam']],
  ['laugh-beam', ['face-laugh-beam']],
  ['face-laugh-squint', ['face-laugh-squint']],
  ['laugh-squint', ['face-laugh-squint']],
  ['face-laugh-wink', ['face-laugh-wink']],
  ['laugh-wink', ['face-laugh-wink']],
  ['face-lying', ['face-lying']],
  ['face-mask', ['face-mask']],
  ['face-meh', ['face-meh']],
  ['meh', ['face-meh']],
  ['face-meh-blank', ['face-meh-blank']],
  ['meh-blank', ['face-meh-blank']],
  ['face-melting', ['face-melting']],
  ['face-monocle', ['face-monocle']],
  ['face-nauseated', ['face-nauseated']],
  ['face-nose-steam', ['face-nose-steam']],
  ['face-party', ['face-party']],
  ['face-pensive', ['face-pensive']],
  ['face-persevering', ['face-persevering']],
  ['face-pleading', ['face-pleading']],
  ['face-pouting', ['face-pouting']],
  ['face-raised-eyebrow', ['face-raised-eyebrow']],
  ['face-relieved', ['face-relieved']],
  ['face-rolling-eyes', ['face-rolling-eyes']],
  ['meh-rolling-eyes', ['face-rolling-eyes']],
  ['face-sad-cry', ['face-sad-cry']],
  ['sad-cry', ['face-sad-cry']],
  ['face-sad-sweat', ['face-sad-sweat']],
  ['face-sad-tear', ['face-sad-tear']],
  ['sad-tear', ['face-sad-tear']],
  ['face-saluting', ['face-saluting']],
  ['face-scream', ['face-scream']],
  ['face-shush', ['face-shush']],
  ['face-sleeping', ['face-sleeping']],
  ['face-sleepy', ['face-sleepy']],
  ['face-smile', ['face-smile']],
  ['smile', ['face-smile']],
  ['face-smile-beam', ['face-smile-beam']],
  ['smile-beam', ['face-smile-beam']],
  ['face-smile-halo', ['face-smile-halo']],
  ['face-smile-hearts', ['face-smile-hearts']],
  ['face-smile-horns', ['face-smile-horns']],
  ['face-smile-plus', ['face-smile-plus']],
  ['smile-plus', ['face-smile-plus']],
  ['face-smile-relaxed', ['face-smile-relaxed']],
  ['face-smile-tear', ['face-smile-tear']],
  ['face-smile-tongue', ['face-smile-tongue']],
  ['face-smile-upside-down', ['face-smile-upside-down']],
  ['face-smile-wink', ['face-smile-wink']],
  ['smile-wink', ['face-smile-wink']],
  ['face-smiling-hands', ['face-smiling-hands']],
  ['face-smirking', ['face-smirking']],
  ['face-spiral-eyes', ['face-spiral-eyes']],
  ['face-sunglasses', ['face-sunglasses']],
  ['face-surprise', ['face-surprise']],
  ['surprise', ['face-surprise']],
  ['face-swear', ['face-swear']],
  ['face-thermometer', ['face-thermometer']],
  ['face-thinking', ['face-thinking']],
  ['face-tired', ['face-tired']],
  ['tired', ['face-tired']],
  ['face-tissue', ['face-tissue']],
  ['face-tongue-money', ['face-tongue-money']],
  ['face-tongue-sweat', ['face-tongue-sweat']],
  ['face-unamused', ['face-unamused']],
  ['face-viewfinder', ['face-viewfinder']],
  ['face-vomit', ['face-vomit']],
  ['face-weary', ['face-weary']],
  ['face-woozy', ['face-woozy']],
  ['face-worried', ['face-worried']],
  ['face-zany', ['face-zany']],
  ['face-zipper', ['face-zipper']],
  ['facebook', ['facebook']],
  ['facebook-f', ['facebook-f']],
  ['facebook-messenger', ['facebook-messenger']],
  ['falafel', ['falafel']],
  ['family', ['family']],
  ['family-dress', ['family-dress']],
  ['family-pants', ['family-pants']],
  ['fan', ['fan']],
  ['fan-table', ['fan-table']],
  ['fantasy-flight-games', ['fantasy-flight-games']],
  ['farm', ['farm']],
  ['barn-silo', ['farm']],
  ['faucet', ['faucet']],
  ['faucet-drip', ['faucet-drip']],
  ['fax', ['fax']],
  ['feather', ['feather']],
  ['feather-pointed', ['feather-pointed']],
  ['feather-alt', ['feather-pointed']],
  ['fedex', ['fedex']],
  ['fedora', ['fedora']],
  ['fence', ['fence']],
  ['ferris-wheel', ['ferris-wheel']],
  ['ferry', ['ferry']],
  ['field-hockey-stick-ball', ['field-hockey-stick-ball']],
  ['field-hockey', ['field-hockey-stick-ball']],
  ['figma', ['figma']],
  ['file', ['file']],
  ['file-arrow-down', ['file-arrow-down']],
  ['file-download', ['file-arrow-down']],
  ['file-arrow-up', ['file-arrow-up']],
  ['file-upload', ['file-arrow-up']],
  ['file-audio', ['file-audio']],
  ['file-binary', ['file-binary']],
  ['file-certificate', ['file-certificate']],
  ['file-award', ['file-certificate']],
  ['file-chart-column', ['file-chart-column']],
  ['file-chart-line', ['file-chart-column']],
  ['file-chart-pie', ['file-chart-pie']],
  ['file-check', ['file-check']],
  ['file-circle-check', ['file-circle-check']],
  ['file-circle-exclamation', ['file-circle-exclamation']],
  ['file-circle-info', ['file-circle-info']],
  ['file-circle-minus', ['file-circle-minus']],
  ['file-circle-plus', ['file-circle-plus']],
  ['file-circle-question', ['file-circle-question']],
  ['file-circle-xmark', ['file-circle-xmark']],
  ['file-code', ['file-code']],
  ['file-contract', ['file-contract']],
  ['file-csv', ['file-csv']],
  ['file-dashed-line', ['file-dashed-line']],
  ['page-break', ['file-dashed-line']],
  ['file-excel', ['file-excel']],
  ['file-exclamation', ['file-exclamation']],
  ['file-export', ['file-export']],
  ['arrow-right-from-file', ['file-export']],
  ['file-heart', ['file-heart']],
  ['file-image', ['file-image']],
  ['file-import', ['file-import']],
  ['arrow-right-to-file', ['file-import']],
  ['file-invoice', ['file-invoice']],
  ['file-invoice-dollar', ['file-invoice-dollar']],
  ['file-lines', ['file-lines']],
  ['file-alt', ['file-lines']],
  ['file-text', ['file-lines']],
  ['file-lock', ['file-lock']],
  ['file-magnifying-glass', ['file-magnifying-glass']],
  ['file-search', ['file-magnifying-glass']],
  ['file-medical', ['file-medical']],
  ['file-minus', ['file-minus']],
  ['file-music', ['file-music']],
  ['file-pdf', ['file-pdf']],
  ['file-pen', ['file-pen']],
  ['file-edit', ['file-pen']],
  ['file-plus', ['file-plus']],
  ['file-plus-minus', ['file-plus-minus']],
  ['file-powerpoint', ['file-powerpoint']],
  ['file-prescription', ['file-prescription']],
  ['file-shield', ['file-shield']],
  ['file-signature', ['file-signature']],
  ['file-slash', ['file-slash']],
  ['file-spreadsheet', ['file-spreadsheet']],
  ['file-user', ['file-user']],
  ['file-video', ['file-video']],
  ['file-waveform', ['file-waveform']],
  ['file-medical-alt', ['file-waveform']],
  ['file-word', ['file-word']],
  ['file-xmark', ['file-xmark']],
  ['file-times', ['file-xmark']],
  ['file-zipper', ['file-zipper']],
  ['file-archive', ['file-zipper']],
  ['files', ['files']],
  ['files-medical', ['files-medical']],
  ['fill', ['fill']],
  ['fill-drip', ['fill-drip']],
  ['film', ['film']],
  ['film-canister', ['film-canister']],
  ['film-cannister', ['film-canister']],
  ['film-simple', ['film-simple']],
  ['film-alt', ['film-simple']],
  ['film-slash', ['film-slash']],
  ['films', ['films']],
  ['filter', ['filter']],
  ['filter-circle-dollar', ['filter-circle-dollar']],
  ['funnel-dollar', ['filter-circle-dollar']],
  ['filter-circle-xmark', ['filter-circle-xmark']],
  ['filter-list', ['filter-list']],
  ['filter-slash', ['filter-slash']],
  ['filters', ['filters']],
  ['fingerprint', ['fingerprint']],
  ['fire', ['fire']],
  ['fire-burner', ['fire-burner']],
  ['fire-extinguisher', ['fire-extinguisher']],
  ['fire-flame', ['fire-flame']],
  ['flame', ['fire-flame']],
  ['fire-flame-curved', ['fire-flame-curved']],
  ['fire-alt', ['fire-flame-curved']],
  ['fire-flame-simple', ['fire-flame-simple']],
  ['burn', ['fire-flame-simple']],
  ['fire-hydrant', ['fire-hydrant']],
  ['fire-smoke', ['fire-smoke']],
  ['firefox', ['firefox']],
  ['firefox-browser', ['firefox-browser']],
  ['fireplace', ['fireplace']],
  ['first-order', ['first-order']],
  ['first-order-alt', ['first-order-alt']],
  ['firstdraft', ['firstdraft']],
  ['fish', ['fish']],
  ['fish-bones', ['fish-bones']],
  ['fish-cooked', ['fish-cooked']],
  ['fish-fins', ['fish-fins']],
  ['fishing-rod', ['fishing-rod']],
  ['flag', ['flag']],
  ['flag-checkered', ['flag-checkered']],
  ['flag-pennant', ['flag-pennant']],
  ['pennant', ['flag-pennant']],
  ['flag-swallowtail', ['flag-swallowtail']],
  ['flag-alt', ['flag-swallowtail']],
  ['flag-usa', ['flag-usa']],
  ['flashlight', ['flashlight']],
  ['flask', ['flask']],
  ['flask-round-poison', ['flask-round-poison']],
  ['flask-poison', ['flask-round-poison']],
  ['flask-round-potion', ['flask-round-potion']],
  ['flask-potion', ['flask-round-potion']],
  ['flask-vial', ['flask-vial']],
  ['flatbread', ['flatbread']],
  ['flatbread-stuffed', ['flatbread-stuffed']],
  ['flickr', ['flickr']],
  ['flipboard', ['flipboard']],
  ['floppy-disk', ['floppy-disk']],
  ['save', ['floppy-disk']],
  ['floppy-disk-circle-arrow-right', ['floppy-disk-circle-arrow-right']],
  ['save-circle-arrow-right', ['floppy-disk-circle-arrow-right']],
  ['floppy-disk-circle-xmark', ['floppy-disk-circle-xmark']],
  ['floppy-disk-times', ['floppy-disk-circle-xmark']],
  ['save-circle-xmark', ['floppy-disk-circle-xmark']],
  ['save-times', ['floppy-disk-circle-xmark']],
  ['floppy-disk-pen', ['floppy-disk-pen']],
  ['floppy-disks', ['floppy-disks']],
  ['florin-sign', ['florin-sign']],
  ['flower', ['flower']],
  ['flower-daffodil', ['flower-daffodil']],
  ['flower-tulip', ['flower-tulip']],
  ['flute', ['flute']],
  ['flux-capacitor', ['flux-capacitor']],
  ['fly', ['fly']],
  ['flying-disc', ['flying-disc']],
  ['folder', ['folder']],
  ['folder-blank', ['folder']],
  ['folder-arrow-down', ['folder-arrow-down']],
  ['folder-download', ['folder-arrow-down']],
  ['folder-arrow-up', ['folder-arrow-up']],
  ['folder-upload', ['folder-arrow-up']],
  ['folder-bookmark', ['folder-bookmark']],
  ['folder-closed', ['folder-closed']],
  ['folder-gear', ['folder-gear']],
  ['folder-cog', ['folder-gear']],
  ['folder-grid', ['folder-grid']],
  ['folder-heart', ['folder-heart']],
  ['folder-image', ['folder-image']],
  ['folder-magnifying-glass', ['folder-magnifying-glass']],
  ['folder-search', ['folder-magnifying-glass']],
  ['folder-medical', ['folder-medical']],
  ['folder-minus', ['folder-minus']],
  ['folder-music', ['folder-music']],
  ['folder-open', ['folder-open']],
  ['folder-plus', ['folder-plus']],
  ['folder-tree', ['folder-tree']],
  ['folder-user', ['folder-user']],
  ['folder-xmark', ['folder-xmark']],
  ['folder-times', ['folder-xmark']],
  ['folders', ['folders']],
  ['fondue-pot', ['fondue-pot']],
  ['font', ['font']],
  ['font-awesome', ['font-awesome']],
  ['font-awesome-flag', ['font-awesome']],
  ['font-awesome-logo-full', ['font-awesome']],
  ['font-case', ['font-case']],
  ['fonticons', ['fonticons']],
  ['fonticons-fi', ['fonticons-fi']],
  ['football', ['football']],
  ['football-ball', ['football']],
  ['football-helmet', ['football-helmet']],
  ['fork', ['fork']],
  ['utensil-fork', ['fork']],
  ['fork-knife', ['fork-knife']],
  ['utensils-alt', ['fork-knife']],
  ['forklift', ['forklift']],
  ['fort', ['fort']],
  ['fort-awesome', ['fort-awesome']],
  ['fort-awesome-alt', ['fort-awesome-alt']],
  ['forumbee', ['forumbee']],
  ['forward', ['forward']],
  ['forward-fast', ['forward-fast']],
  ['fast-forward', ['forward-fast']],
  ['forward-step', ['forward-step']],
  ['step-forward', ['forward-step']],
  ['foursquare', ['foursquare']],
  ['frame', ['frame']],
  ['franc-sign', ['franc-sign']],
  ['free-code-camp', ['free-code-camp']],
  ['freebsd', ['freebsd']],
  ['french-fries', ['french-fries']],
  ['frog', ['frog']],
  ['fulcrum', ['fulcrum']],
  ['function', ['function']],
  ['futbol', ['futbol']],
  ['futbol-ball', ['futbol']],
  ['soccer-ball', ['futbol']],
  ['g', ['g']],
  ['galactic-republic', ['galactic-republic']],
  ['galactic-senate', ['galactic-senate']],
  ['galaxy', ['galaxy']],
  ['gallery-thumbnails', ['gallery-thumbnails']],
  ['game-board', ['game-board']],
  ['game-board-simple', ['game-board-simple']],
  ['game-board-alt', ['game-board-simple']],
  ['game-console-handheld', ['game-console-handheld']],
  ['gamepad', ['gamepad']],
  ['gamepad-modern', ['gamepad-modern']],
  ['gamepad-alt', ['gamepad-modern']],
  ['garage', ['garage']],
  ['garage-car', ['garage-car']],
  ['garage-open', ['garage-open']],
  ['garlic', ['garlic']],
  ['gas-pump', ['gas-pump']],
  ['gas-pump-slash', ['gas-pump-slash']],
  ['gauge', ['gauge']],
  ['dashboard', ['gauge']],
  ['gauge-med', ['gauge']],
  ['tachometer-alt-average', ['gauge']],
  ['gauge-circle-bolt', ['gauge-circle-bolt']],
  ['gauge-circle-minus', ['gauge-circle-minus']],
  ['gauge-circle-plus', ['gauge-circle-plus']],
  ['gauge-high', ['gauge-high']],
  ['tachometer-alt', ['gauge-high']],
  ['tachometer-alt-fast', ['gauge-high']],
  ['gauge-low', ['gauge-low']],
  ['tachometer-alt-slow', ['gauge-low']],
  ['gauge-max', ['gauge-max']],
  ['tachometer-alt-fastest', ['gauge-max']],
  ['gauge-min', ['gauge-min']],
  ['tachometer-alt-slowest', ['gauge-min']],
  ['gauge-simple', ['gauge-simple']],
  ['gauge-simple-med', ['gauge-simple']],
  ['tachometer-average', ['gauge-simple']],
  ['gauge-simple-high', ['gauge-simple-high']],
  ['tachometer', ['gauge-simple-high']],
  ['tachometer-fast', ['gauge-simple-high']],
  ['gauge-simple-low', ['gauge-simple-low']],
  ['tachometer-slow', ['gauge-simple-low']],
  ['gauge-simple-max', ['gauge-simple-max']],
  ['tachometer-fastest', ['gauge-simple-max']],
  ['gauge-simple-min', ['gauge-simple-min']],
  ['tachometer-slowest', ['gauge-simple-min']],
  ['gavel', ['gavel']],
  ['legal', ['gavel']],
  ['gear', ['gear']],
  ['cog', ['gear']],
  ['gears', ['gears']],
  ['cogs', ['gears']],
  ['gem', ['gem']],
  ['genderless', ['genderless']],
  ['get-pocket', ['get-pocket']],
  ['gg', ['gg']],
  ['gg-circle', ['gg-circle']],
  ['ghost', ['ghost']],
  ['gif', ['gif']],
  ['gift', ['gift']],
  ['gift-card', ['gift-card']],
  ['gifts', ['gifts']],
  ['gingerbread-man', ['gingerbread-man']],
  ['git', ['git']],
  ['git-alt', ['git-alt']],
  ['github', ['github']],
  ['github-alt', ['github-alt']],
  ['gitkraken', ['gitkraken']],
  ['gitlab', ['gitlab']],
  ['gitter', ['gitter']],
  ['glass', ['glass']],
  ['glass-citrus', ['glass-citrus']],
  ['glass-empty', ['glass-empty']],
  ['glass-half', ['glass-half']],
  ['glass-half-empty', ['glass-half']],
  ['glass-half-full', ['glass-half']],
  ['glass-water', ['glass-water']],
  ['glass-water-droplet', ['glass-water-droplet']],
  ['glasses', ['glasses']],
  ['glasses-round', ['glasses-round']],
  ['glasses-alt', ['glasses-round']],
  ['glide', ['glide']],
  ['glide-g', ['glide-g']],
  ['globe', ['globe']],
  ['globe-snow', ['globe-snow']],
  ['globe-stand', ['globe-stand']],
  ['goal-net', ['goal-net']],
  ['gofore', ['gofore']],
  ['golang', ['golang']],
  ['golf-ball-tee', ['golf-ball-tee']],
  ['golf-ball', ['golf-ball-tee']],
  ['golf-club', ['golf-club']],
  ['golf-flag-hole', ['golf-flag-hole']],
  ['goodreads', ['goodreads']],
  ['goodreads-g', ['goodreads-g']],
  ['google', ['google']],
  ['google-drive', ['google-drive']],
  ['google-pay', ['google-pay']],
  ['google-play', ['google-play']],
  ['google-plus', ['google-plus']],
  ['google-plus-g', ['google-plus-g']],
  ['google-wallet', ['google-wallet']],
  ['gopuram', ['gopuram']],
  ['graduation-cap', ['graduation-cap']],
  ['mortar-board', ['graduation-cap']],
  ['gramophone', ['gramophone']],
  ['grapes', ['grapes']],
  ['grate', ['grate']],
  ['grate-droplet', ['grate-droplet']],
  ['gratipay', ['gratipay']],
  ['grav', ['grav']],
  ['greater-than', ['greater-than']],
  ['greater-than-equal', ['greater-than-equal']],
  ['grid', ['grid']],
  ['grid-3', ['grid']],
  ['grid-2', ['grid-2']],
  ['grid-2-plus', ['grid-2-plus']],
  ['grid-4', ['grid-4']],
  ['grid-5', ['grid-5']],
  ['grid-dividers', ['grid-dividers']],
  ['grid-horizontal', ['grid-horizontal']],
  ['grill', ['grill']],
  ['grill-fire', ['grill-fire']],
  ['grill-hot', ['grill-hot']],
  ['grip', ['grip']],
  ['grip-horizontal', ['grip']],
  ['grip-dots', ['grip-dots']],
  ['grip-dots-vertical', ['grip-dots-vertical']],
  ['grip-lines', ['grip-lines']],
  ['grip-lines-vertical', ['grip-lines-vertical']],
  ['grip-vertical', ['grip-vertical']],
  ['gripfire', ['gripfire']],
  ['group-arrows-rotate', ['group-arrows-rotate']],
  ['grunt', ['grunt']],
  ['guarani-sign', ['guarani-sign']],
  ['guilded', ['guilded']],
  ['guitar', ['guitar']],
  ['guitar-electric', ['guitar-electric']],
  ['guitars', ['guitars']],
  ['gulp', ['gulp']],
  ['gun', ['gun']],
  ['gun-slash', ['gun-slash']],
  ['gun-squirt', ['gun-squirt']],
  ['h', ['h']],
  ['h1', ['h1']],
  ['h2', ['h2']],
  ['h3', ['h3']],
  ['h4', ['h4']],
  ['h5', ['h5']],
  ['h6', ['h6']],
  ['hacker-news', ['hacker-news']],
  ['hackerrank', ['hackerrank']],
  ['hammer', ['hammer']],
  ['hammer-crash', ['hammer-crash']],
  ['hammer-war', ['hammer-war']],
  ['hamsa', ['hamsa']],
  ['hand', ['hand']],
  ['hand-paper', ['hand']],
  ['hand-back-fist', ['hand-back-fist']],
  ['hand-rock', ['hand-back-fist']],
  ['hand-back-point-down', ['hand-back-point-down']],
  ['hand-back-point-left', ['hand-back-point-left']],
  ['hand-back-point-ribbon', ['hand-back-point-ribbon']],
  ['hand-back-point-right', ['hand-back-point-right']],
  ['hand-back-point-up', ['hand-back-point-up']],
  ['hand-dots', ['hand-dots']],
  ['allergies', ['hand-dots']],
  ['hand-fingers-crossed', ['hand-fingers-crossed']],
  ['hand-fist', ['hand-fist']],
  ['fist-raised', ['hand-fist']],
  ['hand-heart', ['hand-heart']],
  ['hand-holding', ['hand-holding']],
  ['hand-holding-box', ['hand-holding-box']],
  ['hand-holding-dollar', ['hand-holding-dollar']],
  ['hand-holding-usd', ['hand-holding-dollar']],
  ['hand-holding-droplet', ['hand-holding-droplet']],
  ['hand-holding-water', ['hand-holding-droplet']],
  ['hand-holding-hand', ['hand-holding-hand']],
  ['hand-holding-heart', ['hand-holding-heart']],
  ['hand-holding-magic', ['hand-holding-magic']],
  ['hand-holding-medical', ['hand-holding-medical']],
  ['hand-holding-seedling', ['hand-holding-seedling']],
  ['hand-holding-skull', ['hand-holding-skull']],
  ['hand-horns', ['hand-horns']],
  ['hand-lizard', ['hand-lizard']],
  ['hand-love', ['hand-love']],
  ['hand-middle-finger', ['hand-middle-finger']],
  ['hand-peace', ['hand-peace']],
  ['hand-point-down', ['hand-point-down']],
  ['hand-point-left', ['hand-point-left']],
  ['hand-point-ribbon', ['hand-point-ribbon']],
  ['hand-point-right', ['hand-point-right']],
  ['hand-point-up', ['hand-point-up']],
  ['hand-pointer', ['hand-pointer']],
  ['hand-scissors', ['hand-scissors']],
  ['hand-sparkles', ['hand-sparkles']],
  ['hand-spock', ['hand-spock']],
  ['hand-wave', ['hand-wave']],
  ['handcuffs', ['handcuffs']],
  ['hands', ['hands']],
  ['sign-language', ['hands']],
  ['signing', ['hands']],
  ['hands-asl-interpreting', ['hands-asl-interpreting']],
  ['american-sign-language-interpreting', ['hands-asl-interpreting']],
  ['asl-interpreting', ['hands-asl-interpreting']],
  ['hands-american-sign-language-interpreting', ['hands-asl-interpreting']],
  ['hands-bound', ['hands-bound']],
  ['hands-bubbles', ['hands-bubbles']],
  ['hands-wash', ['hands-bubbles']],
  ['hands-clapping', ['hands-clapping']],
  ['hands-holding', ['hands-holding']],
  ['hands-holding-child', ['hands-holding-child']],
  ['hands-holding-circle', ['hands-holding-circle']],
  ['hands-holding-diamond', ['hands-holding-diamond']],
  ['hand-receiving', ['hands-holding-diamond']],
  ['hands-holding-dollar', ['hands-holding-dollar']],
  ['hands-usd', ['hands-holding-dollar']],
  ['hands-holding-heart', ['hands-holding-heart']],
  ['hands-heart', ['hands-holding-heart']],
  ['hands-praying', ['hands-praying']],
  ['praying-hands', ['hands-praying']],
  ['handshake', ['handshake']],
  ['handshake-angle', ['handshake-angle']],
  ['hands-helping', ['handshake-angle']],
  ['handshake-simple', ['handshake-simple']],
  ['handshake-alt', ['handshake-simple']],
  ['handshake-simple-slash', ['handshake-simple-slash']],
  ['handshake-alt-slash', ['handshake-simple-slash']],
  ['handshake-slash', ['handshake-slash']],
  ['hanukiah', ['hanukiah']],
  ['hard-drive', ['hard-drive']],
  ['hdd', ['hard-drive']],
  ['hashnode', ['hashnode']],
  ['hashtag', ['hashtag']],
  ['hashtag-lock', ['hashtag-lock']],
  ['hat-chef', ['hat-chef']],
  ['hat-cowboy', ['hat-cowboy']],
  ['hat-cowboy-side', ['hat-cowboy-side']],
  ['hat-santa', ['hat-santa']],
  ['hat-winter', ['hat-winter']],
  ['hat-witch', ['hat-witch']],
  ['hat-wizard', ['hat-wizard']],
  ['head-side', ['head-side']],
  ['head-side-brain', ['head-side-brain']],
  ['head-side-cough', ['head-side-cough']],
  ['head-side-cough-slash', ['head-side-cough-slash']],
  ['head-side-goggles', ['head-side-goggles']],
  ['head-vr', ['head-side-goggles']],
  ['head-side-headphones', ['head-side-headphones']],
  ['head-side-heart', ['head-side-heart']],
  ['head-side-mask', ['head-side-mask']],
  ['head-side-medical', ['head-side-medical']],
  ['head-side-virus', ['head-side-virus']],
  ['heading', ['heading']],
  ['header', ['heading']],
  ['headphones', ['headphones']],
  ['headphones-simple', ['headphones-simple']],
  ['headphones-alt', ['headphones-simple']],
  ['headset', ['headset']],
  ['heart', ['heart']],
  ['heart-circle-bolt', ['heart-circle-bolt']],
  ['heart-circle-check', ['heart-circle-check']],
  ['heart-circle-exclamation', ['heart-circle-exclamation']],
  ['heart-circle-minus', ['heart-circle-minus']],
  ['heart-circle-plus', ['heart-circle-plus']],
  ['heart-circle-xmark', ['heart-circle-xmark']],
  ['heart-crack', ['heart-crack']],
  ['heart-broken', ['heart-crack']],
  ['heart-half', ['heart-half']],
  ['heart-half-stroke', ['heart-half-stroke']],
  ['heart-half-alt', ['heart-half-stroke']],
  ['heart-pulse', ['heart-pulse']],
  ['heartbeat', ['heart-pulse']],
  ['heat', ['heat']],
  ['helicopter', ['helicopter']],
  ['helicopter-symbol', ['helicopter-symbol']],
  ['helmet-battle', ['helmet-battle']],
  ['helmet-safety', ['helmet-safety']],
  ['hard-hat', ['helmet-safety']],
  ['hat-hard', ['helmet-safety']],
  ['helmet-un', ['helmet-un']],
  ['hexagon', ['hexagon']],
  ['hexagon-check', ['hexagon-check']],
  ['hexagon-divide', ['hexagon-divide']],
  ['hexagon-exclamation', ['hexagon-exclamation']],
  ['hexagon-image', ['hexagon-image']],
  ['hexagon-minus', ['hexagon-minus']],
  ['minus-hexagon', ['hexagon-minus']],
  ['hexagon-plus', ['hexagon-plus']],
  ['plus-hexagon', ['hexagon-plus']],
  ['hexagon-vertical-nft', ['hexagon-vertical-nft']],
  ['hexagon-vertical-nft-slanted', ['hexagon-vertical-nft-slanted']],
  ['hexagon-xmark', ['hexagon-xmark']],
  ['times-hexagon', ['hexagon-xmark']],
  ['xmark-hexagon', ['hexagon-xmark']],
  ['high-definition', ['high-definition']],
  ['rectangle-hd', ['high-definition']],
  ['highlighter', ['highlighter']],
  ['highlighter-line', ['highlighter-line']],
  ['hill-avalanche', ['hill-avalanche']],
  ['hill-rockslide', ['hill-rockslide']],
  ['hippo', ['hippo']],
  ['hips', ['hips']],
  ['hire-a-helper', ['hire-a-helper']],
  ['hive', ['hive']],
  ['hockey-mask', ['hockey-mask']],
  ['hockey-puck', ['hockey-puck']],
  ['hockey-stick-puck', ['hockey-stick-puck']],
  ['hockey-sticks', ['hockey-sticks']],
  ['holly-berry', ['holly-berry']],
  ['honey-pot', ['honey-pot']],
  ['hood-cloak', ['hood-cloak']],
  ['hooli', ['hooli']],
  ['horizontal-rule', ['horizontal-rule']],
  ['hornbill', ['hornbill']],
  ['horse', ['horse']],
  ['horse-head', ['horse-head']],
  ['horse-saddle', ['horse-saddle']],
  ['hose', ['hose']],
  ['hose-reel', ['hose-reel']],
  ['hospital', ['hospital']],
  ['hospital-alt', ['hospital']],
  ['hospital-wide', ['hospital']],
  ['hospital-user', ['hospital-user']],
  ['hospitals', ['hospitals']],
  ['hot-tub-person', ['hot-tub-person']],
  ['hot-tub', ['hot-tub-person']],
  ['hotdog', ['hotdog']],
  ['hotel', ['hotel']],
  ['hotjar', ['hotjar']],
  ['hourglass', ['hourglass']],
  ['hourglass-empty', ['hourglass']],
  ['hourglass-clock', ['hourglass-clock']],
  ['hourglass-end', ['hourglass-end']],
  ['hourglass-3', ['hourglass-end']],
  ['hourglass-half', ['hourglass-half']],
  ['hourglass-2', ['hourglass-half']],
  ['hourglass-start', ['hourglass-start']],
  ['hourglass-1', ['hourglass-start']],
  ['house', ['house']],
  ['home', ['house']],
  ['home-alt', ['house']],
  ['home-lg-alt', ['house']],
  ['house-blank', ['house-blank']],
  ['home-blank', ['house-blank']],
  ['house-building', ['house-building']],
  ['house-chimney', ['house-chimney']],
  ['home-lg', ['house-chimney']],
  ['house-chimney-blank', ['house-chimney-blank']],
  ['house-chimney-crack', ['house-chimney-crack']],
  ['house-damage', ['house-chimney-crack']],
  ['house-chimney-heart', ['house-chimney-heart']],
  ['house-chimney-medical', ['house-chimney-medical']],
  ['clinic-medical', ['house-chimney-medical']],
  ['house-chimney-user', ['house-chimney-user']],
  ['house-chimney-window', ['house-chimney-window']],
  ['house-circle-check', ['house-circle-check']],
  ['house-circle-exclamation', ['house-circle-exclamation']],
  ['house-circle-xmark', ['house-circle-xmark']],
  ['house-crack', ['house-crack']],
  ['house-day', ['house-day']],
  ['house-fire', ['house-fire']],
  ['house-flag', ['house-flag']],
  ['house-flood-water', ['house-flood-water']],
  ['house-flood-water-circle-arrow-right', ['house-flood-water-circle-arrow-right']],
  ['house-heart', ['house-heart']],
  ['home-heart', ['house-heart']],
  ['house-laptop', ['house-laptop']],
  ['laptop-house', ['house-laptop']],
  ['house-lock', ['house-lock']],
  ['house-medical', ['house-medical']],
  ['house-medical-circle-check', ['house-medical-circle-check']],
  ['house-medical-circle-exclamation', ['house-medical-circle-exclamation']],
  ['house-medical-circle-xmark', ['house-medical-circle-xmark']],
  ['house-medical-flag', ['house-medical-flag']],
  ['house-night', ['house-night']],
  ['house-person-leave', ['house-person-leave']],
  ['house-leave', ['house-person-leave']],
  ['house-person-depart', ['house-person-leave']],
  ['house-person-return', ['house-person-return']],
  ['house-person-arrive', ['house-person-return']],
  ['house-return', ['house-person-return']],
  ['house-signal', ['house-signal']],
  ['house-tree', ['house-tree']],
  ['house-tsunami', ['house-tsunami']],
  ['house-turret', ['house-turret']],
  ['house-user', ['house-user']],
  ['home-user', ['house-user']],
  ['house-water', ['house-water']],
  ['house-flood', ['house-water']],
  ['house-window', ['house-window']],
  ['houzz', ['houzz']],
  ['hryvnia-sign', ['hryvnia-sign']],
  ['hryvnia', ['hryvnia-sign']],
  ['html5', ['html5']],
  ['hubspot', ['hubspot']],
  ['hundred-points', ['hundred-points']],
  ['100', ['hundred-points']],
  ['hurricane', ['hurricane']],
  ['hyphen', ['hyphen']],
  ['i', ['i']],
  ['i-cursor', ['i-cursor']],
  ['ice-cream', ['ice-cream']],
  ['ice-skate', ['ice-skate']],
  ['icicles', ['icicles']],
  ['icons', ['icons']],
  ['heart-music-camera-bolt', ['icons']],
  ['id-badge', ['id-badge']],
  ['id-card', ['id-card']],
  ['drivers-license', ['id-card']],
  ['id-card-clip', ['id-card-clip']],
  ['id-card-alt', ['id-card-clip']],
  ['ideal', ['ideal']],
  ['igloo', ['igloo']],
  ['image', ['image']],
  ['image-landscape', ['image-landscape']],
  ['landscape', ['image-landscape']],
  ['image-polaroid', ['image-polaroid']],
  ['image-polaroid-user', ['image-polaroid-user']],
  ['image-portrait', ['image-portrait']],
  ['portrait', ['image-portrait']],
  ['image-slash', ['image-slash']],
  ['image-user', ['image-user']],
  ['images', ['images']],
  ['images-user', ['images-user']],
  ['imdb', ['imdb']],
  ['inbox', ['inbox']],
  ['inbox-full', ['inbox-full']],
  ['inbox-in', ['inbox-in']],
  ['inbox-arrow-down', ['inbox-in']],
  ['inbox-out', ['inbox-out']],
  ['inbox-arrow-up', ['inbox-out']],
  ['inboxes', ['inboxes']],
  ['indent', ['indent']],
  ['indian-rupee-sign', ['indian-rupee-sign']],
  ['indian-rupee', ['indian-rupee-sign']],
  ['inr', ['indian-rupee-sign']],
  ['industry', ['industry']],
  ['industry-windows', ['industry-windows']],
  ['industry-alt', ['industry-windows']],
  ['infinity', ['infinity']],
  ['info', ['info']],
  ['inhaler', ['inhaler']],
  ['input-numeric', ['input-numeric']],
  ['input-pipe', ['input-pipe']],
  ['input-text', ['input-text']],
  ['instagram', ['instagram']],
  ['instalod', ['instalod']],
  ['integral', ['integral']],
  ['intercom', ['intercom']],
  ['internet-explorer', ['internet-explorer']],
  ['intersection', ['intersection']],
  ['invision', ['invision']],
  ['ioxhost', ['ioxhost']],
  ['island-tropical', ['island-tropical']],
  ['island-tree-palm', ['island-tropical']],
  ['italic', ['italic']],
  ['itch-io', ['itch-io']],
  ['itunes', ['itunes']],
  ['itunes-note', ['itunes-note']],
  ['j', ['j']],
  ['jack-o-lantern', ['jack-o-lantern']],
  ['jar', ['jar']],
  ['jar-wheat', ['jar-wheat']],
  ['java', ['java']],
  ['jedi', ['jedi']],
  ['jedi-order', ['jedi-order']],
  ['jenkins', ['jenkins']],
  ['jet-fighter', ['jet-fighter']],
  ['fighter-jet', ['jet-fighter']],
  ['jet-fighter-up', ['jet-fighter-up']],
  ['jira', ['jira']],
  ['joget', ['joget']],
  ['joint', ['joint']],
  ['joomla', ['joomla']],
  ['joystick', ['joystick']],
  ['js', ['js']],
  ['jsfiddle', ['jsfiddle']],
  ['jug', ['jug']],
  ['jug-detergent', ['jug-detergent']],
  ['k', ['k']],
  ['kaaba', ['kaaba']],
  ['kaggle', ['kaggle']],
  ['kazoo', ['kazoo']],
  ['kerning', ['kerning']],
  ['key', ['key']],
  ['key-skeleton', ['key-skeleton']],
  ['key-skeleton-left-right', ['key-skeleton-left-right']],
  ['keybase', ['keybase']],
  ['keyboard', ['keyboard']],
  ['keyboard-brightness', ['keyboard-brightness']],
  ['keyboard-brightness-low', ['keyboard-brightness-low']],
  ['keyboard-down', ['keyboard-down']],
  ['keyboard-left', ['keyboard-left']],
  ['keycdn', ['keycdn']],
  ['keynote', ['keynote']],
  ['khanda', ['khanda']],
  ['kickstarter', ['kickstarter']],
  ['kickstarter-k', ['kickstarter-k']],
  ['kidneys', ['kidneys']],
  ['kip-sign', ['kip-sign']],
  ['kit-medical', ['kit-medical']],
  ['first-aid', ['kit-medical']],
  ['kitchen-set', ['kitchen-set']],
  ['kite', ['kite']],
  ['kiwi-bird', ['kiwi-bird']],
  ['kiwi-fruit', ['kiwi-fruit']],
  ['knife', ['knife']],
  ['utensil-knife', ['knife']],
  ['knife-kitchen', ['knife-kitchen']],
  ['korvue', ['korvue']],
  ['l', ['l']],
  ['lacrosse-stick', ['lacrosse-stick']],
  ['lacrosse-stick-ball', ['lacrosse-stick-ball']],
  ['lambda', ['lambda']],
  ['lamp', ['lamp']],
  ['lamp-desk', ['lamp-desk']],
  ['lamp-floor', ['lamp-floor']],
  ['lamp-street', ['lamp-street']],
  ['land-mine-on', ['land-mine-on']],
  ['landmark', ['landmark']],
  ['landmark-dome', ['landmark-dome']],
  ['landmark-alt', ['landmark-dome']],
  ['landmark-flag', ['landmark-flag']],
  ['language', ['language']],
  ['laptop', ['laptop']],
  ['laptop-arrow-down', ['laptop-arrow-down']],
  ['laptop-code', ['laptop-code']],
  ['laptop-file', ['laptop-file']],
  ['laptop-medical', ['laptop-medical']],
  ['laptop-mobile', ['laptop-mobile']],
  ['phone-laptop', ['laptop-mobile']],
  ['laptop-slash', ['laptop-slash']],
  ['laravel', ['laravel']],
  ['lari-sign', ['lari-sign']],
  ['lasso', ['lasso']],
  ['lasso-sparkles', ['lasso-sparkles']],
  ['lastfm', ['lastfm']],
  ['layer-group', ['layer-group']],
  ['layer-minus', ['layer-minus']],
  ['layer-group-minus', ['layer-minus']],
  ['layer-plus', ['layer-plus']],
  ['layer-group-plus', ['layer-plus']],
  ['leaf', ['leaf']],
  ['leaf-heart', ['leaf-heart']],
  ['leaf-maple', ['leaf-maple']],
  ['leaf-oak', ['leaf-oak']],
  ['leafy-green', ['leafy-green']],
  ['leanpub', ['leanpub']],
  ['left', ['left']],
  ['arrow-alt-left', ['left']],
  ['left-from-line', ['left-from-line']],
  ['arrow-alt-from-right', ['left-from-line']],
  ['left-long', ['left-long']],
  ['long-arrow-alt-left', ['left-long']],
  ['left-long-to-line', ['left-long-to-line']],
  ['left-right', ['left-right']],
  ['arrows-alt-h', ['left-right']],
  ['left-to-line', ['left-to-line']],
  ['arrow-alt-to-left', ['left-to-line']],
  ['lemon', ['lemon']],
  ['less', ['less']],
  ['less-than', ['less-than']],
  ['less-than-equal', ['less-than-equal']],
  ['life-ring', ['life-ring']],
  ['light-ceiling', ['light-ceiling']],
  ['light-emergency', ['light-emergency']],
  ['light-emergency-on', ['light-emergency-on']],
  ['light-switch', ['light-switch']],
  ['light-switch-off', ['light-switch-off']],
  ['light-switch-on', ['light-switch-on']],
  ['lightbulb', ['lightbulb']],
  ['lightbulb-cfl', ['lightbulb-cfl']],
  ['lightbulb-cfl-on', ['lightbulb-cfl-on']],
  ['lightbulb-dollar', ['lightbulb-dollar']],
  ['lightbulb-exclamation', ['lightbulb-exclamation']],
  ['lightbulb-exclamation-on', ['lightbulb-exclamation-on']],
  ['lightbulb-on', ['lightbulb-on']],
  ['lightbulb-slash', ['lightbulb-slash']],
  ['lights-holiday', ['lights-holiday']],
  ['line', ['line']],
  ['line-columns', ['line-columns']],
  ['line-height', ['line-height']],
  ['lines-leaning', ['lines-leaning']],
  ['link', ['link']],
  ['chain', ['link']],
  ['link-horizontal', ['link-horizontal']],
  ['chain-horizontal', ['link-horizontal']],
  ['link-horizontal-slash', ['link-horizontal-slash']],
  ['chain-horizontal-slash', ['link-horizontal-slash']],
  ['link-simple', ['link-simple']],
  ['link-simple-slash', ['link-simple-slash']],
  ['link-slash', ['link-slash']],
  ['chain-broken', ['link-slash']],
  ['chain-slash', ['link-slash']],
  ['unlink', ['link-slash']],
  ['linkedin', ['linkedin']],
  ['linkedin-in', ['linkedin-in']],
  ['linode', ['linode']],
  ['linux', ['linux']],
  ['lips', ['lips']],
  ['lira-sign', ['lira-sign']],
  ['list', ['list']],
  ['list-squares', ['list']],
  ['list-check', ['list-check']],
  ['tasks', ['list-check']],
  ['list-dropdown', ['list-dropdown']],
  ['list-music', ['list-music']],
  ['list-ol', ['list-ol']],
  ['list-1-2', ['list-ol']],
  ['list-numeric', ['list-ol']],
  ['list-radio', ['list-radio']],
  ['list-timeline', ['list-timeline']],
  ['list-tree', ['list-tree']],
  ['list-ul', ['list-ul']],
  ['list-dots', ['list-ul']],
  ['litecoin-sign', ['litecoin-sign']],
  ['loader', ['loader']],
  ['lobster', ['lobster']],
  ['location-arrow', ['location-arrow']],
  ['location-check', ['location-check']],
  ['map-marker-check', ['location-check']],
  ['location-crosshairs', ['location-crosshairs']],
  ['location', ['location-crosshairs']],
  ['location-crosshairs-slash', ['location-crosshairs-slash']],
  ['location-slash', ['location-crosshairs-slash']],
  ['location-dot', ['location-dot']],
  ['map-marker-alt', ['location-dot']],
  ['location-dot-slash', ['location-dot-slash']],
  ['map-marker-alt-slash', ['location-dot-slash']],
  ['location-exclamation', ['location-exclamation']],
  ['map-marker-exclamation', ['location-exclamation']],
  ['location-minus', ['location-minus']],
  ['map-marker-minus', ['location-minus']],
  ['location-pen', ['location-pen']],
  ['map-marker-edit', ['location-pen']],
  ['location-pin', ['location-pin']],
  ['map-marker', ['location-pin']],
  ['location-pin-lock', ['location-pin-lock']],
  ['location-pin-slash', ['location-pin-slash']],
  ['map-marker-slash', ['location-pin-slash']],
  ['location-plus', ['location-plus']],
  ['map-marker-plus', ['location-plus']],
  ['location-question', ['location-question']],
  ['map-marker-question', ['location-question']],
  ['location-smile', ['location-smile']],
  ['map-marker-smile', ['location-smile']],
  ['location-xmark', ['location-xmark']],
  ['map-marker-times', ['location-xmark']],
  ['map-marker-xmark', ['location-xmark']],
  ['lock', ['lock']],
  ['lock-a', ['lock-a']],
  ['lock-hashtag', ['lock-hashtag']],
  ['lock-keyhole', ['lock-keyhole']],
  ['lock-alt', ['lock-keyhole']],
  ['lock-keyhole-open', ['lock-keyhole-open']],
  ['lock-open-alt', ['lock-keyhole-open']],
  ['lock-open', ['lock-open']],
  ['locust', ['locust']],
  ['lollipop', ['lollipop']],
  ['lollypop', ['lollipop']],
  ['loveseat', ['loveseat']],
  ['couch-small', ['loveseat']],
  ['luchador-mask', ['luchador-mask']],
  ['luchador', ['luchador-mask']],
  ['mask-luchador', ['luchador-mask']],
  ['lungs', ['lungs']],
  ['lungs-virus', ['lungs-virus']],
  ['lyft', ['lyft']],
  ['m', ['m']],
  ['mace', ['mace']],
  ['magento', ['magento']],
  ['magnet', ['magnet']],
  ['magnifying-glass', ['magnifying-glass']],
  ['search', ['magnifying-glass']],
  ['magnifying-glass-arrow-right', ['magnifying-glass-arrow-right']],
  ['magnifying-glass-chart', ['magnifying-glass-chart']],
  ['magnifying-glass-dollar', ['magnifying-glass-dollar']],
  ['search-dollar', ['magnifying-glass-dollar']],
  ['magnifying-glass-location', ['magnifying-glass-location']],
  ['search-location', ['magnifying-glass-location']],
  ['magnifying-glass-minus', ['magnifying-glass-minus']],
  ['search-minus', ['magnifying-glass-minus']],
  ['magnifying-glass-plus', ['magnifying-glass-plus']],
  ['search-plus', ['magnifying-glass-plus']],
  ['mailbox', ['mailbox']],
  ['mailchimp', ['mailchimp']],
  ['manat-sign', ['manat-sign']],
  ['mandalorian', ['mandalorian']],
  ['mandolin', ['mandolin']],
  ['mango', ['mango']],
  ['manhole', ['manhole']],
  ['map', ['map']],
  ['map-location', ['map-location']],
  ['map-marked', ['map-location']],
  ['map-location-dot', ['map-location-dot']],
  ['map-marked-alt', ['map-location-dot']],
  ['map-pin', ['map-pin']],
  ['markdown', ['markdown']],
  ['marker', ['marker']],
  ['mars', ['mars']],
  ['mars-and-venus', ['mars-and-venus']],
  ['mars-and-venus-burst', ['mars-and-venus-burst']],
  ['mars-double', ['mars-double']],
  ['mars-stroke', ['mars-stroke']],
  ['mars-stroke-right', ['mars-stroke-right']],
  ['mars-stroke-h', ['mars-stroke-right']],
  ['mars-stroke-up', ['mars-stroke-up']],
  ['mars-stroke-v', ['mars-stroke-up']],
  ['martini-glass', ['martini-glass']],
  ['glass-martini-alt', ['martini-glass']],
  ['martini-glass-citrus', ['martini-glass-citrus']],
  ['cocktail', ['martini-glass-citrus']],
  ['martini-glass-empty', ['martini-glass-empty']],
  ['glass-martini', ['martini-glass-empty']],
  ['mask', ['mask']],
  ['mask-face', ['mask-face']],
  ['mask-snorkel', ['mask-snorkel']],
  ['mask-ventilator', ['mask-ventilator']],
  ['masks-theater', ['masks-theater']],
  ['theater-masks', ['masks-theater']],
  ['mastodon', ['mastodon']],
  ['mattress-pillow', ['mattress-pillow']],
  ['maxcdn', ['maxcdn']],
  ['maximize', ['maximize']],
  ['expand-arrows-alt', ['maximize']],
  ['mdb', ['mdb']],
  ['meat', ['meat']],
  ['medal', ['medal']],
  ['medapps', ['medapps']],
  ['medium', ['medium']],
  ['medium-m', ['medium']],
  ['medrt', ['medrt']],
  ['meetup', ['meetup']],
  ['megaphone', ['megaphone']],
  ['megaport', ['megaport']],
  ['melon', ['melon']],
  ['melon-slice', ['melon-slice']],
  ['memo', ['memo']],
  ['memo-circle-check', ['memo-circle-check']],
  ['memo-circle-info', ['memo-circle-info']],
  ['memo-pad', ['memo-pad']],
  ['memory', ['memory']],
  ['mendeley', ['mendeley']],
  ['menorah', ['menorah']],
  ['mercury', ['mercury']],
  ['merge', ['merge']],
  ['message', ['message']],
  ['comment-alt', ['message']],
  ['message-arrow-down', ['message-arrow-down']],
  ['comment-alt-arrow-down', ['message-arrow-down']],
  ['message-arrow-up', ['message-arrow-up']],
  ['comment-alt-arrow-up', ['message-arrow-up']],
  ['message-arrow-up-right', ['message-arrow-up-right']],
  ['message-bot', ['message-bot']],
  ['message-captions', ['message-captions']],
  ['comment-alt-captions', ['message-captions']],
  ['message-check', ['message-check']],
  ['comment-alt-check', ['message-check']],
  ['message-code', ['message-code']],
  ['message-dollar', ['message-dollar']],
  ['comment-alt-dollar', ['message-dollar']],
  ['message-dots', ['message-dots']],
  ['comment-alt-dots', ['message-dots']],
  ['messaging', ['message-dots']],
  ['message-exclamation', ['message-exclamation']],
  ['comment-alt-exclamation', ['message-exclamation']],
  ['message-image', ['message-image']],
  ['comment-alt-image', ['message-image']],
  ['message-lines', ['message-lines']],
  ['comment-alt-lines', ['message-lines']],
  ['message-medical', ['message-medical']],
  ['comment-alt-medical', ['message-medical']],
  ['message-middle', ['message-middle']],
  ['comment-middle-alt', ['message-middle']],
  ['message-middle-top', ['message-middle-top']],
  ['comment-middle-top-alt', ['message-middle-top']],
  ['message-minus', ['message-minus']],
  ['comment-alt-minus', ['message-minus']],
  ['message-music', ['message-music']],
  ['comment-alt-music', ['message-music']],
  ['message-pen', ['message-pen']],
  ['comment-alt-edit', ['message-pen']],
  ['message-edit', ['message-pen']],
  ['message-plus', ['message-plus']],
  ['comment-alt-plus', ['message-plus']],
  ['message-question', ['message-question']],
  ['message-quote', ['message-quote']],
  ['comment-alt-quote', ['message-quote']],
  ['message-slash', ['message-slash']],
  ['comment-alt-slash', ['message-slash']],
  ['message-smile', ['message-smile']],
  ['comment-alt-smile', ['message-smile']],
  ['message-sms', ['message-sms']],
  ['message-text', ['message-text']],
  ['comment-alt-text', ['message-text']],
  ['message-xmark', ['message-xmark']],
  ['comment-alt-times', ['message-xmark']],
  ['message-times', ['message-xmark']],
  ['messages', ['messages']],
  ['comments-alt', ['messages']],
  ['messages-dollar', ['messages-dollar']],
  ['comments-alt-dollar', ['messages-dollar']],
  ['messages-question', ['messages-question']],
  ['meta', ['meta']],
  ['meteor', ['meteor']],
  ['meter', ['meter']],
  ['meter-bolt', ['meter-bolt']],
  ['meter-droplet', ['meter-droplet']],
  ['meter-fire', ['meter-fire']],
  ['microblog', ['microblog']],
  ['microchip', ['microchip']],
  ['microchip-ai', ['microchip-ai']],
  ['microphone', ['microphone']],
  ['microphone-lines', ['microphone-lines']],
  ['microphone-alt', ['microphone-lines']],
  ['microphone-lines-slash', ['microphone-lines-slash']],
  ['microphone-alt-slash', ['microphone-lines-slash']],
  ['microphone-slash', ['microphone-slash']],
  ['microphone-stand', ['microphone-stand']],
  ['microscope', ['microscope']],
  ['microsoft', ['microsoft']],
  ['microwave', ['microwave']],
  ['mill-sign', ['mill-sign']],
  ['minimize', ['minimize']],
  ['compress-arrows-alt', ['minimize']],
  ['minus', ['minus']],
  ['subtract', ['minus']],
  ['mistletoe', ['mistletoe']],
  ['mitten', ['mitten']],
  ['mix', ['mix']],
  ['mixcloud', ['mixcloud']],
  ['mixer', ['mixer']],
  ['mizuni', ['mizuni']],
  ['mobile', ['mobile']],
  ['mobile-android', ['mobile']],
  ['mobile-phone', ['mobile']],
  ['mobile-button', ['mobile-button']],
  ['mobile-notch', ['mobile-notch']],
  ['mobile-iphone', ['mobile-notch']],
  ['mobile-retro', ['mobile-retro']],
  ['mobile-screen', ['mobile-screen']],
  ['mobile-android-alt', ['mobile-screen']],
  ['mobile-screen-button', ['mobile-screen-button']],
  ['mobile-alt', ['mobile-screen-button']],
  ['mobile-signal', ['mobile-signal']],
  ['mobile-signal-out', ['mobile-signal-out']],
  ['modx', ['modx']],
  ['monero', ['monero']],
  ['money-bill', ['money-bill']],
  ['money-bill-1', ['money-bill-1']],
  ['money-bill-alt', ['money-bill-1']],
  ['money-bill-1-wave', ['money-bill-1-wave']],
  ['money-bill-wave-alt', ['money-bill-1-wave']],
  ['money-bill-simple', ['money-bill-simple']],
  ['money-bill-simple-wave', ['money-bill-simple-wave']],
  ['money-bill-transfer', ['money-bill-transfer']],
  ['money-bill-trend-up', ['money-bill-trend-up']],
  ['money-bill-wave', ['money-bill-wave']],
  ['money-bill-wheat', ['money-bill-wheat']],
  ['money-bills', ['money-bills']],
  ['money-bills-simple', ['money-bills-simple']],
  ['money-bills-alt', ['money-bills-simple']],
  ['money-check', ['money-check']],
  ['money-check-dollar', ['money-check-dollar']],
  ['money-check-alt', ['money-check-dollar']],
  ['money-check-dollar-pen', ['money-check-dollar-pen']],
  ['money-check-edit-alt', ['money-check-dollar-pen']],
  ['money-check-pen', ['money-check-pen']],
  ['money-check-edit', ['money-check-pen']],
  ['money-from-bracket', ['money-from-bracket']],
  ['money-simple-from-bracket', ['money-simple-from-bracket']],
  ['monitor-waveform', ['monitor-waveform']],
  ['monitor-heart-rate', ['monitor-waveform']],
  ['monkey', ['monkey']],
  ['monument', ['monument']],
  ['moon', ['moon']],
  ['moon-cloud', ['moon-cloud']],
  ['moon-over-sun', ['moon-over-sun']],
  ['eclipse-alt', ['moon-over-sun']],
  ['moon-stars', ['moon-stars']],
  ['moped', ['moped']],
  ['mortar-pestle', ['mortar-pestle']],
  ['mosque', ['mosque']],
  ['mosquito', ['mosquito']],
  ['mosquito-net', ['mosquito-net']],
  ['motorcycle', ['motorcycle']],
  ['mound', ['mound']],
  ['mountain', ['mountain']],
  ['mountain-city', ['mountain-city']],
  ['mountain-sun', ['mountain-sun']],
  ['mountains', ['mountains']],
  ['mouse-field', ['mouse-field']],
  ['mp3-player', ['mp3-player']],
  ['mug', ['mug']],
  ['mug-hot', ['mug-hot']],
  ['mug-marshmallows', ['mug-marshmallows']],
  ['mug-saucer', ['mug-saucer']],
  ['coffee', ['mug-saucer']],
  ['mug-tea', ['mug-tea']],
  ['mug-tea-saucer', ['mug-tea-saucer']],
  ['mushroom', ['mushroom']],
  ['music', ['music']],
  ['music-note', ['music-note']],
  ['music-alt', ['music-note']],
  ['music-note-slash', ['music-note-slash']],
  ['music-alt-slash', ['music-note-slash']],
  ['music-slash', ['music-slash']],
  ['n', ['n']],
  ['naira-sign', ['naira-sign']],
  ['napster', ['napster']],
  ['narwhal', ['narwhal']],
  ['neos', ['neos']],
  ['nesting-dolls', ['nesting-dolls']],
  ['network-wired', ['network-wired']],
  ['neuter', ['neuter']],
  ['newspaper', ['newspaper']],
  ['nfc', ['nfc']],
  ['nfc-directional', ['nfc-directional']],
  ['nfc-lock', ['nfc-lock']],
  ['nfc-magnifying-glass', ['nfc-magnifying-glass']],
  ['nfc-pen', ['nfc-pen']],
  ['nfc-signal', ['nfc-signal']],
  ['nfc-slash', ['nfc-slash']],
  ['nfc-symbol', ['nfc-symbol']],
  ['nfc-trash', ['nfc-trash']],
  ['nimblr', ['nimblr']],
  ['node', ['node']],
  ['node-js', ['node-js']],
  ['not-equal', ['not-equal']],
  ['notdef', ['notdef']],
  ['note', ['note']],
  ['note-medical', ['note-medical']],
  ['note-sticky', ['note-sticky']],
  ['sticky-note', ['note-sticky']],
  ['notebook', ['notebook']],
  ['notes', ['notes']],
  ['notes-medical', ['notes-medical']],
  ['npm', ['npm']],
  ['ns8', ['ns8']],
  ['nutritionix', ['nutritionix']],
  ['o', ['o']],
  ['object-exclude', ['object-exclude']],
  ['object-group', ['object-group']],
  ['object-intersect', ['object-intersect']],
  ['object-subtract', ['object-subtract']],
  ['object-ungroup', ['object-ungroup']],
  ['object-union', ['object-union']],
  ['objects-align-bottom', ['objects-align-bottom']],
  ['objects-align-center-horizontal', ['objects-align-center-horizontal']],
  ['objects-align-center-vertical', ['objects-align-center-vertical']],
  ['objects-align-left', ['objects-align-left']],
  ['objects-align-right', ['objects-align-right']],
  ['objects-align-top', ['objects-align-top']],
  ['objects-column', ['objects-column']],
  ['octagon', ['octagon']],
  ['octagon-check', ['octagon-check']],
  ['octagon-divide', ['octagon-divide']],
  ['octagon-exclamation', ['octagon-exclamation']],
  ['octagon-minus', ['octagon-minus']],
  ['minus-octagon', ['octagon-minus']],
  ['octagon-plus', ['octagon-plus']],
  ['plus-octagon', ['octagon-plus']],
  ['octagon-xmark', ['octagon-xmark']],
  ['times-octagon', ['octagon-xmark']],
  ['xmark-octagon', ['octagon-xmark']],
  ['octopus-deploy', ['octopus-deploy']],
  ['odnoklassniki', ['odnoklassniki']],
  ['oil-can', ['oil-can']],
  ['oil-can-drip', ['oil-can-drip']],
  ['oil-temperature', ['oil-temperature']],
  ['oil-temp', ['oil-temperature']],
  ['oil-well', ['oil-well']],
  ['old-republic', ['old-republic']],
  ['olive', ['olive']],
  ['olive-branch', ['olive-branch']],
  ['om', ['om']],
  ['omega', ['omega']],
  ['onion', ['onion']],
  ['opencart', ['opencart']],
  ['openid', ['openid']],
  ['opera', ['opera']],
  ['optin-monster', ['optin-monster']],
  ['option', ['option']],
  ['orcid', ['orcid']],
  ['ornament', ['ornament']],
  ['osi', ['osi']],
  ['otter', ['otter']],
  ['outdent', ['outdent']],
  ['dedent', ['outdent']],
  ['outlet', ['outlet']],
  ['oven', ['oven']],
  ['overline', ['overline']],
  ['p', ['p']],
  ['padlet', ['padlet']],
  ['page', ['page']],
  ['page-caret-down', ['page-caret-down']],
  ['file-caret-down', ['page-caret-down']],
  ['page-caret-up', ['page-caret-up']],
  ['file-caret-up', ['page-caret-up']],
  ['page4', ['page4']],
  ['pagelines', ['pagelines']],
  ['pager', ['pager']],
  ['paint-roller', ['paint-roller']],
  ['paintbrush', ['paintbrush']],
  ['paint-brush', ['paintbrush']],
  ['paintbrush-fine', ['paintbrush-fine']],
  ['paint-brush-alt', ['paintbrush-fine']],
  ['paint-brush-fine', ['paintbrush-fine']],
  ['paintbrush-alt', ['paintbrush-fine']],
  ['paintbrush-pencil', ['paintbrush-pencil']],
  ['palette', ['palette']],
  ['palfed', ['palfed']],
  ['pallet', ['pallet']],
  ['pallet-box', ['pallet-box']],
  ['pallet-boxes', ['pallet-boxes']],
  ['palette-boxes', ['pallet-boxes']],
  ['pallet-alt', ['pallet-boxes']],
  ['pan-food', ['pan-food']],
  ['pan-frying', ['pan-frying']],
  ['pancakes', ['pancakes']],
  ['panel-ews', ['panel-ews']],
  ['panel-fire', ['panel-fire']],
  ['panorama', ['panorama']],
  ['paper-plane', ['paper-plane']],
  ['paper-plane-top', ['paper-plane-top']],
  ['paper-plane-alt', ['paper-plane-top']],
  ['send', ['paper-plane-top']],
  ['paperclip', ['paperclip']],
  ['paperclip-vertical', ['paperclip-vertical']],
  ['parachute-box', ['parachute-box']],
  ['paragraph', ['paragraph']],
  ['paragraph-left', ['paragraph-left']],
  ['paragraph-rtl', ['paragraph-left']],
  ['party-bell', ['party-bell']],
  ['party-horn', ['party-horn']],
  ['passport', ['passport']],
  ['paste', ['paste']],
  ['file-clipboard', ['paste']],
  ['patreon', ['patreon']],
  ['pause', ['pause']],
  ['paw', ['paw']],
  ['paw-claws', ['paw-claws']],
  ['paw-simple', ['paw-simple']],
  ['paw-alt', ['paw-simple']],
  ['paypal', ['paypal']],
  ['peace', ['peace']],
  ['peach', ['peach']],
  ['peanut', ['peanut']],
  ['peanuts', ['peanuts']],
  ['peapod', ['peapod']],
  ['pear', ['pear']],
  ['pedestal', ['pedestal']],
  ['pegasus', ['pegasus']],
  ['pen', ['pen']],
  ['pen-circle', ['pen-circle']],
  ['pen-clip', ['pen-clip']],
  ['pen-alt', ['pen-clip']],
  ['pen-clip-slash', ['pen-clip-slash']],
  ['pen-alt-slash', ['pen-clip-slash']],
  ['pen-fancy', ['pen-fancy']],
  ['pen-fancy-slash', ['pen-fancy-slash']],
  ['pen-field', ['pen-field']],
  ['pen-line', ['pen-line']],
  ['pen-nib', ['pen-nib']],
  ['pen-nib-slash', ['pen-nib-slash']],
  ['pen-paintbrush', ['pen-paintbrush']],
  ['pencil-paintbrush', ['pen-paintbrush']],
  ['pen-ruler', ['pen-ruler']],
  ['pencil-ruler', ['pen-ruler']],
  ['pen-slash', ['pen-slash']],
  ['pen-swirl', ['pen-swirl']],
  ['pen-to-square', ['pen-to-square']],
  ['edit', ['pen-to-square']],
  ['pencil', ['pencil']],
  ['pencil-alt', ['pencil']],
  ['pencil-slash', ['pencil-slash']],
  ['people', ['people']],
  ['people-arrows', ['people-arrows']],
  ['people-arrows-left-right', ['people-arrows']],
  ['people-carry-box', ['people-carry-box']],
  ['people-carry', ['people-carry-box']],
  ['people-dress', ['people-dress']],
  ['people-dress-simple', ['people-dress-simple']],
  ['people-group', ['people-group']],
  ['people-line', ['people-line']],
  ['people-pants', ['people-pants']],
  ['people-pants-simple', ['people-pants-simple']],
  ['people-pulling', ['people-pulling']],
  ['people-robbery', ['people-robbery']],
  ['people-roof', ['people-roof']],
  ['people-simple', ['people-simple']],
  ['pepper', ['pepper']],
  ['pepper-hot', ['pepper-hot']],
  ['perbyte', ['perbyte']],
  ['percent', ['percent']],
  ['percentage', ['percent']],
  ['period', ['period']],
  ['periscope', ['periscope']],
  ['person', ['person']],
  ['male', ['person']],
  ['person-arrow-down-to-line', ['person-arrow-down-to-line']],
  ['person-arrow-up-from-line', ['person-arrow-up-from-line']],
  ['person-biking', ['person-biking']],
  ['biking', ['person-biking']],
  ['person-biking-mountain', ['person-biking-mountain']],
  ['biking-mountain', ['person-biking-mountain']],
  ['person-booth', ['person-booth']],
  ['person-breastfeeding', ['person-breastfeeding']],
  ['person-burst', ['person-burst']],
  ['person-cane', ['person-cane']],
  ['person-carry-box', ['person-carry-box']],
  ['person-carry', ['person-carry-box']],
  ['person-chalkboard', ['person-chalkboard']],
  ['person-circle-check', ['person-circle-check']],
  ['person-circle-exclamation', ['person-circle-exclamation']],
  ['person-circle-minus', ['person-circle-minus']],
  ['person-circle-plus', ['person-circle-plus']],
  ['person-circle-question', ['person-circle-question']],
  ['person-circle-xmark', ['person-circle-xmark']],
  ['person-digging', ['person-digging']],
  ['digging', ['person-digging']],
  ['person-dolly', ['person-dolly']],
  ['person-dolly-empty', ['person-dolly-empty']],
  ['person-dots-from-line', ['person-dots-from-line']],
  ['diagnoses', ['person-dots-from-line']],
  ['person-dress', ['person-dress']],
  ['female', ['person-dress']],
  ['person-dress-burst', ['person-dress-burst']],
  ['person-dress-simple', ['person-dress-simple']],
  ['person-drowning', ['person-drowning']],
  ['person-falling', ['person-falling']],
  ['person-falling-burst', ['person-falling-burst']],
  ['person-from-portal', ['person-from-portal']],
  ['portal-exit', ['person-from-portal']],
  ['person-half-dress', ['person-half-dress']],
  ['person-harassing', ['person-harassing']],
  ['person-hiking', ['person-hiking']],
  ['hiking', ['person-hiking']],
  ['person-military-pointing', ['person-military-pointing']],
  ['person-military-rifle', ['person-military-rifle']],
  ['person-military-to-person', ['person-military-to-person']],
  ['person-pinball', ['person-pinball']],
  ['person-praying', ['person-praying']],
  ['pray', ['person-praying']],
  ['person-pregnant', ['person-pregnant']],
  ['person-rays', ['person-rays']],
  ['person-rifle', ['person-rifle']],
  ['person-running', ['person-running']],
  ['running', ['person-running']],
  ['person-seat', ['person-seat']],
  ['person-seat-reclined', ['person-seat-reclined']],
  ['person-shelter', ['person-shelter']],
  ['person-sign', ['person-sign']],
  ['person-simple', ['person-simple']],
  ['person-skating', ['person-skating']],
  ['skating', ['person-skating']],
  ['person-ski-jumping', ['person-ski-jumping']],
  ['ski-jump', ['person-ski-jumping']],
  ['person-ski-lift', ['person-ski-lift']],
  ['ski-lift', ['person-ski-lift']],
  ['person-skiing', ['person-skiing']],
  ['skiing', ['person-skiing']],
  ['person-skiing-nordic', ['person-skiing-nordic']],
  ['skiing-nordic', ['person-skiing-nordic']],
  ['person-sledding', ['person-sledding']],
  ['sledding', ['person-sledding']],
  ['person-snowboarding', ['person-snowboarding']],
  ['snowboarding', ['person-snowboarding']],
  ['person-snowmobiling', ['person-snowmobiling']],
  ['snowmobile', ['person-snowmobiling']],
  ['person-swimming', ['person-swimming']],
  ['swimmer', ['person-swimming']],
  ['person-through-window', ['person-through-window']],
  ['person-to-door', ['person-to-door']],
  ['person-to-portal', ['person-to-portal']],
  ['portal-enter', ['person-to-portal']],
  ['person-walking', ['person-walking']],
  ['walking', ['person-walking']],
  ['person-walking-arrow-loop-left', ['person-walking-arrow-loop-left']],
  ['person-walking-arrow-right', ['person-walking-arrow-right']],
  ['person-walking-dashed-line-arrow-right', ['person-walking-dashed-line-arrow-right']],
  ['person-walking-luggage', ['person-walking-luggage']],
  ['person-walking-with-cane', ['person-walking-with-cane']],
  ['blind', ['person-walking-with-cane']],
  ['peseta-sign', ['peseta-sign']],
  ['peso-sign', ['peso-sign']],
  ['phabricator', ['phabricator']],
  ['phoenix-framework', ['phoenix-framework']],
  ['phoenix-squadron', ['phoenix-squadron']],
  ['phone', ['phone']],
  ['phone-arrow-down-left', ['phone-arrow-down-left']],
  ['phone-arrow-down', ['phone-arrow-down-left']],
  ['phone-incoming', ['phone-arrow-down-left']],
  ['phone-arrow-up-right', ['phone-arrow-up-right']],
  ['phone-arrow-up', ['phone-arrow-up-right']],
  ['phone-outgoing', ['phone-arrow-up-right']],
  ['phone-flip', ['phone-flip']],
  ['phone-alt', ['phone-flip']],
  ['phone-hangup', ['phone-hangup']],
  ['phone-intercom', ['phone-intercom']],
  ['phone-missed', ['phone-missed']],
  ['phone-office', ['phone-office']],
  ['phone-plus', ['phone-plus']],
  ['phone-rotary', ['phone-rotary']],
  ['phone-slash', ['phone-slash']],
  ['phone-volume', ['phone-volume']],
  ['volume-control-phone', ['phone-volume']],
  ['phone-xmark', ['phone-xmark']],
  ['photo-film', ['photo-film']],
  ['photo-video', ['photo-film']],
  ['photo-film-music', ['photo-film-music']],
  ['php', ['php']],
  ['pi', ['pi']],
  ['piano', ['piano']],
  ['piano-keyboard', ['piano-keyboard']],
  ['pickleball', ['pickleball']],
  ['pie', ['pie']],
  ['pied-piper', ['pied-piper']],
  ['pied-piper-alt', ['pied-piper-alt']],
  ['pied-piper-hat', ['pied-piper-hat']],
  ['pied-piper-pp', ['pied-piper-pp']],
  ['pig', ['pig']],
  ['piggy-bank', ['piggy-bank']],
  ['pills', ['pills']],
  ['pinata', ['pinata']],
  ['pinball', ['pinball']],
  ['pineapple', ['pineapple']],
  ['pinterest', ['pinterest']],
  ['pinterest-p', ['pinterest-p']],
  ['pipe', ['pipe']],
  ['pipe-circle-check', ['pipe-circle-check']],
  ['pipe-collar', ['pipe-collar']],
  ['pipe-section', ['pipe-section']],
  ['pipe-smoking', ['pipe-smoking']],
  ['pipe-valve', ['pipe-valve']],
  ['pix', ['pix']],
  ['pizza', ['pizza']],
  ['pizza-slice', ['pizza-slice']],
  ['place-of-worship', ['place-of-worship']],
  ['plane', ['plane']],
  ['plane-arrival', ['plane-arrival']],
  ['plane-circle-check', ['plane-circle-check']],
  ['plane-circle-exclamation', ['plane-circle-exclamation']],
  ['plane-circle-xmark', ['plane-circle-xmark']],
  ['plane-departure', ['plane-departure']],
  ['plane-engines', ['plane-engines']],
  ['plane-alt', ['plane-engines']],
  ['plane-lock', ['plane-lock']],
  ['plane-prop', ['plane-prop']],
  ['plane-slash', ['plane-slash']],
  ['plane-tail', ['plane-tail']],
  ['plane-up', ['plane-up']],
  ['plane-up-slash', ['plane-up-slash']],
  ['planet-moon', ['planet-moon']],
  ['planet-ringed', ['planet-ringed']],
  ['plant-wilt', ['plant-wilt']],
  ['plate-utensils', ['plate-utensils']],
  ['plate-wheat', ['plate-wheat']],
  ['play', ['play']],
  ['play-pause', ['play-pause']],
  ['playstation', ['playstation']],
  ['plug', ['plug']],
  ['plug-circle-bolt', ['plug-circle-bolt']],
  ['plug-circle-check', ['plug-circle-check']],
  ['plug-circle-exclamation', ['plug-circle-exclamation']],
  ['plug-circle-minus', ['plug-circle-minus']],
  ['plug-circle-plus', ['plug-circle-plus']],
  ['plug-circle-xmark', ['plug-circle-xmark']],
  ['plus', ['plus']],
  ['add', ['plus']],
  ['plus-large', ['plus-large']],
  ['plus-minus', ['plus-minus']],
  ['podcast', ['podcast']],
  ['podium', ['podium']],
  ['podium-star', ['podium-star']],
  ['police-box', ['police-box']],
  ['poll-people', ['poll-people']],
  ['pompebled', ['pompebled']],
  ['poo', ['poo']],
  ['poo-storm', ['poo-storm']],
  ['poo-bolt', ['poo-storm']],
  ['pool-8-ball', ['pool-8-ball']],
  ['poop', ['poop']],
  ['popcorn', ['popcorn']],
  ['popsicle', ['popsicle']],
  ['pot-food', ['pot-food']],
  ['potato', ['potato']],
  ['power-off', ['power-off']],
  ['prescription', ['prescription']],
  ['prescription-bottle', ['prescription-bottle']],
  ['prescription-bottle-medical', ['prescription-bottle-medical']],
  ['prescription-bottle-alt', ['prescription-bottle-medical']],
  ['presentation-screen', ['presentation-screen']],
  ['presentation', ['presentation-screen']],
  ['pretzel', ['pretzel']],
  ['print', ['print']],
  ['print-magnifying-glass', ['print-magnifying-glass']],
  ['print-search', ['print-magnifying-glass']],
  ['print-slash', ['print-slash']],
  ['product-hunt', ['product-hunt']],
  ['projector', ['projector']],
  ['pump', ['pump']],
  ['pump-medical', ['pump-medical']],
  ['pump-soap', ['pump-soap']],
  ['pumpkin', ['pumpkin']],
  ['pushed', ['pushed']],
  ['puzzle', ['puzzle']],
  ['puzzle-piece', ['puzzle-piece']],
  ['puzzle-piece-simple', ['puzzle-piece-simple']],
  ['puzzle-piece-alt', ['puzzle-piece-simple']],
  ['python', ['python']],
  ['q', ['q']],
  ['qq', ['qq']],
  ['qrcode', ['qrcode']],
  ['question', ['question']],
  ['quinscape', ['quinscape']],
  ['quora', ['quora']],
  ['quote-left', ['quote-left']],
  ['quote-left-alt', ['quote-left']],
  ['quote-right', ['quote-right']],
  ['quote-right-alt', ['quote-right']],
  ['quotes', ['quotes']],
  ['r', ['r']],
  ['r-project', ['r-project']],
  ['rabbit', ['rabbit']],
  ['rabbit-running', ['rabbit-running']],
  ['rabbit-fast', ['rabbit-running']],
  ['racquet', ['racquet']],
  ['radar', ['radar']],
  ['radiation', ['radiation']],
  ['radio', ['radio']],
  ['radio-tuner', ['radio-tuner']],
  ['radio-alt', ['radio-tuner']],
  ['rainbow', ['rainbow']],
  ['raindrops', ['raindrops']],
  ['ram', ['ram']],
  ['ramp-loading', ['ramp-loading']],
  ['ranking-star', ['ranking-star']],
  ['raspberry-pi', ['raspberry-pi']],
  ['ravelry', ['ravelry']],
  ['raygun', ['raygun']],
  ['react', ['react']],
  ['reacteurope', ['reacteurope']],
  ['readme', ['readme']],
  ['rebel', ['rebel']],
  ['receipt', ['receipt']],
  ['record-vinyl', ['record-vinyl']],
  ['rectangle', ['rectangle']],
  ['rectangle-landscape', ['rectangle']],
  ['rectangle-ad', ['rectangle-ad']],
  ['ad', ['rectangle-ad']],
  ['rectangle-barcode', ['rectangle-barcode']],
  ['barcode-alt', ['rectangle-barcode']],
  ['rectangle-code', ['rectangle-code']],
  ['rectangle-history', ['rectangle-history']],
  ['rectangle-history-circle-plus', ['rectangle-history-circle-plus']],
  ['rectangle-history-circle-user', ['rectangle-history-circle-user']],
  ['rectangle-list', ['rectangle-list']],
  ['list-alt', ['rectangle-list']],
  ['rectangle-pro', ['rectangle-pro']],
  ['pro', ['rectangle-pro']],
  ['rectangle-terminal', ['rectangle-terminal']],
  ['rectangle-vertical', ['rectangle-vertical']],
  ['rectangle-portrait', ['rectangle-vertical']],
  ['rectangle-vertical-history', ['rectangle-vertical-history']],
  ['rectangle-wide', ['rectangle-wide']],
  ['rectangle-xmark', ['rectangle-xmark']],
  ['rectangle-times', ['rectangle-xmark']],
  ['times-rectangle', ['rectangle-xmark']],
  ['window-close', ['rectangle-xmark']],
  ['rectangles-mixed', ['rectangles-mixed']],
  ['recycle', ['recycle']],
  ['red-river', ['red-river']],
  ['reddit', ['reddit']],
  ['reddit-alien', ['reddit-alien']],
  ['redhat', ['redhat']],
  ['reel', ['reel']],
  ['refrigerator', ['refrigerator']],
  ['registered', ['registered']],
  ['renren', ['renren']],
  ['repeat', ['repeat']],
  ['repeat-1', ['repeat-1']],
  ['reply', ['reply']],
  ['mail-reply', ['reply']],
  ['reply-all', ['reply-all']],
  ['mail-reply-all', ['reply-all']],
  ['reply-clock', ['reply-clock']],
  ['reply-time', ['reply-clock']],
  ['replyd', ['replyd']],
  ['republican', ['republican']],
  ['researchgate', ['researchgate']],
  ['resolving', ['resolving']],
  ['restroom', ['restroom']],
  ['restroom-simple', ['restroom-simple']],
  ['retweet', ['retweet']],
  ['rev', ['rev']],
  ['rhombus', ['rhombus']],
  ['ribbon', ['ribbon']],
  ['right', ['right']],
  ['arrow-alt-right', ['right']],
  ['right-from-bracket', ['right-from-bracket']],
  ['sign-out-alt', ['right-from-bracket']],
  ['right-from-line', ['right-from-line']],
  ['arrow-alt-from-left', ['right-from-line']],
  ['right-left', ['right-left']],
  ['exchange-alt', ['right-left']],
  ['right-long', ['right-long']],
  ['long-arrow-alt-right', ['right-long']],
  ['right-long-to-line', ['right-long-to-line']],
  ['right-to-bracket', ['right-to-bracket']],
  ['sign-in-alt', ['right-to-bracket']],
  ['right-to-line', ['right-to-line']],
  ['arrow-alt-to-right', ['right-to-line']],
  ['ring', ['ring']],
  ['ring-diamond', ['ring-diamond']],
  ['rings-wedding', ['rings-wedding']],
  ['road', ['road']],
  ['road-barrier', ['road-barrier']],
  ['road-bridge', ['road-bridge']],
  ['road-circle-check', ['road-circle-check']],
  ['road-circle-exclamation', ['road-circle-exclamation']],
  ['road-circle-xmark', ['road-circle-xmark']],
  ['road-lock', ['road-lock']],
  ['road-spikes', ['road-spikes']],
  ['robot', ['robot']],
  ['robot-astromech', ['robot-astromech']],
  ['rocket', ['rocket']],
  ['rocket-launch', ['rocket-launch']],
  ['rocketchat', ['rocketchat']],
  ['rockrms', ['rockrms']],
  ['roller-coaster', ['roller-coaster']],
  ['rotate', ['rotate']],
  ['sync-alt', ['rotate']],
  ['rotate-exclamation', ['rotate-exclamation']],
  ['rotate-left', ['rotate-left']],
  ['rotate-back', ['rotate-left']],
  ['rotate-backward', ['rotate-left']],
  ['undo-alt', ['rotate-left']],
  ['rotate-right', ['rotate-right']],
  ['redo-alt', ['rotate-right']],
  ['rotate-forward', ['rotate-right']],
  ['route', ['route']],
  ['route-highway', ['route-highway']],
  ['route-interstate', ['route-interstate']],
  ['router', ['router']],
  ['rss', ['rss']],
  ['feed', ['rss']],
  ['ruble-sign', ['ruble-sign']],
  ['rouble', ['ruble-sign']],
  ['rub', ['ruble-sign']],
  ['ruble', ['ruble-sign']],
  ['rug', ['rug']],
  ['rugby-ball', ['rugby-ball']],
  ['ruler', ['ruler']],
  ['ruler-combined', ['ruler-combined']],
  ['ruler-horizontal', ['ruler-horizontal']],
  ['ruler-triangle', ['ruler-triangle']],
  ['ruler-vertical', ['ruler-vertical']],
  ['rupee-sign', ['rupee-sign']],
  ['rupee', ['rupee-sign']],
  ['rupiah-sign', ['rupiah-sign']],
  ['rust', ['rust']],
  ['rv', ['rv']],
  ['s', ['s']],
  ['sack', ['sack']],
  ['sack-dollar', ['sack-dollar']],
  ['sack-xmark', ['sack-xmark']],
  ['safari', ['safari']],
  ['sailboat', ['sailboat']],
  ['salad', ['salad']],
  ['bowl-salad', ['salad']],
  ['salesforce', ['salesforce']],
  ['salt-shaker', ['salt-shaker']],
  ['sandwich', ['sandwich']],
  ['sass', ['sass']],
  ['satellite', ['satellite']],
  ['satellite-dish', ['satellite-dish']],
  ['sausage', ['sausage']],
  ['saxophone', ['saxophone']],
  ['saxophone-fire', ['saxophone-fire']],
  ['sax-hot', ['saxophone-fire']],
  ['scale-balanced', ['scale-balanced']],
  ['balance-scale', ['scale-balanced']],
  ['scale-unbalanced', ['scale-unbalanced']],
  ['balance-scale-left', ['scale-unbalanced']],
  ['scale-unbalanced-flip', ['scale-unbalanced-flip']],
  ['balance-scale-right', ['scale-unbalanced-flip']],
  ['scalpel', ['scalpel']],
  ['scalpel-line-dashed', ['scalpel-line-dashed']],
  ['scalpel-path', ['scalpel-line-dashed']],
  ['scanner-gun', ['scanner-gun']],
  ['scanner', ['scanner-gun']],
  ['scanner-image', ['scanner-image']],
  ['scanner-keyboard', ['scanner-keyboard']],
  ['scanner-touchscreen', ['scanner-touchscreen']],
  ['scarecrow', ['scarecrow']],
  ['scarf', ['scarf']],
  ['schlix', ['schlix']],
  ['school', ['school']],
  ['school-circle-check', ['school-circle-check']],
  ['school-circle-exclamation', ['school-circle-exclamation']],
  ['school-circle-xmark', ['school-circle-xmark']],
  ['school-flag', ['school-flag']],
  ['school-lock', ['school-lock']],
  ['scissors', ['scissors']],
  ['cut', ['scissors']],
  ['screen-users', ['screen-users']],
  ['users-class', ['screen-users']],
  ['screencast', ['screencast']],
  ['screenpal', ['screenpal']],
  ['screwdriver', ['screwdriver']],
  ['screwdriver-wrench', ['screwdriver-wrench']],
  ['tools', ['screwdriver-wrench']],
  ['scribble', ['scribble']],
  ['scribd', ['scribd']],
  ['scroll', ['scroll']],
  ['scroll-old', ['scroll-old']],
  ['scroll-torah', ['scroll-torah']],
  ['torah', ['scroll-torah']],
  ['scrubber', ['scrubber']],
  ['scythe', ['scythe']],
  ['sd-card', ['sd-card']],
  ['sd-cards', ['sd-cards']],
  ['seal', ['seal']],
  ['seal-exclamation', ['seal-exclamation']],
  ['seal-question', ['seal-question']],
  ['searchengin', ['searchengin']],
  ['seat-airline', ['seat-airline']],
  ['section', ['section']],
  ['seedling', ['seedling']],
  ['sprout', ['seedling']],
  ['sellcast', ['sellcast']],
  ['sellsy', ['sellsy']],
  ['semicolon', ['semicolon']],
  ['send-back', ['send-back']],
  ['send-backward', ['send-backward']],
  ['sensor', ['sensor']],
  ['sensor-cloud', ['sensor-cloud']],
  ['sensor-smoke', ['sensor-cloud']],
  ['sensor-fire', ['sensor-fire']],
  ['sensor-on', ['sensor-on']],
  ['sensor-triangle-exclamation', ['sensor-triangle-exclamation']],
  ['sensor-alert', ['sensor-triangle-exclamation']],
  ['server', ['server']],
  ['servicestack', ['servicestack']],
  ['shapes', ['shapes']],
  ['triangle-circle-square', ['shapes']],
  ['share', ['share']],
  ['arrow-turn-right', ['share']],
  ['mail-forward', ['share']],
  ['share-all', ['share-all']],
  ['share-from-square', ['share-from-square']],
  ['share-square', ['share-from-square']],
  ['share-nodes', ['share-nodes']],
  ['share-alt', ['share-nodes']],
  ['sheep', ['sheep']],
  ['sheet-plastic', ['sheet-plastic']],
  ['shekel-sign', ['shekel-sign']],
  ['ils', ['shekel-sign']],
  ['shekel', ['shekel-sign']],
  ['sheqel', ['shekel-sign']],
  ['sheqel-sign', ['shekel-sign']],
  ['shelves', ['shelves']],
  ['inventory', ['shelves']],
  ['shelves-empty', ['shelves-empty']],
  ['shield', ['shield']],
  ['shield-blank', ['shield']],
  ['shield-cat', ['shield-cat']],
  ['shield-check', ['shield-check']],
  ['shield-cross', ['shield-cross']],
  ['shield-dog', ['shield-dog']],
  ['shield-exclamation', ['shield-exclamation']],
  ['shield-halved', ['shield-halved']],
  ['shield-alt', ['shield-halved']],
  ['shield-heart', ['shield-heart']],
  ['shield-keyhole', ['shield-keyhole']],
  ['shield-minus', ['shield-minus']],
  ['shield-plus', ['shield-plus']],
  ['shield-quartered', ['shield-quartered']],
  ['shield-slash', ['shield-slash']],
  ['shield-virus', ['shield-virus']],
  ['shield-xmark', ['shield-xmark']],
  ['shield-times', ['shield-xmark']],
  ['ship', ['ship']],
  ['shirt', ['shirt']],
  ['t-shirt', ['shirt']],
  ['tshirt', ['shirt']],
  ['shirt-long-sleeve', ['shirt-long-sleeve']],
  ['shirt-running', ['shirt-running']],
  ['shirt-tank-top', ['shirt-tank-top']],
  ['shirtsinbulk', ['shirtsinbulk']],
  ['shish-kebab', ['shish-kebab']],
  ['shoe-prints', ['shoe-prints']],
  ['shop', ['shop']],
  ['store-alt', ['shop']],
  ['shop-lock', ['shop-lock']],
  ['shop-slash', ['shop-slash']],
  ['store-alt-slash', ['shop-slash']],
  ['shopify', ['shopify']],
  ['shopware', ['shopware']],
  ['shovel', ['shovel']],
  ['shovel-snow', ['shovel-snow']],
  ['shower', ['shower']],
  ['shower-down', ['shower-down']],
  ['shower-alt', ['shower-down']],
  ['shredder', ['shredder']],
  ['shrimp', ['shrimp']],
  ['shuffle', ['shuffle']],
  ['random', ['shuffle']],
  ['shutters', ['shutters']],
  ['shuttle-space', ['shuttle-space']],
  ['space-shuttle', ['shuttle-space']],
  ['shuttlecock', ['shuttlecock']],
  ['sickle', ['sickle']],
  ['sidebar', ['sidebar']],
  ['sidebar-flip', ['sidebar-flip']],
  ['sigma', ['sigma']],
  ['sign-hanging', ['sign-hanging']],
  ['sign', ['sign-hanging']],
  ['signal', ['signal']],
  ['signal-5', ['signal']],
  ['signal-perfect', ['signal']],
  ['signal-bars', ['signal-bars']],
  ['signal-alt', ['signal-bars']],
  ['signal-alt-4', ['signal-bars']],
  ['signal-bars-strong', ['signal-bars']],
  ['signal-bars-fair', ['signal-bars-fair']],
  ['signal-alt-2', ['signal-bars-fair']],
  ['signal-bars-good', ['signal-bars-good']],
  ['signal-alt-3', ['signal-bars-good']],
  ['signal-bars-slash', ['signal-bars-slash']],
  ['signal-alt-slash', ['signal-bars-slash']],
  ['signal-bars-weak', ['signal-bars-weak']],
  ['signal-alt-1', ['signal-bars-weak']],
  ['signal-fair', ['signal-fair']],
  ['signal-2', ['signal-fair']],
  ['signal-good', ['signal-good']],
  ['signal-3', ['signal-good']],
  ['signal-slash', ['signal-slash']],
  ['signal-stream', ['signal-stream']],
  ['signal-stream-slash', ['signal-stream-slash']],
  ['signal-strong', ['signal-strong']],
  ['signal-4', ['signal-strong']],
  ['signal-weak', ['signal-weak']],
  ['signal-1', ['signal-weak']],
  ['signature', ['signature']],
  ['signature-lock', ['signature-lock']],
  ['signature-slash', ['signature-slash']],
  ['signs-post', ['signs-post']],
  ['map-signs', ['signs-post']],
  ['sim-card', ['sim-card']],
  ['sim-cards', ['sim-cards']],
  ['simplybuilt', ['simplybuilt']],
  ['sink', ['sink']],
  ['siren', ['siren']],
  ['siren-on', ['siren-on']],
  ['sistrix', ['sistrix']],
  ['sitemap', ['sitemap']],
  ['sith', ['sith']],
  ['sitrox', ['sitrox']],
  ['skeleton', ['skeleton']],
  ['sketch', ['sketch']],
  ['ski-boot', ['ski-boot']],
  ['ski-boot-ski', ['ski-boot-ski']],
  ['skull', ['skull']],
  ['skull-cow', ['skull-cow']],
  ['skull-crossbones', ['skull-crossbones']],
  ['skyatlas', ['skyatlas']],
  ['skype', ['skype']],
  ['slack', ['slack']],
  ['slack-hash', ['slack']],
  ['slash', ['slash']],
  ['slash-back', ['slash-back']],
  ['slash-forward', ['slash-forward']],
  ['sleigh', ['sleigh']],
  ['slider', ['slider']],
  ['sliders', ['sliders']],
  ['sliders-h', ['sliders']],
  ['sliders-simple', ['sliders-simple']],
  ['sliders-up', ['sliders-up']],
  ['sliders-v', ['sliders-up']],
  ['slideshare', ['slideshare']],
  ['slot-machine', ['slot-machine']],
  ['smog', ['smog']],
  ['smoke', ['smoke']],
  ['smoking', ['smoking']],
  ['snake', ['snake']],
  ['snapchat', ['snapchat']],
  ['snapchat-ghost', ['snapchat']],
  ['snooze', ['snooze']],
  ['zzz', ['snooze']],
  ['snow-blowing', ['snow-blowing']],
  ['snowflake', ['snowflake']],
  ['snowflakes', ['snowflakes']],
  ['snowman', ['snowman']],
  ['snowman-head', ['snowman-head']],
  ['frosty-head', ['snowman-head']],
  ['snowplow', ['snowplow']],
  ['soap', ['soap']],
  ['socks', ['socks']],
  ['soft-serve', ['soft-serve']],
  ['creemee', ['soft-serve']],
  ['solar-panel', ['solar-panel']],
  ['solar-system', ['solar-system']],
  ['sort', ['sort']],
  ['unsorted', ['sort']],
  ['sort-down', ['sort-down']],
  ['sort-desc', ['sort-down']],
  ['sort-up', ['sort-up']],
  ['sort-asc', ['sort-up']],
  ['soundcloud', ['soundcloud']],
  ['sourcetree', ['sourcetree']],
  ['spa', ['spa']],
  ['space-awesome', ['space-awesome']],
  ['space-station-moon', ['space-station-moon']],
  ['space-station-moon-construction', ['space-station-moon-construction']],
  ['space-station-moon-alt', ['space-station-moon-construction']],
  ['spade', ['spade']],
  ['spaghetti-monster-flying', ['spaghetti-monster-flying']],
  ['pastafarianism', ['spaghetti-monster-flying']],
  ['sparkles', ['sparkles']],
  ['speakap', ['speakap']],
  ['speaker', ['speaker']],
  ['speaker-deck', ['speaker-deck']],
  ['speakers', ['speakers']],
  ['spell-check', ['spell-check']],
  ['spider', ['spider']],
  ['spider-black-widow', ['spider-black-widow']],
  ['spider-web', ['spider-web']],
  ['spinner', ['spinner']],
  ['spinner-third', ['spinner-third']],
  ['split', ['split']],
  ['splotch', ['splotch']],
  ['spoon', ['spoon']],
  ['utensil-spoon', ['spoon']],
  ['sportsball', ['sportsball']],
  ['spotify', ['spotify']],
  ['spray-can', ['spray-can']],
  ['spray-can-sparkles', ['spray-can-sparkles']],
  ['air-freshener', ['spray-can-sparkles']],
  ['sprinkler', ['sprinkler']],
  ['sprinkler-ceiling', ['sprinkler-ceiling']],
  ['square', ['square']],
  ['square-0', ['square-0']],
  ['square-1', ['square-1']],
  ['square-2', ['square-2']],
  ['square-3', ['square-3']],
  ['square-4', ['square-4']],
  ['square-5', ['square-5']],
  ['square-6', ['square-6']],
  ['square-7', ['square-7']],
  ['square-8', ['square-8']],
  ['square-9', ['square-9']],
  ['square-a', ['square-a']],
  ['square-a-lock', ['square-a-lock']],
  ['square-ampersand', ['square-ampersand']],
  ['square-arrow-down', ['square-arrow-down']],
  ['arrow-square-down', ['square-arrow-down']],
  ['square-arrow-down-left', ['square-arrow-down-left']],
  ['square-arrow-down-right', ['square-arrow-down-right']],
  ['square-arrow-left', ['square-arrow-left']],
  ['arrow-square-left', ['square-arrow-left']],
  ['square-arrow-right', ['square-arrow-right']],
  ['arrow-square-right', ['square-arrow-right']],
  ['square-arrow-up', ['square-arrow-up']],
  ['arrow-square-up', ['square-arrow-up']],
  ['square-arrow-up-left', ['square-arrow-up-left']],
  ['square-arrow-up-right', ['square-arrow-up-right']],
  ['external-link-square', ['square-arrow-up-right']],
  ['square-b', ['square-b']],
  ['square-behance', ['square-behance']],
  ['behance-square', ['square-behance']],
  ['square-bolt', ['square-bolt']],
  ['square-c', ['square-c']],
  ['square-caret-down', ['square-caret-down']],
  ['caret-square-down', ['square-caret-down']],
  ['square-caret-left', ['square-caret-left']],
  ['caret-square-left', ['square-caret-left']],
  ['square-caret-right', ['square-caret-right']],
  ['caret-square-right', ['square-caret-right']],
  ['square-caret-up', ['square-caret-up']],
  ['caret-square-up', ['square-caret-up']],
  ['square-check', ['square-check']],
  ['check-square', ['square-check']],
  ['square-chevron-down', ['square-chevron-down']],
  ['chevron-square-down', ['square-chevron-down']],
  ['square-chevron-left', ['square-chevron-left']],
  ['chevron-square-left', ['square-chevron-left']],
  ['square-chevron-right', ['square-chevron-right']],
  ['chevron-square-right', ['square-chevron-right']],
  ['square-chevron-up', ['square-chevron-up']],
  ['chevron-square-up', ['square-chevron-up']],
  ['square-code', ['square-code']],
  ['square-d', ['square-d']],
  ['square-dashed', ['square-dashed']],
  ['square-divide', ['square-divide']],
  ['square-dollar', ['square-dollar']],
  ['dollar-square', ['square-dollar']],
  ['usd-square', ['square-dollar']],
  ['square-down', ['square-down']],
  ['arrow-alt-square-down', ['square-down']],
  ['square-down-left', ['square-down-left']],
  ['square-down-right', ['square-down-right']],
  ['square-dribbble', ['square-dribbble']],
  ['dribbble-square', ['square-dribbble']],
  ['square-e', ['square-e']],
  ['square-ellipsis', ['square-ellipsis']],
  ['square-ellipsis-vertical', ['square-ellipsis-vertical']],
  ['square-envelope', ['square-envelope']],
  ['envelope-square', ['square-envelope']],
  ['square-exclamation', ['square-exclamation']],
  ['exclamation-square', ['square-exclamation']],
  ['square-f', ['square-f']],
  ['square-facebook', ['square-facebook']],
  ['facebook-square', ['square-facebook']],
  ['square-font-awesome', ['square-font-awesome']],
  ['square-font-awesome-stroke', ['square-font-awesome-stroke']],
  ['font-awesome-alt', ['square-font-awesome-stroke']],
  ['square-fragile', ['square-fragile']],
  ['box-fragile', ['square-fragile']],
  ['square-wine-glass-crack', ['square-fragile']],
  ['square-full', ['square-full']],
  ['square-g', ['square-g']],
  ['square-git', ['square-git']],
  ['git-square', ['square-git']],
  ['square-github', ['square-github']],
  ['github-square', ['square-github']],
  ['square-gitlab', ['square-gitlab']],
  ['gitlab-square', ['square-gitlab']],
  ['square-google-plus', ['square-google-plus']],
  ['google-plus-square', ['square-google-plus']],
  ['square-h', ['square-h']],
  ['h-square', ['square-h']],
  ['square-hacker-news', ['square-hacker-news']],
  ['hacker-news-square', ['square-hacker-news']],
  ['square-heart', ['square-heart']],
  ['heart-square', ['square-heart']],
  ['square-i', ['square-i']],
  ['square-info', ['square-info']],
  ['info-square', ['square-info']],
  ['square-instagram', ['square-instagram']],
  ['instagram-square', ['square-instagram']],
  ['square-j', ['square-j']],
  ['square-js', ['square-js']],
  ['js-square', ['square-js']],
  ['square-k', ['square-k']],
  ['square-kanban', ['square-kanban']],
  ['square-l', ['square-l']],
  ['square-lastfm', ['square-lastfm']],
  ['lastfm-square', ['square-lastfm']],
  ['square-left', ['square-left']],
  ['arrow-alt-square-left', ['square-left']],
  ['square-list', ['square-list']],
  ['square-m', ['square-m']],
  ['square-minus', ['square-minus']],
  ['minus-square', ['square-minus']],
  ['square-n', ['square-n']],
  ['square-nfi', ['square-nfi']],
  ['square-o', ['square-o']],
  ['square-odnoklassniki', ['square-odnoklassniki']],
  ['odnoklassniki-square', ['square-odnoklassniki']],
  ['square-p', ['square-p']],
  ['square-parking', ['square-parking']],
  ['parking', ['square-parking']],
  ['square-parking-slash', ['square-parking-slash']],
  ['parking-slash', ['square-parking-slash']],
  ['square-pen', ['square-pen']],
  ['pen-square', ['square-pen']],
  ['pencil-square', ['square-pen']],
  ['square-person-confined', ['square-person-confined']],
  ['square-phone', ['square-phone']],
  ['phone-square', ['square-phone']],
  ['square-phone-flip', ['square-phone-flip']],
  ['phone-square-alt', ['square-phone-flip']],
  ['square-phone-hangup', ['square-phone-hangup']],
  ['phone-square-down', ['square-phone-hangup']],
  ['square-pied-piper', ['square-pied-piper']],
  ['pied-piper-square', ['square-pied-piper']],
  ['square-pinterest', ['square-pinterest']],
  ['pinterest-square', ['square-pinterest']],
  ['square-plus', ['square-plus']],
  ['plus-square', ['square-plus']],
  ['square-poll-horizontal', ['square-poll-horizontal']],
  ['poll-h', ['square-poll-horizontal']],
  ['square-poll-vertical', ['square-poll-vertical']],
  ['poll', ['square-poll-vertical']],
  ['square-q', ['square-q']],
  ['square-quarters', ['square-quarters']],
  ['square-question', ['square-question']],
  ['question-square', ['square-question']],
  ['square-quote', ['square-quote']],
  ['square-r', ['square-r']],
  ['square-reddit', ['square-reddit']],
  ['reddit-square', ['square-reddit']],
  ['square-right', ['square-right']],
  ['arrow-alt-square-right', ['square-right']],
  ['square-ring', ['square-ring']],
  ['square-root', ['square-root']],
  ['square-root-variable', ['square-root-variable']],
  ['square-root-alt', ['square-root-variable']],
  ['square-rss', ['square-rss']],
  ['rss-square', ['square-rss']],
  ['square-s', ['square-s']],
  ['square-share-nodes', ['square-share-nodes']],
  ['share-alt-square', ['square-share-nodes']],
  ['square-sliders', ['square-sliders']],
  ['sliders-h-square', ['square-sliders']],
  ['square-sliders-vertical', ['square-sliders-vertical']],
  ['sliders-v-square', ['square-sliders-vertical']],
  ['square-small', ['square-small']],
  ['square-snapchat', ['square-snapchat']],
  ['snapchat-square', ['square-snapchat']],
  ['square-star', ['square-star']],
  ['square-steam', ['square-steam']],
  ['steam-square', ['square-steam']],
  ['square-t', ['square-t']],
  ['square-terminal', ['square-terminal']],
  ['square-this-way-up', ['square-this-way-up']],
  ['box-up', ['square-this-way-up']],
  ['square-tumblr', ['square-tumblr']],
  ['tumblr-square', ['square-tumblr']],
  ['square-twitter', ['square-twitter']],
  ['twitter-square', ['square-twitter']],
  ['square-u', ['square-u']],
  ['square-up', ['square-up']],
  ['arrow-alt-square-up', ['square-up']],
  ['square-up-left', ['square-up-left']],
  ['square-up-right', ['square-up-right']],
  ['external-link-square-alt', ['square-up-right']],
  ['square-user', ['square-user']],
  ['square-v', ['square-v']],
  ['square-viadeo', ['square-viadeo']],
  ['viadeo-square', ['square-viadeo']],
  ['square-vimeo', ['square-vimeo']],
  ['vimeo-square', ['square-vimeo']],
  ['square-virus', ['square-virus']],
  ['square-w', ['square-w']],
  ['square-whatsapp', ['square-whatsapp']],
  ['whatsapp-square', ['square-whatsapp']],
  ['square-x', ['square-x']],
  ['square-xing', ['square-xing']],
  ['xing-square', ['square-xing']],
  ['square-xmark', ['square-xmark']],
  ['times-square', ['square-xmark']],
  ['xmark-square', ['square-xmark']],
  ['square-y', ['square-y']],
  ['square-youtube', ['square-youtube']],
  ['youtube-square', ['square-youtube']],
  ['square-z', ['square-z']],
  ['squarespace', ['squarespace']],
  ['squid', ['squid']],
  ['squirrel', ['squirrel']],
  ['stack-exchange', ['stack-exchange']],
  ['stack-overflow', ['stack-overflow']],
  ['stackpath', ['stackpath']],
  ['staff', ['staff']],
  ['staff-snake', ['staff-snake']],
  ['rod-asclepius', ['staff-snake']],
  ['rod-snake', ['staff-snake']],
  ['staff-aesculapius', ['staff-snake']],
  ['stairs', ['stairs']],
  ['stamp', ['stamp']],
  ['standard-definition', ['standard-definition']],
  ['rectangle-sd', ['standard-definition']],
  ['stapler', ['stapler']],
  ['star', ['star']],
  ['star-and-crescent', ['star-and-crescent']],
  ['star-christmas', ['star-christmas']],
  ['star-exclamation', ['star-exclamation']],
  ['star-half', ['star-half']],
  ['star-half-stroke', ['star-half-stroke']],
  ['star-half-alt', ['star-half-stroke']],
  ['star-of-david', ['star-of-david']],
  ['star-of-life', ['star-of-life']],
  ['star-sharp', ['star-sharp']],
  ['star-sharp-half', ['star-sharp-half']],
  ['star-sharp-half-stroke', ['star-sharp-half-stroke']],
  ['star-sharp-half-alt', ['star-sharp-half-stroke']],
  ['star-shooting', ['star-shooting']],
  ['starfighter', ['starfighter']],
  ['starfighter-twin-ion-engine', ['starfighter-twin-ion-engine']],
  ['starfighter-alt', ['starfighter-twin-ion-engine']],
  ['starfighter-twin-ion-engine-advanced', ['starfighter-twin-ion-engine-advanced']],
  ['starfighter-alt-advanced', ['starfighter-twin-ion-engine-advanced']],
  ['stars', ['stars']],
  ['starship', ['starship']],
  ['starship-freighter', ['starship-freighter']],
  ['staylinked', ['staylinked']],
  ['steak', ['steak']],
  ['steam', ['steam']],
  ['steam-symbol', ['steam-symbol']],
  ['steering-wheel', ['steering-wheel']],
  ['sterling-sign', ['sterling-sign']],
  ['gbp', ['sterling-sign']],
  ['pound-sign', ['sterling-sign']],
  ['stethoscope', ['stethoscope']],
  ['sticker-mule', ['sticker-mule']],
  ['stocking', ['stocking']],
  ['stomach', ['stomach']],
  ['stop', ['stop']],
  ['stopwatch', ['stopwatch']],
  ['stopwatch-20', ['stopwatch-20']],
  ['store', ['store']],
  ['store-lock', ['store-lock']],
  ['store-slash', ['store-slash']],
  ['strava', ['strava']],
  ['strawberry', ['strawberry']],
  ['street-view', ['street-view']],
  ['stretcher', ['stretcher']],
  ['strikethrough', ['strikethrough']],
  ['stripe', ['stripe']],
  ['stripe-s', ['stripe-s']],
  ['stroopwafel', ['stroopwafel']],
  ['studiovinari', ['studiovinari']],
  ['stumbleupon', ['stumbleupon']],
  ['stumbleupon-circle', ['stumbleupon-circle']],
  ['subscript', ['subscript']],
  ['suitcase', ['suitcase']],
  ['suitcase-medical', ['suitcase-medical']],
  ['medkit', ['suitcase-medical']],
  ['suitcase-rolling', ['suitcase-rolling']],
  ['sun', ['sun']],
  ['sun-bright', ['sun-bright']],
  ['sun-alt', ['sun-bright']],
  ['sun-cloud', ['sun-cloud']],
  ['sun-dust', ['sun-dust']],
  ['sun-haze', ['sun-haze']],
  ['sun-plant-wilt', ['sun-plant-wilt']],
  ['sunglasses', ['sunglasses']],
  ['sunrise', ['sunrise']],
  ['sunset', ['sunset']],
  ['superpowers', ['superpowers']],
  ['superscript', ['superscript']],
  ['supple', ['supple']],
  ['suse', ['suse']],
  ['sushi', ['sushi']],
  ['nigiri', ['sushi']],
  ['sushi-roll', ['sushi-roll']],
  ['maki-roll', ['sushi-roll']],
  ['makizushi', ['sushi-roll']],
  ['swatchbook', ['swatchbook']],
  ['swift', ['swift']],
  ['sword', ['sword']],
  ['sword-laser', ['sword-laser']],
  ['sword-laser-alt', ['sword-laser-alt']],
  ['swords', ['swords']],
  ['swords-laser', ['swords-laser']],
  ['symbols', ['symbols']],
  ['icons-alt', ['symbols']],
  ['symfony', ['symfony']],
  ['synagogue', ['synagogue']],
  ['syringe', ['syringe']],
  ['t', ['t']],
  ['table', ['table']],
  ['table-cells', ['table-cells']],
  ['th', ['table-cells']],
  ['table-cells-large', ['table-cells-large']],
  ['th-large', ['table-cells-large']],
  ['table-columns', ['table-columns']],
  ['columns', ['table-columns']],
  ['table-layout', ['table-layout']],
  ['table-list', ['table-list']],
  ['th-list', ['table-list']],
  ['table-picnic', ['table-picnic']],
  ['table-pivot', ['table-pivot']],
  ['table-rows', ['table-rows']],
  ['rows', ['table-rows']],
  ['table-tennis-paddle-ball', ['table-tennis-paddle-ball']],
  ['ping-pong-paddle-ball', ['table-tennis-paddle-ball']],
  ['table-tennis', ['table-tennis-paddle-ball']],
  ['table-tree', ['table-tree']],
  ['tablet', ['tablet']],
  ['tablet-android', ['tablet']],
  ['tablet-button', ['tablet-button']],
  ['tablet-rugged', ['tablet-rugged']],
  ['tablet-screen', ['tablet-screen']],
  ['tablet-android-alt', ['tablet-screen']],
  ['tablet-screen-button', ['tablet-screen-button']],
  ['tablet-alt', ['tablet-screen-button']],
  ['tablets', ['tablets']],
  ['tachograph-digital', ['tachograph-digital']],
  ['digital-tachograph', ['tachograph-digital']],
  ['taco', ['taco']],
  ['tag', ['tag']],
  ['tags', ['tags']],
  ['tally', ['tally']],
  ['tally-5', ['tally']],
  ['tally-1', ['tally-1']],
  ['tally-2', ['tally-2']],
  ['tally-3', ['tally-3']],
  ['tally-4', ['tally-4']],
  ['tamale', ['tamale']],
  ['tank-water', ['tank-water']],
  ['tape', ['tape']],
  ['tarp', ['tarp']],
  ['tarp-droplet', ['tarp-droplet']],
  ['taxi', ['taxi']],
  ['cab', ['taxi']],
  ['taxi-bus', ['taxi-bus']],
  ['teamspeak', ['teamspeak']],
  ['teddy-bear', ['teddy-bear']],
  ['teeth', ['teeth']],
  ['teeth-open', ['teeth-open']],
  ['telegram', ['telegram']],
  ['telegram-plane', ['telegram']],
  ['telescope', ['telescope']],
  ['temperature-arrow-down', ['temperature-arrow-down']],
  ['temperature-down', ['temperature-arrow-down']],
  ['temperature-arrow-up', ['temperature-arrow-up']],
  ['temperature-up', ['temperature-arrow-up']],
  ['temperature-empty', ['temperature-empty']],
  ['temperature-0', ['temperature-empty']],
  ['thermometer-0', ['temperature-empty']],
  ['thermometer-empty', ['temperature-empty']],
  ['temperature-full', ['temperature-full']],
  ['temperature-4', ['temperature-full']],
  ['thermometer-4', ['temperature-full']],
  ['thermometer-full', ['temperature-full']],
  ['temperature-half', ['temperature-half']],
  ['temperature-2', ['temperature-half']],
  ['thermometer-2', ['temperature-half']],
  ['thermometer-half', ['temperature-half']],
  ['temperature-high', ['temperature-high']],
  ['temperature-list', ['temperature-list']],
  ['temperature-low', ['temperature-low']],
  ['temperature-quarter', ['temperature-quarter']],
  ['temperature-1', ['temperature-quarter']],
  ['thermometer-1', ['temperature-quarter']],
  ['thermometer-quarter', ['temperature-quarter']],
  ['temperature-snow', ['temperature-snow']],
  ['temperature-frigid', ['temperature-snow']],
  ['temperature-sun', ['temperature-sun']],
  ['temperature-hot', ['temperature-sun']],
  ['temperature-three-quarters', ['temperature-three-quarters']],
  ['temperature-3', ['temperature-three-quarters']],
  ['thermometer-3', ['temperature-three-quarters']],
  ['thermometer-three-quarters', ['temperature-three-quarters']],
  ['tencent-weibo', ['tencent-weibo']],
  ['tenge-sign', ['tenge-sign']],
  ['tenge', ['tenge-sign']],
  ['tennis-ball', ['tennis-ball']],
  ['tent', ['tent']],
  ['tent-arrow-down-to-line', ['tent-arrow-down-to-line']],
  ['tent-arrow-left-right', ['tent-arrow-left-right']],
  ['tent-arrow-turn-left', ['tent-arrow-turn-left']],
  ['tent-arrows-down', ['tent-arrows-down']],
  ['tents', ['tents']],
  ['terminal', ['terminal']],
  ['text', ['text']],
  ['text-height', ['text-height']],
  ['text-size', ['text-size']],
  ['text-slash', ['text-slash']],
  ['remove-format', ['text-slash']],
  ['text-width', ['text-width']],
  ['the-red-yeti', ['the-red-yeti']],
  ['themeco', ['themeco']],
  ['themeisle', ['themeisle']],
  ['thermometer', ['thermometer']],
  ['theta', ['theta']],
  ['think-peaks', ['think-peaks']],
  ['thought-bubble', ['thought-bubble']],
  ['thumbs-down', ['thumbs-down']],
  ['thumbs-up', ['thumbs-up']],
  ['thumbtack', ['thumbtack']],
  ['thumb-tack', ['thumbtack']],
  ['tick', ['tick']],
  ['ticket', ['ticket']],
  ['ticket-airline', ['ticket-airline']],
  ['ticket-simple', ['ticket-simple']],
  ['ticket-alt', ['ticket-simple']],
  ['tickets-airline', ['tickets-airline']],
  ['tiktok', ['tiktok']],
  ['tilde', ['tilde']],
  ['timeline', ['timeline']],
  ['timeline-arrow', ['timeline-arrow']],
  ['timer', ['timer']],
  ['tire', ['tire']],
  ['tire-flat', ['tire-flat']],
  ['tire-pressure-warning', ['tire-pressure-warning']],
  ['tire-rugged', ['tire-rugged']],
  ['toggle-large-off', ['toggle-large-off']],
  ['toggle-large-on', ['toggle-large-on']],
  ['toggle-off', ['toggle-off']],
  ['toggle-on', ['toggle-on']],
  ['toilet', ['toilet']],
  ['toilet-paper', ['toilet-paper']],
  ['toilet-paper-blank', ['toilet-paper-blank']],
  ['toilet-paper-alt', ['toilet-paper-blank']],
  ['toilet-paper-blank-under', ['toilet-paper-blank-under']],
  ['toilet-paper-reverse-alt', ['toilet-paper-blank-under']],
  ['toilet-paper-check', ['toilet-paper-check']],
  ['toilet-paper-slash', ['toilet-paper-slash']],
  ['toilet-paper-under', ['toilet-paper-under']],
  ['toilet-paper-reverse', ['toilet-paper-under']],
  ['toilet-paper-under-slash', ['toilet-paper-under-slash']],
  ['toilet-paper-reverse-slash', ['toilet-paper-under-slash']],
  ['toilet-paper-xmark', ['toilet-paper-xmark']],
  ['toilet-portable', ['toilet-portable']],
  ['toilets-portable', ['toilets-portable']],
  ['tomato', ['tomato']],
  ['tombstone', ['tombstone']],
  ['tombstone-blank', ['tombstone-blank']],
  ['tombstone-alt', ['tombstone-blank']],
  ['toolbox', ['toolbox']],
  ['tooth', ['tooth']],
  ['toothbrush', ['toothbrush']],
  ['torii-gate', ['torii-gate']],
  ['tornado', ['tornado']],
  ['tower-broadcast', ['tower-broadcast']],
  ['broadcast-tower', ['tower-broadcast']],
  ['tower-cell', ['tower-cell']],
  ['tower-control', ['tower-control']],
  ['tower-observation', ['tower-observation']],
  ['tractor', ['tractor']],
  ['trade-federation', ['trade-federation']],
  ['trademark', ['trademark']],
  ['traffic-cone', ['traffic-cone']],
  ['traffic-light', ['traffic-light']],
  ['traffic-light-go', ['traffic-light-go']],
  ['traffic-light-slow', ['traffic-light-slow']],
  ['traffic-light-stop', ['traffic-light-stop']],
  ['trailer', ['trailer']],
  ['train', ['train']],
  ['train-subway', ['train-subway']],
  ['subway', ['train-subway']],
  ['train-subway-tunnel', ['train-subway-tunnel']],
  ['subway-tunnel', ['train-subway-tunnel']],
  ['train-track', ['train-track']],
  ['train-tram', ['train-tram']],
  ['train-tunnel', ['train-tunnel']],
  ['transformer-bolt', ['transformer-bolt']],
  ['transgender', ['transgender']],
  ['transgender-alt', ['transgender']],
  ['transporter', ['transporter']],
  ['transporter-1', ['transporter-1']],
  ['transporter-2', ['transporter-2']],
  ['transporter-3', ['transporter-3']],
  ['transporter-4', ['transporter-4']],
  ['transporter-5', ['transporter-5']],
  ['transporter-6', ['transporter-6']],
  ['transporter-7', ['transporter-7']],
  ['transporter-empty', ['transporter-empty']],
  ['trash', ['trash']],
  ['trash-arrow-up', ['trash-arrow-up']],
  ['trash-restore', ['trash-arrow-up']],
  ['trash-can', ['trash-can']],
  ['trash-alt', ['trash-can']],
  ['trash-can-arrow-up', ['trash-can-arrow-up']],
  ['trash-restore-alt', ['trash-can-arrow-up']],
  ['trash-can-check', ['trash-can-check']],
  ['trash-can-clock', ['trash-can-clock']],
  ['trash-can-list', ['trash-can-list']],
  ['trash-can-plus', ['trash-can-plus']],
  ['trash-can-slash', ['trash-can-slash']],
  ['trash-alt-slash', ['trash-can-slash']],
  ['trash-can-undo', ['trash-can-undo']],
  ['trash-can-arrow-turn-left', ['trash-can-undo']],
  ['trash-undo-alt', ['trash-can-undo']],
  ['trash-can-xmark', ['trash-can-xmark']],
  ['trash-check', ['trash-check']],
  ['trash-clock', ['trash-clock']],
  ['trash-list', ['trash-list']],
  ['trash-plus', ['trash-plus']],
  ['trash-slash', ['trash-slash']],
  ['trash-undo', ['trash-undo']],
  ['trash-arrow-turn-left', ['trash-undo']],
  ['trash-xmark', ['trash-xmark']],
  ['treasure-chest', ['treasure-chest']],
  ['tree', ['tree']],
  ['tree-christmas', ['tree-christmas']],
  ['tree-city', ['tree-city']],
  ['tree-deciduous', ['tree-deciduous']],
  ['tree-alt', ['tree-deciduous']],
  ['tree-decorated', ['tree-decorated']],
  ['tree-large', ['tree-large']],
  ['tree-palm', ['tree-palm']],
  ['trees', ['trees']],
  ['trello', ['trello']],
  ['triangle', ['triangle']],
  ['triangle-exclamation', ['triangle-exclamation']],
  ['exclamation-triangle', ['triangle-exclamation']],
  ['warning', ['triangle-exclamation']],
  ['triangle-instrument', ['triangle-instrument']],
  ['triangle-music', ['triangle-instrument']],
  ['triangle-person-digging', ['triangle-person-digging']],
  ['construction', ['triangle-person-digging']],
  ['trillium', ['trillium']],
  ['trophy', ['trophy']],
  ['trophy-star', ['trophy-star']],
  ['trophy-alt', ['trophy-star']],
  ['trowel', ['trowel']],
  ['trowel-bricks', ['trowel-bricks']],
  ['truck', ['truck']],
  ['truck-arrow-right', ['truck-arrow-right']],
  ['truck-bolt', ['truck-bolt']],
  ['truck-clock', ['truck-clock']],
  ['shipping-timed', ['truck-clock']],
  ['truck-container', ['truck-container']],
  ['truck-container-empty', ['truck-container-empty']],
  ['truck-droplet', ['truck-droplet']],
  ['truck-fast', ['truck-fast']],
  ['shipping-fast', ['truck-fast']],
  ['truck-field', ['truck-field']],
  ['truck-field-un', ['truck-field-un']],
  ['truck-flatbed', ['truck-flatbed']],
  ['truck-front', ['truck-front']],
  ['truck-medical', ['truck-medical']],
  ['ambulance', ['truck-medical']],
  ['truck-monster', ['truck-monster']],
  ['truck-moving', ['truck-moving']],
  ['truck-pickup', ['truck-pickup']],
  ['truck-plane', ['truck-plane']],
  ['truck-plow', ['truck-plow']],
  ['truck-ramp', ['truck-ramp']],
  ['truck-ramp-box', ['truck-ramp-box']],
  ['truck-loading', ['truck-ramp-box']],
  ['truck-ramp-couch', ['truck-ramp-couch']],
  ['truck-couch', ['truck-ramp-couch']],
  ['truck-tow', ['truck-tow']],
  ['trumpet', ['trumpet']],
  ['tty', ['tty']],
  ['teletype', ['tty']],
  ['tty-answer', ['tty-answer']],
  ['teletype-answer', ['tty-answer']],
  ['tugrik-sign', ['tugrik-sign']],
  ['tumblr', ['tumblr']],
  ['turkey', ['turkey']],
  ['turkish-lira-sign', ['turkish-lira-sign']],
  ['try', ['turkish-lira-sign']],
  ['turkish-lira', ['turkish-lira-sign']],
  ['turn-down', ['turn-down']],
  ['level-down-alt', ['turn-down']],
  ['turn-down-left', ['turn-down-left']],
  ['turn-down-right', ['turn-down-right']],
  ['turn-up', ['turn-up']],
  ['level-up-alt', ['turn-up']],
  ['turntable', ['turntable']],
  ['turtle', ['turtle']],
  ['tv', ['tv']],
  ['television', ['tv']],
  ['tv-alt', ['tv']],
  ['tv-music', ['tv-music']],
  ['tv-retro', ['tv-retro']],
  ['twitch', ['twitch']],
  ['twitter', ['twitter']],
  ['typewriter', ['typewriter']],
  ['typo3', ['typo3']],
  ['u', ['u']],
  ['uber', ['uber']],
  ['ubuntu', ['ubuntu']],
  ['ufo', ['ufo']],
  ['ufo-beam', ['ufo-beam']],
  ['uikit', ['uikit']],
  ['umbraco', ['umbraco']],
  ['umbrella', ['umbrella']],
  ['umbrella-beach', ['umbrella-beach']],
  ['umbrella-simple', ['umbrella-simple']],
  ['umbrella-alt', ['umbrella-simple']],
  ['uncharted', ['uncharted']],
  ['underline', ['underline']],
  ['unicorn', ['unicorn']],
  ['uniform-martial-arts', ['uniform-martial-arts']],
  ['union', ['union']],
  ['uniregistry', ['uniregistry']],
  ['unity', ['unity']],
  ['universal-access', ['universal-access']],
  ['unlock', ['unlock']],
  ['unlock-keyhole', ['unlock-keyhole']],
  ['unlock-alt', ['unlock-keyhole']],
  ['unsplash', ['unsplash']],
  ['untappd', ['untappd']],
  ['up', ['up']],
  ['arrow-alt-up', ['up']],
  ['up-down', ['up-down']],
  ['arrows-alt-v', ['up-down']],
  ['up-down-left-right', ['up-down-left-right']],
  ['arrows-alt', ['up-down-left-right']],
  ['up-from-bracket', ['up-from-bracket']],
  ['up-from-dotted-line', ['up-from-dotted-line']],
  ['up-from-line', ['up-from-line']],
  ['arrow-alt-from-bottom', ['up-from-line']],
  ['up-left', ['up-left']],
  ['up-long', ['up-long']],
  ['long-arrow-alt-up', ['up-long']],
  ['up-right', ['up-right']],
  ['up-right-and-down-left-from-center', ['up-right-and-down-left-from-center']],
  ['expand-alt', ['up-right-and-down-left-from-center']],
  ['up-right-from-square', ['up-right-from-square']],
  ['external-link-alt', ['up-right-from-square']],
  ['up-to-dotted-line', ['up-to-dotted-line']],
  ['up-to-line', ['up-to-line']],
  ['arrow-alt-to-top', ['up-to-line']],
  ['upload', ['upload']],
  ['ups', ['ups']],
  ['usb', ['usb']],
  ['usb-drive', ['usb-drive']],
  ['user', ['user']],
  ['user-alien', ['user-alien']],
  ['user-astronaut', ['user-astronaut']],
  ['user-bounty-hunter', ['user-bounty-hunter']],
  ['user-check', ['user-check']],
  ['user-chef', ['user-chef']],
  ['user-clock', ['user-clock']],
  ['user-cowboy', ['user-cowboy']],
  ['user-crown', ['user-crown']],
  ['user-doctor', ['user-doctor']],
  ['user-md', ['user-doctor']],
  ['user-doctor-hair', ['user-doctor-hair']],
  ['user-doctor-hair-long', ['user-doctor-hair-long']],
  ['user-doctor-message', ['user-doctor-message']],
  ['user-md-chat', ['user-doctor-message']],
  ['user-gear', ['user-gear']],
  ['user-cog', ['user-gear']],
  ['user-graduate', ['user-graduate']],
  ['user-group', ['user-group']],
  ['user-friends', ['user-group']],
  ['user-group-crown', ['user-group-crown']],
  ['users-crown', ['user-group-crown']],
  ['user-hair', ['user-hair']],
  ['user-hair-buns', ['user-hair-buns']],
  ['user-hair-long', ['user-hair-long']],
  ['user-hair-mullet', ['user-hair-mullet']],
  ['business-front', ['user-hair-mullet']],
  ['party-back', ['user-hair-mullet']],
  ['trian-balbot', ['user-hair-mullet']],
  ['user-headset', ['user-headset']],
  ['user-helmet-safety', ['user-helmet-safety']],
  ['user-construction', ['user-helmet-safety']],
  ['user-hard-hat', ['user-helmet-safety']],
  ['user-injured', ['user-injured']],
  ['user-large', ['user-large']],
  ['user-alt', ['user-large']],
  ['user-large-slash', ['user-large-slash']],
  ['user-alt-slash', ['user-large-slash']],
  ['user-lock', ['user-lock']],
  ['user-minus', ['user-minus']],
  ['user-music', ['user-music']],
  ['user-ninja', ['user-ninja']],
  ['user-nurse', ['user-nurse']],
  ['user-nurse-hair', ['user-nurse-hair']],
  ['user-nurse-hair-long', ['user-nurse-hair-long']],
  ['user-pen', ['user-pen']],
  ['user-edit', ['user-pen']],
  ['user-pilot', ['user-pilot']],
  ['user-pilot-tie', ['user-pilot-tie']],
  ['user-plus', ['user-plus']],
  ['user-police', ['user-police']],
  ['user-police-tie', ['user-police-tie']],
  ['user-robot', ['user-robot']],
  ['user-robot-xmarks', ['user-robot-xmarks']],
  ['user-secret', ['user-secret']],
  ['user-shakespeare', ['user-shakespeare']],
  ['user-shield', ['user-shield']],
  ['user-slash', ['user-slash']],
  ['user-tag', ['user-tag']],
  ['user-tie', ['user-tie']],
  ['user-tie-hair', ['user-tie-hair']],
  ['user-tie-hair-long', ['user-tie-hair-long']],
  ['user-unlock', ['user-unlock']],
  ['user-visor', ['user-visor']],
  ['user-vneck', ['user-vneck']],
  ['user-vneck-hair', ['user-vneck-hair']],
  ['user-vneck-hair-long', ['user-vneck-hair-long']],
  ['user-xmark', ['user-xmark']],
  ['user-times', ['user-xmark']],
  ['users', ['users']],
  ['users-between-lines', ['users-between-lines']],
  ['users-gear', ['users-gear']],
  ['users-cog', ['users-gear']],
  ['users-line', ['users-line']],
  ['users-medical', ['users-medical']],
  ['users-rays', ['users-rays']],
  ['users-rectangle', ['users-rectangle']],
  ['users-slash', ['users-slash']],
  ['users-viewfinder', ['users-viewfinder']],
  ['usps', ['usps']],
  ['ussunnah', ['ussunnah']],
  ['utensils', ['utensils']],
  ['cutlery', ['utensils']],
  ['utensils-slash', ['utensils-slash']],
  ['utility-pole', ['utility-pole']],
  ['utility-pole-double', ['utility-pole-double']],
  ['v', ['v']],
  ['vaadin', ['vaadin']],
  ['vacuum', ['vacuum']],
  ['vacuum-robot', ['vacuum-robot']],
  ['value-absolute', ['value-absolute']],
  ['van-shuttle', ['van-shuttle']],
  ['shuttle-van', ['van-shuttle']],
  ['vault', ['vault']],
  ['vector-circle', ['vector-circle']],
  ['vector-polygon', ['vector-polygon']],
  ['vector-square', ['vector-square']],
  ['vent-damper', ['vent-damper']],
  ['venus', ['venus']],
  ['venus-double', ['venus-double']],
  ['venus-mars', ['venus-mars']],
  ['vest', ['vest']],
  ['vest-patches', ['vest-patches']],
  ['viacoin', ['viacoin']],
  ['viadeo', ['viadeo']],
  ['vial', ['vial']],
  ['vial-circle-check', ['vial-circle-check']],
  ['vial-virus', ['vial-virus']],
  ['vials', ['vials']],
  ['viber', ['viber']],
  ['video', ['video']],
  ['video-camera', ['video']],
  ['video-arrow-down-left', ['video-arrow-down-left']],
  ['video-arrow-up-right', ['video-arrow-up-right']],
  ['video-plus', ['video-plus']],
  ['video-slash', ['video-slash']],
  ['vihara', ['vihara']],
  ['vimeo', ['vimeo']],
  ['vimeo-v', ['vimeo-v']],
  ['vine', ['vine']],
  ['violin', ['violin']],
  ['virus', ['virus']],
  ['virus-covid', ['virus-covid']],
  ['virus-covid-slash', ['virus-covid-slash']],
  ['virus-slash', ['virus-slash']],
  ['viruses', ['viruses']],
  ['vk', ['vk']],
  ['vnv', ['vnv']],
  ['voicemail', ['voicemail']],
  ['volcano', ['volcano']],
  ['volleyball', ['volleyball']],
  ['volleyball-ball', ['volleyball']],
  ['volume', ['volume']],
  ['volume-medium', ['volume']],
  ['volume-high', ['volume-high']],
  ['volume-up', ['volume-high']],
  ['volume-low', ['volume-low']],
  ['volume-down', ['volume-low']],
  ['volume-off', ['volume-off']],
  ['volume-slash', ['volume-slash']],
  ['volume-xmark', ['volume-xmark']],
  ['volume-mute', ['volume-xmark']],
  ['volume-times', ['volume-xmark']],
  ['vr-cardboard', ['vr-cardboard']],
  ['vuejs', ['vuejs']],
  ['w', ['w']],
  ['waffle', ['waffle']],
  ['wagon-covered', ['wagon-covered']],
  ['walker', ['walker']],
  ['walkie-talkie', ['walkie-talkie']],
  ['wallet', ['wallet']],
  ['wand', ['wand']],
  ['wand-magic', ['wand-magic']],
  ['magic', ['wand-magic']],
  ['wand-magic-sparkles', ['wand-magic-sparkles']],
  ['magic-wand-sparkles', ['wand-magic-sparkles']],
  ['wand-sparkles', ['wand-sparkles']],
  ['warehouse', ['warehouse']],
  ['warehouse-full', ['warehouse-full']],
  ['warehouse-alt', ['warehouse-full']],
  ['washing-machine', ['washing-machine']],
  ['washer', ['washing-machine']],
  ['watch', ['watch']],
  ['watch-apple', ['watch-apple']],
  ['watch-calculator', ['watch-calculator']],
  ['watch-fitness', ['watch-fitness']],
  ['watch-smart', ['watch-smart']],
  ['watchman-monitoring', ['watchman-monitoring']],
  ['water', ['water']],
  ['water-arrow-down', ['water-arrow-down']],
  ['water-lower', ['water-arrow-down']],
  ['water-arrow-up', ['water-arrow-up']],
  ['water-rise', ['water-arrow-up']],
  ['water-ladder', ['water-ladder']],
  ['ladder-water', ['water-ladder']],
  ['swimming-pool', ['water-ladder']],
  ['watermelon-slice', ['watermelon-slice']],
  ['wave-pulse', ['wave-pulse']],
  ['heart-rate', ['wave-pulse']],
  ['wave-sine', ['wave-sine']],
  ['wave-square', ['wave-square']],
  ['wave-triangle', ['wave-triangle']],
  ['waveform', ['waveform']],
  ['waveform-lines', ['waveform-lines']],
  ['waveform-path', ['waveform-lines']],
  ['waze', ['waze']],
  ['weebly', ['weebly']],
  ['weibo', ['weibo']],
  ['weight-hanging', ['weight-hanging']],
  ['weight-scale', ['weight-scale']],
  ['weight', ['weight-scale']],
  ['weixin', ['weixin']],
  ['whale', ['whale']],
  ['whatsapp', ['whatsapp']],
  ['wheat', ['wheat']],
  ['wheat-awn', ['wheat-awn']],
  ['wheat-alt', ['wheat-awn']],
  ['wheat-awn-circle-exclamation', ['wheat-awn-circle-exclamation']],
  ['wheat-awn-slash', ['wheat-awn-slash']],
  ['wheat-slash', ['wheat-slash']],
  ['wheelchair', ['wheelchair']],
  ['wheelchair-move', ['wheelchair-move']],
  ['wheelchair-alt', ['wheelchair-move']],
  ['whiskey-glass', ['whiskey-glass']],
  ['glass-whiskey', ['whiskey-glass']],
  ['whiskey-glass-ice', ['whiskey-glass-ice']],
  ['glass-whiskey-rocks', ['whiskey-glass-ice']],
  ['whistle', ['whistle']],
  ['whmcs', ['whmcs']],
  ['wifi', ['wifi']],
  ['wifi-3', ['wifi']],
  ['wifi-strong', ['wifi']],
  ['wifi-exclamation', ['wifi-exclamation']],
  ['wifi-fair', ['wifi-fair']],
  ['wifi-2', ['wifi-fair']],
  ['wifi-slash', ['wifi-slash']],
  ['wifi-weak', ['wifi-weak']],
  ['wifi-1', ['wifi-weak']],
  ['wikipedia-w', ['wikipedia-w']],
  ['wind', ['wind']],
  ['wind-turbine', ['wind-turbine']],
  ['wind-warning', ['wind-warning']],
  ['wind-circle-exclamation', ['wind-warning']],
  ['window', ['window']],
  ['window-flip', ['window-flip']],
  ['window-alt', ['window-flip']],
  ['window-frame', ['window-frame']],
  ['window-frame-open', ['window-frame-open']],
  ['window-maximize', ['window-maximize']],
  ['window-minimize', ['window-minimize']],
  ['window-restore', ['window-restore']],
  ['windows', ['windows']],
  ['windsock', ['windsock']],
  ['wine-bottle', ['wine-bottle']],
  ['wine-glass', ['wine-glass']],
  ['wine-glass-crack', ['wine-glass-crack']],
  ['fragile', ['wine-glass-crack']],
  ['wine-glass-empty', ['wine-glass-empty']],
  ['wine-glass-alt', ['wine-glass-empty']],
  ['wirsindhandwerk', ['wirsindhandwerk']],
  ['wsh', ['wirsindhandwerk']],
  ['wix', ['wix']],
  ['wizards-of-the-coast', ['wizards-of-the-coast']],
  ['wodu', ['wodu']],
  ['wolf-pack-battalion', ['wolf-pack-battalion']],
  ['won-sign', ['won-sign']],
  ['krw', ['won-sign']],
  ['won', ['won-sign']],
  ['wordpress', ['wordpress']],
  ['wordpress-simple', ['wordpress-simple']],
  ['worm', ['worm']],
  ['wpbeginner', ['wpbeginner']],
  ['wpexplorer', ['wpexplorer']],
  ['wpforms', ['wpforms']],
  ['wpressr', ['wpressr']],
  ['rendact', ['wpressr']],
  ['wreath', ['wreath']],
  ['wrench', ['wrench']],
  ['wrench-simple', ['wrench-simple']],
  ['x', ['x']],
  ['x-ray', ['x-ray']],
  ['xbox', ['xbox']],
  ['xing', ['xing']],
  ['xmark', ['xmark']],
  ['close', ['xmark']],
  ['multiply', ['xmark']],
  ['remove', ['xmark']],
  ['times', ['xmark']],
  ['xmark-large', ['xmark-large']],
  ['xmark-to-slot', ['xmark-to-slot']],
  ['times-to-slot', ['xmark-to-slot']],
  ['vote-nay', ['xmark-to-slot']],
  ['xmarks-lines', ['xmarks-lines']],
  ['y', ['y']],
  ['y-combinator', ['y-combinator']],
  ['yahoo', ['yahoo']],
  ['yammer', ['yammer']],
  ['yandex', ['yandex']],
  ['yandex-international', ['yandex-international']],
  ['yarn', ['yarn']],
  ['yelp', ['yelp']],
  ['yen-sign', ['yen-sign']],
  ['cny', ['yen-sign']],
  ['jpy', ['yen-sign']],
  ['rmb', ['yen-sign']],
  ['yen', ['yen-sign']],
  ['yin-yang', ['yin-yang']],
  ['yoast', ['yoast']],
  ['youtube', ['youtube']],
  ['z', ['z']],
  ['zhihu', ['zhihu']],
]);

export function getPureIconName(icon: string): string {
  return icon.substring(icon.indexOf('-') + 1);
}

export function searchIconsByMeta(meta: string): string[] {
  const result = [];
  iconsMeta.forEach((iconMeta, key) => {
    if (key.indexOf(meta) >= 0) {
      iconMeta.forEach(icon => {
        if (result.indexOf(icon) < 0) {
          result.push(icon);
        }
      });
    }
  });

  return result;
}

export function safeGetRandomIcon(): string {
  const unsafe = [
    'fa-coffin',
    'fa-book-dead',
    'fa-skull-crossbones',
    'fa-tombstone',
    'fa-tombstone-alt',
    'fa-flask-poison',
    'fa-hockey-mask',
    'fa-genderless',
    'fa-mars-double',
    'fa-mars-stroke',
    'fa-mars-stroke-h',
    'fa-mars-stroke-v',
    'fa-transgender',
    'fa-transgender-alt',
    'fa-venus-double',
    'fa-angry',
    'fa-frown',
    'fa-frown-open',
    'fa-sad-tear',
    'fa-sed-cry',
    'fa-tired',
    'fa-poo',
    'fa-om',
    'fa-quran',
  ];
  let icon: string;
  do {
    icon = icons[Math.round(Math.random() * icons.length)];
  } while (unsafe.indexOf(icon) > -1);

  return 'fas ' + icon;
}
