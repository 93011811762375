<div class="d-flex flex-row flex-nowrap pt-1 align-items-center p-2">
  <label class="switch">
    <input type="checkbox" #forceSign [checked]="settings?.includeSubItems" (change)="onSubItemsChange($event.target.checked)">
    <span class="slider round" (click)="$event.stopPropagation()"></span>
  </label>
  <span class="ps-2 cursor-pointer user-select-none" i18n="@@view.settings.includeChildren"
        (click)="forceSign.click()">Include sub-items</span>
  <i class="fas fa-question-circle ms-1 cursor-help"
     tooltip="If turned on, sub-items are always visible no matter of defined filters."
     placement="bottom"
     i18n-tooltip="@@view.settings.includeChildren.help">
  </i>
</div>
