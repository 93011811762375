<button class="btn btn-sm btn-lmr-primary h-100"
        (click)="viewSettings.open()">
  <i class="far fa-line-columns"></i>
</button>

<view-settings-dropdown #viewSettings
                        [origin]="element"
                        [showAttributes]="showAttributes"
                        [settings]="viewSettings$ | async"
                        (attributeSettingsChanged)="onAttributesSettingsChanged($event)"
                        (dataSettingsChange)="onDataSettingsChanged($event)">
</view-settings-dropdown>
