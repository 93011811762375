<div class="px-3 pt-2 pb-3">
  <img [src]="template.templateMetadata?.imageUrl" alt=""
       class="main-image"/>
</div>

<h3 class="pt-2 px-3 mb-0 text-center">{{template.name}}</h3>

<div>
  <div class="px-3 tags d-flex justify-content-center pt-1 flex-wrap" *ngIf="template.templateMetadata?.tags?.length">
    <div *ngFor="let tag of template.templateMetadata.tags" class="tag py-1 px-2 m-1 cursor-pointer text-nowrap"
         (click)="selectTag.emit(tag)">{{tag}}</div>
  </div>
</div>

<div class="p-3 text-center">{{template.description}}</div>
<div class="px-3 pb-3">
  <iframe width="100%" height="100%" [src]="publicViewUrl"></iframe>
</div>
