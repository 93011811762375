<ng-container *ngIf="{workspace: workspace$ | async, controlsShown: controlsShown$ | async} as state">
  <div class="logo">
    <lumeer-logo [height]="36"
                 [style.height.px]="firstLineHeight"
                 [link]="state.workspace | workspaceDefaultUrl:(currentUser$ | async)"
                 [showTooltip]="true"
                 [text]="!(state.workspace | workspaceSet) ? 'Lumeer' : null"
                 class="d-flex align-items-center">
    </lumeer-logo>
  </div>

  <div class="controls-toggle" *ngIf="compact">
    <controls-toggle [style.height.px]="firstLineHeight"
                     class="d-flex align-items-center"
                     (toggle)="onToggleControls()">
    </controls-toggle>
  </div>

  <workspace-panel *ngIf="state.controlsShown && (state.workspace | workspaceSet)"
                   [workspace]="state.workspace"
                   [contentHeight]="firstLineHeight"
                   class="workspace-panel">
  </workspace-panel>

  <user-panel [workspace]="state.workspace"
              [controlsVisible]="!compact || (controlsShown$ | async)"
              [contentHeight]="firstLineHeight"
              [compact]="compact"
              class="user-panel">
  </user-panel>

  <search-box *ngIf="state.controlsShown && (state.workspace | workspaceSet) && searchBoxShown"
              [style.min-width.px]="firstLineHeight"
              class="search-box d-flex align-items-center">
  </search-box>

  <div *ngIf="state.controlsShown"
       class="controls">
    <ng-content></ng-content>
  </div>
</ng-container>
