<select-item
  [items]="attributesResources | selectItemsWithConstraint:restrictedIds"
  [selectedId]="selectedId"
  [removable]="removable"
  [disabled]="disabled"
  [buttonClasses]="buttonClasses"
  [placeholderIcon]="placeholderIcon"
  [placeholderText]="placeholderText"
  [emptyValue]="emptyValue"
  [fitParent]="fitParent"
  (selected)="onSelect($event)"
  (remove)="onRemove()">
</select-item>

<ng-container *ngIf="selectedId">
  <ng-container *ngIf="{configItems: attributesResources | selectItemWithConstraintConfig:selectedId} as data">
    <select-item
      *ngIf="data.configItems.length > 1"
      class="d-block mt-1"
      [items]="data.configItems"
      [selectedId]="(data.configItems | findSelectConstraintItemById:selectedConstraint)?.id || data.configItems[0].id"
      [removable]="false"
      placeholderIcon="fas fa-eye"
      [buttonClasses]="buttonClasses"
      [disabled]="disabled"
      [fitParent]="fitParent"
      [emptyValue]="''"
      (selected)="onSelectConstraint($event)">
    </select-item>
  </ng-container>
</ng-container>
