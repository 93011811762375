<td class="ps-2 py-3">
  <a class="d-flex flex-row no-wrap align-items-center"
     [routerLink]="['/o', workspace?.organizationCode, 'p', workspace?.projectCode, 'c', collection.id]">
    <icons-presenter *ngIf="collection | resourceIconsColors as iconsColors"
                     [colors]="iconsColors.colors"
                     [icons]="iconsColors.icons"
                     [title]="collection.name">
    </icons-presenter>

    <span class="ms-2 flex-grow-1">{{collection.name}}</span>
  </a>

</td>

<td class="text-end align-middle">

  <div class="d-flex flex-row flex-nowrap align-items-center justify-content-end"
       title="Number of records" i18n-title="@@collection.settings.records.count">
    <span class="text-success clickable" (click)="onDocumentsClick()">
      <i class="far fa-file" aria-hidden="true"></i>&nbsp;<span>{{ collection?.documentsCount || 0 }}</span>
    </span>
  </div>

</td>
