<user-table [users]="[staticUsers, otherUsers] | concat"
            [color]="view | viewColor:(collectionsMap$ | async)"
            [teams]="teams"
            [organization]="organization"
            [project]="project"
            [permissionsMap]="resourcePermissionType | resourceTypePermissionsMap:permissions"
            [resourcePermissionType]="resourcePermissionType"
            [removableUserIds]="removableUserIds"
            [editableUserIds]="editableUserIds"
            [emitAllChanges]="true"
            (userRemoved)="userRemoved.emit($event)"
            (userRolesChange)="onUserRolesChange($event)">
</user-table>
