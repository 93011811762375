<div class="d-flex flex-column align-items-center justify-content-center text-center p-3">
  <h5 i18n="@@constraint.action.empty.automation.title">There are no automations yet!</h5>
  <div *ngIf="!permissions?.roles?.TechConfig" class="mt-1" i18n="@@constraint.action.empty.automation.subtitle.norights">I am sorry but you
    do not have permissions to add automations.
  </div>

  <div *ngIf="permissions?.roles?.TechConfig">
    <div class="mt-1" i18n="@@constraint.action.empty.subtitle">Navigate to Table settings and create something
      beautiful! Keep in mind that Automated Links cannot be used in an action.
    </div>
    <button type="button"
            class="btn btn-sm btn-primary cursor-pointer mt-3"
            title="Add automation"
            i18n-title="@@constraint.action.empty.automation.button">
      <a [routerLink]="resource | collectionSettingsPath:(workspace$ | async)" target="_blank" style="color: inherit">
        <i class="fa fa-plus-circle me-1" aria-hidden="true"></i>
        <span class="fw-bold" i18n="@@constraint.action.empty.automation.button">Add automation</span>
      </a>
    </button>

  </div>

</div>
