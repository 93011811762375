<input #addressInput
       [readonly]="readonly"
       [title]="value | formatDataValueTitle"
       [value]="readonly ? (value | formatDataValue | unescapeHtml) : (value | formatDataValueForEdit)"
       class="border-0 w-100 h-100 px-1 bg-transparent"
       [ngClass]="inputClass"
       (focus)="!readonly && onFocused()"
       [style.color]="readonly ? fontColor : null"
       (blur)="!readonly && onBlur()"
       (input)="onInput($event)"
       spellcheck="false"
       type="text">

<options-dropdown *ngIf="!readonly"
                  [options]="addressOptions$ | async"
                  [showBackdrop]="false"
                  [closeOnClickOutside]="false"
                  [origin]="addressInput"
                  [minWidth]="250"
                  (selectOption)="onSelectOption($event)">
</options-dropdown>
