<div class="mt-4 mb-6">
  <table class="table table-borderless table-sm">
    <thead [style.background-color]="(collection$ | async)?.color | lightenColor:.5">
    <tr>
      <th i18n="@@linkType" class="ps-2">Link Type</th>
      <th [style.width.%]="30" class="text-end">
        <div class="input-group input-group-sm">
          <span class="input-group-text"><i class="fas fa-search"></i></span>
          <input class="form-control me-1" type="search"
                 placeholder="Search link type..."
                 inputmode="search"
                 i18n-placeholder="@@collections.tab.linkTypes.search.placeholder"
                 title="Search for link types" i18n-title="@@collections.tab.linkTypes.search.title"
                 (input)="onSearchInputChanged($event.target.value)">
        </div>
      </th>
    </tr>
    </thead>

    <ng-container *ngIf="{linkTypes: linkTypes$ | async, permissions: linkTypesPermissions$ | async, workspace: workspace$ | async} as data">
      <tbody *ngFor="let linkType of data.linkTypes | linkTypeFilter:(searchString$ | async); trackBy:trackByLinkType">
      <tr link-type
          [linkType]="linkType"
          [allLinkTypes]="data.linkTypes"
          [permissions]="data.permissions && data.permissions[linkType.id]"
          [workspace]="data.workspace"
          (delete)="onDeleteLinkType(linkType, $event)"
          class="border-top">
      </tr>
      </tbody>
    </ng-container>

    <tr *ngIf="!(linkTypes$ | async)?.length">
      <td colspan="2" class="align-middle text-center py-4" i18n="@@collection.tab.linkTypes.none">
        There are no link types yet.
      </td>
    </tr>

  </table>

</div>
