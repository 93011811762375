<div class="row mb-4" *ngIf="dashboardViews?.length">
  <label class="col-12" i18n="@@dashboard.tabs.settings.dialog.override.view.title">Select an existing view to use its settings. Leave empty for your own configuration.</label>
  <div class="col-12 col-lg-6 override-view mt-1">
    <select-item id="select"
                 [selectedId]="selectedView?.id"
                 [items]="viewSelectItems"
                 [removable]="true"
                 i18n-emptyValue="@@search.tabs.settings.dialog.override.view.placeholder"
                 emptyValue="Select View"
                 (selected)="selectView.emit($event)"
                 (remove)="selectView.emit(null)">
    </select-item>
    <i *ngIf="selectedView" class="far fa-copy text-primary clickable" (click)="copySelectedView.emit()"
       tooltip="Duplicate the configuration"
       i18n-tooltip="@@search.tabs.settings.dialog.override.view.tooltip"></i>
  </div>

  <div *ngIf="selectedView" class="col-12 mt-3 text-muted" i18n="@@search.tabs.settings.dialog.override.view.warning">
    The dashboard cannot be updated when an existing view is selected above. If you want to update the settings, either duplicate the settings, or remove the selection.
  </div>
</div>

<div class="tabs-content">

  <div class="d-flex flex-wrap align-items-center tabs-list">

    <div *ngFor="let tab of tabs$ | async; let index = index; let last = last; trackBy: trackByTab" class="position-relative tab cursor-pointer mt-1"
         [class.me-2]="last"
         [class.me-1]="!last"
         [class.dragging]="(draggedTabId$ | async) === tab.id"
         [draggable]="editable"
         (dragstart)="onDragStart(tab)"
         (dragend)="onDragEnd()"
         (dragover)="$event.preventDefault()"
         (dragenter)="onDragEnter(tab)"
         (dragleave)="onDragLeave(tab)"
         (drop)="onDrop(tab)">
      <dashboard-tab-badge [tab]="tab"
                           [selected]="tab | isTabSelected:(selectedTabId$ | async)"
                           (remove)="removeTab(index)"
                           [editable]="editable"
                           (toggleHidden)="toggleHiddenTab(index)"
                           (click)="selectTab(tab)">
      </dashboard-tab-badge>
      <div *ngIf="(dragging$ | async) && tab.id !== draggedTab?.id" class="dragging-area position-absolute"></div>
    </div>
  </div>

  <div *ngIf="editable" class="px-2 text-primary clickable d-flex align-items-center"
       tooltip="Add a new tab"
       i18n-tooltip="@@search.tabs.settings.dialog.add.tab"
       (click)="addTab()">
    <i class="far fa-plus-circle"></i>
  </div>

</div>

<div class="alert alert-danger mt-3" *ngIf="editable && !(tabsAreValid$ | async)" i18n="@@search.tabs.settings.dialog.error.empty">
  There must be at least one visible tab in the dashboard.
</div>

<hr>

<dashboard-tab-settings *ngIf="selectedTab$ | async as selectedTab"
                        [editable]="editable"
                        [tab]="selectedTab"
                        (tabChange)="onSelectedTabChange($event)">
</dashboard-tab-settings>
