<div class="d-inline-flex align-items-center px-2 py-1">
  <i class="fas me-2" [ngClass]="lock?.locked ? 'fa-lock color-danger' : 'fa-lock-open color-success'"></i>
  <span *ngIf="lock?.locked" i18n="@@constraint.action.lock.disabled.short">Always locked</span>&nbsp;<span *ngIf="lock?.locked && stats?.groups?.length" i18n="@@constraint.action.lock.disabled.short.until">until</span>
  <span *ngIf="!lock?.locked" i18n="@@constraint.action.lock.enabled.short">Always editable</span>&nbsp;<span *ngIf="!lock?.locked && stats?.groups?.length" i18n="@@constraint.action.lock.enabled.short.unless">unless</span>
</div>
<ng-container *ngIf="stats?.groups?.length">
  <div class="content-grid mt-2" *ngIf="{attributesMap: attributesMap$ | async} as data">
    <ng-container *ngFor="let group of stats.groups; trackBy: trackByIndex; let last = last">

      <div class="larger-column text-start fst-italic" *ngIf="!group.filtersStats?.length" i18n="@@resource.attribute.lock.stats.group.user">You are a specific user</div>
      <ng-container *ngFor="let stat of group.filtersStats">
        <ng-container *ngIf="data.attributesMap && data.attributesMap[stat.filter.attributeId] as attribute">
          <div class="text-start pe-2 text-truncate">{{attribute.name}}</div>
          <div class="text-start text-truncate">
            <attribute-filter-lock-preview [constraintData]="constraintData"
                                           [attribute]="attribute"
                                           [condition]="stat.filter.condition"
                                           [conditionValues]="stat.filter.conditionValues">
            </attribute-filter-lock-preview>
          </div>
          <div class="text-start ps-2">
            <i class="fas"
               [ngClass]="stat.satisfy !== !lock?.locked ? 'text-success fa-check-circle' : 'text-danger fa-times-circle'"></i>
          </div>
        </ng-container>
      </ng-container>

      <div class="larger-column my-2 divider-text text-muted" *ngIf="!last">
        <hr class="m-0">
        <div class="px-2" i18n="@@or">Or</div>
        <hr class="m-0">
      </div>

    </ng-container>
  </div>
</ng-container>
