<div class="header" [style.background-color]="color | lightenColor:.5">
  <div class="p-2 fw-bold" i18n="@@users.header.user">User</div>
  <div class="roles">
    <div class="fw-bold py-2 pe-2 text-nowrap">
      <span i18n="@@users.header.teams.roles">Teams & Rights</span>
      <i class="far fa-question-circle ms-1" [tooltip]="legend" containerClass="bigger-tooltip"></i>
    </div>
    <div class="ps-3 input-group input-group-sm">
      <span class="input-group-text" id="search-user1"><i class="fas fa-search"></i></span>
      <input id="userSearch" class="form-control me-1" type="search"
             placeholder="Search user..."
             inputmode="search"
             i18n-placeholder="@@users.header.search.placeholder"
             title="Search for users" i18n-title="@@users.header.search.title"
             aria-describedby="search-user1"
             [(ngModel)]="searchString">
    </div>
  </div>
  <div><i class="fa fa-fw m-1"></i></div>
</div>
<user-component *ngFor="let user of users | userFilter:searchString; trackBy:trackByUserId"
                class="user"
                [teams]="teams"
                [resourcePermissionType]="resourcePermissionType"
                [user]="user"
                [workspace]="workspace"
                [transitiveRoles]="organization | userTransitiveRoles:project:user:resourcePermissionType:permissionsMap:viewsMap"
                [permissionsMap]="permissionsMap"
                [deletable]="deletableUserIds | includes:user.id"
                [removable]="removableUserIds | includes:user.id"
                [changeRoles]="editableUserIds | includes:user.id"
                [changeTeams]="editableGroups"
                [emitAllChanges]="emitAllChanges"
                [canManageUserDetail]="canManageUserDetail"
                (rolesUpdate)="userRolesChange.emit({user: user, roles: $event})"
                (userUpdated)="userUpdated.emit($event)"
                (userDeleted)="userDeleted.emit($event)"
                (userRemoved)="userRemoved.emit($event)"
                (teamsUpdate)="userTeamsChange.emit({user: user, teams: $event})">
</user-component>

<ng-template #legend>
  <div class="text-start">
    <span class="badge badge-lg badge-outline-info" i18n="@@users.header.teams.roles.tooltip.teams">Teams</span>
    <span class="ms-1" i18n="@@users.header.teams.roles.tooltip.member">the user is member of</span><br/>
    <span class="badge badge-lg badge-outline-success mt-1" i18n="@@users.header.teams.roles.tooltip.rights">Rights</span>
    <span class="ms-1" i18n="@@users.header.teams.roles.tooltip.direct">directly assigned</span><br/>
    <span class="badge badge-lg badge-outline-secondary mt-1" i18n="@@users.header.teams.roles.tooltip.rights">Rights</span>
    <span class="ms-1" i18n="@@users.header.teams.roles.tooltip.indirect">gained from organization, project, or team</span>
  </div>
</ng-template>
