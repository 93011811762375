/*
 * Lumeer: Modern Data Definition and Processing Platform
 *
 * Copyright (C) since 2017 Lumeer.io, s.r.o. and/or its affiliates.
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */
import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';

import {FilterBuilderModule} from '../../../builder/filter-builder/filter-builder.module';
import {FilterPreviewModule} from '../../../builder/filter-preview/filter-preview.module';
import {PipesModule} from '../../../pipes/pipes.module';
import {PresenterModule} from '../../../presenter/presenter.module';
import {SelectModule} from '../../../select/select.module';
import {ConstraintConditionFormComponent} from './conditions/condition/constraint-condition-form.component';
import {ConstraintConditionsFormComponent} from './conditions/constraint-conditions-form.component';
import {AttributeModalHeaderComponent} from './header/attribute-modal-header.component';

@NgModule({
  imports: [CommonModule, PresenterModule, PipesModule, SelectModule, FilterPreviewModule, FilterBuilderModule],
  exports: [AttributeModalHeaderComponent, ConstraintConditionsFormComponent],
  declarations: [AttributeModalHeaderComponent, ConstraintConditionFormComponent, ConstraintConditionsFormComponent],
})
export class AttributeCommonModalModule {}
