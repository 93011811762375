/*
 * Lumeer: Modern Data Definition and Processing Platform
 *
 * Copyright (C) since 2017 Lumeer.io, s.r.o. and/or its affiliates.
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */
import {Injectable, Pipe, PipeTransform} from '@angular/core';

import {ResourcePermissionType} from '../../../../core/model/resource-permission-type';
import {Permissions, Role} from '../../../../core/store/permissions/permissions';
import {ViewUserPermissionsPipe} from './view-user-permissions.pipe';

@Pipe({
  name: 'viewUserPermissionsMap',
})
@Injectable()
export class ViewUserPermissionsMapPipe implements PipeTransform {
  constructor(private userPermissionsPipe: ViewUserPermissionsPipe) {}

  public transform(
    collectionPermissions: Permissions,
    linkTypePermissions: Permissions,
    collectionRoles: Record<string, Role[]>,
    linkTypeRoles: Record<string, Role[]>
  ): Record<ResourcePermissionType, Permissions> {
    return {
      [ResourcePermissionType.ViewCollection]: this.userPermissionsPipe.transform(
        collectionPermissions,
        collectionRoles
      ),
      [ResourcePermissionType.ViewLinkType]: this.userPermissionsPipe.transform(linkTypePermissions, linkTypeRoles),
    } as Record<ResourcePermissionType, Permissions>;
  }
}
