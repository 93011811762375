<div class="lmr-checkbox d-flex align-items-center"
     (click)="onDivClick($event)">
  <input #booleanInput
         [id]="inputId"
         [attr.checked]="(value | serializeDataValue) || null"
         [readonly]="readonly || null"
         [indeterminate]="indeterminate"
         [style.cursor]="readonly ? 'unset' : null"
         (click)="onInputClick($event)"
         (focus)="onFocus.emit($event)"
         [ngClass]="inputClass"
         type="checkbox">
  <label [for]="inputId"
         (click)="onLabelClick($event)">
  </label>
</div>
<span class="ms-2" *ngIf="label">{{label}}</span>
