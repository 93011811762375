<div class="list-group list-group-horizontal py-3">
  <a class="list-group-item list-group-item-action" [class.active]="selectedInvitationType === invitationType.JoinOnly" (click)="setInvitationType(invitationType.JoinOnly)">
    <div class="text-center py-2"><i class="fa fa-2x fa-eye-slash"></i></div>
    <div class="text-center py-2" i18n="@@inviteUser.dialog.readOnlyNoAccess.title2">Just Join</div>
  </a>
  <a class="list-group-item list-group-item-action" [class.active]="selectedInvitationType === invitationType.ReadOnly" (click)="setInvitationType(invitationType.ReadOnly)">
    <div class="text-center py-2"><i class="fa fa-2x fa-eye"></i></div>
    <div class="text-center py-2" i18n="@@inviteUser.dialog.readOnly.title2">View</div>
  </a>
  <a class="list-group-item list-group-item-action" [class.active]="selectedInvitationType === invitationType.ReadWrite" (click)="setInvitationType(invitationType.ReadWrite)">
    <div class="text-center py-2"><i class="fa fa-2x fa-pencil"></i></div>
    <div class="text-center py-2" i18n="@@inviteUser.dialog.write.title2">Collaborate</div>
  </a>
  <a class="list-group-item list-group-item-action" [class.active]="selectedInvitationType === invitationType.Manage" (click)="setInvitationType(invitationType.Manage)">
    <div class="text-center py-2"><i class="fa fa-2x fa-cogs"></i></div>
    <div class="text-center py-2" i18n="@@inviteUser.dialog.manage.title2">Create</div>
  </a>
</div>
