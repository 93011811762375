<input #searchInput
       [value]="text"
       [placeholder]="queryItems.length === 0 ? emptyPlaceholder : placeholder"
       (blur)="onBlur()"
       [disabled]="readonly"
       (focus)="onFocus()"
       (input)="onInput($event)"
       (keydown)="onKeyDown($event)"
       (keyup.enter)="onEnterKeyUp()"
       autoSizeInput
       class="mw-100 py-1 px-2"/>

<search-suggestions [queryItems]="queryItems"
                    class="cursor-default"
                    [origin]="hostElement"
                    [suggestByItems]="restrictedMode"
                    [restrictedTypes]="restrictedMode ? restrictedItemTypes : []"
                    [text]="suggesting ? (text || '') : null"
                    (useSuggestion)="onUseSuggestion($event)"
                    (onCloseByClickOutside)="searchInput.blur()">
</search-suggestions>
