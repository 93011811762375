<ng-container *ngIf="constraint | constraintAsText; else otherConstraint">
  <span [innerHTML]="dataValue | previewDataValue | safeHtml"
        class="white-space-normal"
        [title]="dataValue | formatDataValueTitle"
        [class.text-nowrap]="configuration?.common?.inline">
  </span>
</ng-container>

<ng-template #otherConstraint>
  <ng-container [ngSwitch]="constraint.type">
    <boolean-data-input *ngSwitchCase="constraintType.Boolean"
                        [label]="configuration?.boolean?.additionalLabel"
                        [class.justify-content-center]="configuration?.boolean?.center"
                        [readonly]="true" [value]="dataValue" class="w-100 px-1">
    </boolean-data-input>

    <color-data-input *ngSwitchCase="constraintType.Color"
                      [style.max-width.px]="configuration?.color?.limitWidth ? (hasValue ?  50 : 0) : null"
                      [class.h-100]="configuration?.common?.inline"
                      [style.min-width.px]="configuration?.common?.minWidth"
                      [readonly]="true"
                      [value]="dataValue"
                      [configuration]="configuration?.color"
                      [commonConfiguration]="commonConfiguration"
                      class="d-block w-100">
    </color-data-input>

    <select-data-input *ngSwitchCase="constraintType.Select"
                       [configuration]="configuration?.select"
                       [commonConfiguration]="commonConfiguration"
                       [readonly]="true"
                       [value]="dataValue"
                       class="d-block w-100">
    </select-data-input>

    <user-data-input *ngSwitchCase="constraintType.User"
                     [focus]="false"
                     [readonly]="true"
                     [commonConfiguration]="commonConfiguration"
                     [configuration]="configuration?.user"
                     [value]="dataValue"
                     class="w-100">
    </user-data-input>

    <view-data-input *ngSwitchCase="constraintType.View"
                     [focus]="false"
                     [readonly]="true"
                     [commonConfiguration]="commonConfiguration"
                     [value]="dataValue"
                     class="w-100">
    </view-data-input>

    <files-data-input *ngSwitchCase="constraintType.Files"
                      [focus]="false"
                      [readonly]="true"
                      [cursor]="cursor"
                      [value]="dataValue">
    </files-data-input>

    <link-data-input *ngSwitchCase="constraintType.Link"
                     [focus]="false"
                     [readonly]="true"
                     [commonConfiguration]="commonConfiguration"
                     [value]="dataValue">
    </link-data-input>

    <action-data-input *ngSwitchCase="constraintType.Action"
                       [readonly]="true"
                       [configuration]="configuration?.action"
                       [value]="dataValue">
    </action-data-input>

    <percentage-data-input *ngSwitchCase="constraintType.Percentage"
                           [readonly]="true"
                           [style.width.px]="100"
                           [commonConfiguration]="commonConfiguration"
                           [value]="dataValue">
    </percentage-data-input>

  </ng-container>
</ng-template>
