<div class="px-5 py-4">
  <div class="pb-2">
    <h4 i18n="@@collections.tab.workflow.heading">What best describes your intentions with this table?</h4>
    <p class="text-muted" i18n="@@collections.tab.workflow.subHeading">
      Lumeer provides you most help when he understands what do you plan to store in the table. What is your desired workflow?
    </p>
  </div>
  <div>
    <collection-purpose-content class="d-block pb-6"
                                [collection]="collection$ | async"
                                [workspace]="workspace$ | async">
    </collection-purpose-content>
  </div>
</div>
