<modal-wrapper
  [dialogType]="dialogType.Primary"
  (onClose)="hideDialog()"
  (onSubmit)="onSubmit()"
  [submitDisabled]="!(selectedDocumentId$ | async)"
  *ngIf="{collections: collections$ | async, documents: documents$ | async, selectedCollectionId: selectedCollectionId$ | async} as data">

  <span class="title" i18n="@@modal.kanban.chooseDocument.title">Choose a Record</span>

  <div class="body p-3 h-100">
    <ng-container *ngIf="data.collections.length && data.documents.length; else noData">
      <div class="text-muted text-center"
           i18n="@@modal.kanban.chooseDocument.description">
        Select to which record you want to link the new one.
      </div>

      <div>
        <preview-results-tabs
          *ngIf="data.collections.length > 1"
          class="d-block mt-2"
          [resources]="data.collections"
          [selectedId]="data.selectedCollectionId"
          (selectResource)="onSelectCollection($event)">
        </preview-results-tabs>
      </div>
      <preview-results-table class="mt-2 overflow-hidden results-table"
                             [dataResources]="data.documents | documentsByCollection:data.selectedCollectionId"
                             [resource]="data.collections | collectionById:data.selectedCollectionId"
                             [selectedId]="selectedDocumentId$ | async"
                             [attributesSettings]="(viewSettings$ | async)?.attributes"
                             [view]="view$ | async"
                             [resizeable]="false"
                             [constraintData]="constraintData$ | async"
                             (selectDataResource)="onSelectDocument($event)">
      </preview-results-table>
    </ng-container>
  </div>

  <span class="button-cancel">
      <i class="fal fa-times"></i>&nbsp;<span i18n="@@button.cancel">Cancel</span>
  </span>

  <span class="button-submit">
      <i class="far fa-check"></i>&nbsp;<span i18n="@@button.choose">Choose</span>
  </span>

</modal-wrapper>

<ng-template #noData>
  <span class="text-muted" i18n="@@choose.empty">I am sorry but I did not find anything.</span>
</ng-template>
