/*
 * Lumeer: Modern Data Definition and Processing Platform
 *
 * Copyright (C) since 2017 Lumeer.io, s.r.o. and/or its affiliates.
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */
import {createAction, props} from '@ngrx/store';

import {FormConfig, FormModel} from './form-model';

export const add = createAction('[Form] Add', props<{model: FormModel}>());

export const remove = createAction('[Form] Remove', props<{id: string}>());

export const setConfig = createAction('[Form] Set Config', props<{id: string; config: FormConfig}>());

export const clear = createAction('[Form] Clear');
