<div class="modal-header"
     [ngClass]="customHeader ? 'p-0' : ('bg-' + dialogType  + ' justify-content-center align-items-center text-center p-3')"
     *ngIf="showHeader">
  <ng-container *ngIf="!customHeader">
    <i *ngIf="icon" class="{{icon}} icon text-white me-2"></i>
    <h4 class="modal-title text-white">
      <ng-content select=".title"></ng-content>
    </h4>
    <ng-content select=".description"></ng-content>
  </ng-container>
  <ng-container *ngIf="customHeader">
    <ng-content select=".custom-header"></ng-content>
  </ng-container>
</div>
<div class="modal-body p-0" [class.allow-overflow]="allowBodyOverflow">
  <ng-content select=".body"></ng-content>
</div>
<div class="modal-footer footer-bg p-3"
     *ngIf="showFooter">
  <div *ngIf="progress" class="progress-wrapper">
    <div class="progress" [style.width.px]="width$ | async"></div>
  </div>
  <div class="d-block w-100">
    <button *ngIf="showClose"
            [attr.aria-disabled]="closeDisabled || performingAction"
            [disabled]="closeDisabled || performingAction"
            type="button"
            class="btn btn-outline-primary text-nowrap float-start" (click)="onCloseClick()">
      <ng-content select=".button-cancel"></ng-content>
    </button>
    <button *ngIf="showSubmit"
            [attr.aria-disabled]="submitDisabled || performingAction"
            [disabled]="submitDisabled || performingAction"
            (click)="onSubmitClick()"
            type="button"
            class="btn btn-{{dialogType}} text-nowrap float-end">
      <span *ngIf="performingAction" class="spinner-grow spinner-grow-sm me-2" role="status" aria-hidden="true"></span>
      <ng-content select=".button-submit"></ng-content>
    </button>
    <button *ngIf="showSecondarySubmit"
            [attr.aria-disabled]="secondarySubmitDisabled || performingAction || performingSecondaryAction"
            [disabled]="secondarySubmitDisabled || performingAction || performingSecondaryAction"
            (click)="onSecondarySubmitClick()"
            type="button"
            [class.me-2]="showSubmit"
            [class.border-transparent]="secondaryBorderless"
            class="btn btn-link clickable text-decoration-none text-{{secondarySubmitClass || dialogType}} text-nowrap float-end">
      <span *ngIf="performingSecondaryAction" class="spinner-grow spinner-grow-sm me-2" role="status" aria-hidden="true"></span>
      <ng-content select=".button-secondary-submit"></ng-content>
    </button>
    <span *ngIf="secondaryInfo" class="float-end text-muted py-1" [class.me-3]="showSubmit || showSecondarySubmit">
      {{secondaryInfo}}
    </span>
  </div>
</div>
