<form [formGroup]="form">
  <div class="mb-3">
    <label class="form-label" i18n="@@attribute.type.label">
      Type
    </label>
    <attribute-type-select [type]="typeControl.value"
                           (typeChange)="typeControl.setValue($event)"
                           class="d-block"
                           data-test="attribute-type-select">
    </attribute-type-select>
  </div>

  <attribute-common-config class="d-block mt-3"
                           [type]="typeControl.value"
                           [form]="commonConfigForm">
  </attribute-common-config>

  <constraint-config-form [config]="attribute.constraint?.type === typeControl.value ? attribute.constraint?.config : null"
                          [form]="configForm"
                          [resource]="resource"
                          [attribute]="attribute"
                          [type]="typeControl.value"
                          [lockControl]="lockControl"
                          [permissions]="permissions">
  </constraint-config-form>
</form>
