<cdk-virtual-scroll-viewport [itemSize]="32" class="h-100">
  <table class="table table-borderless table-sm">
    <thead>
    <tr>
      <th class="sticky-header check-column"
          [style.top]="inverseTranslation"
          scope="col">
        <custom-checkbox class="d-inline-block"
                         checkboxId="checkAll"
                         [checked]="isAllChecked"
                         (checkedChange)="onCheckedAllChange($event)"
                         (click)="$event.stopPropagation()">
        </custom-checkbox>
      </th>
      <th class="sticky-header"
          [style.top]="inverseTranslation"
          *ngFor="let attr of linkTypeAttributes; trackBy:trackByAttribute; let idx = index"
          scope="col"
          [style.font-weight]="'normal'"
          title="{{attr.name}}">
        {{attr.name}}
      </th>
      <th class="sticky-header"
          [style.background-color]="collection.color | lightenColor:0.5"
          [style.top]="inverseTranslation"
          [style.font-weight]="attr.id === collection.defaultAttributeId || (!collection.defaultAttributeId && idx === 0) ? 'bold' : 'normal'"
          *ngFor="let attr of collectionAttributes; trackBy:trackByAttribute; let idx = index"
          scope="col"
          title="{{attr.name}}">
        {{attr.name}}
      </th>
    </tr>
    </thead>
    <tbody>
    <tr *ngIf="documents.length === 0">
      <td [attr.colspan]="collectionAttributes.length" class="text-center fw-bold">
        <h4 i18n="@@shared.preview.results.noResults">No records found.</h4>
      </td>
    </tr>
    <tr *cdkVirtualFor="let row of rows; trackBy:trackByRow"
        class="clickable-row" (click)="toggleRow(row)">
      <td class="check-column align-middle">
        <custom-checkbox class="d-inline-block"
                         [checkboxId]="row.linkInstance?.id || row.document.id"
                         [checked]="row | isResultRowChecked:removedLinkInstancesIds:selectedDocumentIds"
                         (checkedChange)="onCheckedChange($event, row)"
                         (click)="$event.stopPropagation()">
        </custom-checkbox>
      </td>
      <td *ngFor="let attr of linkTypeAttributes">
        <data-input-preview *ngIf="row.linkInstance"
                            [constraint]="attr.constraint"
                            [configuration]="configuration"
                            [cursor]="row.linkInstance | dataCursor : attr.id:view?.id"
                            [dataValue]="(row.linkInstance.data && row.linkInstance.data[attr.id]) | dataValue:attr.constraint:constraintData">
        </data-input-preview>
      </td>
      <td *ngFor="let attr of collectionAttributes">
        <data-input-preview [constraint]="attr.constraint"
                            [configuration]="configuration"
                            [cursor]="row.document | dataCursor : attr.id:view?.id"
                            [dataValue]="(row.document.data && row.document.data[attr.id]) | dataValue:attr.constraint:constraintData">
        </data-input-preview>
      </td>
    </tr>
    </tbody>
  </table>
</cdk-virtual-scroll-viewport>
