<ng-container
  *ngIf="{columnFocused: columnFocused$ | async, columnEditing: columnEditing$ | async} as data">
  <ng-container *ngFor="let column of columns; trackBy: trackByColumn; let index = index">
    <td class="table-border position-relative"
        *ngIf="{dataValue: !(editedValue | isNullOrUndefined) && data.columnEditing === index ? editedValue : (column.linkTypeId ? row.linkInstance?.data || {} : row.document?.data || {})[column.attribute.id] | dataValue:column.attribute?.constraint:constraintData,
          editing: data.columnEditing === index, focused: data.columnFocused === index,
          lockStats: (column.linkTypeId ? linkType : collection) | attributeLockStats :(column.linkTypeId ? row.linkInstance : row.document): column.attribute:constraintData,
          styles: (column.linkTypeId ? linkType : collection) | resourceAttributeFormattingStyle:column.attribute?.id:(column.linkTypeId ? row.linkInstance : row.document):constraintData,
          editable: column.linkTypeId ? linkEditable : documentEditable} as columnData"
        [style.width.px]="column.width"
        [style.background-color]="columnData.styles?.background"
        [class.focused]="columnData.focused"
        [class.editing]="columnData.editing && !column?.attribute?.constraint?.isDirectlyEditable"
        [class.affected]="!(attributeEditing?.documentId | isNullOrUndefined) &&
      (row.document?.id === attributeEditing?.documentId) &&
      (column.collectionId && column.attribute.id === attributeEditing?.attributeId)"
        [ngClass]="columnData.styles?.classes"
        (click)="onDataInputFocus(index, $event)"
        (dblclick)="onDataInputDblClick(index, $event)"
        (mouseenter)="onMouseEnter(column?.id)"
        (mouseleave)="onMouseLeave(column?.id)">
      <ng-container
        *ngIf="column.attribute | dataInputEditInfo:columnData.dataValue:columnData.editable:columnData.editing:columnData.lockStats as dataInputInfo">
        <data-input *ngIf="column.linkTypeId"
                    [constraint]="column.attribute?.constraint"
                    [cursor]="row.linkInstance && (row.linkInstance | dataCursor : column.attribute.id:workspace?.viewId)"
                    [dataValue]="columnData.dataValue"
                    [focus]="true"
                    [workspace]="workspace"
                    [editableInReadonly]="dataInputInfo.editable"
                    [preventEventBubble]="preventEventBubble"
                    [configuration]="configuration"
                    [fontColor]="columnData.styles?.color"
                    [readonly]="dataInputInfo.readonly"
                    [class.cursor-pointer]="!dataInputInfo.readonly"
                    (save)="onNewValue(index, $event)"
                    (cancel)="onDataInputCancel(index)"
                    (enterInvalid)="onEnterInvalid()"
                    (clickOutside)="onDataInputCancel(index)">
        </data-input>

        <data-input *ngIf="column.collectionId"
                    [constraint]="column.attribute.constraint"
                    [cursor]="row.document && (row.document | dataCursor : column.attribute.id:workspace?.viewId)"
                    [dataValue]="columnData.dataValue"
                    [focus]="true"
                    [workspace]="workspace"
                    [editableInReadonly]="dataInputInfo.editable"
                    [preventEventBubble]="preventEventBubble"
                    [configuration]="configuration"
                    [fontColor]="columnData.styles?.color"
                    [readonly]="dataInputInfo.readonly"
                    [class.cursor-pointer]="!dataInputInfo.readonly"
                    (save)="onNewValue(index, $event)"
                    (keydown)="onDataInputKeyDown($event)"
                    (valueChange)="onValueChange(index, $event)"
                    (cancel)="onDataInputCancel(index)"
                    (clickOutside)="onDataInputCancel(index)"
                    (enterInvalid)="onEnterInvalid()">
        </data-input>

        <div *ngIf="column?.id === (mouseHoverColumnId$ | async) && dataInputInfo.showLockStats && !(column?.attribute | isAttributeEnabledByLockStats:columnData.lockStats)"
             class="lock-icon cursor-pointer" @enterOpacityAnimation
             [tooltip]="statsTooltip" containerClass="bigger-tooltip">
          <i class="fas fa-sm fa-lock"></i>

          <ng-template #statsTooltip>
            <attribute-lock-filters-stats [lock]="column?.attribute?.lock"
                                          [stats]="columnData.lockStats"
                                          [constraintData]="constraintData"
                                          [attributesMap]="(column.linkTypeId ? linkType : collection) | resourceAttributesMap">
            </attribute-lock-filters-stats>
          </ng-template>
        </div>
      </ng-container>
    </td>
  </ng-container>
  <td class="fixed-column border-0">
    <div class="d-flex flex-nowrap flex-row h-100 align-items-center px-2">
      <i class="fas fa-file-search clickable me-2"
         *ngIf="allowSelect && documentPermissions?.read && row.document"
         (click)="onDetail()"
         i18n-title="@@show.detail" title="Show detail" aria-hidden="true"></i>
      <i class="fas fa-unlink clickable"
         *ngIf="allowUnlink && linkPermissions?.delete && row.document"
         (click)="onUnlink()"
         i18n-title="@@unlink" title="Unlink" aria-hidden="true"></i>
    </div>
  </td>

  <document-hints
    *ngIf="canSuggest && !(data.columnEditing | isNullOrUndefined) && columns[data.columnEditing].collectionId && (columns[data.columnEditing].attribute | canShowAttributeHints)"
    [attributeId]="columns[data.columnEditing]?.attribute?.id"
    [correlationId]="row.correlationId"
    [dataValue]="suggesting$ | async"
    [collectionId]="collection?.id"
    [viewId]="workspace?.viewId"
    [excludedDocumentIds]="row.document?.id ? [row.document.id] : []"
    [columns]="columns | documentHintsColumns"
    [offsetLeft]="columns | calculateHintsOffset"
    [linkedDocumentId]="documentId"
    [linkInstanceId]="row.linkInstance?.id"
    [linkTypeId]="linkType?.id"
    [constraintData]="constraintData"
    [origin]="element"
    (useHint)="onUseHint()"
    (createLink)="createLink.emit($event)"
    (updateLink)="updateLink.emit($event)">
  </document-hints>
</ng-container>
