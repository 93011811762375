<div>
  <a [routerLink]="userSettingsUrl" [queryParams]="userSettingsParams" *ngIf="canManageUserDetail">
    <user-stamp class="d-block p-2" [email]="user.email" [userName]="user.name"></user-stamp>
  </a>
  <user-stamp *ngIf="!canManageUserDetail" class="d-block p-2" [email]="user.email" [userName]="user.name"></user-stamp>
</div>

<div class="pb-2 pt-3">
  <user-teams [teams]="teams"
              [selectedTeamIds]="user | filterUserTeams:teams"
              [editable]="changeTeams"
              (save)="onTeamsSave($event)">
  </user-teams>
  <roles class="mt-3"
         [resourcePermissionType]="resourcePermissionType"
         [editable]="changeRoles"
         [transitiveRoles]="transitiveRoles"
         [emitAllChanges]="emitAllChanges"
         [roles]="user | userRolesMap:permissionsMap:resourcePermissionType"
         (change)="rolesUpdate.emit($event)">
  </roles>
</div>

<div class="d-flex align-items-center">
  <a [routerLink]="userSettingsUrl" [queryParams]="userSettingsParams" *ngIf="canManageUserDetail">
    <i class="fas fa-fw fa-user-cog m-1 cursor-pointer" aria-hidden="true" title="Review user rights, teams, and activities" i18n-title="@@user.actions.review.rights"></i>
  </a>
  <i class="far fa-fw fa-trash-alt text-danger m-1 cursor-pointer"
     *ngIf="deletable"
     aria-hidden="true"
     title="Delete User"
     i18n-title="@@users.actions.delete"
     (click)="onDelete()"></i>
  <i class="far fa-fw fa-times text-danger m-1 cursor-pointer"
     *ngIf="removable"
     aria-hidden="true"
     title="Remove User"
     i18n-title="@@users.actions.remove"
     (click)="onRemove()"></i>
  <i class="fa fa-fw m-1" *ngIf="!deletable && !removable"></i>
</div>
