<modal-wrapper
  [dialogType]="dialogType.Primary"
  [showClose]="!data.dataResource?.id"
  [showSubmit]="true"
  [performingAction]="performingAction$ | async"
  [showHeader]="false"
  (onSubmit)="onSubmit(data.dataResource)"
  (onClose)="onClose()"
  *ngIf="{resource: resource$ | async, dataResource: dataResource$ | async} as data">

  <div class="body p-3">
    <data-resource-detail *ngIf="data.resource && data.dataResource"
                          [resource]="data.resource"
                          [dataResource]="data.dataResource"
                          [query]="query$ | async"
                          [preventEventBubble]="true"
                          [defaultView]="currentView$ | async"
                          [settingsStem]="detailSettingsQueryStem"
                          [settingsQuery]="settingsQuery$ | async"
                          [toolbarRef]="toolbarRef"
                          [editable]="!(performingAction$ | async)"
                          (dataResourceChanged)="onDataResourceChanged($event)"
                          (routingPerformed)="onClose()"
                          (documentSelect)="selectCollectionAndDocument($event)">
    </data-resource-detail>
  </div>

  <span class="button-cancel">
      <i class="fal fa-times me-2"></i><span i18n="@@button.close">Close</span>
  </span>

  <span class="button-submit">
    <i class="fal fa-check me-2"></i><span i18n="@@button.done">Done</span>
  </span>

</modal-wrapper>
