<ng-container *ngIf="{style: style$| async} as data">
  <div i18n="resource.attribute.lock.group.conditions.title">Format values if...</div>
  <constraint-conditions-form class="d-block mt-1"
                              [resource]="resource"
                              [filtersArray]="filtersControl"
                              buttonLabel="Add condition"
                              i18n-buttonLabel="@@resource.attribute.formatting.condition.add"
                              [equation]="group?.equation">
  </constraint-conditions-form>

  <div class="mt-2" i18n="resource.attribute.formatting.format.title">Formatting style</div>
  <div class="card mt-1">
    <div class="card-header px-3 py-2"
         [style.color]="data.style.color"
         [style.background-color]="data.style.background"
         [ngClass]="data.style.classes"
         i18n="@@example">Example
    </div>
    <div class="card-body d-flex align-items-center mt-1 px-3 py-2">
      <i class="fa fa-bold me-2 format-icon" [class.active]="stylesControl.value | includes:fontStyle.Bold" (click)="toggleStyle(fontStyle.Bold)" tooltip="Bold" placement="top" [delay]="popupDelay" i18n-tooltip="resource.attribute.formatting.style.bold"></i>
      <i class="fa fa-italic me-2 format-icon" [class.active]="stylesControl.value | includes:fontStyle.Italic" (click)="toggleStyle(fontStyle.Italic)" tooltip="Italic" placement="top" [delay]="popupDelay" i18n-tooltip="resource.attribute.formatting.style.italic"></i>
      <i class="fa fa-underline me-2 format-icon" [class.active]="stylesControl.value | includes:fontStyle.Underline" (click)="toggleStyle(fontStyle.Underline)" tooltip="Underline" placement="top" [delay]="popupDelay" i18n-tooltip="resource.attribute.formatting.style.underline"></i>
      <i class="fa fa-strikethrough me-2 format-icon" [class.active]="stylesControl.value | includes:fontStyle.StrikeThrough" (click)="toggleStyle(fontStyle.StrikeThrough)" tooltip="Strike through" placement="top" [delay]="popupDelay" i18n-tooltip="resource.attribute.formatting.style.strikeThrough"></i>
      <i class="fa-duotone fa-pen-paintbrush me-2 format-icon" [style]="{'--fa-secondary-color': colorControl.value}" #colorElement (click)="colorPicker.toggle()" tooltip="Color" placement="top" [delay]="popupDelay" i18n-tooltip="resource.attribute.formatting.style.color"></i>
      <i class="fa-duotone fa-fill-drip me-2 format-icon" [style]="{'--fa-secondary-color': backgroundControl.value}" #backgroundElement (click)="backgroundPicker.toggle()" tooltip="Background" placement="top" [delay]="popupDelay" i18n-tooltip="resource.attribute.formatting.style.background"></i>
      <i class="fa fa-text-slash me-2 format-icon" (click)="resetStyle()" tooltip="Clear format" placement="top" [delay]="popupDelay" i18n-tooltip="resource.attribute.formatting.style.clearFormat"></i>
    </div>
  </div>

  <color-picker #colorPicker
                [color]="colorControl.value"
                [origin]="colorElement"
                (save)="onColorChange($event)">
  </color-picker>

  <color-picker #backgroundPicker
                [color]="backgroundControl.value"
                [origin]="backgroundElement"
                (save)="onBackgroundChange($event)">
  </color-picker>
</ng-container>

<i class="fal fa-trash-alt delete-icon text-danger clickable"
   tooltip="Delete whole set"
   i18n-tooltip="@@resource.attribute.lock.group.action.delete" (click)="delete.emit()"></i>
