<div class="user-box" [style.grid-template-columns]="size + 'px 1fr'">
  <img ngxGravatar
       [email]="email"
       [size]="size"
       class="">
  <div class="flex-column align-self-center user-name ms-3 text-truncate">
    <div class="flex-nowrap text-truncate" [ngClass]="titleClasses">{{userName || email}}</div>
    <div *ngIf="userName && userName !== email" class="text-muted">{{email}}</div>
  </div>
</div>
