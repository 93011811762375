<top-panel-wrapper *ngIf="{view: view$ | async} as data"
                   [searchBoxShown]="true">

  <view-controls *ngIf="data.view"
                 [novice]="!(viewsExist$ | async)"
                 [view]="data.view"
                 (save)="onSave($event)"
                 (saveOrClone)="onSaveOrClone($event)"
                 class="controls">
  </view-controls>

  <div class="content flex-grow-1 d-flex flex-column h-100 overflow-auto">
    <div *ngIf="!data.view"
         class="d-flex mx-3">
      <div class="d-none d-xl-block side-panel pointer-events-none"></div>
      <div class="flex-grow-1">
        <div class="alert alert-warning mb-4"
             i18n="@@view.not.exist">View does not exist!
        </div>
      </div>
      <div class="d-none d-xl-block side-panel pointer-events-none"></div>
    </div>

    <router-outlet></router-outlet>
  </div>
</top-panel-wrapper>
