/*
 * Lumeer: Modern Data Definition and Processing Platform
 *
 * Copyright (C) since 2017 Lumeer.io, s.r.o. and/or its affiliates.
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */
import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';

import {TooltipModule} from 'ngx-bootstrap/tooltip';

import {BlocklyModule} from '../../../blockly/blockly.module';
import {PipesModule} from '../../../pipes/pipes.module';
import {PresenterModule} from '../../../presenter/presenter.module';
import {ModalWrapperModule} from '../../wrapper/modal-wrapper.module';
import {AttributeFunctionModalComponent} from './attribute-function-modal.component';
import {AttributeFunctionContentComponent} from './content/attribute-function-content.component';
import {AttributeFunctionBlocklyComponent} from './content/blockly/attribute-function-blockly.component';
import {AttributeFunctionEmptyComponent} from './content/empty/attribute-function-empty.component';
import {AttributeFunctionRestrictedComponent} from './content/restricted/attribute-function-restricted.component';
import {AttributeFunctionHeaderComponent} from './header/attribute-function-header.component';

@NgModule({
  declarations: [
    AttributeFunctionModalComponent,
    AttributeFunctionHeaderComponent,
    AttributeFunctionContentComponent,
    AttributeFunctionEmptyComponent,
    AttributeFunctionRestrictedComponent,
    AttributeFunctionBlocklyComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ModalWrapperModule,
    BlocklyModule,
    PresenterModule,
    PipesModule,
    TooltipModule,
  ],
  exports: [AttributeFunctionModalComponent],
})
export class AttributeFunctionModalModule {}
