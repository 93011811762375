<dropdown [origin]="origin"
          [pushOnScreen]="true"
          [showBackdrop]="false"
          [positions]="dropdownPositions"
          (onCloseByClickOutside)="onCancel()">

  <div class="card link-dialog"
       [style.min-width.px]="300"
       (click)="$event.stopPropagation();"
       (mousedown)="$event.stopPropagation()">
    <form [formGroup]="form" class="grid">

      <div class="small pb-1">
        <span i18n="@@link.input.dropdown.link">Link</span><span class="text-danger">*</span>
      </div>
      <div class="grid-empty-cell"></div>
      <input class="form-control" type="text" #linkElement
             formControlName="link"
             placeholder="https://..."
             inputmode="url"
             (blur)="onLinkBlur()"
             (keydown)="onLinkKeyDown($event)">
      <div class="grid-empty-cell"></div>

      <div i18n="@@link.input.dropdown.title" class="mt-3 pb-1 small">Title</div>
      <div class="grid-empty-cell"></div>
      <input class="form-control" type="text" #titleElement
             formControlName="title"
             placeholder="Custom title"
             i18n-placeholder="@@link.input.dropdown.title.placeholder"
             (keydown)="onTitleKeyDown($event)">
      <button class="btn btn-sm btn-primary ms-2"
              [disabled]="invalid$ | async"
              (click)="$event.stopPropagation(); onSave()">
        <span i18n="@@button.save">Save</span>
      </button>
    </form>
  </div>

</dropdown>
