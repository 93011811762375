<div class="row m-0" *ngIf="{lists: lists$ | async} as data">
  <div class="col-12 col-md-6 col-lg-4 col-xl-3 col-xxl-2 p-1">
    <div class="card p-5 d-flex align-items-center justify-content-center clickable" (click)="onAdd()">
      <i class="fas fa-plus-circle"></i>
      <div i18n="@@selection.lists.modal.add" class="mt-1 text-center">Add a new list</div>
    </div>
  </div>
  <div class="col-12 col-md-6 col-lg-4 col-xl-3 col-xxl-2 p-1" *ngIf="!data.lists.length">
    <div *ngIf="addingSampleData$ | async" class="card p-5 d-flex align-items-center justify-content-center">
      <span class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
      <div i18n="@@selection.lists.modal.adding.sample" class="mt-1">Adding sample lists</div>
    </div>
    <div *ngIf="!(addingSampleData$ | async)" class="card p-5 d-flex align-items-center justify-content-center clickable" (click)="onAddSample()">
      <i class="fas fa-plus-circle"></i>
      <div i18n="@@selection.lists.modal.add.sample" class="mt-1 text-center">Add sample lists</div>
    </div>
  </div>
  <div *ngFor="let list of data.lists; trackBy: trackByList"
       class="col-12 col-md-6 col-lg-4 col-xl-3 col-xxl-2 p-1">
    <selection-list [editable]="true"
                    [list]="list"
                    (update)="onUpdate(list)"
                    (copy)="onCopy(list)"
                    (delete)="onDelete(list)">
    </selection-list>
  </div>
</div>
