<div class="overflow-auto" #tableWrapper>
  <table class="table table-without-padding table-borderless position-relative"
         *ngIf="{columns: columns$ | async} as data">
    <thead links-list-table-header
           [columns]="data.columns"
           [collectionPermissions]="collectionPermissions"
           [linkTypePermissions]="linkTypePermissions"
           (resizeColumn)="onResizeColumn($event)"
           (attributeType)="onAttributeType($event)"
           (attributeFunction)="onAttributeFunction($event)"
           (attributeDescription)="onAttributeDescription($event)">
    </thead>
    <tbody links-list-table-body
           [collectionPermissions]="collectionPermissions"
           [linkTypePermissions]="linkTypePermissions"
           [columns]="data.columns"
           [constraintData]="constraintData"
           [rows]="rows$ | async | sortLinkRows:collection:linkType:attributesSettings:constraintData"
           [linkType]="linkType"
           [collection]="collection"
           [document]="document"
           [workspace]="workspace"
           [currentUser]="currentUser$ | async"
           [allowSelect]="allowSelect"
           [allowCreate]="allowCreate"
           [allowUnlink]="allowUnlink"
           [preventEventBubble]="preventEventBubble"
           (columnFocus)="onColumnFocus($event)"
           (detail)="onDetail($event)"
           (unlink)="onUnLink($event)"
           (newLink)="onNewLink($event)"
           (patchDocumentData)="patchDocumentData.emit($event)"
           (patchLinkData)="patchLinkData.emit($event)"
           (createLink)="createLink.emit($event)"
           (updateLink)="updateLink.emit($event)">
    </tbody>

  </table>
</div>
