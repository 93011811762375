<h5 class="text-center fw-bold" i18n="@@getting.started.invite.users.title">We're almost there</h5>
<div class="text-center mt-2" i18n="@@getting.started.invite.users.subtitle">Invite your teammates and build your project together</div>


<form class="input-group-multiline emails-wrapper m-auto mt-2">
  <ng-container *ngFor="let invitation of service.invitations$ | async; trackBy: trackByIndex; let index = index;">
    <input class="form-control form-control-sm w-100 email-input"
           [value]="invitation.email"
           inputmode="email"
           type="email"
           autocomplete="chrome-off"
           (blur)="onEmailChanged(index, $event.target.value)"
           placeholder="Type email here"
           i18n-placeholder="@@getting.started.invite.users.email.placeholder"/>

    <div class="input-group-append">
      <select-item [items]="items"
                   [selectedId]="invitation.type"
                   [removable]="false"
                   [fitParent]="false"
                   (selected)="onTypeChanged(index, $event)">
      </select-item>
    </div>
  </ng-container>

  <div class="mt-2">
    <button class="btn btn-link px-0 small align-self-start text-start" (click)="onAddInvitation()"><i
      class="fas fa-plus-circle me-2"></i><span
      i18n="@@getting.started.invite.users.add">Add colleague</span>
    </button>
  </div>

</form>
