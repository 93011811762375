/*
 * Lumeer: Modern Data Definition and Processing Platform
 *
 * Copyright (C) since 2017 Lumeer.io, s.r.o. and/or its affiliates.
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */
import {LocationDto} from '../../dto/location.dto';
import {GeoLocation} from './geo-location';

export namespace GeocodingConverter {
  export function fromDtos(locations: LocationDto[]): GeoLocation[] {
    return locations.map(location => GeocodingConverter.fromDto(location)).filter(model => !!model);
  }

  export function fromDto(location: LocationDto): GeoLocation {
    let city = '';
    if (location.address.suburb) {
      if (location.address.cityDistrict) {
        if (location.address.cityDistrict === location.address.suburb) {
          city = location.address.suburb;
        } else {
          city = location.address.suburb + ', ' + location.address.cityDistrict;
        }
      } else {
        city = location.address.suburb;
      }
    } else {
      if (location.address.cityDistrict) {
        city = location.address.cityDistrict;
      }
    }

    if (location.address.city) {
      const locationCity = location.address.city;
      if (city) {
        if (locationCity !== location.address.cityDistrict && locationCity !== location.address.suburb) {
          city = city + ', ' + locationCity;
        }
      } else {
        city = locationCity;
      }
    }

    return {
      coordinates: location.coordinates,
      address: {
        ...location.address,
        city,
      },
    };
  }
}
