<div class="d-flex align-items-center h-100 p-1" #wrapperElement
     *ngIf="selectedOptions$ | async as selectedOptions"
     [class.flex-wrap]="configuration?.wrapItems"
     [title]="selectedOptions | selectOptionsJoined | unescapeHtml"
     [ngClass]="readonly || !multi ? 'overflow-hidden w-100' : 'invisible-scroll-y'">

  <div class="text-muted px-1" *ngIf="readonly && placeholder && !selectedOptions.length">{{placeholder}}</div>

  <ng-container *ngIf="multi || readonly">

    <ng-container *ngFor="let option of selectedOptions; trackBy: trackByOption; let first = first">
        <span class="select-tag"
              [class.m-1]="configuration?.wrapItems"
              [style.background]="option.background"
              [style.color]="fontColor || (option.background | contrastColor)"
              [class.invalid-value]="!commonConfiguration?.skipValidation && !(option | selectOptionIsValid:value.config:selectedOptions?.length)"
              [spellcheck]="false"
              [innerHTML]="option.displayValue || option.value"
              [class.ms-2]="!first && !configuration?.wrapItems">
        </span>
    </ng-container>

  </ng-container>

  <ng-container *ngIf="!readonly">

    <input #textInput
           [(ngModel)]="text"
           (focus)="onFocused()"
           (blur)="onBlur()"
           (ngModelChange)="onInput()"
           [class.ms-1]="selectedOptions?.length > 0"
           [spellcheck]="false"
           [ngClass]="inputClass"
           autoSizeInput
           [class.w-100]="!configuration?.wrapItems"
           [class.h-100]="!configuration?.wrapItems"
           class="border-0 bg-transparent select-input"
           type="text">

    <options-dropdown [options]="dropdownOptions | filterDropdownOptions:text"
                      [showBackdrop]="false"
                      [closeOnClickOutside]="false"
                      [origin]="textInput"
                      [multiSelect]="multi"
                      [highlightedText]="text"
                      [selectedValues]="selectedOptions | selectOptionsValues"
                      [firstItemActive]="!multi && text?.length > 0"
                      (selectOption)="onSelect($event)">
    </options-dropdown>

  </ng-container>

</div>
