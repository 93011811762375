<modal-wrapper
  [dialogType]="dialogType.Primary"
  (onClose)="hideDialog()"
  [customHeader]="true"
  [showSubmit]="false">

  <div class="custom-header text-truncate p-3 bg-primary text-white w-100 rounded-top h5" [innerHTML]="title"></div>

  <div class="body h-100 w-100">
    <ng-container *ngIf="{resources: resources$ | async, dataResources: dataResources$ | async, selectedDataResource: selectedDataResource$ | async} as data">

      <div class="parent h-100 w-100" @enterEmpty *ngIf=" data.resources.length || data.dataResources.length; else noData">

        <data-resources-preview *ngIf="!data.selectedDataResource"
                                class="h-100 mw-100 p-3 body-item flex-1"
                                @enterRightAnimation
                                [constraintData]="constraintData$ | async"
                                [dataResources]="data.dataResources"
                                [resources]="data.resources"
                                [showDuplicates]="showDuplicates$ | async"
                                [hasDuplicates]="hasDuplicates"
                                [selectedResource]="selectedResource$ | async"
                                [viewSettings]="viewSettings$ | async"
                                (selectDataResource)="onSelectDataResource($event)"
                                (selectResource)="onSelectResource($event)"
                                (showDuplicatesChange)="showDuplicates$.next($event)">
        </data-resources-preview>
        <data-resources-detail *ngIf="data.selectedDataResource"
                               class="h-100 mw-100 py-3 body-item flex-1"
                               @enterLeftAnimation
                               [resource]="selectedResource$ | async"
                               [viewId]="viewId"
                               [dataResource]="data.selectedDataResource"
                               (routingPerformed)="hideDialog()"
                               (back)="resetSelectedDataResource()">
        </data-resources-detail>
      </div>
    </ng-container>
  </div>

  <span class="button-cancel">
      <i class="fal fa-times"></i>&nbsp;<span i18n="@@button.cancel">Cancel</span>
  </span>

</modal-wrapper>

<ng-template #noData>
  <div class="text-center p-5">
    <span class="text-muted" i18n="@@choose.empty">I am sorry but I did not find anything.</span>
  </div>
</ng-template>
