<h6 class="fw-bold mb-3 mx-3" i18n="@@search.tabs.settings.dialog.rows.title">Dashboard Preview</h6>

<div class="dashboard-rows px-1" cdkDropList
     (cdkDropListDropped)="rowDropped($event)"
     [cdkDropListDisabled]="!editable">
  <dashboard-row-settings *ngFor="let row of rows; let index = index; trackBy:trackByRow"
                          cdkDrag
                          cdkDragLockAxis="y"
                          cdkDragBoundary=".cdk-drop-list"
                          class="dashboard-row d-block px-2"
                          [row]="row"
                          [views]="views"
                          [editable]="editable"
                          [selectedColumn]="selectedCoordinates?.row === index ? selectedCoordinates?.column : null"
                          (rowChange)="rowChange.emit({row: $event, index: index})"
                          (delete)="rowDelete.emit(index)"
                          (cellSelect)="cellSelect.emit({row: index, column: $event})">
    <i *ngIf="editable" class="fa-fw fa fa-grip-vertical cursor-move me-1 text-black-50" cdkDragHandle></i>
  </dashboard-row-settings>
</div>

<ng-container *ngIf="editable">
  <div class="px-3">
    <button #buttonElement class="btn w-100 btn-outline-primary" (click)="layout.toggle()">
      <i class="far fa-plus me-2"></i>
      <span i18n="@@search.tabs.settings.dialog.rows.add">Add a Row</span>
    </button>
  </div>

  <row-layout-dropdown #layout
                       [origin]="buttonElement"
                       [fitParent]="true"
                       (layoutSelected)="rowAdd.emit($event)">
  </row-layout-dropdown>
</ng-container>
