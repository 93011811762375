<div [formGroup]="form">
  <p class="text-muted" i18n="@@constraint.view.description">
    Allows you to choose views and navigate to them with ease.
  </p>

  <div class="mt-3">
    <div class="d-flex flex-row flex-nowrap pt-1 align-items-center">
      <label class="switch" [for]="formControlName.Multi">
        <input type="checkbox" [formControlName]="formControlName.Multi" [id]="formControlName.Multi" #multi>
        <span class="slider round" (click)="$event.stopPropagation()"></span>
      </label>
      <span class="ps-2 cursor-pointer user-select-none" i18n="@@constraint.view.multi.label" (click)="multi.click()">Allow select multiple views</span>
    </div>

    <div class="d-flex flex-row flex-nowrap mt-3 pt-2 align-items-center">
      <label class="switch" [for]="formControlName.OpenInNewWindow">
        <input type="checkbox" [formControlName]="formControlName.OpenInNewWindow"
               [id]="formControlName.OpenInNewWindow" #openInNewWindow>
        <span class="slider round" (click)="$event.stopPropagation()"></span>
      </label>
      <span class="ps-2 cursor-pointer user-select-none" i18n="@@constraint.view.newWindow.label"
            (click)="openInNewWindow.click()">Open view in new window</span>
    </div>

  </div>
</div>
