<a *ngIf="!value.config?.openInApp" [href]="completeLinkValue" target="_blank" class="overflow-hidden text-nowrap"
   [style.color]="fontColor"
   [class.color-inherit]="commonConfiguration?.inheritColor">{{titleValue || linkValue}}</a>
<a *ngIf="value.config?.openInApp" class="overflow-hidden text-nowrap"
   [class.color-inherit]="commonConfiguration?.inheritColor"
   [style.color]="fontColor"
   (click)="onLinkClick($event)">{{titleValue || linkValue}}</a>

<link-input-dropdown *ngIf="!readonly"
                     [linkValue]="rawLinkValue"
                     [titleValue]="rawTitleValue"
                     [origin]="element"
                     (cancel)="onCancel()"
                     (save)="onSave($event)">
</link-input-dropdown>
