<div [formGroup]="form">
  <div class="d-flex mb-3">
    <span class="text-muted"
          i18n="@@constraint.percentage.description">
      Format any recognizable values as percentages.
      There is no need to enter the percent (%) character. It is added automatically.
    </span>
  </div>

  <div class="d-flex">
    <div class="mt-2 flex-grow-1">
      <label class="form-label" for="numberDecimals" i18n="@@constraint.percentage.decimals.label">
        Round to no. of decimal digits (0-10):
      </label>
      <input class="form-control"
             formControlName="decimals"
             id="numberDecimals"
             integer
             maxValue="10"
             inputmode="numeric"
             min="0"
             minValue="0"
             max="10"
             type="number">
      <span class="d-inline-block text-muted small mt-1"
            i18n="@@constraint.percentage.decimals.description">
          Leave empty for no rounding.
        </span>
    </div>
  </div>

  <div class="d-flex">
    <div class="mt-3 flex-grow-1">
      <label class="form-label" for="numberMinValue"
             i18n="@@constraint.number.minValue.label">
        Minimum value
      </label>
      <div class="d-flex">
        <input class="form-control"
               formControlName="minValue"
               id="numberMinValue"
               inputmode="numeric"
               type="number">
        <span class="ms-2 percent-mark">%</span>
      </div>
    </div>

    <div class="mt-3 ms-3 flex-grow-1">
      <label class="form-label" for="numberMaxValue"
             i18n="@@constraint.number.maxValue.label">
        Maximum value
      </label>
      <div class="d-flex">
        <input class="form-control"
               formControlName="maxValue"
               id="numberMaxValue"
               inputmode="numeric"
               type="number">
        <span class="ms-2 percent-mark">%</span>
      </div>
    </div>
  </div>

  <div
    *ngIf="form.errors && form.errors['minMaxInvalid'] && form.controls['maxValue'].touched && form.controls['minValue'].touched"
    class="text-danger form-text mt-3"
    i18n="@@form.errors.minMaxInvalid">
    Minimum must be less or equal than maximum.
  </div>

  <percentage-constraint-config-style [form]="form">
  </percentage-constraint-config-style>

</div>
