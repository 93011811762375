<label for="url" i18n="@@search.tabs.settings.dialog.config.image.title">Image Address</label>
<input class="form-control w-100"
       #urlInput
       [(ngModel)]="url"
       placeholder="https://..."
       [disabled]="!editable"
       (blur)="onBlur()"
       (keydown.enter)="urlInput.blur()"
       (keydown.escape)="revertUrl(); urlInput.blur()"
       id="url"
       type="text">

<label class="mt-2" i18n="@@search.tabs.settings.dialog.config.scale.title">Scale</label>
<div class="d-flex align-items-center">
  <div class="scale-type fit me-2" [class.cursor-pointer]="editable" (click)="onSelectScale(scapeType.Fit)"
       tooltip="Fit image within area and maintain its aspect ratio"
       i18n-tooltip="@@search.tabs.settings.dialog.config.scale.fit">
    <i class="fal fa-square image-border"></i>
    <i class="fas fa-image image text-gray-700" [class.text-success]="scale === scapeType.Fit"></i>
  </div>
  <div class="scale-type crop me-2" [class.cursor-pointer]="editable"  (click)="onSelectScale(scapeType.Crop)"
       tooltip="Crop image to fit area"
       i18n-tooltip="@@search.tabs.settings.dialog.config.scale.crop">
    <i class="fas fa-image image text-gray-700" [class.text-success]="scale === scapeType.Crop"></i>
    <i class="fal fa-square image-border"></i>
  </div>
</div>
