<form [formGroup]="form">

  <div class="row">
    <label class="col-12 form-label"
           i18n="@@selection.lists.modal.name.title" for="name">Name:</label>

    <div class="col-12">
      <input type="text"
             id="name"
             formControlName="name"
             class="form-control"
             i18n-placeholder="@@selection.lists.modal.name.placeholder"
             placeholder="Enter unique name">
    </div>
    <small class="col-12 form-text text-danger mt-1" *ngIf="nameControl.touched && nameControl.errors?.minLength"
           i18n="@@selection.lists.modal.name.error.minLength">List name can not be empty</small>
    <small class="col-12 form-text text-danger mt-1" *ngIf="nameControl.errors?.notUnique"
           i18n="@@selection.lists.modal.name.error.notUnique">List name already exists</small>
  </div>

  <div #optionsOrigin>
    <button *ngIf="selectionListsOptions?.length" class="btn btn-link mt-2 px-0" (click)="optionsDropdown.toggle()">
      <span i18n="@@selection.lists.modal.action.copy">Copy values from attribute</span>&nbsp;<i class="fa fa-caret-down"></i>
    </button>
  </div>

  <select-constraint-options-form class="d-block mt-2"
                                  [form]="form"
                                  [options]="list.options">
  </select-constraint-options-form>

</form>

<options-dropdown #optionsDropdown
                  [options]="selectionListsOptions"
                  [origin]="optionsOrigin"
                  (selectOption)="onSelectionListSelected($event)">
</options-dropdown>
