<div class="panel-item invite ms-2 me-1 position-relative"
     *ngIf="(isWorkspaceDefined$ | async) && (projectPermissions$ | async)?.roles?.UserConfig">
  <button *ngIf="!compact; else compactIcon"
          class="btn btn-success btn-sm align-baseline fw-bold"
          tooltip="Invite a colleague"
          i18n-tooltip="@@inviteUser.button.title"
          placement="bottom"
          data-tour="invite-users"
          (click)="onInviteUser()">
    <i class="fas fa-user-plus me-2"></i>
    <span i18n="@@inviteUser.button.text">Invite</span>
  </button>
  <hint *ngIf="!((projectUsers$ | async) > 1)" title="You are all alone here" i18n-title="@inviteUser.hint.title">
    <span i18n="@@inviteUser.hint.content">Invite your colleagues using this button.</span>
  </hint>
</div>

<ng-template #compactIcon>
  <i class="fas fa-user-plus me-2 text-success align-baseline cursor-pointer"
     tooltip="Invite a colleague"
     i18n-tooltip="@@inviteUser.button.title"
     placement="bottom"
     title="Invite a colleague"
     i18n-title="@@inviteUser.button.title"
     (click)="onInviteUser()"></i>
</ng-template>
