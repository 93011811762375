<ng-container
  *ngIf="{changeableUsers: changeableUsers$| async, newUsers: newUsers$ | async, userRoles: userRoles$ | async,
   staticUsers: staticUsers$ | async, teamRoles: teamRoles$ | async} as data">

  <share-view-copy
    [viewShareUrl]="viewShareUrl$ | async"
    (onCopy)="copyToClipboard()">
  </share-view-copy>

  <div class="p-3">

    <hint-box additionalClasses="mt-2" [hintKey]="userHintsKeys.viewTeamsHintDismissed">
      <span i18n="@@view.share.instructions">Add Users or Teams (if not already present) to the list. Then set rights of individual Users and Teams for this View.</span>
    </hint-box>

    <ng-container *ngIf="selectedTab$ | async as selectedTab">
      <ul class="nav nav-tabs">
        <li class="nav-item">
          <a class="nav-link text-truncate"
             [class.active]="selectedTab === viewTab.Users"
             i18n="@@view.share.tabs.users"
             (click)="selectedTab$.next(viewTab.Users)">Users</a>
        </li>
        <li class="nav-item">
          <a class="nav-link text-truncate"
             [class.active]="selectedTab === viewTab.Teams"
             i18n="@@view.share.tabs.teams"
             (click)="selectedTab$.next(viewTab.Teams)">Teams</a>
        </li>
      </ul>

      <ng-container *ngIf="selectedTab === viewTab.Users">
        <view-users-input class="d-block mb-2 mt-4"
                          [readableUsers]="usersWithReadPermission"
                          [currentUsers]="[data.staticUsers, data.changeableUsers, data.newUsers] | concat"
                          [addNewUsers]="true"
                          (selectUser)="onUserSelected($event)"
                          (addUser)="addNewUser($event)">
        </view-users-input>

        <view-users [view]="view"
                    [organization]="organization"
                    [project]="project"
                    [currentUser]="currentUser"
                    [staticUsers]="data.staticUsers"
                    [teams]="teams"
                    [permissions]="view?.permissions | viewUserPermissions:data.userRoles"
                    [otherUsers]="[data.changeableUsers, data.newUsers] | concat"
                    (userRemoved)="deleteUser($event)"
                    (userRolesChange)="onNewRoles($event.user, $event.roles)">
        </view-users>

      </ng-container>

      <view-teams *ngIf="selectedTab === viewTab.Teams"
                  class="d-block mt-4"
                  [organization]="organization"
                  [project]="project"
                  [view]="view"
                  [permissions]="view?.permissions | viewGroupPermissions:data.teamRoles"
                  [users]="users"
                  [teams]="teamsWithReadPermission"
                  [currentUser]="currentUser"
                  (teamRolesChange)="onNewTeamRoles($event.team, $event.roles)">
      </view-teams>
    </ng-container>

  </div>
</ng-container>
