<form [formGroup]="form" class="row m-0 h-100" (ngSubmit)="onSubmit()">

  <div class="col-12">
    <create-resource-dialog-parent
      *ngIf="parentId"
      [parentId]="parentId"
      [resourceType]="resourceType">
    </create-resource-dialog-parent>

    <div class="mb-3 row">

      <label class="col-form-label col-2 mb-2" i18n="@@resource.new.dialog.icon.label">Icon:</label>

      <div class="col-8 d-block mb-3 text-center cursor-pointer" (click)="togglePicker()">
        <div class="text-muted small my-2" i18n="@@resource.new.dialog.icon.label.hint">(click the icon to change it)</div>
        <i class="fa-fw {{ icon }} fa-3x"
           [style.color]="color" #pickerOrigin></i>
        <icon-color-picker [origin]="pickerOrigin"
                           [color]="color"
                           [icon]="icon"
                           (preview)="onIconColorChange($event)"
                           (selected)="onIconColorChange($event)"
                           (cancel)="onIconColorChange($event)">
        </icon-color-picker>
      </div>

      <div class="col-2"></div>
    </div>

    <resource-code-input class="d-block mb-3"
                         [form]="form"
                         (keydown.enter)="onEnter($event)">
    </resource-code-input>

    <resource-name-input class="d-block mb-3"
                         [form]="form"
                         (keydown.enter)="onEnter($event)">
    </resource-name-input>

  </div>

</form>

