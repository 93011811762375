<div class="form-control w-100 h-auto p-0"
     data-tour="search-box">
  <form *ngIf="form$ | async as form"
        [formGroup]="form"
        class="d-flex">
    <ng-container
      *ngIf="{canChangeQuery: canChangeQuery$ | async, query: query$ | async, perspective: perspective$ | async, currentView: currentView$ | async, queryItems: queryItems$ | async} as data">
      <div *ngIf="constraintData$ | async as constraintData"
           class="d-flex flex-wrap flex-grow-1 pb-1 pe-1"
           [style.width.px]="1"
           formArrayName="queryItems">
        <query-item
          *ngFor="let queryItemData of data.queryItems | createCompactQueryItems:(searchBoxData$ | async); trackBy:trackByQueryItem"
          [queryItemForm]="queryItemsControl.at(queryItemData.realIndex)"
          [queryItem]="queryItemData.queryItem"
          [constraintData]="constraintData"
          [readonly]="!(queryItemData.realIndex | isQueryItemEditable:data.queryItems:data.canChangeQuery:data.currentView?.query)"
          [restrictedMode]="!data.canChangeQuery"
          (change)="onQueryItemsChanged()"
          (remove)="onRemoveQueryItem(queryItemData.realIndex)"
          (focusInput)="searchInput.focusInput()"
          (stemToggle)="onToggleExpandStem($event)"
          (stemTextChange)="onStemTextChanged($event)"
          (stemQueryItemAdd)="onAddQueryItemToStem($event.item, queryItemData.stemIndex, $event.stemId)"
          class="d-inline-flex mt-1 ms-1 align-items-center">
        </query-item>

        <search-input #searchInput
                      [queryItems]="data.queryItems"
                      [restrictedMode]="!data.canChangeQuery"
                      (addQueryItem)="onAddQueryItem($event)"
                      (removeQueryItem)="onRemoveLastQueryItem(data.canChangeQuery)"
                      (search)="onSearch()"
                      class="flex-grow-1 mt-1 ms-1 mw-100">
        </search-input>
      </div>

      <settings-button *ngIf="(canManageConfig$ | async) && (data.perspective | shouldShowSettings:data.query)"
                       [showAttributes]="data.perspective | shouldShowAttributesSettings:data.query"
                       class="flex-grow-0">
      </settings-button>

    </ng-container>

    <search-button class="py-0 flex-grow-0"
                   (search)="onSearch($event)"
                   [disabled]="!form.valid">
    </search-button>
  </form>
</div>
