<ng-container *ngIf="!trial">
  <h5 i18n="@@organizations.tab.detail.order.prolong">Keep your Lumeer for Business active!</h5>
  <p i18n="@@organizations.tab.detail.order.activeUntil">Subscription is active until {{serviceLimits?.validUntil | date : 'mediumDate'}}. <strong>Extend it today!</strong></p>
</ng-container>
<h5 *ngIf="trial">
  <span i18n="@@organizations.tab.detail.order.get"
        [ngClass]="discountAmount <= 0 ? 'rounded-pill bg-danger p-2' : ''">Get Lumeer for Business now!</span>
</h5>
<div style="cursor: default;" *ngIf="discountAmount > 0" class="btn btn-lg btn-danger">
  {{discountDescription}} <span class="badge bg-success">-{{discountAmount}}%</span>
</div>
<form #orderForm="ngForm" class="mt-4">
  <label *ngIf="trial" class="form-label" for="subscriptionLength" i18n="@@organizations.tab.detail.order.duration">Subscription Duration</label>
  <label *ngIf="!trial" class="form-label" for="subscriptionLength" i18n="@@organizations.tab.detail.order.extension">Subscription Extension</label>
  <div>{{subscriptionLength}} =
    <del *ngIf="discountAmount > 0">{{discountInfoPerUser}}</del>
    <strong>{{prefix}}{{pricePerUser}}{{suffix}}</strong>
    <span i18n="@@organizations.tab.detail.order.perUserMonth">&nbsp;per user and month</span>
    <span *ngIf="discount" class="badge rounded-pill bg-success ms-1" i18n="@@organizations.tab.detail.order.bestOffer">-17% Best offer!</span>
  </div>
  <div class="mt-2">
    <slider
      i18n-values="@@organizations.tab.detail.order.values"
      values="1 month|2 months|3 months|6 months|1 year|2 years|3 years"
      defaultValue="4"
      id="subscriptionLength"
      (onSlide)="onSliderUpdated($event)">
    </slider>
  </div>
  <div class="mt-3">
    <label *ngIf="trial" class="form-label" for="startFrom" i18n="@@organizations.tab.detail.order.start">Subscription Start Date</label>
    <label *ngIf="!trial" class="form-label" for="startFrom" i18n="@@organizations.tab.detail.order.extension.start">Subscription Extension Start Date</label>
    <input class="form-control" type="date" [(ngModel)]="startDateText" (input)="updateStartDate($event)" required id="startFrom" name="startDate"/>
  </div>
  <div class="mt-3">
    <label for="numberOfUsers" class="form-label" i18n="@@organizations.tab.detail.order.users">Number of Users in your Organization</label>
    <input class="form-control" type="number" [(ngModel)]="numberOfUsers" (input)="updatePrice()"
           min="1" max="100" required id="numberOfUsers" name="users" ngModel #users="ngModel" pattern="[1-9][0-9]?"
    />
    <small id="usersValidMin" class="invalid-message" *ngIf="numberOfUsers < 1" i18n="@@organizations.tab.detail.order.users.invalidMin">
      Minimal order is one user.
    </small>
    <small id="usersValidMax" class="invalid-message" *ngIf="numberOfUsers > 99" i18n="@@organizations.tab.detail.order.users.invalidMax">
      For orders of more than 99 users please contact our <a href="mailto:support@lumeer.io">support</a>.
    </small>
  </div>
  <div class="mt-3">
    <label class="form-label">Currency</label>
    <div class="form-control">
      <div class="form-check form-check-inline">
        <input class="form-check-input" [(ngModel)]="currency" (change)="updatePrice()" type="radio" name="currency" id="currency1" value="EUR" checked>
        <label class="form-check-label" for="currency1">€ (EUR)</label>
      </div>
      <div class="form-check form-check-inline">
        <input class="form-check-input" [(ngModel)]="currency" (change)="updatePrice()" type="radio" name="currency" id="currency2" value="USD">
        <label class="form-check-label" for="currency2">$ (USD)</label>
      </div>
      <div class="form-check form-check-inline">
        <input class="form-check-input" [(ngModel)]="currency" (change)="updatePrice()" type="radio" name="currency" id="currency3" value="CZK">
        <label class="form-check-label" for="currency3">Kč (CZK)</label>
      </div>
    </div>
  </div>
  <div class="text-end mt-4">
    <a href="mailto:support@lumeer.io" *ngIf="numberOfUsers >= 100">
      <button class="btn btn-lg btn-primary mb-2" i18n="@@organizations.tab.detail.order.contactUs">Contact us for more users!</button>
    </a>
    <button *ngIf="numberOfUsers < 100" type="submit" (click)="placeOrder()"
            class="btn btn-lg btn-success mb-2"
            i18n="@@organizations.tab.detail.order.orderBtn"
            [disabled]="!orderForm.valid">
      Get Lumeer for only
      <del *ngIf="discountAmount > 0">{{prefix}}{{discountInfo | number:'1.2-2'}}{{suffix}}</del>
      <strong>{{prefix}}{{price | number:'1.2-2'}}{{suffix}}</strong>!
    </button>
  </div>
</form>
