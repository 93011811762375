<div class="pe-3">
  <input-box
    #nameInput
    innerClass="bg-transparent fw-bold"
    [canStayEmpty]="false"
    [userData]="false"
    [fontSizeRem]="1.2"
    [title]="team.name"
    [editable]="editable"
    [maxLength]="100"
    [initialValue]="team.name"
    (newValue)="onNewName($event, nameInput)">
  </input-box>

  <input-box
    innerClass="bg-transparent text-muted"
    [canStayEmpty]="true"
    [userData]="false"
    [fontSizeRem]="1"
    [paddingRem]="0.2"
    [maxLength]="1000"
    [editable]="editable"
    placeholder="Enter '{{team.name}}' description"
    i18n-placeholder="@@team.description.placeholder"
    [title]="team.description"
    [initialValue]="team.description"
    (newValue)="onNewDescription($event)">
  </input-box>

</div>

<div>
  <team-users class="d-block py-2"
              [selectedUserIds]="team.users"
              [users]="users"
              [editable]="editable"
              (save)="onUsersSave($event)">
  </team-users>
  <roles class="mt-2"
         [roles]="team | teamRolesMap:permissionsMap:resourcePermissionType"
         [resourcePermissionType]="resourcePermissionType"
         [emitAllChanges]="emitAllChanges"
         [transitiveRoles]="transitiveRoles"
         [editable]="changeRoles"
         (change)="teamRolesChange.emit($event)">
  </roles>
</div>

<div class="pt-2">
  <i class="far fa-fw fa-trash-alt text-danger m-1 cursor-pointer"
     *ngIf="deletable"
     aria-hidden="true"
     title="Remove team"
     i18n-title="@@team.actions.remove"
     (click)="onDelete()"></i>
  <i class="far fa-fw fa-times text-danger m-1 cursor-pointer"
     *ngIf="removable"
     aria-hidden="true"
     title="Remove team"
     i18n-title="@@team.actions.remove"
     (click)="onRemove()"></i>
  <i class="fa fa-fw m-1" *ngIf="!deletable"></i>
</div>


