<div [formGroup]="form">
  <div class="d-flex">
    <span class="text-muted" i18n="@@constraint.duration.description">
      Stores time duration for instance of a task or an event.
      The duration is specified in a format that is easy to write and read (e.g. 1h30m = 1 hour and 30 minutes).
      You can switch between a work type of duration where each day has 8 work hours,
      or normal time where a day has 24 hours.
    </span>
  </div>

  <div class="mt-3">
    <label class="form-label" [for]="controls.Type"
           i18n="@@constraint.duration.type.label">
      Time scope
    </label>
    <select-item class="w-100 d-block"
                 [items]="typeItems"
                 [removable]="false"
                 [selectedId]="typeControl.value"
                 (selected)="onTypeSelect($event)"
                 data-test="duration-constraint-format-select"
                 buttonClasses="flex-grow-1 text-truncate">
    </select-item>
  </div>

  <duration-constraint-config-form-conversion class="d-block mt-3"
                                              [formGroup]="form"
                                              [editable]="typeControl.value === type.Custom">
  </duration-constraint-config-form-conversion>

  <div class="mt-3">
    <label class="form-label" [for]="controls.MaxUnit"
           i18n="@@constraint.duration.maxUnit.label">Maximal unit</label>
    <select-item class="w-100 d-block"
                 [items]="maxUnitItems"
                 [removable]="false"
                 [selectedId]="maxUnitControl.value"
                 (selected)="onMaxUnitSelect($event)"
                 buttonClasses="flex-grow-1 text-truncate">
    </select-item>
  </div>

  <p class="text-muted mt-3">
    <span i18n="@@constraint.example">Example:</span> {{exampleString$ | async}}
  </p>

</div>
