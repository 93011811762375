<dropdown [closeOnClickOutside]="closeOnClickOutside"
          [closeOnClickOrigin]="closeOnClickOrigin"
          [showBackdrop]="showBackdrop"
          [fitParent]="fitParent"
          [origin]="origin"
          [pushOnScreen]="true"
          [minWidth]="minWidth || 100"
          [maxWidth]="maxWidth"
          [positions]="dropdownPositions"
          (positionChange)="onPositionChange($event)"
          (onClose)="onClose.emit()">
  <div *ngIf="options?.length > 0"
       class="card dropdown-menu overflow-auto" [class.w-100]="fitParent">

    <ng-container
      *ngFor="let group of options | reverseArrayByDropdownPosition:(dropdownPosition$ | async) | groupDropdownOptions">

      <span *ngIf="group.group" class="text-muted py-2 px-4 small"
            (click)="$event.preventDefault(); $event.stopPropagation()">{{group.group}}</span>

      <ng-container *ngFor="let option of group.options;">
        <a *ngIf="{selectable: editable && (allowSelection || (selectedValues | includes:option.value))} as optionData"
           [class.active]="(activeValue$ | async) === option.value"
           [class.cursor-default]="!optionData.selectable"
           (mousedown)="$event.stopImmediatePropagation(); $event.preventDefault();"
           (click)="optionData.selectable && onOptionSelect($event, option)"
           class="dropdown-item text-truncate"
           [class.dropdown-item-multi]="multiSelect"
           [class.dropdown-item-disabled]="!optionData.selectable"
           data-test="select-item-option"
           tabindex>

          <i *ngIf="multiSelect || maxSelected || showSelection" class="fa-fw far me-2" [ngClass]="(selectedValues | includes:option.value) ? 'fa-check' : ''"></i>

          <div class="option d-inline-block"
               [ngClass]="option.classList"
               [style.background]="option.background"
               [style.border-color]="option.border"
               [class.option-background]="!!option.background"
               [class.option-border]="!!option.border"
               [style.color]="option.color || (option.background && (option.background | contrastColor))">

            <img ngxGravatar
                 *ngIf="option.gravatar"
                 [email]="option.gravatar"
                 [size]="avatarSize"
                 [class.me-2]="(option.displayValue || option.value) | isNotNullOrUndefined">

            <img *ngIf="option.imageUrl"
                 [src]="option.imageUrl"
                 [class.me-2]="(option.displayValue || option.value) | isNotNullOrUndefined">

            <icons-presenter *ngIf="option.icons && option.iconColors"
                             [class.me-1]="(option.displayValue || option.value) | isNotNullOrUndefined"
                             [colors]="option.iconColors"
                             [icons]="option.icons">
            </icons-presenter>

            <i *ngIf="option.icons && !option.iconColors && option.icons.length === 1"
               class="fa-fw {{option.icons[0]}}"
               [class.me-1]="(option.displayValue || option.value) | isNotNullOrUndefined">
            </i>
            <span class="text-nowrap" [innerHTML]="(option.displayValue || option.value) | highlightText:highlightedText">
        </span>
          </div>
          <div *ngIf="option.description" class="text-muted small px-2 option-description">{{option.description}}</div>
        </a>
      </ng-container>

    </ng-container>
  </div>
</dropdown>
