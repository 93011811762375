<ng-container
  *ngIf="{usersMap: usersMap$ | async, constraintData: constraintData$ | async, serviceLimits: serviceLimits$ | async, revertingIds: revertingAuditLogs$ | async, viewsMap: viewsMap$ | async, collectionsMap: collectionsMap$ | async, linkTypesMap: linkTypesMap$ | async} as data">
  <audit-logs-filters class="d-block pb-5"
                      *ngIf="data.serviceLimits?.serviceLevel === serviceLevel.BASIC"
                      [filters]="filters$ | async"
                      [constraintData]="constraintData$ | async"
                      [viewsMap]="data.viewsMap"
                      [collectionsMap]="data.collectionsMap"
                      [linkTypesMap]="data.linkTypesMap"
                      [configuration]="configuration"
                      [resourceType]="resourceType"
                      (filtersChanged)="onFiltersChanged($event)">
    <ng-content></ng-content>
  </audit-logs-filters>
  <ng-container *ngIf="auditLogs?.length; else emptyState">
    <ng-container *ngIf="auditLogs | filterAuditLogs:(filters$ | async): data.constraintData: data.viewsMap: data.collectionsMap: data.linkTypesMap as filteredAuditLogs">
      <audit-log *ngFor="let auditLog of filteredAuditLogs; trackBy:trackByAudit; let first = first; let last = last"
                 [auditLog]="auditLog"
                 [parent]="auditLog | auditLogParent:data.collectionsMap:data.linkTypesMap"
                 [first]="first"
                 [reverting]="data.revertingIds | includes:auditLog.id"
                 [last]="last"
                 [constraintData]="data.constraintData"
                 [allowRevert]="configuration?.allowRevert && (data.serviceLimits && data.serviceLimits?.serviceLevel !== serviceLevel.FREE) && (auditLog | auditLogRevertable: auditLogs)"
                 [showDetail]="configuration?.objectDetail && (auditLog | auditLogHasDetail: auditLogs)"
                 [usersMap]="data.usersMap"
                 [viewsMap]="data.viewsMap"
                 (revert)="onRevert(auditLog)"
                 (detail)="onDetail(auditLog)">
      </audit-log>
      <empty-search *ngIf="!filteredAuditLogs.length" [compact]="true" class="px-lg-5 px-3 d-block">
      </empty-search>
    </ng-container>
  </ng-container>
  <div *ngIf="!data.serviceLimits || data.serviceLimits.serviceLevel === serviceLevel.FREE"
       class="py-3 mt-3 d-flex flex-column align-items-center text-center">
  <span i18n="@@document.detail.audit.limit.free">Showing only 3 most recent entries from last {{data.serviceLimits?.auditDays || defaultAuditDays}} days. Upgrade to the Business license to see the full history of the last {{data.serviceLimits?.auditDays || defaultAuditDays}} days. <a
    href="https://www.lumeer.io/contact/" target="_blank">Contact us</a> with special requests.</span>
    <button *ngIf="organizationPermissions$ | async | canEnterOrganizationSettings"
            (click)="openServiceOrder()"
            class="btn btn-success mt-2">
      <span i18n="@@collection.config.tab.rules.upgradePlan">Upgrade to Business Plan!</span>
    </button>
  </div>
  <div *ngIf="data.serviceLimits?.serviceLevel === serviceLevel.BASIC"
       class="py-3 mt-3 d-flex flex-column align-items-center text-center">
    <span i18n="@@document.detail.audit.limit.basic">Showing last {{data.serviceLimits?.auditDays || defaultAuditDays}} days of history. <a href="https://www.lumeer.io/contact/" target="_blank">Contact us</a> with special needs.</span>
  </div>
</ng-container>

<ng-template #emptyState>
  <div *ngIf="loading" class="d-flex align-items-center justify-content-center p-lg-5 p-3">
    <span class="spinner-grow spinner-grow-sm me-2" role="status" aria-hidden="true"></span>
    <span i18n="@@message.loading">Loading...</span>
  </div>
  <div *ngIf="!loading" class="text-center p-1 fst-italic" i18n="@@document.detail.activity.empty">
    No activity yet.
  </div>
</ng-template>
