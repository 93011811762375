<div class="text-center text-muted px-0"
     i18n="@@color-picker.select.icon">1. Choose color
</div>
<div class="colors mt-2">
  <div class="rounded"
    *ngFor="let colorOption of greyscaleColors"
    [style.background]="colorOption"
    [style.border-color]="colorOption | highlightColor:color"
    (mouseenter)="onPreview(elem, colorOption)"
    (mouseleave)="unPreview(elem, colorOption)"
      #elem
    (click)="onSelect(colorOption)">
  </div>
  <div class="rounded text-primary d-flex align-items-center justify-content-center"
    [style.border-color]="customColor ? (color | highlightColor:color) : 'transparent'"
    [style.background]="customColor ? color : 'transparent'">
    <i [style.color]="customColor ? 'transparent' : ''"
      [colorPicker]="color"
      [cpCancelButton]="true"
      [cpCancelButtonClass]="'btn btn-outline-secondary btn-sm me-2'"
      [cpCancelButtonText]="cancelButtonText"
      [cpOKButton]="true"
      [cpOKButtonClass]="'btn btn-primary btn-sm'"
      [cpOKButtonText]="okButtonText"
      [cpPositionOffset]="'-50%'"
      (colorPickerSelect)="onPickerSelect($event)"
      (colorPickerChange)="onPickerChange($event)"
      (colorPickerCancel)="onPickerCancel($event)"
      class="far fa-crosshairs">
    </i>
  </div>
  <div class="rounded"
       *ngFor="let colorOption of sepiaColors"
       [style.background]="colorOption"
       [style.border-color]="colorOption | highlightColor:color"
       (mouseenter)="onPreview(elem, colorOption)"
       (mouseleave)="unPreview(elem, colorOption)"
       #elem
       (click)="onSelect(colorOption)">
  </div>
  <div class="rounded"
    *ngFor="let colorOption of saturatedColors"
    [style.background]="colorOption"
    [style.border-color]="colorOption | highlightColor:color"
    (mouseenter)="onPreview(elem, colorOption)"
    (mouseleave)="unPreview(elem, colorOption)"
      #elem
    (click)="onSelect(colorOption)"
       data-test="color-picker-saturated-color">
  </div>
</div>
<div class="colors mt-2">
  <div class="rounded"
    *ngFor="let colorOption of colors"
    [style.background]="colorOption"
    [style.border-color]="colorOption | highlightColor:color"
    (mouseenter)="onPreview(elem,colorOption)"
    (mouseleave)="unPreview(elem, colorOption)"
      #elem
    (click)="onSelect(colorOption)">
  </div>
</div>
