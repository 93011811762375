<team-table *ngIf="{serviceLimits: serviceLimits$ | async} as data"
            [users]="users"
            [teams]="teams"
            [color]="view | viewColor:(collectionsMap$ | async)"
            [resourcePermissionType]="resourcePermissionType"
            [permissionsMap]="resourcePermissionType | resourceTypePermissionsMap:permissions"
            [editableTeams]="data.serviceLimits?.groups"
            [organization]="organization"
            [project]="project"
            [changeRoles]="data.serviceLimits?.groups"
            [emitAllChanges]="true"
            (teamRolesChange)="onTeamRolesChange($event)">
</team-table>
