<dropdown [origin]="origin"
          [positions]="dropdownPositions"
          [closeOnClickOutside]="true"
          [closeOnClickOrigin]="true"
          [pushOnScreen]="true"
          [showBackdrop]="false">

  <filter-builder-content class="card p-2"
                          [attribute]="attribute"
                          [selectedCondition]="condition"
                          [selectedValues]="conditionValues"
                          [constraintData]="constraintData$ | async"
                          [visible]="visible$ | async"
                          (valueChange)="onValueChange($event)"
                          (finishEditing)="onFinishEditing()">
  </filter-builder-content>

</dropdown>
