<quill-view-html class="quill-editor overflow-hidden {{inputClass}}"
                 *ngIf="readonly && (value | previewDataValue)"
                 [ngClass]="multilineMode ? 'multiline' : 'single-line'"
                 [class.h-100]="multilineMode"
                 [content]="value | previewDataValue"
                 [title]="value | formatDataValueTitle"
                 (dblclick)="onDoubleClick()"
                 [style.color]="fontColor"
                 [class.invalid-value]="!commonConfiguration?.skipValidation && !valid">
</quill-view-html>

<span class="icon-fullscreen" @enterOpacityAnimation
      *ngIf="!readonly || (mouseEntered$ | async)"
      [class.always-visible]="!readonly"
      (mousedown)="$event.stopImmediatePropagation(); $event.preventDefault();"
      (click)="openTextEditor($event)">
  <i class="fa fa-expand-alt"></i>
</span>

<quill-editor *ngIf="!readonly"
              class="w-100 quill-editor edit {{inputClass}}"
              [ngClass]="multilineMode ? 'multiline' : 'single-line'"
              [class.h-100]="multilineMode"
              [(ngModel)]="text"
              [readOnly]="readonly"
              [modules]="modules"
              [maxLength]="value.config.maxLength"
              [minLength]="value.config.minLength"
              [placeholder]="''"
              theme="bubble"
              (onBlur)="onBlur()"
              (onEditorCreated)="onEditorCreated($event)"
              (onContentChanged)="contentChanged($event)">
</quill-editor>

<rich-text-dropdown [modalData]="modalData$ | async"
                    [origin]="element"
                    [content]="text"
                    [readonly]="readonly && !editableInReadonly"
                    [minLength]="value?.config?.minLength"
                    [maxLength]="value?.config?.maxLength"
                    (save)="onSave($event)"
                    (cancel)="onCancel()"
                    (dataChange)="onModalDataChange($event)">
</rich-text-dropdown>
