<h4 class="mb-2" i18n="@@collections.tab.attributes.new.header">Add a new attribute</h4>
<form [formGroup]="form">
  <div class="input-group input-group-sm">
    <input
      class="form-control"
      type="text"
      placeholder="Enter attribute name"
      formControlName="attribute"
      (input)="onInput($event.target.value)"
      (keypress)="onKeyPress($event)"
      i18n-placeholder="@@collection.tab.attributes.attribute.placeholder"
    >
    <div class="input-group-append">
      <button type="button"
              [disabled]="form.invalid"
              class="btn btn-sm btn-primary"
              (click)="onCreateAttribute()">
        <i class="fas fa-plus"></i>&nbsp;
        <span class="fw-bold" i18n="@@collection.tab.attributes.attribute.add">Add attribute</span>
      </button>
    </div>
  </div>
</form>
<div *ngIf="attributeControl?.errors?.notUnique"
     class="text-danger mt-1 small"
     i18n="@@collection.tab.attributes.create.notUnique">
  Attribute name must be unique!
</div>
