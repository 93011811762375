<form class="p-3">

  <ng-container *ngIf="allowedUsers?.length; else noAllowedUsers">
    <div class="input-group">
      <input ngModel [value]="email" name="emailDropdown" #emailDropdown="ngModel"
             id="emailDropdown" class="form-control form-control-sm" type="email"
             list="allowedUsersList"
             [placeholder]="emailPlaceHolder()"
             (keypress)="onKeyPress($event)"
             (input)="onInputChanged(emailDropdown.control.value)" email>
      <datalist id="allowedUsersList">
        <option *ngFor="let user of allowedUsers" [value]="user">{{user}}</option>
      </datalist>
      <div class="input-group-append">
        <button type="button"
                class="btn btn-sm btn-success"
                [disabled]="isDuplicate || !isAllowed"
                (click)="onAddUser()">
          <i class="fas fa-plus me-2"></i>
          <span class="fw-bold" i18n="@@inviteUser.dialog.button">Add</span>
        </button>
      </div>
    </div>

    <div class="flex-row pt-1">
      <small id="emailDropdownValid" class="d-block invalid-message" *ngIf="emailDropdown.invalid && emailDropdown.touched" i18n="@@inviteUser.dialog.user.emailDropdown.invalid">
        Please select one of the colleagues who are already in your organization.
      </small>
    </div>
  </ng-container>

  <ng-template #noAllowedUsers>
    <div class="input-group">
      <input [value]="email" name="emailField" #emailField="ngModel" ngModel
             id="emailField" class="form-control form-control-sm" type="email"
             [placeholder]="emailPlaceHolder()"
             (keypress)="onKeyPress($event)"
             (input)="onInputChanged(emailField.control.value)" email>
      <div class="input-group-append">
        <button type="button"
                class="btn btn-sm btn-success"
                [disabled]="!isValid || isDuplicate"
                (click)="onAddUser()">
          <i class="fas fa-plus me-2"></i>
          <span class="fw-bold" i18n="@@inviteUser.dialog.button">Add</span>
        </button>
      </div>
    </div>
    <div class="flex-row pt-1">
      <small id="emailValid" class="invalid-message" *ngIf="!isValid && emailField.touched" i18n="@@inviteUser.dialog.user.email.invalid">
        Please enter a valid e-mail address (e.g. alan.turing@lumeerio.com).
      </small>
      <small class="invalid-message" *ngIf="isDuplicate" i18n="@@inviteUser.dialog.email.duplicate">
        A colleague with this email is already invited. Try another one!
      </small>
    </div>

  </ng-template>

</form>
