<input [title]="value | formatDataValueTitle"
       [value]="readonly ? (value | formatDataValue | unescapeHtml) : (value | formatDataValueForEdit)"
       [style.color]="readonly ? fontColor : null"
       #numberInput
       type="text"
       class="border-0 w-100 h-100 px-1 bg-transparent"
       inputmode="numeric"
       (blur)="!readonly && onBlur()"
       (focus)="!readonly && onFocus()"
       (input)="onInput($event)"
       [class.invalid-value]="!commonConfiguration?.skipValidation && !valid"
       [ngClass]="readonly || valid || commonConfiguration?.skipValidation ? 'bg-transparent ' + inputClass : 'bg-danger-light ' + inputClass"
       [readonly]="readonly">
