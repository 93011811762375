<div [formGroup]="formGroup">
  <label class="form-label" for="linkName" i18n="@@collection.new.dialog.link.label">Link</label>
  <div class="input-group">
    <div class="input-group-text px-2">
      <icons-presenter [colors]="colors"
                       [icons]="icons">
      </icons-presenter>
    </div>
    <input #linkName
           [class.is-invalid]="linkNameInput.invalid"
           formControlName="linkName"
           id="linkName"
           type="text"
           class="form-control"
           data-test="link-name-input"
           placeholder="Enter link name..." i18n-placeholder="@@collection.new.dialog.link.name.placeholder">
    <div class="invalid-feedback form-text">
      <div *ngIf="linkNameInput.errors && linkNameInput.errors.notUnique"
           i18n="@@validation.link.name.existing">
        Such a Link already exists.
      </div>
      <div *ngIf="linkNameInput.errors &&linkNameInput.errors.minLength"
           i18n="@@validation.link.name.short">
        Link name needs to have at least 3 characters.
      </div>
    </div>
  </div>
</div>
