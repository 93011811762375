<div [formGroup]="form">
  <div>
    <label class="form-label" i18n="@@constraint.text.caseStyle.label">
      Case style
    </label>
    <select-item class="w-100 d-block"
                 [items]="items"
                 [removable]="false"
                 [selectedId]="form.get(textConstraintFormControl.CaseStyle).value"
                 buttonClasses="text-truncate flex-grow-1"
                 data-test="text-constraint-case-style-select"
                 (selected)="onCaseSelect($event)">
    </select-item>
  </div>

  <div class="d-flex">
    <div class="mt-3 flex-grow-1">
      <label class="form-label" for="textMinLength" i18n="@@constraint.text.minLength.label">Minimum length</label>
      <input class="form-control"
             min="0"
             formControlName="minLength"
             id="textMinLength"
             integer
             [minValue]="0"
             type="number">
    </div>

    <div class="mt-3 ms-3 flex-grow-1">
      <label class="form-label" for="textMaxLength" i18n="@@constraint.text.maxLength.label">Maximum length</label>
      <input class="form-control"
             formControlName="maxLength"
             id="textMaxLength"
             integer
             type="number">
    </div>
  </div>

  <div *ngIf="form.errors && form.errors['minMaxInvalid']"
       class="text-danger mt-3 form-text" i18n="@@form.errors.minMaxInvalid">
    Minimum must be less or equal than maximum.
  </div>
</div>
