<span tooltip="Notifications" i18n-tooltip="@@topPanel.notifications.title" placement="bottom" class="fa-stack ms-1 cursor-pointer" #origin (click)="dropdown.open()">
  <i class="fa fa-bell fa-stack-1x font-size-15"
     [class.text-primary]="unreadNotifications?.length > 0"
     [class.text-secondary]="!unreadNotifications || unreadNotifications.length === 0"></i>
  <span class="fa-stack-1x" *ngIf="unreadNotifications?.length > 0">
    <span class="notifications-count">{{unreadNotifications.length}}</span>
  </span>
</span>

<notifications-menu-dropdown #dropdown
                             [organizations]="organizations"
                             [allNotifications]="allNotifications"
                             [unreadNotifications]="unreadNotifications"
                             [unreadOnly]="unreadOnly"
                             [origin]="origin"
                             (clickNotification)="clickNotification.emit($event)"
                             (deleteNotification)="deleteNotification.emit($event)"
                             (readNotification)="readNotification.emit($event)"
                             (toggleUnread)="toggleUnread.emit($event)">
</notifications-menu-dropdown>
