<div class="d-flex align-items-center h-100 flex-wrap wrapper-element" #wrapperElement
     *ngIf="selectedTeams$ | async as selectedTeams">

  <div *ngFor="let team of selectedTeams; trackBy: trackByTeam"
       (mousedown)="onMouseDown($event)"
       (click)="onClick()"
       class="badge badge-lg badge-pill badge-outline-info me-2 mb-1 team-tag cursor-pointer">{{team.name}}</div>

  <div #textInputParent>
    <button class="btn btn-sm btn-link text-nowrap py-0 mb-1" *ngIf="editable && !(suggesting$ | async)"
            (click)="$event.stopPropagation(); onEditClick()">
      <i class="far fa-users me-1"></i>
      <span *ngIf="!(selectedTeams | empty)" i18n="@@edit">Edit</span>
      <span *ngIf="selectedTeams | empty" i18n="@@users.teams.add.teams">Add Teams</span>
    </button>

    <input *ngIf="editable && (suggesting$ | async)"
           #textInput
           [(ngModel)]="name"
           (blur)="onBlur()"
           (focus)="onFocus()"
           (click)="$event.stopPropagation()"
           placeholder="Add team"
           i18n-placeholder="@@user.teams.search.placeholder"
           (keydown)="onKeyDown($event)"
           [class.ms-1]="selectedTeams.length > 0"
           [spellcheck]="false"
           class="border-0 bg-transparent mb-1"
           type="text">

    <options-dropdown [options]="teams | filterTeams:name"
                      [showBackdrop]="false"
                      [closeOnClickOutside]="!editable"
                      [origin]="textInputParent"
                      [highlightedText]="name"
                      [multiSelect]="true"
                      [editable]="editable"
                      [dropdownPositions]="dropdownPositions"
                      [selectedValues]="selectedTeams | teamsIds"
                      [firstItemActive]="false"
                      (selectOption)="onSelectOption($event)">
    </options-dropdown>
  </div>


</div>
