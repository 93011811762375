<input [id]="checkboxId"
       [attr.checked]="checked"
       [checked]="checked"
       type="checkbox"
       #checkbox
       (change)="checkedChange.emit($event.target.checked)"
       class="form-check-input">
<label [for]="checkboxId"
       class="form-check-label">
</label>
<span *ngIf="checkboxTitle" class="user-select-none cursor-pointer" (click)="checkbox.click()">{{checkboxTitle}}</span>
