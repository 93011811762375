<ng-container *ngIf="{loading: loading$ | async, stats: stats$ | async} as data">
  <button class="btn btn-sm small text-truncate mx-1 text-nowrap"
          *ngIf="{opacity: (data.stats?.disabled || data.loading) ? 0.5 : 1} as buttonData"
          [style.background]="value?.config?.background"
          [class.disabled]="data.stats?.disabled || data.loading"
          [tooltip]="statsTooltip"
          containerClass="bigger-tooltip"
          [isDisabled]="!data.stats?.disabled || !(!!cursor?.documentId || !!cursor?.linkInstanceId)"
          [style.color]="fontColor || (value?.config?.background | contrastColor:null:buttonData.opacity)"
          (click)="onClick($event)">
    <span *ngIf="data.loading" class="spinner-grow spinner-grow-sm me-2" role="status" aria-hidden="true"></span>
    <i *ngIf="icon" [ngClass]="icon"></i>
    <ng-container *ngIf="title && icon || !title && !icon">&nbsp;</ng-container>
    <span *ngIf="title">{{title}}</span>
  </button>

  <ng-template #statsTooltip>
    <attribute-lock-filters-stats [lock]="data.stats?.lock"
                                  [stats]="data.stats"
                                  [constraintData]="data.stats?.constraintData"
                                  [attributesMap]="data.stats?.attributesMap">
    </attribute-lock-filters-stats>
  </ng-template>

</ng-container>
