<div class="d-flex align-items-center h-100 pe-1 py-1 flex-wrap" #wrapperElement
     *ngIf="selectedUsers$ | async as selectedUsers"
     [class.ps-2]="selectedUsers.length"
     [title]="selectedUsers | usersNames | join">

  <img ngxGravatar class="user cursor-pointer"
       *ngFor="let user of selectedUsers; trackBy:trackByUser; let first = first"
       (click)="onClick()"
       (mousedown)="onMouseDown($event)"
       [email]="user.email"
       [size]="avatarSize"
       [style.min-width.px]="avatarSize"
       [style.min-height.px]="avatarSize">

  <div #textInputParent>
    <button class="btn btn-sm btn-link text-nowrap py-0" *ngIf="editable && !(suggesting$ | async)"
            (click)="$event.stopPropagation(); onEditClick()">
      <i class="far fa-users me-1"></i>
      <span *ngIf="!(selectedUsers | empty)" i18n="@@edit">Edit</span>
      <span *ngIf="selectedUsers | empty" i18n="@@team.users.add.users">Add Users</span>
    </button>

    <input *ngIf="editable && (suggesting$ | async)"
           #textInput
           [(ngModel)]="name"
           (blur)="onBlur()"
           (focus)="onFocus()"
           (click)="$event.stopPropagation()"
           placeholder="Search user"
           i18n-placeholder="@@teams.users.search.placeholder"
           (keydown)="onKeyDown($event)"
           [class.ms-1]="selectedUsers.length > 0"
           [spellcheck]="false"
           class="border-0 bg-transparent"
           type="text">

    <options-dropdown [options]="users | filterUsers:name"
                      [showBackdrop]="false"
                      [closeOnClickOutside]="!editable"
                      [origin]="textInputParent"
                      [highlightedText]="name"
                      [multiSelect]="true"
                      [editable]="editable"
                      [dropdownPositions]="dropdownPositions"
                      [selectedValues]="selectedUsers | usersIds"
                      [firstItemActive]="false"
                      (selectOption)="onSelectOption($event)">
    </options-dropdown>
  </div>

</div>
