<div class="toolbar d-flex">
  <i class="ms-auto far fa-fw fa-trash-alt text-danger cursor-pointer"
     (click)="delete.emit()"
     title="Delete" i18n-title="@@delete"
     aria-hidden="true">
  </i>
</div>

<form [formGroup]="form">

  <div class="row mb-0">

    <label class="col-form-label col-2 mb-2" i18n="@@resource.new.dialog.icon.label">Icon:</label>

    <div class="col-8 d-block mb-3 text-center cursor-pointer" (click)="pickerComponent.toggle()">
      <div class="text-muted small my-2" i18n="@@resource.new.dialog.icon.label.hint">(click the icon to change it)</div>
      <i class="fa-fw {{ iconControl.value }} fa-3x"
         [style.color]="colorControl.value" #pickerOrigin></i>
      <icon-color-picker #pickerComponent
                         [origin]="pickerOrigin"
                         [color]="colorControl.value"
                         [icon]="iconControl.value"
                         (preview)="onIconColorChange($event)"
                         (selected)="onIconColorChange($event)"
                         (cancel)="onIconColorChange($event)">
      </icon-color-picker>
    </div>

    <div class="col-2"></div>
    <div class="col-12 text-center">
      <button class="btn btn-link" type="button"
              [class.hidden]="colorControl.value === defaultColor && iconControl.value === defaultIcon"
              (click)="resetIconAndColor()"><span class="small" i18n="@@view.settings.reset.iconColor">Reset icon and color</span>
      </button>
    </div>
  </div>

  <div class="mt-3 row">
    <label class="col-12 form-label"
           i18n="@@resource.new.dialog.name.label"
           for="name">Name:</label>

    <div class="col-12">
      <input type="text"
             id="name"
             formControlName="name"
             class="form-control"
             i18n-placeholder="@@resource.new.dialog.name.placeholder"
             placeholder="Enter name"
             (keydown.enter)="$event.target.blur()">
    </div>
    <small class="col-12 text-danger mt-1" *ngIf="nameControl.errors?.notEmpty"
           i18n="@@view.modal.settings.name.notEmpty">View name can not be empty</small>
  </div>

  <div class="row mt-3">
    <label class="col-12 form-label"
           i18n="@@view.modal.settings.priority"
           for="priority">Priority:</label>

    <div class="col-12">
      <input type="number"
             id="priority"
             formControlName="priority"
             class="form-control"
             integer
             inputmode="numeric"
             i18n-placeholder="@@view.modal.settings.priority.placeholder"
             placeholder="Enter any number"
             (keydown.enter)="$event.target.blur()">
    </div>
    <small class="col-12 text-danger mt-1" *ngIf="priorityControl.errors?.invalid"
           i18n="@@view.modal.settings.priority.invalid">Number is not valid</small>
    <small class="col-12 text-danger mt-1" *ngIf="priorityControl.errors?.invalidRange"
           i18n="@@view.modal.settings.priority.invalidRange">Number is not from valid range ({{minInteger}}
      - {{maxInteger}})</small>
    <div class="col-12 mt-1 text-muted small"
         i18n="@@view.modal.settings.priority.help">By default, views are sorted by last used. Setting an priority
      overrides the default behavior. A higher priority means that the view will be above others.
    </div>
  </div>

  <div class="row mt-3">
    <label class="col-12 form-label"
           i18n="@@view.modal.settings.folders"
           for="name">Folders:</label>

    <div class="col-12">
      <input-tags [formGroup]="form"
                  [suggestions]="views | viewsUniqueFolders"
                  controlName="folders"
                  placeholderTitle="Enter folder name and press Enter"
                  i18n-placeholderTitle="@@view.modal.settings.folders.placeholder"
                  removeTagTitle="Remove folder"
                  i18n-removeTagTitle="@@view.modal.settings.folders.remove">
      </input-tags>
    </div>
    <div class="col-12 mt-1 text-muted small"
         i18n="@@view.modal.settings.folders.help">Use "/" to create nested folders i.e "Folder/Subfolder"
    </div>
  </div>

</form>
