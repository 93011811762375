<top-panel-wrapper>
  <div *ngIf="{project: project$ | async, permissions: permissions$ | async } as data"
       class="col-12 col-lg-11 col-xl-10 container-fluid mx-auto content">
    <resource-header
      [resource]="data.project"
      [resourceType]="projectType"
      [permissions]="data.permissions"
      (back)="goBack()"
      (delete)="onDelete()"
      (colorIconChange)="onNewColorOrIcon($event)"
      (descriptionChange)="onNewDescription($event)"
      (nameChange)="onNewName($event)"
      (codeChange)="onNewCode($event)">

      <div class="d-flex flex-row flex-nowrap align-items-center me-3"
           title="Number of users" i18n-title="@@project.settings.users.count">
        <i class="far fa-user" aria-hidden="true"></i>&nbsp;<span>{{ userCount$ | async }}</span>
      </div>

      <div class="d-flex flex-row flex-nowrap align-items-center cursor-pointer color-success me-3"
           (click)="onCollectionsClick()"
           title="Number of tables" i18n-title="@@project.settings.collections.count">
        <i class="far fa-edit" aria-hidden="true"></i>&nbsp;<span>{{data.project.collectionsCount}}</span>
      </div>

      <div class="d-flex flex-row flex-nowrap align-items-center cursor-pointer color-success me-3"
           *ngIf="data.permissions?.roles?.Manage"
           (click)="onDownloadRawContent()"
           title="Download project content" i18n-title="@@project.settings.collections.downloadContent">
        <i class="far fa-download" aria-hidden="true"></i>&nbsp;
      </div>

      <project-upload
        *ngIf="data.permissions?.roles?.Manage && data.permissions?.roles?.CollectionContribute && data.permissions?.roles?.LinkContribute && data.permissions?.roles?.ViewContribute"
        [uploadProgress]="uploadProgress$ | async"
        (add)="uploadRawProjectContent($event)">
      </project-upload>

      <div class="d-flex flex-row flex-nowrap align-items-center cursor-pointer color-danger me-3"
           *ngIf="data.permissions?.roles?.Manage"
           (click)="onSampleDataEraseClick()"
           title="Delete sample data from the project" i18n-title="@@project.settings.collections.deleteSampleData">
        <i class="far fa-eraser" aria-hidden="true"></i>&nbsp;
      </div>
    </resource-header>

    <ul class="nav nav-tabs">
      <li role="presentation" class="nav-item" *ngIf="data.permissions?.roles?.UserConfig">
        <a [routerLink]="'users'"
           routerLinkActive="active"
           class="nav-link"
           i18n="@@project.settings.tab.users">Users</a>
      </li>
      <li role="presentation" class="nav-item" *ngIf="data.permissions?.roles?.UserConfig">
        <a [routerLink]="'teams'"
           routerLinkActive="active"
           class="nav-link"
           i18n="@@project.settings.tab.teams">Teams</a>
      </li>
      <li role="presentation" class="nav-item" *ngIf="data.permissions?.roles?.TechConfig">
        <a [routerLink]="'sequences'"
           routerLinkActive="active"
           class="nav-link"
           i18n="@@project.settings.tab.sequences">Sequences</a>
      </li>
      <li role="presentation" class="nav-item" *ngIf="data.permissions?.roles?.TechConfig">
        <a [routerLink]="'template'"
           routerLinkActive="active"
           class="nav-link"
           i18n="@@project.settings.tab.template">Publish Project</a>
      </li>
      <li role="presentation" class="nav-item" *ngIf="data.permissions?.roles?.TechConfig">
        <a [routerLink]="'selection'"
           routerLinkActive="active"
           class="nav-link"
           i18n="@@project.settings.tab.selection">Selection Lists</a>
      </li>
      <li role="presentation" class="nav-item" *ngIf="data.permissions?.roles?.TechConfig">
        <a [routerLink]="'variables'"
           routerLinkActive="active"
           class="nav-link"
           i18n="@@project.settings.tab.variables">Variables</a>
      </li>
      <li role="presentation" class="nav-item" *ngIf="data.permissions?.roles?.Manage">
        <a [routerLink]="'activity'"
           routerLinkActive="active"
           class="nav-link"
           i18n="@@audit.log.activity">Activity</a>
      </li>
    </ul>
    <router-outlet></router-outlet>
  </div>
</top-panel-wrapper>
