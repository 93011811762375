<form [formGroup]="form">
  <div class="row m-0 mb-4">
    <label class="col-sm-12 col-md-3 px-0 col-lg-2 col-form-label">
      &nbsp;
    </label>
    <collection-purpose-select class="col-sm-12 col-md-6 col-lg-5 col-xl-4 px-0" [type]="typeControl.value"
                               (typeChange)="typeControl.setValue($event)">
    </collection-purpose-select>
  </div>

  <collection-purpose-form class="d-block pt-3"
                           [form]="metaDataForm"
                           [collection]="collection"
                           [type]="typeControl.value">
  </collection-purpose-form>


</form>
