<select-item2 class="d-inline-block"
              [items]="typeItems"
              [selectedPath]="[typeControl.value]"
              [removable]="false"
              (selectPath)="onTypePathSelected($event)">
</select-item2>

<user-data-input *ngIf="typeControl.value === type.UsersAndTeams"
                 class="w-100 form-control p-0 mt-2 cursor-pointer"
                 [value]="userDataValue$ | async"
                 [focus]="true"
                 [readonly]="!(editing$ | async)"
                 placeholder="Select Users and Teams"
                 i18n-placeholder="@@resource.attribute.lock.group.type.users.placeholder"
                 (click)="onClick()"
                 (cancel)="onCancel()"
                 (save)="onSave($event)">
</user-data-input>

<div class="fst-italic mb-2-5" [ngClass]="typeControl.value === type.UsersAndTeams ? 'mt-2' : 'mt-1'">
  <span *ngIf="locked && filtersControl.length" i18n="@@resource.attribute.lock.group.divider.locked">can edit when</span>
  <span *ngIf="locked && !filtersControl.length" i18n="@@resource.attribute.lock.group.divider.locked.short">can edit</span>
  <span *ngIf="!locked && filtersControl.length" i18n="@@resource.attribute.lock.group.divider.editable">can not edit when</span>
  <span *ngIf="!locked && !filtersControl.length" i18n="@@resource.attribute.lock.group.divider.editable.short">can not edit</span>
</div>

<constraint-conditions-form class="d-block mt-1"
                            [resource]="resource"
                            [filtersArray]="filtersControl"
                            buttonLabel="Add exception"
                            i18n-buttonLabel="@@resource.attribute.lock.group.exception.add"
                            [equation]="exceptionGroup?.equation">
</constraint-conditions-form>

<i class="fal fa-trash-alt delete-icon text-danger clickable"
   tooltip="Delete whole set"
   i18n-tooltip="@@resource.attribute.lock.group.action.delete" (click)="delete.emit()"></i>
