<modal-wrapper class="d-block"
               [dialogType]="dialogType.Success"
               [performingAction]="performingAction$ | async"
               [submitDisabled]="!data.view || form.invalid"
               [customHeader]="true"
               (onSubmit)="onSubmit()"
               (onClose)="hideDialog()"
               *ngIf="{view: view$ | async} as data">

  <view-header class="custom-header" [viewId]="view?.id"
               prefix="Settings" i18n-prefix="@@settings">
  </view-header>

  <div class="body p-3" *ngIf="data.view || (performingAction$ | async); else contentInvalid">

    <view-settings-modal-body [form]="form"
                              [views]="views$ | async"
                              [defaultColor]="defaultColor"
                              [defaultIcon]="defaultIcon"
                              (delete)="onDeleteView()">
    </view-settings-modal-body>

  </div>

  <span class="button-cancel" i18n="@@button.cancel">Cancel</span>
  <span class="button-submit" i18n="@@button.save">Save</span>

</modal-wrapper>

<ng-template #contentInvalid>
  <h4 class="text-danger text-center my-5"
      i18n="@@resource.new.dialog.invalid">I am sorry, something went wrong.
  </h4>
</ng-template>
