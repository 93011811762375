<h4 i18n="@@collections.tab.attributes.header">Existing attributes</h4>
<table class="table table-borderless table-striped table-sm">
  <thead [style.background-color]="color">
  <tr>
    <th class="text-center" style="width: 3rem;" *ngIf="hasDefaultAttribute">
      <i class="fas fa-fw fa-check-circle cursor-pointer"
         title="Displayed attribute"
         i18n-title="@@collection.tab.attributes.attribute.default"></i>
    </th>
    <th i18n="@@collections.tab.attributes.attribute">Attribute</th>
    <th i18n="@@collections.tab.attributes.usage">Usage Count</th>
    <th style="width: 30%;" class="text-end">
      <div class="input-group input-group-sm">
        <span class="input-group-text" id="search-user1"><i class="fas fa-search"></i></span>
        <input id="attributeSearch" class="form-control me-1" type="search"
               placeholder="Search attribute..."
               inputmode="search"
               i18n-placeholder="@@collections.tab.attributes.search.placeholder"
               title="Search for attributes" i18n-title="@@collections.tab.attributes.search.title"
               aria-describedby="search-attribute1"
               [(ngModel)]="searchString">
      </div>
    </th>
  </tr>
  </thead>
  <tbody>
  <tr *ngFor="let attribute of resource.attributes | attributeFilter:searchString; trackBy:trackByAttributeId">
    <th scope="row" *ngIf="hasDefaultAttribute"
        class="icon-hover text-center align-items-center align-middle"
        [class.visible]="attribute.id | isDefaultAttribute:resource">
      <i *ngIf="attribute.id | isDefaultAttribute:resource; else notDefaultIcon"
         class="fas fa-fw fa-check-circle cursor-pointer"
         title="Displayed attribute"
         i18n-title="@@collection.tab.attributes.attribute.default"
         (click)="setDefaultAttribute(attribute)"></i>
      <ng-template #notDefaultIcon>
        <i class="fas fa-fw fa-check cursor-pointer"
           title="Set displayed attribute"
           i18n-title="@@collection.tab.attributes.attribute.default.set"
           (click)="setDefaultAttribute(attribute)"></i>
      </ng-template>
    </th>
    <td class="align-middle">
      <input-box
        innerClass="bg-transparent"
        [class.fw-bold]="attribute.id | isDefaultAttribute:resource"
        [canStayEmpty]="true"
        [userData]="false"
        [title]="attribute.name"
        placeholder="Enter attribute name"
        i18n-placeholder="@@collection.tab.attributes.attribute.placeholder"
        [initialValue]="attribute.name"
        [filter]="inputRegex"
        (newValue)="onNewName(attribute, $event)"
        #attributeNameInput>
      </input-box>
    </td>
    <td class="align-middle">
          <span [class.fw-bold]="attribute.id | isDefaultAttribute:resource">
            {{attribute.usageCount}}
          </span>
    </td>
    <td class="text-end align-middle">
      <div class="d-flex align-items-center justify-content-end">
        <i class="fa-fw cursor-pointer me-2"
           [ngClass]="attribute.constraint?.type !== constraintType.Unknown ? 'text-success ' + (attribute?.constraint | constraintTypeIcon) : 'text-primary far fa-shapes'"
           (click)="onAttributeType(attribute)"
           [title]="attribute.constraint | constraintTypeIconTitle">
        </i>
        <i *ngIf="permissions?.roles?.TechConfig && attribute?.constraint?.type !== constraintType.Action"
           class="fa-fw fa-function cursor-pointer me-2"
           [ngClass]="(attribute | attributeFunctionDefined:resource.rules) ? 'fa text-success' : 'far text-primary'"
           (click)="onAttributeFunction(attribute)"
           title="Edit function..." i18n-title="@@table.header.menu.editFunction">
        </i>
        <i *ngIf="permissions?.roles?.TechConfig && attribute?.constraint?.type === constraintType.Action"
           class="fa-fw fa-function cursor-pointer me-2"
           [ngClass]="(attribute | attributeFunctionDefined:resource.rules) ? 'fa text-success' : 'far text-primary'"
           (click)="onAttributeFunction(attribute)"
           title="Edit automation..." i18n-title="@@table.header.menu.editAutomation">
        </i>
        <i class="far fa-fw fa-trash-alt text-danger cursor-pointer me-1"
           (click)="onDeleteAttribute(attribute)"
           title="Remove Attribute" i18n-title="@@remove.attribute">
        </i>
      </div>
    </td>
  </tr>
  <tr *ngIf="!resource.attributes?.length">
    <td [colSpan]="hasDefaultAttribute ? 4 : 3" class="align-middle text-center" i18n="@@collection.tab.attributes.none">There are no attributes
      yet. Create one!
    </td>
  </tr>
  </tbody>
</table>
