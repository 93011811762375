<ng-container *ngIf="linkTypes?.length > 0; else noLinkTypes">
  <accordion *ngIf="{collections: collections$ | async, constraintData: constraintData$ | async} as data">
    <accordion-group *ngFor="let linkType of linkTypes; trackBy:trackById"
                     panelClass="lmr-accordion"
                     [isOpen]="!(collapsedLinkTypes | includes:linkType.id)"
                     [isDisabled]="false"
                     (isOpenChange)="isOpenChanged($event, linkType.id)">
      <div class="header-wrapper" accordion-heading
           *ngIf="{iconColors: linkType | asAttributeResource | resourceIconsColors} as iconData">
        <div class="me-2">
          <i *ngIf="!(collapsedLinkTypes | includes:linkType.id)" class="fas fa-caret-down"></i>
          <i *ngIf="collapsedLinkTypes | includes:linkType.id" class="fas fa-caret-right"></i>
        </div>
        <icons-presenter class="d-block me-1"
                         [colors]="iconData.iconColors.colors"
                         [icons]="iconData.iconColors.icons">
        </icons-presenter>
        <div class="fw-bold text-truncate me-2" [title]="linkType.name">{{linkType.name}}</div>
        <div class="d-flex align-items-center">
          <i *ngIf="linkType | canReadLinkDirectly:permissions?.linkTypes"
             class="far fa-fw fa-user-plus me-2"
             title="Set Link Type permissions"
             i18n-title="@@table.body.row.links.permissions"
             (click)="onPermissions($event, linkType)"></i>
          <i *ngIf="linkType | canCreateLinks:permissions?.linkTypes"
             class="fas fa-plus-circle me-2"
             title="Add/Remove links"
             i18n-title="@@table.body.row.links.update"
             (click)="onSetLinks($event, linkType)"></i>
          <span class="small text-muted">{{document | linksCount:linkType | async}}</span>
        </div>
      </div>
      <div>
        <links-list-table
          *ngIf="linkType | linkTypeOtherCollection:data.collections:collection.id as otherCollection"
          class="d-block mt-2"
          [visible]="!(collapsedLinkTypes | includes:linkType.id)"
          [linkType]="linkType"
          [document]="document"
          [collection]="otherCollection"
          [collectionPermissions]="permissions?.collections && permissions?.collections[otherCollection.id]"
          [linkTypePermissions]="permissions?.linkTypes && permissions?.linkTypes[linkType.id]"
          [query]="query$ | async"
          [constraintData]="data.constraintData"
          [allowSelect]="allowSelectDocument"
          [allowUnlink]="true"
          [workspace]="workspace"
          [allowCreate]="true"
          [preventEventBubble]="preventEventBubble"
          [attributesSettings]="attributesSettings"
          (unLink)="unLinkDocument($event)"
          (detail)="onSelectDocument($event)"
          (patchDocumentData)="patchDocumentData.emit($event)"
          (patchLinkData)="patchLinkData.emit($event)"
          (createDocumentWithLink)="createDocumentWithLink.emit($event)"
          (createLink)="createLink.emit($event)"
          (updateLink)="updateLink.emit($event)"
          (attributesSettingsChanged)="attributesSettingsChanged.emit($event)"
          (attributeDescription)="attributeDescription.emit($event)"
          (attributeFunction)="attributeFunction.emit($event)"
          (attributeType)="attributeType.emit($event)">
        </links-list-table>
      </div>
    </accordion-group>
  </accordion>
</ng-container>

<ng-template #noLinkTypes>
  <p class="p-4 text-center fst-italic" i18n="@@document.detail.links.noLinks">There are no links.</p>
</ng-template>
