<i *ngIf="{downloading: downloading$ | async} as state"
   (click)="onClick()"
   class="far fa-fw"
   [tooltip]="tooltip"
   containerClass="bigger-tooltip"
   [ngClass]="state.downloading ? 'fa-spinner fa-spin' : fileAttachment.fileName | fileTypeIcon"
   [class.cursor-pointer]="!state.downloading && !fileAttachment.uploading"
   [class.text-secondary]="fileAttachment.uploading">
</i>

<ng-template #tooltip>
  <file-attachment-tooltip [fileAttachment]="fileAttachment"
                           [createdBy]="createdByUser$ | async">
  </file-attachment-tooltip>
</ng-template>
