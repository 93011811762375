<ul class="nav nav-tabs">
  <li class="nav-item">
    <a class="nav-link text-truncate"
       [class.active]="activeTab === detailTabTypes.Detail"
       title="Detail"
       i18n-title="@@document.detail.tabs.detail"
       i18n="@@document.detail.tabs.detail"
       (click)="onTabSelect.emit(detailTabTypes.Detail)">Detail</a>
  </li>
  <li *ngIf="showLinks" class="nav-item">
    <a class="nav-link text-truncate"
       [class.active]="activeTab === detailTabTypes.Links"
       title="Links"
       i18n-title="@@document.detail.tabs.links"
       (click)="onTabSelect.emit(detailTabTypes.Links)">
      <span i18n="@@document.detail.tabs.links">Links</span>
      <span class="small">{{linksCount ? ' (' + linksCount + ')' : ''}}</span>
    </a>
  </li>
  <li *ngIf="showTables" class="nav-item">
    <a class="nav-link text-truncate"
       [class.active]="activeTab === detailTabTypes.Tables"
       title="Tables"
       i18n-title="@@collections"
       (click)="onTabSelect.emit(detailTabTypes.Tables)">
      <span i18n="@@collections">Tables</span>
      <span class="small">{{documentsCount ? ' (' + documentsCount + ')' : ''}}</span>
    </a>
  </li>
  <li *ngIf="showComments" class="nav-item">
    <a class="nav-link text-truncate"
       [class.active]="activeTab === detailTabTypes.Comments"
       title="Comments"
       i18n-title="@@document.detail.tabs.comments"
       (click)="onTabSelect.emit(detailTabTypes.Comments)">
      <span i18n="@@document.detail.tabs.comments">Comments</span>
      <span class="small">{{commentsCount ? ' (' + commentsCount + ')' : ''}}</span>
    </a>
  </li>
  <li *ngIf="showActivity" class="nav-item">
    <a class="nav-link text-truncate"
       [class.active]="activeTab === detailTabTypes.Activity"
       title="Activity"
       i18n-title="@@document.detail.tabs.activity"
       i18n="@@audit.log.activity"
       (click)="onTabSelect.emit(detailTabTypes.Activity)">Activity</a>
  </li>
</ul>
