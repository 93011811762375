<ng-container *ngIf="loaded && data; else loading">
  <div class="d-flex align-items-center">
    <span class="h4 m-0 pe-2">{{title}}</span>
    <span class="badge bg-success">{{data.objects.length}}</span>
  </div>

  <ng-container *ngIf="data.objects.length; else empty">
    <div *ngFor="let datum of data.objects; trackBy:trackByDatum; let first = first"
         class="card p-3" [class.mt-3]="first" [class.mt-2]="!first"
         [class.active]="selectable && selectedId === datum.id"
         [class.cursor-pointer]="selectable"
         (click)="onClick(datum)">

      <div class="bigger-font">
        <icons-presenter [icons]="datum.icons"
                         [colors]="datum.colors">
        </icons-presenter>
        <span class="ps-2 fw-bold">{{datum.name}}</span>
      </div>
      <roles class="small mt-1"
             [resourcePermissionType]="resourcePermissionType"
             [editable]="false"
             [roles]="resourcePermissionType | resourceTypeRolesMap:datum.roles"
             [transitiveRoles]="resourcePermissionType | resourceTypeRolesMap:datum.transitiveRoles">
      </roles>

    </div>
  </ng-container>

</ng-container>

<ng-template #loading>
  <div class="h4">{{title}}</div>
  <div class="d-flex align-items-center pt-3">
    <span class="spinner-grow spinner-grow-sm me-2" role="status" aria-hidden="true"></span>
    <span i18n="@@message.loading">Loading...</span>
  </div>
</ng-template>

<ng-template #empty>
  <div class="d-flex align-items-center pt-3">
    <span class="fst-italic">{{data.emptyTitle}}</span>
  </div>
</ng-template>
