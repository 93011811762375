<div class="action">
  <i class="fa-fw" #pickerOrigin
     [class.cursor-pointer]="editable"
     [ngClass]="icon$ | async"
     [style.color]="color$ | async"
     (click)="editable && pickerComponent.toggle()">
  </i>

  <select-item id="select"
               [selectedId]="action.config?.viewId"
               [items]="viewSelectItems"
               [removable]="false"
               [disabled]="!editable"
               i18n-emptyValue="@@search.tabs.settings.dialog.config.view.placeholder"
               emptyValue="Select View"
               (selected)="onViewSelected($event)">
  </select-item>

  <i *ngIf="editable" class="fal fa-trash-alt text-danger clickable" (click)="remove.emit()"></i>

</div>

<icon-color-picker #pickerComponent
                   [origin]="pickerOrigin"
                   [color]="color$ | async"
                   [icon]="icon$ | async"
                   (preview)="onIconColorChange($event)"
                   (selected)="onIconColorChange($event)"
                   (cancel)="onIconColorChange($event)"
                   (save)="onIconColorSave($event)">
</icon-color-picker>
