<h6 class="fw-bold mb-3" i18n="@@search.tabs.settings.dialog.cell.item.config">Item Configuration</h6>

<div *ngIf="editable && !cell"
     class="text-center"
     i18n="@@search.tabs.settings.dialog.cell.empty">Select an item from the dashboard preview to configure it
</div>
<div *ngIf="!editable && !cell"
     class="text-center"
     i18n="@@search.tabs.settings.dialog.cell.disabled.empty">Select an item from the dashboard preview to see its configuration.
</div>
<select-item *ngIf="cell"
             class="mb-4 d-block"
             [selectedId]="cell?.type"
             [items]="typeItems"
             [removable]="false"
             [disabled]="!editable"
             i18n-emptyValue="@@search.tabs.settings.dialog.cell.type.placeholder"
             emptyValue="Select Item Type"
             (selected)="onTypeSelected($event)">
</select-item>

<ng-container [ngSwitch]="cell?.type">
  <dashboard-view-config *ngSwitchCase="type.View"
                         [config]="cell.config"
                         [editable]="editable"
                         [views]="views"
                         (configChange)="onConfigChanged($event)">
  </dashboard-view-config>
  <dashboard-image-config *ngSwitchCase="type.Image"
                          [config]="cell.config"
                          [editable]="editable"
                          (configChange)="onConfigChanged($event)">
  </dashboard-image-config>
</ng-container>

<dashboard-cell-title *ngIf="cell?.type" class="d-block mt-4"
                      [title]="cell.title || ''"
                      [editable]="editable"
                      (titleChange)="onTitleChange($event)">
</dashboard-cell-title>

<dashboard-actions-config *ngIf="cell?.type"
                          class="d-block mt-3"
                          [actions]="cell.actions"
                          [views]="views"
                          [editable]="editable"
                          (actionsChange)="onActionsChange($event)">
</dashboard-actions-config>
