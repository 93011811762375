<div class="input-group flex-nowrap"
     #selectItemParent>
  <ng-container *ngIf="placeholderIcon || placeholderText">
    <span [style.color]="placeholderColor"
          [title]="placeholderTitle || ''"
          class="input-group-text">
      <i *ngIf="placeholderIcon"
         [title]="placeholderTitle || ''"
         class="fa-fw {{placeholderIcon}}"></i>
      {{placeholderText}}
    </span>
  </ng-container>

  <button class="btn btn-outline-gray-600 dropdown-toggle flex-grow-1 d-flex align-items-center {{buttonClasses}}"
          type="button"
          [class.sharp-top-left]="placeholderIcon || placeholderText"
          [class.sharp-bottom-left]="placeholderIcon || placeholderText"
          [disabled]="disabled"
          [ngClass]="disabled ? 'cursor-not-allowed' : 'cursor-pointer'"
          (click)="onDropdownClick()"
          (keydown)="onKeyDown($event)"
          *ngIf="{selectedItem: selectedId | getSelectItem:items } as data">

    <select-item-row *ngIf="data.selectedItem; else empty"
                     class="w-100 text-truncate"
                     [item]="data.selectedItem"
                     [disabled]="disabled"
                     [removable]="removable"
                     (remove)="remove.emit()">
    </select-item-row>
  </button>

  <options-dropdown [fitParent]="fitParent"
                    [options]="dropdownOptions"
                    [showBackdrop]="false"
                    [closeOnClickOrigin]="true"
                    [origin]="selectItemParent"
                    [highlightedValue]="selectedId"
                    [positionReverse]="positionReverse"
                    (selectOption)="onSelect($event)">
  </options-dropdown>

</div>

<ng-template #empty>
      <span class="flex-grow-1 text-start empty-text text-truncate"
            [title]="emptyValue">{{emptyValue}}</span>
</ng-template>
