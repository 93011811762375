<ng-container [ngSwitch]="queryItem.type">

  <query-stem-query-item *ngSwitchCase="queryItemType.QueryStem"
                         [readonly]="readonly"
                         [queryItem]="queryItem"
                         (toggleExpand)="stemToggle.emit($event)"
                         (remove)="remove.emit($event)">
  </query-stem-query-item>

  <query-stem-input-query-item *ngSwitchCase="queryItemType.QueryStemInput"
                               [queryItem]="queryItem"
                               [restrictedMode]="restrictedMode"
                               (addQueryItem)="onQueryItemAdd($event)"
                               (textChange)="onTextChange($event)">
  </query-stem-input-query-item>

  <simple-query-item *ngSwitchDefault
                     [constraintData]="constraintData"
                     [queryItem]="queryItem"
                     [queryItemForm]="queryItemForm"
                     [readonly]="readonly"
                     (change)="change.emit($event)"
                     (focusInput)="focusInput.emit($event)"
                     (remove)="remove.emit($event)">
  </simple-query-item>


</ng-container>
