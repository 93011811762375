<a *ngIf="displayButtons | includes:(displayTypes.DisplayError)" class="ms-3" (click)="display(displayTypes.DisplayError)">
  <i class="fas fa-bug text-danger cursor-pointer"></i>
</a>
<a *ngIf="displayButtons | includes:(displayTypes.DisplayJs)" class="ms-3" (click)="display(displayTypes.DisplayJs)">
  <i class="fab fa-js-square text-secondary cursor-pointer"></i>
</a>
<a *ngIf="displayButtons | includes:(displayTypes.DisplayLog)" class="ms-3" (click)="display(displayTypes.DisplayLog)">
  <i class="fas fa-poll-h text-secondary cursor-pointer"></i>
</a>
<a class="ms-3"
   title="Help"
   i18n-title="@@button.help"
   i18n-href="@@help.functionsAndRules"
   href="https://www.lumeer.io/functions-and-rules" target="_blank">
  <i class="fas fa-question-circle text-success cursor-pointer help-icon"></i>
</a>
