<ng-container *ngIf="showLockStats && !(row?.attribute | isAttributeEnabledByLockStats:lockStats); else emptyIcon">
  <i class="fas fa-fw fa-lock me-2 row-hover-button cursor-pointer"
     [tooltip]="statsTooltip" containerClass="bigger-tooltip"></i>

  <ng-template #statsTooltip>
    <attribute-lock-filters-stats [lock]="row?.attribute?.lock"
                                  [stats]="lockStats"
                                  [constraintData]="constraintData"
                                  [collectionId]="cursor?.collectionId"
                                  [linkTypeId]="cursor?.linkTypeId">
    </attribute-lock-filters-stats>
  </ng-template>
</ng-container>
<i class="fa fa-fw fa-shapes me-2 clickable text-success row-hover-button"
   aria-hidden="true"
   *ngIf="row?.attribute?.id && canEditType; else emptyIcon"
   title="Attribute settings..."
   i18n-title="@@table.header.menu.changeType"
   tooltip="Attribute settings..."
   i18n-tooltip="@@table.header.menu.changeType"
   (click)="onAttributeType()">
</i>

<i class="fa fa-fw fa-function me-2 clickable text-success row-hover-button"
   aria-hidden="true"
   *ngIf="row?.attribute?.constraint?.type !== type.Action && canEditAutomation"
   title="Edit function..."
   i18n-title="@@table.header.menu.editFunction"
   tooltip="Edit function..."
   i18n-tooltip="@@table.header.menu.editFunction"
   (click)="onAttributeFunction()">
</i>
<i class="fa fa-fw fa-function me-2 clickable text-success row-hover-button"
   aria-hidden="true"
   *ngIf="row?.attribute?.constraint?.type === type.Action && canEditAutomation"
   title="Edit automation..."
   i18n-title="@@table.header.menu.editAutomation"
   tooltip="Edit automation..."
   i18n-tooltip="@@table.header.menu.editAutomation"
   (click)="onAttributeFunction()">
</i>
<i *ngIf="!canEditAutomation" class="fa-fw fa fa-icon-none me-2"
   aria-hidden="true">
</i>

<ng-container *ngIf="{iconName: (row.attribute?.constraint | constraintTypeIcon)} as typeData">
  <i *ngIf="typeData.iconName"
     [ngClass]="typeData.iconName"
     [title]="row.attribute?.constraint | constraintTypeIconTitle"
     class="me-2 text-muted small fa-fw"></i>
  <i *ngIf="!typeData.iconName" class="me-2 fa-fw fa fa-icon-none small"
     aria-hidden="true">
  </i>
</ng-container>

<ng-template #emptyIcon>
  <i class="fa-fw fa fa-icon-none me-2"
     aria-hidden="true">
  </i>
</ng-template>
