<ng-container *ngIf="{rows: dataRowService.rows$ | async, writable: dataResource | dataResourceIsWritable:resource:permissions:user:constraintData} as data">

  <hidden-input (newInput)="onNewHiddenInput($event)"
                class="position-fixed">
  </hidden-input>

  <ng-container *ngIf="toolbarRef" [ngTemplateOutlet]="toolbarRef"></ng-container>

  <data-resource-data-row *ngFor="let row of data.rows; trackBy:trackByRow; let index = index"
                          [row]="row"
                          [permissions]="permissions"
                          [cursor]="dataResource | dataCursor:row.attribute?.id:view?.id"
                          [constraintData]="constraintData"
                          [lockStats]="resource | attributeLockStats :dataResource: row.attribute:constraintData"
                          [readonly]="!editableValues || !data.writable"
                          [unusedAttributes]="unusedAttributes$ | async"
                          [preventEventBubble]="preventEventBubble"
                          [workspace]="workspace"
                          [editableKey]="editableKeys"
                          [formattingStyle]="resource | resourceAttributeFormattingStyle:row.attribute?.id:dataResource:constraintData"
                          (newValue)="onNewValue($event, row, index)"
                          (newKey)="onNewKey($event, index)"
                          (deleteRow)="onRemoveRow(index)"
                          (attributeFunctionClick)="onAttributeFunction(row)"
                          (attributeTypeClick)="onAttributeType(row)"
                          (onFocus)="onFocus(index, $event)"
                          (onEdit)="onEdit(index, $event)"
                          (resetFocusAndEdit)="onResetFocusAndEdit(index, $event)">
  </data-resource-data-row>

</ng-container>
