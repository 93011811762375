<td class="ps-2 py-3">
  <a class="d-flex flex-row no-wrap align-items-center"
     [routerLink]="['/o', workspace?.organizationCode, 'p', workspace?.projectCode, 'l', linkType.id]">
    <icons-presenter
      [colors]="linkType.collections | colors"
      [icons]="linkType.collections | icons"
      [title]="linkType.name">
    </icons-presenter>

    <span class="ms-2 flex-grow-1">{{linkType.name}}</span>

  </a>
</td>

<td class="text-end align-middle">

  <span class="me-3" title="Number of links" i18n-title="@@links.count">
    <i class="far fa-link me-1" aria-hidden="true"></i>
    <span>{{linkType.linksCount || 0}}</span>
  </span>

  <a [routerLink]="['/o', workspace?.organizationCode, 'p', workspace?.projectCode, 'l', linkType.id]">
    <i class="fas fa-fw fa-cog me-2"
       title="Settings" i18n-title="@@settings"
       aria-hidden="true"></i>
  </a>

  <i *ngIf="permissions?.roles?.Manage" class="far fa-fw fa-trash-alt text-danger cursor-pointer delete"
     (click)="onDelete()"
     title="Delete" i18n-title="@@delete"
     aria-hidden="true"></i>

</td>
