<div class="d-flex align-items-center">
  <span class="h4 m-0 pe-2" i18n="@@projects">Projects</span>
  <span class="badge bg-success">{{projectsData.objects.length}}</span>
</div>

<ul class="nav nav-tabs mt-3" *ngIf="projectsData.objects.length; else empty">
  <li *ngFor="let datum of projectsData.objects; trackBy: trackByDatum; let last = last"
      role="presentation" class="nav-item">
    <div class="nav-link cursor-pointer" [class.active]="datum.id === workspace.projectId" (click)="selected.emit(datum)">
      <icons-presenter [icons]="datum.icons"
                       [colors]="datum.colors">
      </icons-presenter>
      <span class="ps-1 fw-bold">{{datum.name}}</span>
      <span class="ms-2 badge bg-primary"
            tooltip="Number of enabled user rights"
            i18n-tooltip="@@user.resources.roles.count">{{datum.roles | rolesCount: datum.transitiveRoles}}</span>
    </div>
  </li>
</ul>

<roles *ngIf="selectedDatum"
       class="mt-2"
       [editable]="false"
       [roles]="resourcePermissionType | resourceTypeRolesMap:selectedDatum.roles"
       [transitiveRoles]="resourcePermissionType | resourceTypeRolesMap:selectedDatum.transitiveRoles"
       [resourcePermissionType]="resourcePermissionType">
</roles>

<ng-template #empty>
  <div class="d-flex align-items-center pt-3">
    <span class="fst-italic">{{projectsData.emptyTitle}}</span>
  </div>
</ng-template>
