<div class="card-body pt-2">
  <div class="small text-secondary mb-2"
       i18n="@@resource.detail.type">{type, select, organization {Organization} project {Project} other {}}</div>

  <h5 class="mb-2 text-truncate">
    <i [class]="resource?.icon" [class.me-1]="true" [style.color]="resource?.color"></i>
    {{resource?.code}}
  </h5>
  <h6 class="card-subtitle mb-2 text-muted">{{resource?.name}}</h6>

  <p class="card-text">
    <ng-container *ngIf="type | isOrganizationType">
      <resource-status-line [organization]="resource"></resource-status-line>
      <button *ngIf="canManageDetail" (click)="goToOrganizationSettings('users')" class="ms-3 btn btn-primary btn-sm">
        <i class="fas fa-user-plus me-1"></i>
        <span i18n="@@resource.detail.organization.invite">Invite Colleagues</span>
      </button>
    </ng-container>
    <ng-container *ngIf="!(type | isOrganizationType)">
      <resource-status-line [project]="resource"></resource-status-line>
    </ng-container>
  </p>

  <p *ngIf="resource?.description" class="card-text mt-1" [class.mb-3]="resource?.description"
     [title]="resource?.description">{{resource?.description | truncate:256}}</p>

  <div *ngIf="(type | isOrganizationType) && canManageDetail"
       class="text-center mt-2 d-flex flex-row justify-content-center">
    <button (click)="goToOrganizationSettings('detail')" href="#" class="btn btn-primary btn-sm me-1">
      <i class="fas fa-cog me-1"></i>
      <span i18n="@@resource.detail.organization.configure">Configure</span>
    </button>
    <button (click)="goToOrganizationSettings('detail')" href="#" class="btn btn-danger btn-sm ms-1">
      <i class="fas fa-credit-card me-1"></i>
      <span i18n="@@resource.detail.organization.upgrade">Upgrade Plan</span>
    </button>
  </div>

  <div *ngIf="!(type | isOrganizationType) && canManageDetail" class="text-center mt-2">
    <button (click)="goToProjectSettings('users')" class="btn btn-primary btn-sm">
      <i class="fas fa-cog me-1"></i>
      <span i18n="@@resource.detail.organization.configure">Configure</span>
    </button>
  </div>

</div>
