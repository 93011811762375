<span class="text-primary clickable" (click)="detailSettings.open()">
  <i class="far fa-line-columns"></i>
</span>

<detail-settings-dropdown #detailSettings
                          [origin]="element"
                          [resource]="resource"
                          [resourceType]="resourceType"
                          [attributesSettings]="attributesSettings"
                          (attributesSettingsChanged)="onAttributesSettingsChanged($event)">
</detail-settings-dropdown>
