<span *ngFor="let role of translatedRoles; trackBy:trackByRole" class="badge badge-lg me-2 mb-2"
      (click)="onClick()"
      [class.cursor-pointer]="editable"
      [class.badge-outline-secondary]="role.fromParentOrTeams"
      [class.badge-outline-success]="!role.fromParentOrTeams"
      [tooltip]="role | roleTooltip:resourcePermissionType">{{role.title}}</span>

<ng-container *ngIf="editable">
  <div class="btn btn-sm btn-link mb-1 text-nowrap" #buttonElement
       (click)="$event.stopPropagation(); onClick()">
    <i class="far fa-pencil me-1"></i>
    <span *ngIf="!(translatedRoles | empty)" i18n="@@edit">Edit</span>
    <span *ngIf="translatedRoles | empty" i18n="@@roles.add">Add Rights</span>
  </div>

  <roles-dropdown [origin]="buttonElement"
                  [selectedRoles]="selectedRoles"
                  [resourceType]="resourcePermissionType"
                  [groups]="groups"
                  [emitAllChanges]="emitAllChanges"
                  (change)="onRolesChange($event)">
  </roles-dropdown>
</ng-container>
