<bookmark-toolbar [additionalClasses]="['mt-5']" *ngIf="{queryData: queryData$ | async, collectionsMap: collectionsMap$ | async} as data">
  <ng-container *ngFor="let view of (views$ | async)">
    <bookmark [color]="view| viewColor:data.collectionsMap"
              [icon]="view | viewIcon"
              [link]="view | viewLink:workspace">
      {{view.name}}
      <i *ngIf="(view.query | queryItems:data.queryData) | containsDeletedQueryItem"
         class="fa fa-exclamation-triangle text-warning ms-1"
         title="View is not valid because some of the resources were deleted"
         i18n-title="@@view.detail.warning.deleted"></i>
    </bookmark>
  </ng-container>
</bookmark-toolbar>
