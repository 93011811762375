<div class="d-flex flex-row flex-wrap justify-content-center">

  <div *ngFor="let collection of collections; let index = index"
       class="card post-it cursor-pointer me-4 mb-4"
       [class.active]="selectedCollectionId === collection.id"
       (click)="select(collection, index)">

    <div class="card-body text-center">
      <div [style.color]="collection.color"
           [title]="collection.name"
           data-test="collection-card-icon">
        <i class="fa-3x {{ collection.icon }}"
           aria-hidden="true">
        </i>
      </div>

      <div class="mt-3 text-truncate">{{collection.name}}</div>
    </div>

  </div>
</div>
