<ng-container *ngIf="icons && colors">
  <ng-container [ngSwitch]="icons.length">
    <i *ngSwitchCase="1"
       [style.color]="colors[0]"
       aria-hidden="true"
       class="{{icons[0]}} fa-fw">
    </i>
    <span *ngSwitchCase="2"
          class="link-icons">
      <i [style.color]="colors[0]"
         class="{{icons[0]}} fa-fw align-top icon-small">
      </i><!-- TODO remove space somehow
   --><i [style.color]="colors[1]"
         class="{{icons[1]}} fa-fw align-bottom icon-small"></i><!-- TODO remove space somehow
 --></span>
  </ng-container>
</ng-container>
