<div class="row mt-4 mb-6">
  <div class="col">
    <ul class="list-group"
        *ngIf="{collection: collection$ | async, rulesLimit: rulesCountLimit$ | async, ruleNames: ruleNames$ | async, editingRules: editingRules$ | async} as data">
      <li
        *ngIf="!(data.collection?.rules)"
        no-rules
        class="list-group-item flex-column align-items-center">
      </li>

      <li
        *ngFor="let rule of data.collection?.rules; let idx = index; trackBy:trackByRuleName"
        class="list-group-item py-2-5 flex-column align-items-center">
        <add-rule-form
          *ngIf="data.editingRules[rule.id]"
          [collection]="data.collection"
          [rule]="rule"
          [ruleIndex]="idx"
          [ruleNames]="data.ruleNames"
          [originalRuleName]="rule.name"
          (onCancelNewRule)="onCancelRuleEdit(rule)"
          (onSaveRule)="onSaveRule(data.collection, $event)">
        </add-rule-form>
        <single-rule
          *ngIf="!data.editingRules[rule.id]"
          [attributes]="data.collection.attributes"
          [canDuplicate]="data.collection && (data.rulesLimit === -1 || data.rulesLimit > data.collection.rules.length)"
          (onEdit)="onEditStart(rule)"
          (onDelete)="deleteRule(data.collection, rule)"
          (onDuplicate)="onDuplicateRule($event)"
          [rule]="rule">
        </single-rule>
      </li>

      <li
        *ngFor="let newRule of addingRules; let idx = index;"
        class="list-group-item py-2-5 flex-column align-items-center">
        <add-rule-form
          [collection]="data.collection"
          [rule]="newRule"
          [ruleIndex]="idx"
          [ruleNames]="data.ruleNames"
          (onCancelNewRule)="onCancelNewRule($event)"
          (onSaveRule)="onSaveRule(data.collection, $event)">
        </add-rule-form>
      </li>

      <li
        *ngIf="data.collection && (data.rulesLimit === -1 || data.rulesLimit > data.collection.rules.length); else freePlan"
        add-rule
        (click)="onNewRule()"
        class="list-group-item py-2-5 list-group-item-action flex-column align-items-center cursor-pointer">
      </li>
    </ul>
  </div>
</div>

<ng-template #freePlan>
  <li class="list-group-item list-group-item-action flex-column align-items-center cursor-pointer">
    <span class="align-middle" i18n="@@collection.config.tab.rules.serviceLimit">
      You can have only a single automation in the Free Plan.
    </span>
    <button
      class="btn btn-sm btn-success ms-3"
      (click)="openServiceOrder()">
      <span i18n="@@collection.config.tab.rules.upgradePlan">Upgrade to Business Plan!</span>
    </button>
  </li>
</ng-template>
