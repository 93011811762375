<div class="text-end" *ngIf="{index : scrolledIndex$ | async} as data">
  <strong>{{data.index | previewRangeString:(numVisibleRows$ | async):dataResources.length}}</strong>
  &nbsp;<span i18n="@@count.of">of</span>&nbsp;{{dataResources.length}}
</div>
<div class="position-relative table-wrapper" [id]="resource?.id" [style.height.px]="tableHeight">
  <preview-results-alternative-header *ngIf="hasData"
                                      [columns]="columns"
                                      [columnWidth]="columnWidth$ | async"
                                      [columnHeight]="rowHeight">
  </preview-results-alternative-header>
  <cdk-virtual-scroll-viewport class="overflow-auto h-100" #table
                               [lmrItemSize]="rowHeight"
                               [headerHeight]="rowHeight"
                               [baseOffset]="-1"
                               [columns]="columns">
    <table class="table table-borderless lmr-table table-sm" [class.table-fixed]="hasData" id="preview-results-table">
      <thead>
      <tr>
        <th class="sticky-header"
            *ngFor="let column of columns; trackBy:trackByAttribute; let idx = index"
            [style.background-color]="column.color"
            [style.width.px]="hasData ? (columnWidth$ | async) || column.width : null"
            [style.font-weight]="column.bold ? 'bold' : 'normal'"
            scope="col"
            title="{{column.name}}">{{column.name}}</th>
      </tr>
      </thead>
      <tbody>
      <tr *ngIf="!hasData">
        <td [attr.colspan]="resource?.attributes?.length" class="text-center fw-bold">
          <h4 class="pt-3" i18n="@@shared.preview.results.noResults">No records found.</h4>
        </td>
      </tr>
      <tr *cdkVirtualFor="let dataResource of dataResources; trackBy:trackByDataResource"
          class="clickable-row"
          [class.active]="(dataResource.id || dataResource.correlationId) === selectedId"
          (click)="activate(dataResource)"
          (dblclick)="detail(dataResource)">
        <td *ngFor="let column of columns"
            [style.width.px]="hasData ? (columnWidth$ | async) || column.width : null">
          <data-input-preview [constraint]="column.constraint"
                              [configuration]="configuration"
                              [cursor]="dataResource | dataCursor : column.id:view?.id"
                              [dataValue]="dataResource?.data[column.id] | dataValue:column.constraint:constraintData">
          </data-input-preview>
        </td>
      </tr>
      </tbody>
    </table>
  </cdk-virtual-scroll-viewport>
</div>
<ng-content></ng-content>
<div *ngIf="resizeable && hasData" resizer class="lmr-resizer mb-3 w-100"
     tagName="lmr-table"
     [minHeight]="100"
     [reference]="resource?.id"
     (onResize)="onTableResize(table, $event)"
     i18n-title="@@perspective.workflow.table.resize"
     title="Resize table">
  <i class="fal fa-grip-lines"></i>
</div>
