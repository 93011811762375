<ng-container [ngSwitch]="notNullConstraintType">
  <address-data-input *ngSwitchCase="constraintType.Address"
                      [focus]="focus"
                      [readonly]="readonly"
                      [commonConfiguration]="configuration?.common"
                      [value]="dataValue"
                      [fontColor]="fontColor"
                      [style.background-color]="backgroundColor"
                      (valueChange)="onValueChange($event)"
                      (save)="onSaveValueWithAction($event)"
                      (keydown)="onDataKeyDown($event)"
                      (cancel)="onCancel()"
                      class="d-block w-100">
  </address-data-input>

  <boolean-data-input *ngSwitchCase="constraintType.Boolean"
                      [readonly]="readonly"
                      [value]="dataValue"
                      [class.justify-content-center]="configuration?.boolean?.center"
                      [class.cursor-pointer]="!readonly"
                      [editableInReadonly]="editableInReadonly"
                      [style.background-color]="backgroundColor"
                      (dblclick)="$event.stopPropagation()"
                      (valueChange)="onValueChange($event)"
                      (save)="onSaveValueWithAction($event)"
                      class="w-100 px-1">
  </boolean-data-input>

  <coordinates-data-input *ngSwitchCase="constraintType.Coordinates"
                          [focus]="focus"
                          [readonly]="readonly"
                          [commonConfiguration]="configuration?.common"
                          [value]="dataValue"
                          [fontColor]="fontColor"
                          [style.background-color]="backgroundColor"
                          (valueChange)="onValueChange($event)"
                          (save)="onSaveValueWithAction($event)"
                          (keydown)="onDataKeyDown($event)"
                          (cancel)="onCancel()"
                          class="d-block w-100">
  </coordinates-data-input>

  <datetime-data-input *ngSwitchCase="constraintType.DateTime"
                       [focus]="focus"
                       [readonly]="readonly"
                       [commonConfiguration]="configuration?.common"
                       [value]="dataValue"
                       [fontColor]="fontColor"
                       [style.background-color]="backgroundColor"
                       (keydown)="onDataKeyDown($event)"
                       (valueChange)="onValueChange($event)"
                       (save)="onSaveValueWithAction($event)"
                       (enterInvalid)="enterInvalid.emit()"
                       (cancel)="onCancel()"
                       class="d-block w-100">
  </datetime-data-input>

  <duration-data-input *ngSwitchCase="constraintType.Duration"
                       [focus]="focus"
                       [readonly]="readonly"
                       [commonConfiguration]="configuration?.common"
                       [value]="dataValue"
                       [fontColor]="fontColor"
                       [style.background-color]="backgroundColor"
                       (valueChange)="onValueChange($event)"
                       (keydown)="onDataKeyDown($event)"
                       (save)="onSaveValueWithAction($event)"
                       (cancel)="onCancel()"
                       (enterInvalid)="enterInvalid.emit()"
                       class="d-block w-100">
  </duration-data-input>

  <files-data-input *ngSwitchCase="constraintType.Files"
                    [cursor]="cursor"
                    [focus]="focus"
                    [readonly]="readonly"
                    [value]="dataValue"
                    [style.background-color]="backgroundColor"
                    [configuration]="configuration?.files"
                    (save)="onSaveValue($event)"
                    (cancel)="onCancel()"
                    class="px-1 w-100">
  </files-data-input>

  <number-data-input *ngSwitchCase="constraintType.Number"
                     [focus]="focus"
                     [readonly]="readonly"
                     [value]="dataValue"
                     [fontColor]="fontColor"
                     [style.background-color]="backgroundColor"
                     [commonConfiguration]="configuration?.common"
                     (valueChange)="onValueChange($event)"
                     (keydown)="onDataKeyDown($event)"
                     (save)="onSaveValueWithAction($event)"
                     (cancel)="onCancel()"
                     (enterInvalid)="enterInvalid.emit()"
                     class="d-block w-100">
  </number-data-input>

  <percentage-data-input *ngSwitchCase="constraintType.Percentage"
                         [focus]="focus"
                         [readonly]="readonly"
                         [value]="dataValue"
                         [fontColor]="fontColor"
                         [style.background-color]="backgroundColor"
                         [commonConfiguration]="configuration?.common"
                         (valueChange)="onValueChange($event)"
                         (keydown)="onDataKeyDown($event)"
                         (save)="onSaveValueWithAction($event)"
                         (cancel)="onCancel()"
                         (enterInvalid)="enterInvalid.emit()"
                         class="d-block w-100">
  </percentage-data-input>

  <color-data-input *ngSwitchCase="constraintType.Color"
                    [focus]="focus"
                    [readonly]="readonly"
                    [value]="dataValue"
                    [fontColor]="fontColor"
                    [style.background-color]="backgroundColor"
                    [commonConfiguration]="configuration?.common"
                    [configuration]="configuration?.color"
                    (valueChange)="onValueChange($event)"
                    (keydown)="onDataKeyDown($event)"
                    (save)="onSaveValueWithAction($event)"
                    (cancel)="onCancel()"
                    (enterInvalid)="enterInvalid.emit()"
                    class="d-block w-100">
  </color-data-input>

  <ng-container *ngSwitchCase="constraintType.Select">
    <select-data-input-compact *ngIf="isMobile"
                               [commonConfiguration]="configuration?.common"
                               [value]="dataValue"
                               [fontColor]="fontColor"
                               [style.background-color]="backgroundColor"
                               [readonly]="readonly"
                               [configuration]="configuration?.select"
                               (save)="onSaveValue($event)"
                               (cancel)="onCancel()">
    </select-data-input-compact>
    <select-data-input *ngIf="!isMobile"
                       [focus]="focus"
                       [readonly]="readonly"
                       [value]="dataValue"
                       [fontColor]="fontColor"
                       [style.background-color]="backgroundColor"
                       (keydown)="onDataKeyDown($event)"
                       [configuration]="configuration?.select"
                       [commonConfiguration]="configuration?.common"
                       (valueChange)="onValueChange($event)"
                       (save)="onSaveValueWithAction($event)"
                       (cancel)="onCancel()"
                       class="d-block w-100">
    </select-data-input>
  </ng-container>

  <ng-container *ngSwitchCase="constraintType.User">

    <user-data-input-compact *ngIf="isMobile"
                             [configuration]="configuration?.user"
                             [readonly]="readonly"
                             [commonConfiguration]="configuration?.common"
                             [fontColor]="fontColor"[style.background-color]="backgroundColor"

                             [value]="dataValue"
                             (save)="onSaveValue($event)"
                             (cancel)="onCancel()">
    </user-data-input-compact>

    <user-data-input *ngIf="!isMobile"
                     [focus]="focus"
                     [readonly]="readonly"
                     [configuration]="configuration?.user"
                     [value]="dataValue"
                     [fontColor]="fontColor"
                     [style.background-color]="backgroundColor"
                     [commonConfiguration]="configuration?.common"
                     (keydown)="onDataKeyDown($event)"
                     (valueChange)="onValueChange($event)"
                     (save)="onSaveValueWithAction($event)"
                     (cancel)="onCancel()"
                     (enterInvalid)="enterInvalid.emit()"
                     class="d-block w-100">
    </user-data-input>
  </ng-container>

  <view-data-input *ngSwitchCase="constraintType.View"
                   [focus]="focus"
                   [readonly]="readonly"
                   [value]="dataValue"
                   [fontColor]="fontColor"
                   [style.background-color]="backgroundColor"
                   [commonConfiguration]="configuration?.common"
                   (keydown)="onDataKeyDown($event)"
                   (valueChange)="onValueChange($event)"
                   (save)="onSaveValueWithAction($event)"
                   (cancel)="onCancel()"
                   (enterInvalid)="enterInvalid.emit()"
                   class="d-block w-100">
  </view-data-input>

  <link-data-input *ngSwitchCase="constraintType.Link" [focus]="focus"
                   [readonly]="readonly"
                   [commonConfiguration]="configuration?.common"
                   [value]="dataValue"
                   [fontColor]="fontColor"
                   [style.background-color]="backgroundColor"
                   (valueChange)="onValueChange($event)"
                   (save)="onSaveValueWithAction($event)"
                   (cancel)="onCancel()"
                   (enterInvalid)="enterInvalid.emit()">
  </link-data-input>

  <rich-text-data-input *ngSwitchCase="constraintType.Text"
                        [focus]="focus"
                        [readonly]="readonly"
                        [editableInReadonly]="editableInReadonly"
                        [commonConfiguration]="configuration?.common"
                        [value]="dataValue"
                        [workspace]="workspace"
                        [fontColor]="fontColor"
                        [style.background-color]="backgroundColor"
                        (keydown)="onDataKeyDown($event)"
                        (valueChange)="onValueChange($event)"
                        (save)="onSaveValueWithAction($event)"
                        (cancel)="onCancel()"
                        class="w-100">
  </rich-text-data-input>

  <action-data-input *ngSwitchCase="constraintType.Action"
                     [readonly]="readonly"
                     [config]="constraint?.config"
                     [value]="dataValue"
                     [fontColor]="fontColor"
                     [style.background-color]="backgroundColor"
                     [configuration]="configuration?.action"
                     [workspace]="workspace"
                     [cursor]="cursor"
                     (cancel)="onCancel()"
                     class="w-100">
  </action-data-input>

  <text-data-input *ngSwitchDefault
                   [focus]="focus"
                   [readonly]="readonly"
                   [configuration]="configuration?.common"
                   [value]="dataValue"
                   [fontColor]="fontColor"
                   [style.background-color]="backgroundColor"
                   [placeholder]="placeholder"
                   [suggestions]="suggestions"
                   (valueChange)="onValueChange($event)"
                   (keydown)="onDataKeyDown($event)"
                   (save)="onSaveValueWithAction($event)"
                   (cancel)="onCancel()"
                   (enterInvalid)="enterInvalid.emit()"
                   class="d-block w-100">
  </text-data-input>
</ng-container>
