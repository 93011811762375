<div [formGroup]="form" class="config-form">
  <div class="mb-3 text-muted small form-message" i18n="@@collection.config.tab.rules.autoLink.info">
    Automatically link records from two tables based on matching column values.
  </div>

  <label class="form-label" i18n="@@collection.config.tab.rules.autoLink.linkType">Link record via</label>
  <select-item
    class="link-select"
    emptyValue="Select a link type"
    i18n-emptyValue="@@collection.config.tab.rules.autoLink.selectLinkType"
    placeholderIcon="fas fa-link"
    [items]="linkTypes | linkTypesSelectItems"
    [selectedId]="linkTypeId"
    (selected)="onSelectLinkType($event)">
  </select-item>
  <label class="form-label" i18n="@@collection.config.tab.rules.autoLink.where">Where</label>
  <div class="d-flex align-items-center flex-wrap">
    <select-item
      class="me-3"
      (selected)="onSelectAttribute1($event)"
      [emptyValue]="attribute1Empty"
      [placeholderIcon]="collection.icon"
      [items]="collection | attributesSelectItems"
      [selectedId]="attribute1Id">
    </select-item>
    <div class="text-nowrap me-3 py-2" i18n="@@collection.config.tab.rules.autoLink.equals">
      equals
    </div>
    <select-item
      (selected)="onSelectAttribute2($event)"
      [emptyValue]="linkedCollection ? attribute2Empty : attribute2NoCollection"
      [placeholderIcon]="linkedCollection ? linkedCollection.icon : 'fas fa-question'"
      [items]="linkedCollection | attributesSelectItems"
      [selectedId]="attribute2Id">
    </select-item>
  </div>
</div>

