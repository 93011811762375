<div class="px-3 pt-2 pb-3">
  <img [src]="tagImageUrl" alt=""
       class="main-image"/>
</div>

<h3 *ngIf="selectedTag" class="mt-3 mb-0 text-center">{{selectedTag}}</h3>

<div class="d-flex justify-content-around flex-wrap mt-4">
  <template-item *ngFor="let template of templates"
                 class="d-block mb-3"
                 [template]="template"
                 (click)="selectTemplate.emit(template)">
  </template-item>
</div>
