<div *ngIf="resourceType | isOrganizationType" class="mt-4">
  <h4 class="mb-2" i18n="@@users.header.inviteUser">Invite a colleague or a friend</h4>
  <new-user
    [users]="users"
    (userCreated)="userCreated.emit($event)">
  </new-user>
</div>
<user-table class="d-block mt-4"
            [users]="users"
            [resourcePermissionType]="resourcePermissionType"
            [teams]="teams$ | async"
            [organization]="organization"
            [project]="project"
            [permissionsMap]="resourcePermissionType | resourceTypePermissionsMap:resource.permissions"
            [color]="resource.color"
            [editableGroups]="(resourceType | isOrganizationType) && serviceLimits?.groups"
            [deletableUserIds]="deletableUserIds"
            [editableUserIds]="editableUserIds"
            [workspace]="workspace"
            [canManageUserDetail]="canManageUserDetail"
            (userTeamsChange)="onUserTeamsChange($event)"
            (userRolesChange)="onUserRolesChanged($event)"
            (userDeleted)="userDeleted.emit($event)"
            (userUpdated)="userUpdated.emit($event)">
</user-table>
