<div [formGroup]="form">
  <div class="d-flex flex-row flex-nowrap pt-2 align-items-center">
    <label class="switch" [for]="formControlName.Utc">
      <input type="checkbox" [formControlName]="formControlName.Utc" [id]="formControlName.Utc" #utc>
      <span class="slider round" (click)="$event.stopPropagation()"></span>
    </label>
    <span class="ps-2 cursor-pointer user-select-none" i18n="@@constraint.dateTime.config.utc"
          (click)="utc.click()">Time is always in UTC</span>
  </div>

  <div class="mt-3">
    <label class="form-label" i18n="@@constraint.dateTime.format.label">
      Format
    </label>
    <select-item class="w-100 d-block"
                 [selectedId]="formatControl.value"
                 [removable]="false"
                 [items]="formatItems"
                 (selected)="formatControl.setValue($event)"
                 buttonClasses="flex-grow-1 text-truncate"
                 data-test="datetime-constraint-format-select">
    </select-item>
  </div>
  <div *ngIf="!formatControl?.value" class="d-flex">
    <div class="flex-fill mt-3">
      <input class="form-control"
             formControlName="customFormat"
             spellcheck="false"
             type="text">
    </div>
    <div class="align-baseline">
      <a [href]="helpUrl" target="_blank" class="text-primary">
        <i class="help fa-fw far fa-question-circle ms-1"></i>
      </a>
    </div>
  </div>
  <div class="mt-3 text-muted">
    <em><span i18n="@@constraint.example">Example:</span> {{ exampleValue$ | async | formatDataValue }}</em>
  </div>

  <div class="d-flex mt-3">
    <div class=" flex-grow-1">
      <label class="form-label" for="dateTimeMinValue"
             i18n="@@constraint.dateTime.minValue.label">
        Minimum value
      </label>
      <date-time-input [format]="formatControl.value"
                       [placeholder]="formatControl.value"
                       id="dateTimeMinValue"
                       [value]="minValueControl.value"
                       (valueChange)="minValueControl.setValue($event)">
      </date-time-input>
    </div>

    <div class="ms-3 flex-grow-1">
      <label class="form-label" for="dateTimeMaxValue"
             i18n="@@constraint.dateTime.maxValue.label">
        Maximum value
      </label>
      <date-time-input [format]="formatControl.value"
                       [placeholder]="formatControl.value"
                       id="dateTimeMaxValue"
                       [value]="maxValueControl.value"
                       (valueChange)="maxValueControl.setValue($event)">
      </date-time-input>
    </div>
  </div>

  <div *ngIf="form.errors && form.errors['minMaxInvalid']"
       class="text-danger form-text"
       i18n="@@form.errors.minMaxInvalid">
    Minimum must be less or equal than maximum.
  </div>

  <div *ngIf="form.errors && form.errors['customEmpty']"
       class="text-danger form-text"
       i18n="@@form.date.errors.customEmpty">
    Format can not be empty.
  </div>

  <div *ngIf="form.errors && form.errors['customInvalid']"
       class="text-danger form-text"
       i18n="@@form.date.errors.customInvalid">
    I am sorry, but this format is not supported. Please enter valid format.
  </div>
</div>
