<input #coordinatesInput
       [readonly]="readonly"
       [title]="value | formatDataValueTitle"
       [value]="readonly ? (value | formatDataValue | unescapeHtml) : (value | formatDataValueForEdit)"
       [class.invalid-value]="!commonConfiguration?.skipValidation && !(value | isDataValueValid)"
       [style.color]="readonly ? fontColor : null"
       (blur)="!readonly && onBlur()"
       (focus)="!readonly && onFocus()"
       (input)="onInput($event)"
       [ngClass]="inputClass"
       inputmode="numeric"
       class="border-0 w-100 h-100 px-1 bg-transparent"
       type="text">
