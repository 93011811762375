<div *ngIf="!editableKey; else editableKeyInput"
     class="p-0 px-1 d-flex flex-row flex-nowrap align-items-center data-input key">
  <span class="fst-italic">{{keyDataValue.value}}</span>
  <div *ngIf="row?.attribute?.description" class="ms-1 text-muted font-normal"
       [tooltip]="descriptionTooltip" [adaptivePosition]="false" placement="bottom" containerClass="bigger-tooltip">
    <i class="far fa-question-circle font-normal"></i>
  </div>
  <ng-template #descriptionTooltip>
    <div [innerHTML]="row?.attribute | attributeTitle | safeHtml"></div>
  </ng-template>
</div>

<ng-template #editableKeyInput>
  <div class="p-0 pe-1 d-flex flex-row flex-nowrap align-items-center justify-content-center">
    <data-input class="h-100 w-100 p-1 data-input key"
                [cursor]="cursor"
                [focus]="true"
                [readonly]="!(keyEditing$ | async)"
                [dataValue]="keyDataValue"
                [placeholder]="placeholder"
                [preventEventBubble]="preventEventBubble"
                [suggestions]="unusedAttributes | attributesToDataSuggestions"
                (save)="onNewKey($event)"
                (dblclick)="onKeyInputDblClick($event)"
                (cancel)="onKeyInputCancel()"
                (click)="onKeyFocus()"
                (clickOutside)="onKeyInputCancel()"
                [class.editing]="keyEditing$ | async"
                [class.cursor-pointer]="!readonly"
                [class.read-only]="readonly">
    </data-input>
  </div>
</ng-template>

<ng-container *ngIf="row.attribute | dataInputEditInfo:dataValue:editable: (editing$ | async):lockStats as dataInputInfo">
  <div class="p-0 ps-1 d-flex flex-row flex-nowrap align-items-center justify-content-center">
    <data-input class="h-100 w-100 p-1 data-input value"
                [constraint]="row.attribute?.constraint"
                [cursor]="cursor"
                [dataValue]="dataValue"
                [focus]="true"
                [configuration]="configuration"
                [readonly]="dataInputInfo.readonly"
                [editableInReadonly]="dataInputInfo.editable"
                [workspace]="workspace"
                [preventEventBubble]="preventEventBubble"
                [fontColor]="formattingStyle?.color"
                [backgroundColor]="formattingStyle?.background"
                [ngClass]="formattingStyle?.classes"
                (save)="onNewValue($event)"
                (dblclick)="onDataInputDblClick($event)"
                (cancel)="onDataInputCancel()"
                (click)="onValueFocus()"
                (valueChange)="onValueEdit($event)"
                (clickOutside)="onDataInputCancel()"
                [class.editing]="dataInputInfo.editing"
                [class.cursor-pointer]="!dataInputInfo.readonly"
                [class.read-only]="dataInputInfo.readonly">
    </data-input>
  </div>

  <data-resource-data-row-icons class="d-flex flex-row flex-nowrap align-items-center ms-1 justify-content-center"
                                [row]="row"
                                [lockStats]="lockStats"
                                [showLockStats]="dataInputInfo.showLockStats"
                                [permissions]="permissions"
                                [constraintData]="constraintData"
                                [cursor]="cursor"
                                (delete)="deleteRow.emit()"
                                (attributeFunctionClick)="attributeFunctionClick.emit()"
                                (attributeTypeClick)="attributeTypeClick.emit()">
  </data-resource-data-row-icons>
</ng-container>
