<ul class="nav nav-tabs">
  <li *ngFor="let resource of resources;" class="nav-item">
    <a
      class="nav-link"
      [class.active]="resource.id === selectedId"
      title="{{resource.name}}"
      (click)="setActiveResource(resource)">
      <icons-presenter *ngIf="resource | resourceIconsColors as iconsColors"
                       [icons]="iconsColors.icons"
                       [colors]="iconsColors.colors">
      </icons-presenter>
      {{resource.name}}
    </a>
  </li>
</ul>
