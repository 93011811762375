<dropdown [origin]="origin"
          [minHeight]="(filesAttachments?.length + files?.length || 0) * 30 + 60"
          [minWidth]="uploadProgress ? 300 : 100"
          [positions]="dropdownPositions"
          [pushOnScreen]="true"
          [showBackdrop]="false">
  <div class="card p-3"
       (click)="onClick($event)">
    <table *ngIf="filesAttachments?.length > 0 || files?.length > 0"
           class="table table-borderless table-sm">
      <tbody>
      <tr *ngFor="let file of filesAttachments; index as fileIndex">
        <td [class.border-top-0]="fileIndex === 0"
            [class.text-secondary]="file.uploading">
          <div [class.btn]="!file.uploading" [class.btn-link]="!file.uploading" class="p-0" (click)="onAttachmentClick(file)">{{ file.fileName }}</div>
        </td>
        <td [class.border-top-0]="fileIndex === 0"
            class="text-end">
          <i *ngIf="!file.uploading"
             (click)="onRemoveFileAttachment(file.id)"
             title="Delete file"
             i18n-title="@@files.dropdown.button.delete"
             class="far fa-trash-alt text-danger cursor-pointer">
          </i>
        </td>
      </tr>
      <tr *ngFor="let file of files; index as fileIndex">
        <td [class.border-top-0]="fileIndex === 0 && !filesAttachments?.length">
          <div class="btn btn-link p-0" (click)="onFileClick(file)">{{ file.name }}</div>
        </td>
        <td [class.border-top-0]="fileIndex === 0 && !filesAttachments?.length"
            class="text-end">
          <i (click)="onRemoveFile(fileIndex)"
             title="Delete file"
             i18n-title="@@files.dropdown.button.delete"
             class="far fa-trash-alt text-danger cursor-pointer">
          </i>
        </td>
      </tr>
      </tbody>
    </table>

    <div *ngIf="fileSizeError$ | async as fileSizeError"
         class="text-danger">
      {{ fileSizeError }}
    </div>

    <div *ngIf="uploadProgress"
         class="progress mb-3"
         [style.min-width.px]="300">
      <div class="progress-bar"
           role="progressbar"
           [style.width.%]="uploadProgress"
           [attr.aria-valuenow]="uploadProgress"
           aria-valuemin="0"
           aria-valuemax="100">
      </div>
    </div>

    <div class="d-flex mt-2 flex-grow-0 justify-content-end">
      <button class="btn btn-sm btn-outline-secondary"
              data-test="files-dropdown-cancel-button"
              (click)="onCancel()">
        <span i18n="@@button.cancel">Cancel</span>
      </button>
      <div class="ms-1">
        <input type="file"
               class="d-none"
               id="inputGroupFile01"
               [attr.disabled]="!!uploadProgress || null"
               (change)="onFileChange($event)">
        <label for="inputGroupFile01"
               class="btn btn-sm btn-primary m-0 cursor-pointer"
               i18n="@@files.dropdown.button.add">Add file</label>
      </div>
    </div>
  </div>
</dropdown>
