<div class="my-4">
  <h5 i18n="@@organizations.tab.detail.payments.payments">Previous Orders</h5>
  <div class="table-responsive">
    <table class="table table-striped table-borderless">
      <caption i18n="@@organizations.tab.detail.payments.list">List of previous orders</caption>
      <thead>
        <tr>
          <th i18n="@@organizations.tab.detail.payments.title.placedOn">Placed on</th>
          <th i18n="@@organizations.tab.detail.payments.title.serviceLevel">Service Level</th>
          <th i18n="@@organizations.tab.detail.payments.title.users"># Users</th>
          <th i18n="@@organizations.tab.detail.payments.title.price">Price</th>
          <th i18n="@@organizations.tab.detail.payments.title.state">State</th>
          <th i18n="@@organizations.tab.detail.payments.title.validFrom">Valid from</th>
          <th i18n="@@organizations.tab.detail.payments.title.validUntil">Valid until</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let payment of payments$ | async; trackBy: trackByPayment">
          <td class="align-middle">{{ payment.date | date : 'shortDate'}}</td>
          <td class="align-middle">
            <span *ngIf="payment.serviceLevel === paymentState.FREE" class="badge rounded-pill bg-danger p-2" i18n="@@organizations.tab.detail.payments.trial">Free</span>
            <span *ngIf="payment.serviceLevel !== paymentState.FREE" class="badge rounded-pill bg-success p-2" i18n="@@organizations.tab.detail.payments.business">Business</span>
          </td>
          <td class="align-middle">
            {{ payment.users }}
            <button *ngIf="payment.state === paymentState.PAID" class="btn btn-sm btn-primary" title="Add more users" i18n-title="@@organizations.tab.detail.payments.addUsers" (click)="addUsers()">
              <i class="fas fa-user-plus"></i>
            </button>
          </td>
          <td class="align-middle">{{ payment.currency }} {{ payment.amount | number:'1.2-2' }}</td>
          <td class="align-middle" *ngIf="payment.state === paymentState.PAID"><span class="badge rounded-pill bg-success p-2" i18n="@@organizations.tab.detail.payments.paid">Paid</span></td>
          <td class="align-middle" *ngIf="payment.state === paymentState.CANCELED"><span class="badge rounded-pill bg-light p-2" i18n="@@organizations.tab.detail.payments.canceled">Canceled</span></td>
          <td class="align-middle" *ngIf="payment.state === paymentState.TIMEOUTED"><span class="badge rounded-pill bg-light p-2" i18n="@@organizations.tab.detail.payments.timeouted">Timeouted</span></td>
          <td class="align-middle" *ngIf="payment.state !== paymentState.PAID && payment.state !== paymentState.CANCELED && payment.state !== paymentState.TIMEOUTED">
            <span class="badge rounded-pill bg-danger p-2" i18n="@@organizations.tab.detail.payments.ordered">Ordered</span>
            <button type="button" class="btn btn-sm btn-primary ms-2" (click)="refreshPayment(payment.paymentId)"
                    title="Refresh payment state" i18n-title="@@organizations.tab.detail.payments.refresh">
              <i class="fas fa-sync-alt"></i>
            </button>
            <button *ngIf="payment.state !== paymentState.AUTHORIZED " type="button" class="btn btn-sm btn-primary ms-2"
                    (click)="repayEvent(payment.gwUrl)" title="Try to pay again"
                    i18n-title="@@organizations.tab.detail.payments.repay">
              <i class="fas fa-credit-card"></i>
            </button>
          </td>
          <td class="align-middle">{{ payment.start | date : 'shortDate'}}</td>
          <td class="align-middle">{{ payment.validUntil | date : 'shortDate' }}</td>
        </tr>
        <tr *ngIf="!(payments$ | async).length">
          <td colspan="7" class="align-middle text-center" i18n="@@organizations.tab.detail.payments.giveItATry">You've never had a chance to see Lumeer for <strong>Business</strong>. Give it a try today!</td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
