<top-panel-wrapper>
  <div *ngIf="organization$ | async as organization"
       class="col-12 col-lg-11 col-xl-10 container-fluid mx-auto content">
    <resource-header
      [resource]="organization"
      [resourceType]="organizationType"
      (back)="goBack()"
      [permissions]="permissions$ | async"
      (delete)="onDelete()"
      (colorIconChange)="onNewColorOrIcon($event)"
      (descriptionChange)="onNewDescription($event)"
      (nameChange)="onNewName($event)"
      (codeChange)="onNewCode($event)">
      <div class="d-flex flex-row flex-nowrap align-items-center me-3"
           title="Number of users" i18n-title="@@organization.settings.users.count">
        <i class="far fa-user" aria-hidden="true"></i>&nbsp;<span>{{ userCount$ | async }}</span>
      </div>

      <div class="d-flex flex-row flex-nowrap align-items-center me-3"
           title="Number of projects" i18n-title="@@organization.settings.projects.count">
        <i class="far fa-edit" aria-hidden="true"></i>&nbsp;<span>{{ projectsCount$ | async }}</span>
      </div>
    </resource-header>

    <ul class="nav nav-tabs" *ngIf="permissions$ | async as permissions">
      <li role="presentation" class="nav-item" *ngIf="permissions?.roles?.Manage">
        <a [routerLink]="'detail'"
           routerLinkActive="active"
           class="nav-link"
           i18n="@@organizations.tab.detail">Detail</a>
      </li>
      <li role="presentation" class="nav-item" *ngIf="permissions?.roles?.UserConfig">
        <a [routerLink]="'users'"
           routerLinkActive="active"
           class="nav-link"
           i18n="@@organizations.tab.users">Users</a>
      </li>
      <li role="presentation" class="nav-item" *ngIf="permissions?.roles?.UserConfig">
        <a [routerLink]="'teams'"
           routerLinkActive="active"
           class="nav-link"
           i18n="@@organizations.tab.teams">Teams</a>
      </li>
    </ul>
    <router-outlet></router-outlet>
  </div>
</top-panel-wrapper>
