<dropdown [pushOnScreen]="true"
          [origin]="origin"
          [positions]="dropdownPositions"
          [closeOnClickOrigin]="true"
          [showBackdrop]="false">
  <div class="panel-item notifications card py-2 overflow-auto">
    <form class="px-3 pt-1 pb-2 text-end">
      <span class="small text-muted me-1 align-text-top" i18n="@@userNotifications.showUnread">Show only unread</span>
      <label class="switch">
        <input type="checkbox" [checked]="unreadOnly" (click)="toggleUnreadFilter($event)">
        <span class="slider round" (click)="$event.stopPropagation()"></span>
      </label>
    </form>

    <ng-container *ngFor="let notification of (unreadOnly ? unreadNotifications : allNotifications);">
      <notification-item
        [notification]="notification"
        [organizations]="organizations"
        (onOpen)="navigateToTarget($event)"
        (onRead)="setNotificationReadEvent($event)"
        (onDelete)="deleteNotificationEvent($event)"
      ></notification-item>
    </ng-container>

    <div
      *ngIf="!(unreadOnly ? unreadNotifications : allNotifications) || (unreadOnly ? unreadNotifications : allNotifications).length === 0"
      class="px-4 py-1 text-center" i18n="@@userNotifications.empty">There are no notifications.
    </div>
  </div>
</dropdown>
