<input #dateTimeInput
       [readonly]="readonly"
       [title]="value | formatDataValueTitle"
       [value]="readonly ? (value | formatDataValue  | unescapeHtml) : (value | formatDataValueForEdit)"
       [class.invalid-value]="commonConfiguration?.skipValidation ? false : !(value | isDataValueValid)"
       [style.color]="readonly ? fontColor : null"
       (input)="onInput($event)"
       (blur)="!readonly && onBlur()"
       (focus)="!readonly && onFocus()"
       [ngClass]="inputClass"
       class="border-0 w-100 h-100 px-1 bg-transparent"
       autocomplete="off"
       inputmode="numeric"
       type="text">

<date-time-picker *ngIf="!readonly"
                  [min]="commonConfiguration?.skipValidation ? null : value?.config?.minValue"
                  [max]="commonConfiguration?.skipValidation ? null : value?.config?.maxValue"
                  [options]="options"
                  [origin]="element"
                  [asUtc]="value?.isUtc"
                  [value]="date"
                  (save)="onSave($event)"
                  (valueChange)="onValueChange($event)"
                  (saveOnClose)="onSaveOnClose(dateTimeInput.value, $event)"
                  (cancel)="onCancel()">
</date-time-picker>
