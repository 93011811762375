<h4 class="m-0 py-3" i18n="@@projects.tab.template.script.title">Embeddable Lumeer</h4>
<p i18n="@@projects.tab.template.script.description">
  Embed this Lumeer project in any web page using the following script.
  You can also use a WordPress shortcode (requires the <a href="https://wordpress.org/plugins/lumeer-embed/" target="_blank">Lumeer Embed Plugin</a> in your WordPress installation).
</p>

<div class="script-content mt-3"
     *ngIf="{script: scriptText$ | async, copied: copied$ | async} as data">
  <div class="me-md-4 d-flex flex-column pt-3" [formGroup]="formGroup">

    <div class="d-flex flex-row flex-nowrap align-items-center">
      <label class="switch" for="showTopPanelScript">
        <input type="checkbox" formControlName="showTopPanel" id="showTopPanelScript" #showTopPanelCheckbox>
        <span class="slider round" (click)="$event.stopPropagation()"></span>
      </label>
      <span class="ps-2 cursor-pointer user-select-none" i18n="@@projects.tab.template.script.showTopPanel"
            (click)="showTopPanelCheckbox.click()">Show Application Controls</span>
    </div>

    <div class="mt-3">
      <select-item class="w-100"
                   [items]="views | viewsSelectItems:collections"
                   emptyValue="Select starting view"
                   i18n-emptyValue="@@projects.tab.template.script.defaultView.empty"
                   [removable]="true"
                   [selectedId]="defaultViewControl.value"
                   [fitParent]="true"
                   (selected)="onViewSelected($event)"
                   (remove)="onViewSelected(null)">
      </select-item>
    </div>

  </div>
  <div>

    <div class="d-flex mt-3 mt-md-0">

      <div class="btn-group me-4" role="group">
        <button type="button" class="btn btn-outline-primary"
                [class.active]="scriptTypeControl.value === scriptType.Html"
                (click)="onScriptTypeSelected(scriptType.Html)"><i
          class="fab fa-html5"></i></button>
        <button type="button" class="btn btn-outline-primary"
                [class.active]="scriptTypeControl.value === scriptType.WordPress"
                (click)="onScriptTypeSelected(scriptType.WordPress)"><i
          class="fab fa-wordpress"></i></button>
        <button type="button" class="btn btn-outline-primary"
                [class.active]="scriptTypeControl.value === scriptType.Link"
                (click)="onScriptTypeSelected(scriptType.Link)"><i
          class="fas fa-link"></i></button>
      </div>

      <button *ngIf="!data.copied" class="btn btn-outline-primary ms-auto"
              (click)="copyValue(data.script)">
        <i class="fal fa-copy"></i>&nbsp;<span i18n="@@projects.tab.template.script.copy">Copy script</span>
      </button>
      <button *ngIf="data.copied" class="btn btn-outline-primary ms-auto"
              [disabled]="true">
        <i class="fal fa-copy"></i>&nbsp;<span i18n="@@projects.tab.template.script.copied">Copied!</span>
      </button>
    </div>
    <div class="custom-file form-control h-auto p-3 mt-3">
      <code class="break-all">{{data.script}}</code>
    </div>
    <div *ngIf="scriptTypeControl.value === scriptType.WordPress" class="text-muted small mt-1"
         i18n="@@projects.tab.template.script.shortcode.note">
      WordPress shortcode, requires the <a href="https://wordpress.org/plugins/lumeer-embed/" target="_blank">Lumeer Embed Plugin</a> installed in your WordPress.
    </div>
  </div>
</div>
