<dropdown [origin]="origin"
          [positions]="dropdownPositions"
          [pushOnScreen]="true"
          [showBackdrop]="false"
          [fitParent]="false"
          (onCloseByClickOutside)="onSave()">

  <div class="card p-0">

    <div class="d-flex flex-md-row flex-column card-body p-0 overflow-auto">

      <color-choose class="d-block w-100 bg-white p-3" [color]="selectedColor$ | async"
                    (selected)="onColorSelected($event)"
                    (preview)="onColorPreview($event)">
      </color-choose>

      <hr class="d-block d-md-none"/>

      <icon-choose class="d-block w-100 bg-white p-3 ps-md-0" [icon]="selectedIcon$ | async"
                   (selected)="onIconSelected($event)"
                   (preview)="onIconPreview($event)">
      </icon-choose>

    </div>

    <div class="card-footer text-end p-3 d-flex">
      <button *ngIf="showClearIcon" class="btn btn-outline-secondary btn-sm" (click)="onClear()">
        <span i18n="@@button.clear">Clear</span>
      </button>
      <button class="btn btn-outline-secondary btn-sm" (click)="onCancel()" [class.ms-auto]="showClearIcon">
        <span i18n="@@button.cancel">Cancel</span>
      </button>
      <button class="btn btn-primary btn-sm ms-2" (click)="onSave()"
              data-test="icon-picker-select-button">
        <span i18n="@@button.select">Select</span>
      </button>
    </div>

  </div>

</dropdown>
