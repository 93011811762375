<dropdown [origin]="origin"
          [positions]="dropdownPositions"
          [closeOnClickOutside]="true"
          [showBackdrop]="false"
          (onCloseByClickOutside)="onCloseByClickOutside()">
  <div class="card"
       (click)="onClick($event)">
    <div class="d-flex"
         [class.flex-column]="!(options | hasTimeOption)"
         [formGroup]="form">
      <bs-datepicker-inline *ngIf="options | hasDateOption"
                            [bsConfig]="datePickerConfig"
                            [minDate]="min"
                            [maxDate]="max"
                            [bsValue]="dateControl.value"
                            (bsValueChange)="onDateChange($event)">
      </bs-datepicker-inline>
      <div class="d-flex flex-column pe-3 pb-3"
           [class.ps-3]="!((options | hasDateOption) && (options | hasTimeOption))"
           [class.pt-3]="!(options | hasDateOption)">
        <div *ngIf="options | hasTimeOption"
             class="d-flex flex-column flex-grow-1 align-items-center justify-content-center mb-3">
          <timepicker formControlName="time"
                      [min]="min"
                      [max]="max"
                      [showMeridian]="options?.meridian"
                      [showMinutes]="options?.minutes"
                      [showSeconds]="options?.seconds"
                      (keydown)="onTimePickerKeyDown($event)">
          </timepicker>
          <small class="text-secondary mt-3">
            {{ timeZone }}
          </small>
        </div>
        <div class="d-flex flex-grow-0 justify-content-end">
          <button class="btn btn-sm btn-outline-secondary"
                  data-test="date-time-picker-cancel-button"
                  (click)="onCancel($event)">
            <span i18n="@@button.cancel">Cancel</span>
          </button>
          <button class="btn btn-sm btn-primary ms-2"
                  data-test="date-time-picker-save-button"
                  [disabled]="!dateControl.value && !timeControl.value"
                  (click)="onSave($event)">
            <span i18n="@@button.save">Save</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</dropdown>
