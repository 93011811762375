<ng-container [ngSwitch]="computedType">

  <img *ngSwitchCase="type.Image"
       [src]="url" alt="" [ngClass]="scale"/>

  <div *ngSwitchCase="type.View"
       class="content p-2" [title]="view.name">
    <i class="me-2" [ngClass]="view.icon" [style.color]="view.color"></i>
    <span class="text-truncate">{{view.name}}</span>
  </div>

  <div *ngSwitchCase="type.Notes" class="content p-2">
    <i class="far fa-list me-2"></i>
    <span [style.margin-bottom.rem]="0.1" class="text-truncate" i18n="@@notes">Notes</span>
  </div>

  <div *ngSwitchDefault class="text-success clickable content p-2">
    <ng-container *ngIf="editable">
      <i class="far fa-pencil me-2"></i>
      <span class="text-truncate" i18n="@@edit">Edit</span>
    </ng-container>
    <ng-container *ngIf="!editable">
      <i class="far fa-eye me-2"></i>
      <span class="text-truncate" i18n="@@show">Show</span>
    </ng-container>
  </div>

</ng-container>
