<icons-presenter *ngIf="formattedItem?.icons && formattedItem?.iconColors"
                 class="d-block me-2"
                 [colors]="formattedItem.iconColors"
                 [icons]="formattedItem.icons">
</icons-presenter>
<i *ngIf="formattedItem?.icons && !formattedItem?.iconColors && formattedItem?.icons.length === 1"
   class="fa-fw me-2 {{formattedItem.icons[0]}}"></i>
<span class="text-nowrap flex-grow-1 text-start text-truncate" [title]="formattedValue">{{formattedValue}}</span>
<small *ngIf="formattedItem?.shortcut" class="text-secondary ms-3">{{formattedItem.shortcut}}</small>
<i *ngIf="removable && !disabled" class="fa-fw fal fa-times mx-1 clear" (click)="onRemove($event)"></i>
