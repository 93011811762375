<team-list class="d-block mb-6"
           [organization]="organization$ | async"
           [project]="project$ | async"
           [resource]="resource$ | async"
           [resourceType]="resourceType"
           [teams]="teams$ | async"
           [currentUser]="currentUser$ | async"
           [serviceLimits]="serviceLimits$ | async"
           (teamCreated)="onNewTeam($event)"
           (teamDeleted)="onTeamDeleted($event)"
           (teamUpdated)="onTeamUpdated($event)"
           (teamRolesChange)="onRolesChange($event.roles, $event.team)">
</team-list>
