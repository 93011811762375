<i class="color-success clickable far fa-upload" aria-hidden="true"
   (click)="fileInput.click()"
   title="Import table content"
   i18n-title="@@collection.import.content.icon"></i>
<input hidden
       type="file"
       #fileInput
       (click)="$event.stopPropagation()"
       (change)="onFileChange($event); $event.target.value = null;"
       accept=".csv, text/csv">
