/*
 * Lumeer: Modern Data Definition and Processing Platform
 *
 * Copyright (C) since 2017 Lumeer.io, s.r.o. and/or its affiliates.
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */
import {ChangeDetectionStrategy, Component, Input} from '@angular/core';

import {Store} from '@ngrx/store';

import {Sequence} from '../../../core/model/sequence';
import {AppState} from '../../../core/store/app.state';
import {SequencesAction} from '../../../core/store/sequences/sequences.action';

@Component({
  selector: 'sequence-list',
  templateUrl: './sequence-list.component.html',
  styleUrls: ['./sequence-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SequenceListComponent {
  @Input()
  public sequences: Sequence[];

  constructor(private store$: Store<AppState>) {}

  public updateSequence(sequence: Sequence) {
    this.store$.dispatch(new SequencesAction.Update({sequence}));
  }

  public removeSequence(sequence: Sequence) {
    this.store$.dispatch(new SequencesAction.Delete({sequence}));
  }
}
