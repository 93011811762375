<div class="input"
     #inputParent>

  <div #input
       [ngClass]="maxLines !== 1 ? 'input-box-multiline' : 'input-box'"
       [class]="innerClass"
       [class.frame]="alwaysFrame"
       [class.user]="userData"
       [class.text-attribute]="textAttribute"
       [class.form-control]="editable"
       [class.cursor-text]="editable"
       [textContent]="mCurrentValue"
       (blur)="input.scrollTo(0,0); onNewValue(input.textContent)"
       [style.color]="color"
       [style.fontSize.rem]="mFontSizeRem"
       [style.maxHeight.rem]="mMaxHeightRem"
       [style.paddingLeft.rem]="mPaddingHRem"
       [style.paddingRight.rem]="mPaddingHRem"
       [style.paddingTop.rem]="mPaddingVRem"
       [style.paddingBottom.rem]="mPaddingVRem"
       (focus)="onFocus()"
       [attr.contenteditable]="editable"
       [attr.placeholder]="mPlaceholder"
       [attr.title]="title"
       (input)="onInterimNewValue(input.textContent)"
       (paste)="pasteContent($event)"
       (keydown.enter)="onEnter(); input.blur()"
       (keydown.Escape)="input.textContent = mCurrentValue; input.blur()"
       (keydown)="onKeyDown($event)"
       [attr.spellcheck]="false"
       class="h-100">
  </div>

</div>
