<ng-container
  *ngIf="{permissions: view | viewControlsInfo:(projectPermissions$ | async):(collectionsPermissions$ | async):(viewsPermissions$ | async):(linkTypes$ | async), viewChanged: viewChanged$ | async} as state">
  <ng-container *ngIf="{disabled: (saveLoading$ | async) || !state.viewChanged || !(name && (!view.code || state.viewChanged) && state.permissions.canSave)} as buttonsData">
    <form class="view-controls-grid">
      <div class="perspective d-flex align-items-center justify-content-start">
        <div class="input-group flex-nowrap me-2 mb-2" data-tour="perspective">
        <span class="input-group-text">
          <strong *ngIf="novice"
                  i18n="@@view.perspective.guide.select">1.&nbsp;Select View</strong>
          <i *ngIf="!novice"
             class="far fa-eye fa-fw">
          </i>
        </span>
          <ng-container *ngIf="perspective$ | async as perspective">

            <button aria-expanded="false" aria-haspopup="true" type="button" #perspectiveElement
                    class="btn btn-outline-gray-600 sharp-top-left sharp-bottom-left dropdown-toggle"
                    [disabled]="view?.id && !state.permissions.canConfig"
                    (click)="onPerspectiveChooserClick($event)">
              <i class="fa-fw {{perspective | perspectiveIcon}}"></i>
              <span class="ms-sm-1 text-nowrap">{{perspective | perspectiveName}}</span>
            </button>

            <options-dropdown
              [closeOnClickOrigin]="true"
              [showBackdrop]="false"
              [origin]="perspectiveElement"
              [options]="perspectives | sortPerspectives | perspectivesOptions"
              [highlightedValue]="perspective"
              (selectOption)="onSelectPerspective($event.value, state.permissions.canConfig)">
            </options-dropdown>

          </ng-container>

        </div>

        <i *ngIf="novice"
           class="view-arrow fas fa-arrow-circle-right me-2 mb-2 text-muted">
        </i>
      </div>

      <div class="view-name input-group flex-nowrap flex-fill mb-2" data-tour="view">
      <span class="input-group-text">
        <strong *ngIf="novice"
                i18n="@@view.perspective.guide.title">2.&nbsp;Set Title</strong>
        <i *ngIf="!novice"
           class="far fa-file-edit fa-fw">
        </i>
      </span>
        <input #viewNameInput
               [class.fst-italic]="!view.code || view.name !== name"
               [value]="name"
               [disabled]="view && !state.permissions.canSave"
               (input)="onNameInput(viewNameInput.value)"
               id="viewName"
               name="viewName"
               (blur)="onInputBlur(state.permissions.canSave)"
               (keyup)="onViewNameKeyPress(!buttonsData.disabled, $event, state.permissions.canClone, viewNameInput)"
               type="text"
               class="form-control"
               autocomplete="off"
               placeholder="Type a title of this page, save, and share!" i18n-placeholder="@@view.untitled">
        <div class="input-group-append">
          <button [disabled]="buttonsData.disabled"
                  class="btn"
                  [class.btn-primary]="!buttonsData.disabled"
                  [class.btn-outline-secondary]="buttonsData.disabled"
                  [class.disabled]="buttonsData.disabled"
                  type="button"
                  title="Save" i18n-title="@@button.save"
                  (click)="onSave(state.permissions.canClone)">
            <i class="fas fa-save"></i>
          </button>
          <button *ngIf="canShareView"
                  [disabled]="!(view.code && state.permissions.canShare)"
                  (click)="onShareClick()"
                  class="btn btn-primary"
                  type="button">
            <span i18n="@@button.share"
                  placement="left"
                  tooltip="To share a view, first set its name and save it using the controls to the left."
                  i18n-tooltip="@@view.share.disabled.tooltip"
                  [isDisabled]="!!view.code || state.permissions.canShare">Share</span>
          </button>
        </div>
      </div>
    </form>
    <div *ngIf="!buttonsData.disabled"
         class="small text-end text-warning">
      <i class="far fa-exclamation-triangle me-1 text-warning" title="Changes are not saved yet!" i18n-title="@@view.changes.not.saved"></i>
      <span class="text-warning" i18n="@@view.changes.not.saved">Changes are not saved yet!</span>
      <span class="btn-link text-success ms-1 cursor-pointer text-underline" (click)="revertChanges()" i18n="@@view.changes.not.saved.undo">Undo changes</span>
    </div>
  </ng-container>
</ng-container>
