<div class="text-start">
  <div class="fw-bold">{{fileAttachment.fileName}}</div>
  <div *ngIf="createdBy" class="d-flex align-items-center mt-2">
    <img ngxGravatar
         [email]="createdBy.email"
         [size]="avatarSize"
         [style.min-width.px]="avatarSize"
         [style.min-height.px]="avatarSize">
    <span class="ps-1">{{createdBy.name || createdBy.email}}</span>
  </div>
  <div *ngIf="fileAttachment.creationDate | isDateValid"
       class="fst-italic small"
       [class.mt-2]="!createdBy">{{fileAttachment.creationDate | fromNow}}</div>
</div>
