<user-list *ngIf="organization$ | async as organization"
           class="d-block mb-6"
           [resourceType]="resourceType"
           [organization]="organization"
           [project]="project$ | async"
           [currentUser]="currentUser$ | async"
           [users]="users$ | async"
           [teams]="teams$ | async"
           [resource]="resource$ | async"
           [workspace]="workspace$ | async"
           [serviceLimits]="serviceLimits$ | async"
           (userCreated)="onNewUser($event)"
           (userUpdated)="onUserUpdated($event)"
           (userDeleted)="onUserDeleted($event)"
           (userRolesChange)="changeUserPermissions($event)"
           (userTeamsChange)="onUserTeamsChange($event)">
</user-list>
