<div class="d-flex flex-row flex-nowrap align-items-center">
  <label class="switch" [for]="id">
    <input type="checkbox" [checked]="checked"
           [disabled]="disabled"
           (change)="checkedChange.emit($event.target.checked)"
           [id]="id" #toggleElement>
    <span class="slider round" (click)="$event.stopPropagation()"></span>
  </label>
  <i *ngIf="icon" class="ps-3 cursor-pointer" [ngClass]="icon" (click)="!disabled && toggleElement.click()"></i>
  <span class="cursor-pointer user-select-none flex-1"
        [class.ps-1]="icon"
        [class.ps-2]="!icon"
        (click)="!disabled && toggleElement.click()">{{label}}</span>
  <span *ngIf="hint" class="ms-1 far fa-question-circle" tooltip="{{hint}}"></span>
</div>
