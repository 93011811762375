<div class="form-control cursor-pointer p-0 h-auto wrapper" #parentElement
     [ngClass]="selectOperator ? 'operator-wrapper' : 'simple-wrapper'">

  <div class="operator h-100 d-flex align-items-center" *ngIf="selectOperator">

    <select-item2 class="px-2 d-inline-flex h-100"
                  [disabled]="false"
                  [items]="operatorSelectItems"
                  [showAsLink]="false"
                  (click)="$event.stopPropagation()"
                  [selectedPath]="[operatorValue$ | async]"
                  (selectPath)="onOperatorSelect($event)">
    </select-item2>

  </div>

  <div class="d-flex flex-wrap align-items-center text-truncate">

    <select-item2 class="ps-2 pe-1"
                  [class.flex-grow-1]="!attributeIdControl.value"
                  [removable]="false"
                  [disabled]="false"
                  emptyValue="Select attribute"
                  i18n-emptyValue="@@select.attribute"
                  [items]="attributeSelectItems"
                  (click)="$event.stopPropagation()"
                  [selectedPath]="[attributeIdControl.value]"
                  (selectPath)="onAttributeSelect($event)">
    </select-item2>

    <filter-preview *ngIf="attributeIdControl.value"
                    class="m-2"
                    [attribute]="attributes | findAttribute:attributeIdControl.value | modifyAttributeForQueryFilter"
                    [condition]="conditionControl.value"
                    [conditionValues]="conditionValuesControl.value">
    </filter-preview>

  </div>

  <i class="fal fa-times p-2 delete-icon" (click)="onRemove()"></i>

</div>

<filter-builder *ngIf="attributeIdControl.value"
                [origin]="parentElement"
                [condition]="conditionControl.value"
                [attribute]="attributes | findAttribute:attributeIdControl.value | modifyAttributeForQueryFilter"
                [conditionValues]="conditionValuesControl.value"
                (valueChange)="onValueChange($event)">
</filter-builder>
