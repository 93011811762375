<div class="my-4">
  <h5 i18n="@@organizations.tab.detail.contact.invoicingContact">Invoicing Contact</h5>
  <form #invoicingContactForm="ngForm">
    <div class="row">
      <div class="col">
        <input type="text" ngModel name="company" i18n-placeholder="@@organizations.tab.detail.contact.company" placeholder="Company Name" class="form-control form-control-sm" id="company">
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-6">
        <input type="text" ngModel name="firstName" i18n-placeholder="@@organizations.tab.detail.contact.firstName" placeholder="First Name" class="form-control form-control-sm" id="firstName">
      </div>
      <div class="col-6">
        <input type="text" ngModel name="lastName" i18n-placeholder="@@organizations.tab.detail.contact.lastName" placeholder="Last Name" class="form-control form-control-sm" id="lastName">
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-6">
        <input type="text" ngModel name="address1" i18n-placeholder="@@organizations.tab.detail.contact.address1" placeholder="Building/Appartment/Office" class="form-control form-control-sm" id="address1">
      </div>
      <div class="col-6">
        <input type="text" ngModel name="address2" i18n-placeholder="@@organizations.tab.detail.contact.address2" placeholder="Street" class="form-control form-control-sm" id="address2">
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-3">
        <input type="text" ngModel name="city" i18n-placeholder="@@organizations.tab.detail.contact.city" placeholder="City" class="form-control form-control-sm" id="city">
      </div>
      <div class="col-3">
        <input type="text" ngModel name="zip" i18n-placeholder="@@organizations.tab.detail.contact.zip" placeholder="ZIP" class="form-control form-control-sm" id="zip">
      </div>
      <div class="col-3">
        <input type="text" ngModel name="state" i18n-placeholder="@@organizations.tab.detail.contact.state" placeholder="State" class="form-control form-control-sm" id="state">
      </div>
      <div class="col-3">
        <select ngModel name="country" class="form-control form-control-sm" id="country" i18n-title="@@organizations.tab.detail.contact.country" title="Select country">
          <option *ngFor="let country of countries">{{country}}</option>
        </select>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-6">
        <input type="text" ngModel name="email" inputmode="email" i18n-placeholder="@@organizations.tab.detail.contact.email" placeholder="E-mail" class="form-control form-control-sm" id="email" #email="ngModel" email>
        <small id="emailValid" class="invalid-message form-text" *ngIf="email.invalid && email.touched" i18n="@@organizations.tab.detail.contact.email.invalid">
          Please enter a valid e-mail address (e.g. alan.turing@lumeer.io).
        </small>
      </div>
      <div class="col-6">
        <input type="text" ngModel name="phone" inputmode="tel" i18n-placeholder="@@organizations.tab.detail.contact.phone" placeholder="Phone" class="form-control form-control-sm" id="phone" #phone="ngModel" pattern="[-+0-9/ ]*">
        <small id="phoneValid" class="invalid-message form-text" *ngIf="phone.invalid && (phone.dirty || phone.touched)" i18n="@@organizations.tab.detail.contact.phone.invalid">
          Please enter a phone number that contains only numbers, characters +-/ and spaces.
        </small>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-6">
        <input type="text" ngModel name="ic" i18n-placeholder="@@organizations.tab.detail.contact.ic" placeholder="Company Reg. No." class="form-control form-control-sm" id="ic" #ic="ngModel" pattern="[0-9 ]*">
        <small id="icValid" class="invalid-message form-text" *ngIf="ic.invalid && (ic.dirty || ic.touched)" i18n="@@organizations.tab.detail.contact.ic.invalid">
          Please enter only numbers and spaces.
        </small>
      </div>
      <div class="col-6">
        <input type="text" ngModel name="dic" i18n-placeholder="@@organizations.tab.detail.contact.dic" placeholder="Tax Ident. No." class="form-control form-control-sm" id="dic">
      </div>
    </div>
    <div class="text-end mt-4" *ngIf="{savingState: savingState$ | async} as formData">
      <button *ngIf="!formData.savingState && invoicingContactForm.form.dirty" type="submit" class="btn btn-primary mb-2" i18n="@@organizations.tab.detail.contact.save" (click)="save()" [disabled]="!invoicingContactForm.form.dirty || !invoicingContactForm.valid">Save</button>
      <button *ngIf="formData.savingState" type="submit" class="btn btn-primary mb-2" [disabled]="true">
        <span class="spinner-grow spinner-grow-sm me-2" role="status" aria-hidden="true"></span><span i18n="@@organizations.tab.detail.contact.saving">Saving</span></button>
      <button *ngIf="!formData.savingState && !invoicingContactForm.form.dirty" type="submit" class="btn btn-primary mb-2" i18n="@@organizations.tab.detail.contact.saved" [disabled]="true">Saved!</button>
    </div>
  </form>
</div>
