<ng-container *ngIf="{comments: comments$ | async} as data">
  <new-comment *ngIf="permissions?.rolesWithView?.CommentContribute"
               [currentUser]="currentUser$ | async"
               [usersMap]="usersMap$ | async"
               [initialComment]="initialComment$ | async"
               [startEditing]="startEditing"
               [bottomBorder]="!maxCommentsCount || data.comments?.length > 0"
               (onNewComment)="newComment($event)"
               (onEdit)="newCommentClick.emit()"
               (onCancel)="onCancelComment.emit()">
  </new-comment>

  <ng-container
    *ngFor="let comment of data.comments | slice:0:(maxCommentsCount ? maxCommentsCount : data.comments.length);">
    <comment-item [currentUser]="currentUser$ | async"
                  [usersMap]="usersMap$ | async"
                  [comment]="comment"
                  (onRemove)="removeComment($event)"
                  (onUpdate)="updateComment($event)"
                  (onRefresh)="newComment($event)">
    </comment-item>
  </ng-container>

  <div *ngIf="!data.comments?.length && !maxCommentsCount" class="text-center p-4 fst-italic"
       i18n="@@document.detail.comments.empty">
    No comments yet. Write the first one!
  </div>
</ng-container>

