<ng-container *ngIf="{selectedItem: selectedPath | getSelectedItems:items } as data">
  <button class="btn text-decoration-none align-items-center w-100 main-content"
          type="button"
          [disabled]="disabled"
          [class.px-0]="!bordered"
          [class.btn-outline-gray-600]="bordered"
          [class.btn-link]="showAsLink"
          [class.justify-content-between]="bordered"
          [matMenuTriggerFor]="menu.childMenu"
          [ngClass]="disabled ? 'cursor-not-allowed' : 'cursor-pointer'"
          (menuOpened)="onMenuOpened()"
          (menuClosed)="onMenuClosed()">

    <select-item-row *ngIf="data.selectedItem; else empty"
                     class="me-2 w-100"
                     [item]="data.selectedItem"
                     [displayValue]="displayValue"
                     [disabled]="disabled"
                     [removable]="removable"
                     (remove)="remove.emit()">
    </select-item-row>

    <i class="fas fa-caret-down" *ngIf="data.selectedItem && !disabled"></i>
  </button>

  <ng-template #empty>
    <span class="empty-text text-truncate me-2 text-start" [title]="emptyValue">{{emptyValue}}</span>
    <i class="fas fa-caret-down empty-text"></i>
  </ng-template>

  <lmr-menu #menu [menuItems]="menuItems"
            (pathSelected)="onSelected($event)"
            [selectedItem]="data.selectedItem">
  </lmr-menu>
</ng-container>
