<div class="row mt-4">
  <div class="col">
    <table class="table table-borderless table-striped table-sm">
      <thead>
        <tr>
          <th>
            <span i18n="@@sequence.list.sequenceName">Sequence Name</span>
          </th>
          <th class="w-25">
            <span i18n="@@sequence.list.sequenceValue">Counter</span>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr sequence-item
          *ngFor="let sequence of sequences"
          [sequence]="sequence"
          (updateSequence)="updateSequence($event)"
          (removeSequence)="removeSequence($event)">
        </tr>
        <tr>
          <td colspan="2" class="text-center text-muted fst-italic" i18n="@@sequence.list.createNew.automation.info">
            To create a new sequence, just use its name in the sequence block in a function or an automation.
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
