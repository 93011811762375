<modal-wrapper
  *ngIf="{collection: collection$ | async, linkType: linkType$ | async, attribute: attribute$ | async, function: attributeFunction$ | async} as data"
  [dialogType]="dialogType.Primary"
  [performingAction]="performingAction$ | async"
  [customHeader]="true"
  [showSubmit]="data.attribute?.constraint?.allowEditFunction || !!data.function"
  (onSubmit)="onSubmit(data.attribute)"
  (onClose)="hideDialog()">

  <attribute-function-header class="custom-header p-0 d-block w-100"
                             [resource]="data.collection || data.linkType"
                             [attribute]="data.attribute">
  </attribute-function-header>

  <attribute-function-content class="body d-block p-4"
                              [linkTypes]="linkTypes$ | async"
                              [collections]="collections$ | async"
                              [views]="views$ | async"
                              [variableNames]="variableNames$ | async"
                              [selectionLists]="selectionLists$ | async"
                              [serviceLimits]="serviceLimits$ | async"
                              [form]="form"
                              [attributeFunction]="data.function"
                              [collection]="data.collection"
                              [linkType]="data.linkType"
                              [attribute]="data.attribute">
  </attribute-function-content>

  <span class="button-cancel" i18n="@@button.cancel">Cancel</span>
  <span class="button-submit" i18n="@@button.save">Save</span>

</modal-wrapper>
