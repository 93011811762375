<div [formGroup]="form" *ngIf="ruleSelectItems.length; else emptyRules">

  <div class="d-flex">
    <span class="text-muted" i18n="@@constraint.action.description">
      An action button that executes an automation based on specified conditions.
    </span>
  </div>

  <div class=" mt-4 mb-0">
    <label class="form-label" i18n="@@constraint.action.automation.label">Automation</label>
    <select-item class="w-100 d-block"
                 [selectedId]="ruleControl.value"
                 [removable]="false"
                 emptyValue="Select automation"
                 i18n-emptyValue="@@constraint.action.automation.empty"
                 [items]="ruleSelectItems"
                 (selected)="ruleControl.setValue($event)"
                 buttonClasses="flex-grow-1 text-truncate">
    </select-item>
  </div>

  <div class="mt-3 mb-0 btn btn-link px-0" i18n="@@constraint.action.lock.label" (click)="configureLock()">Set when this button can be clicked...</div>

  <div class="mt-3 mb-0">
    <label class="form-label" [for]="formControlName.TitleUser"
           i18n="@@constraint.action.title.label">Button Design</label>
    <input class="form-control"
           [formControlName]="formControlName.TitleUser"
           i18n-placeholder="@@constraint.action.title.placeholder"
           placeholder="Enter button label..."
           [id]="formControlName.TitleUser" type="text">
  </div>
  <div *ngIf="form.errors && form.errors.iconOrTitleEmpty"
       class="small text-danger mt-1 form-text" i18n="@@constraint.action.title.error">
    Either icon or button title is required.
  </div>

  <div class="d-flex align-items-center flex-column mt-2">

    <span class="small mb-1 text-muted" i18n="@@constraint.action.color.label">Click on the button to set its icon and color</span>

    <button class="btn text-truncate text-nowrap mw-100" [style.background]="colorControl.value"
            #buttonElement (click)="togglePicker()"
            [style.color]="colorControl.value | contrastColor">
      <i *ngIf="iconControl.value" [ngClass]="iconControl.value"></i>
      <ng-container *ngIf="titleControl.value && iconControl.value || !titleControl.value && !iconControl.value">&nbsp;</ng-container>
      <span *ngIf="titleControl.value">{{titleControl.value}}</span>
    </button>

    <icon-color-picker [color]="colorControl.value"
                       [icon]="iconControl.value"
                       [origin]="buttonElement"
                       [showClearIcon]="true"
                       (preview)="onIconColorChange($event)"
                       (save)="onIconColorChange($event)"
                       (selected)="onIconColorChange($event)"
                       (cancel)="onIconColorChange($event)">
    </icon-color-picker>

  </div>

  <action-constraint-confirmation-form [form]="form">
  </action-constraint-confirmation-form>
</div>


<ng-template #emptyRules>
  <action-constraint-config-empty [permissions]="permissions"
                                  [resource]="resource">
  </action-constraint-config-empty>
</ng-template>
