<ng-container *ngIf="{focused: focused$ | async} as data">
  <div class="d-flex flex-column">

    <div class="text-muted px-2" i18n="@@filter.builder.condition">Choose Condition</div>
    <div *ngFor="let item of conditionItems; let first = first; let row = index"
         class="px-2 py-1 dropdown-item"
         [class.cursor-pointer]="editable"
         [class.mt-2]="first"
         [class.focused]="data.focused?.column === 0 && data.focused?.row === row"
         [class.active]="item.value === selectedCondition"
         [class.fst-italic]="item.value | conditionShouldBeItalic:attribute.constraint"
         (mousedown)="$event.stopImmediatePropagation(); $event.preventDefault()"
         (click)="$event.stopImmediatePropagation(); $event.preventDefault(); onConditionSelect(item, row)">{{item.title}}</div>
  </div>

  <div class="d-flex flex-column ms-3" *ngFor="let column of ngForIndexes">
    <div class="text-muted px-2" i18n="@@filter.builder.value">Choose Value</div>
    <data-input class="h-100 data-input mt-2"
                *ngIf="conditionAttribute | dataInputEditInfo:dataValues[column]:editable: ((editing$ | async) === column) as dataInputInfo"
                [class.data-input-border]="conditionAttribute.constraint?.type !== constraintType.Boolean"
                [class.data-input-padding]="conditionAttribute.constraint?.type !== constraintType.Color"
                [class.editing]="dataInputInfo.editing"
                [class.cursor-pointer]="dataInputInfo.editable"
                [constraint]="conditionAttribute.constraint"
                [dataValue]="dataValues[column]"
                [configuration]="configuration"
                [editableInReadonly]="dataInputInfo.editable"
                [focus]="true"
                [readonly]="dataInputInfo.readonly"
                (cancel)="onInputCancel(column)"
                (save)="onInputSave($event, column)"
                (keydown)="onDataInputKeyDown($event, column)"
                (mousedown)="onDataInputClick($event, column)">
    </data-input>
    <div *ngFor="let item of conditionValueItems; let first = first; let row = index"
         class="px-2 py-1 dropdown-item"
         [class.cursor-pointer]="editable"
         [class.mt-2]="first"
         [class.active]="selectedValues?.length && item.value === selectedValues[column]?.type"
         [class.focused]="data.focused?.column - 1 === column && data.focused?.row - 1 === row"
         (click)="onConditionValueSelect(item, column, row)">
      {{item.title}}
    </div>
  </div>
</ng-container>
