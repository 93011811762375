<div class="row" [formGroup]="form">

  <label class="col-2 col-form-label required"
         i18n="@@resource.new.dialog.code.label"
         for="code">Code:</label>

  <div class="col-10">
    <input type="text"
           id="code"
           trim
           formControlName="code"
           maxlength="5"
           pattern="[A-Za-z0-9_]*"
           class="form-control"
           i18n-placeholder="@@resource.new.dialog.code.placeholder"
           placeholder="Enter code (e.g. LMR)">

  </div>

  <small *ngIf="codeInput.invalid && (codeInput.dirty || codeInput.touched)"
         class="text-danger col-10 ms-auto mt-1">
          <span *ngIf="codeInput.errors.minLength"
                i18n="@@resource.new.dialog.code.error.length.min">
            Code must have at least 2 characters.
          </span>
    <span *ngIf="codeInput.errors.maxlength"
          i18n="@@resource.new.dialog.code.error.length.max">
            Code can have 5 characters at most.
          </span>
    <span *ngIf="codeInput.errors.required"
          i18n="@@resource.new.dialog.code.error.required">
            Code is required.
          </span>
    <span *ngIf="codeInput.errors.pattern && !codeInput.errors.minlength && !codeInput.errors.maxlength"
          i18n="@@resource.new.dialog.code.error.pattern">
            Code must contain only characters and digits.
          </span>
    <span *ngIf="codeInput.errors.notUniqueCode"
          i18n="@@resource.new.dialog.code.error.duplicity">
            Sorry, this code is already taken. Try another one!
          </span>
  </small>

</div>
