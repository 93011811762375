<div class="comment-item"
     [class.editing]="editing$ | async">
  <div class="author text-muted small">
    <user-avatar *ngIf="comment.id"
                 [avatarSize]="15"
                 placement="top"
                 [email]="user?.email || comment?.authorEmail"
                 [tooltipText]="createdByMsg + ' ' + (user?.name || comment?.authorName)" class="me-2">
    </user-avatar>
    <span *ngIf="!comment.id && !comment.error" class="spinner-grow spinner-grow-sm me-2 avatar-indicator" role="status"
          aria-hidden="true"></span>
    <i *ngIf="!comment.id && comment.error" class="fa fa-exclamation-circle text-danger me-2 cursor-pointer avatar-indicator"
       tooltip="I could not create the comment. Click here to try again."
       i18n-tooltip="@@comment.item.create.fail" (click)="onRefresh.emit(comment)"></i>
    <short-date icon="fa-file"
                [date]="comment?.creationDate"
                [tooltipText]="createdOnMsg + ' ' + (comment?.creationDate | date:'medium')"
                placement="top"
                format="ll"
                class="me-3">
    </short-date>
    <short-date *ngIf="comment?.updateDate"
                icon="fa-pencil"
                [date]="comment?.updateDate"
                [tooltipText]="updatedOnMsg + ' ' + (comment?.updateDate | date:'medium')"
                placement="top"
                format="ll"
                class="me-3">
    </short-date>
  </div>

  <div *ngIf="!(editing$ | async)" class="none"></div>

  <div *ngIf="!(editing$ | async)" class="controls">
    <i *ngIf="currentUser?.id === comment?.author"
       tooltip="Edit"
       i18n-tooltip="@@button.edit"
       placement="top"
       class="fal fa-edit color-success me-2 cursor-pointer"
       (click)="editComment(comment)">
    </i>
    <i *ngIf="currentUser?.id === comment?.author"
       tooltip="Delete"
       i18n-tooltip="@@button.remove"
       placement="top"
       class="far fa-trash-alt color-danger cursor-pointer"
       (click)="onRemove.emit(comment)">
    </i>
  </div>

  <div *ngIf="!(editing$ | async)" [innerHTML]="comment.comment | stripHtml | safeHtml" class="comment">
  </div>

  <div class="edit">
    <new-comment *ngIf="editing$ | async"
                 [currentUser]="currentUser"
                 [usersMap]="usersMap"
                 [initialComment]="comment"
                 (onNewComment)="onUpdateComment($event)"
                 (onCancel)="cancelEdit()">
    </new-comment>
  </div>

</div>
