<dropdown [origin]="origin"
          [showBackdrop]="false"
          [pushOnScreen]="true"
          [closeOnClickOrigin]="true"
          [positions]="dropdownPositions">
  <div class="card overflow-auto dropdown-menu" [style.width.rem]="19"
       [style.max-height.vh]="90">
    <resource-detail
      [type]="type"
      [permissions]="permissions$ | async"
      [resource]="resource"
      [workspace]="workspace">
    </resource-detail>

    <resource-list [currentId]="resource?.id"
                   [organizations]="organizations"
                   [projects]="projects"
                   [type]="type"
                   (onResourceSelect)="selectResource($event)">
    </resource-list>

    <ng-container *ngIf="resource | canCreateResource:type:organizations:(currentUser$ | async)">

      <div class="dropdown-divider"></div>
      <a class="dropdown-item pb-2" (click)="newResource()">
        <i class="fas fa-plus fa-fw text-success me-1"></i>
        <span
          i18n="@@resource.menu.createNew">Create New {type, select, organization {Organization} project {Project} other {}}</span>
      </a>
    </ng-container>
  </div>
</dropdown>
