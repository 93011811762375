<notifications-menu-content
  [organizations]="organizations$ | async"
  [unreadNotifications]="unreadNotifications$ | async"
  [unreadOnly]="unreadOnly$ | async"
  [allNotifications]="notifications$ | async"
  (deleteNotification)="deleteNotification($event)"
  (readNotification)="setNotificationReadEvent($event)"
  (clickNotification)="navigateToTarget($event)"
  (toggleUnread)="toggleUnreadFilter()">
</notifications-menu-content>
