<input #textInput
       [attr.maxlength]="configuration?.skipValidation ? null : value?.config?.maxLength"
       [readonly]="readonly"
       [value]="readonly ? (value | formatDataValue | unescapeHtml) : (value | formatDataValueForEdit)"
       [title]="value | formatDataValueTitle"
       (blur)="!readonly && onBlur()"
       (input)="onInput($event)"
       (focus)="!readonly && onFocused()"
       [placeholder]="placeholder || ''"
       [spellcheck]="false"
       [style.color]="fontColor"
       class="border-0 w-100 h-100 px-1 bg-transparent"
       [ngClass]="readonly || valid || configuration?.skipValidation ? 'bg-transparent ' + inputClass : 'bg-danger-light ' + inputClass"
       type="text">

<options-dropdown *ngIf="!readonly"
                  [options]="suggestions | filterDataSuggestions:text"
                  [showBackdrop]="false"
                  [closeOnClickOutside]="false"
                  [origin]="textInput"
                  [firstItemActive]="text?.length > 0"
                  (selectOption)="onSelectOption($event)">
</options-dropdown>
