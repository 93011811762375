<modal-wrapper dialogType="primary"
               [performingAction]="performingAction$ | async"
               [submitDisabled]="formInvalid$ | async"
               [showClose]="!(preventClose$ | async)"
               (onSubmit)="onSubmit()"
               (onClose)="onClose()">
  <span class="title" i18n="@@resource.new.dialog.title">
  Create a new {resourceType, select, organization {Organization} project {Project}}
  </span>

  <div class="body h-100">
    <create-resource-dialog-form class="d-block h-100 p-3"
                                 *ngIf="contentValid$ | async; else contentInvalid"
                                 [parentId]="parentId"
                                 [form]="form"
                                 [resourceType]="resourceType"
                                 [usedCodes]="usedCodes$ | async"
                                 (submitResource)="submitResource($event)">
    </create-resource-dialog-form>

  </div>

  <span class="button-cancel" i18n="@@button.cancel">Cancel</span>
  <span class="button-submit" i18n="@@button.create">Create</span>

</modal-wrapper>

<ng-template #contentInvalid>
  <h4 class="text-danger text-center my-5"
      i18n="@@resource.new.dialog.invalid">I am sorry, something went wrong.
  </h4>
</ng-template>
