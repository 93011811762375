<ng-container *ngIf="type === resourceType.Organization && organizations?.length > 1">
  <div class="dropdown-divider"></div>
  <ng-container *ngFor="let organization of organizations">
    <a *ngIf="organization.id !== currentId" class="dropdown-item" (click)="selectResource(organization)">
      <i class="fa-fw {{organization.icon}}" [class.me-1]="true" [style.color]="organization.color"></i>[{{organization.code}}] {{organization.name}}</a>
  </ng-container>
</ng-container>

<ng-container *ngIf="type === resourceType.Project && projects?.length > 1">
  <div class="dropdown-divider"></div>
  <ng-container *ngFor="let project of projects">
    <a *ngIf="project.id !== currentId" class="dropdown-item" (click)="selectResource(project)">
      <i class="fa-fw {{project.icon}}" [class.me-1]="true" [style.color]="project.color"></i>[{{project.code}}] {{project.name}}</a>
  </ng-container>
</ng-container>
