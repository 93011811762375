<ul *ngIf="isFree" class="list-group">
  <li class="list-group-item list-group-item-light text-sm-center" i18n="@@organizations.tab.detail.state.trial">You are on the <strong>Free</strong> program now.</li>
  <li class="list-group-item list-group-item-success text-lg-center" i18n="@@organizations.tab.detail.state.getBusiness">Get Lumeer for Business!</li>
  <li class="list-group-item">
    <i class="far fa-fw fa-list-ul me-2"></i><span i18n="@@organizations.tab.detail.state.allInclusive">All features included</span>
  </li>
  <li class="list-group-item">
    <i class="far fa-fw fa-check-circle me-2"></i><span i18n="@@organizations.tab.detail.state.newFeature"><em>Verified</em> updates and new features</span><br/>
    <span class="small text-danger" i18n="@@organizations.tab.detail.state.newFeature.warn">Features in the Free program are tested but can be a little buggy still.</span>
  </li>
  <li class="list-group-item">
    <i class="far fa-fw fa-envelope me-2"></i><span i18n="@@organizations.tab.detail.state.nextDaySupport"><em>Next business day</em> e-mail support</span><br/>
    <span class="small text-danger" i18n="@@organizations.tab.detail.state.nextDaySupport.warn">Now you have best effort e-mail support.</span>
  </li>
  <li class="list-group-item">
    <i class="fas fa-fw fa-database me-2"></i><span i18n="@@organizations.tab.detail.state.backupAndRecovery">Data backup and <em>next business day recovery</em></span><br/>
    <span class="small text-danger" i18n="@@organizations.tab.detail.state.backupAndRecovery.warn">There are no recovery guarantees in Free.</span>
  </li>
  <li class="list-group-item">
    <i class="fas fa-fw fa-server me-2"></i><span i18n="@@organizations.tab.detail.state.powerfulServer"><em>Powerful</em> shared server resources</span><br/>
    <span class="small text-danger" i18n="@@organizations.tab.detail.state.powerfulServer.warn">You run now on commodity shared virtual machine.</span>
  </li>
  <li class="list-group-item">
    <i class="far fa-fw fa-file me-2"></i><span i18n="@@organizations.tab.detail.state.unlimitedProjects"><em>Unlimited</em> projects, users, tables, automations and functions</span><br/>
    <span class="small text-danger" i18n="@@organizations.tab.detail.state.unlimitedProjects.warn">Now you have only {{serviceLimits?.users}} users, {{serviceLimits?.projects}} projects, {{serviceLimits?.files}} tables, {{serviceLimits?.rulesPerCollection}}+{{serviceLimits?.functionsPerCollection}} automation and function per table.</span>
  </li>
  <li class="list-group-item">
    <i class="far fa-fw fa-clone me-2"></i><span i18n="@@organizations.tab.detail.state.dataLimit">Plenty of data (1,000,000 records in total)</span><br/>
    <span class="small text-danger" i18n="@@organizations.tab.detail.state.dataLimit.warn">Now you have only {{serviceLimits?.documents}} records available.</span>
  </li>
</ul>
<ul *ngIf="isBasic" class="list-group">
  <li class="list-group-item list-group-item-success text-lg-center" i18n="@@organizations.tab.detail.order.onBusiness">Congratulations, <strong>Business</strong> program active!</li>
  <li class="list-group-item list-group-item-light text-sm-center" i18n="@@organizations.tab.detail.basic.active">Subscription is active until {{serviceLimits.validUntil | date : 'mediumDate'}}</li>
  <li class="list-group-item" i18n="@@organizations.tab.detail.basic.allInclusive">
    <i class="far fa-fw fa-list-ul me-2"></i>
    <span>All features included</span>
  </li>
  <li class="list-group-item">
    <i class="far fa-fw fa-check-circle me-2"></i>
    <span i18n="@@organizations.tab.detail.state.newFeature"><em>Verified</em> updates and new features</span>
  </li>
  <li class="list-group-item">
    <i class="far fa-fw fa-envelope me-2"></i>
    <span i18n="@@organizations.tab.detail.state.nextDaySupport"><em>Next business day</em> e-mail support</span>
  </li>
  <li class="list-group-item">
    <i class="fas fa-fw fa-database me-2"></i>
    <span i18n="@@organizations.tab.detail.state.backupAndRecovery">Data backup and <em>next business day recovery</em></span>
  </li>
  <li class="list-group-item">
    <i class="fas fa-fw fa-server me-2"></i>
    <span i18n="@@organizations.tab.detail.state.powerfulServer"><em>Powerful</em> shared server resources</span>
  </li>
  <li class="list-group-item">
    <i class="far fa-fw fa-file me-2"></i>
    <span i18n="@@organizations.tab.detail.state.unlimitedProjects"><em>Unlimited</em> projects, users, tables, automations and functions</span>
  </li>
  <li class="list-group-item">
    <i class="far fa-fw fa-clone me-2"></i>
    <span i18n="@@organizations.tab.detail.state.dataLimit">Plenty of data (1,000,000 records in total)</span>
  </li>
</ul>
