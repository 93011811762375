<div class="header" [style.background-color]="color | lightenColor:.5">
  <div class="p-2 fw-bold" i18n="@@teams.header.team">Team</div>
  <div class="roles">
    <div class="fw-bold py-2 pe-2 text-nowrap">
      <span i18n="@@teams.header.roles">Users & Rights</span>
      <i class="far fa-question-circle ms-1" [tooltip]="legend" containerClass="bigger-tooltip"></i>
    </div>
    <div class="ps-3 input-group input-group-sm">
      <span class="input-group-text" id="search-team1"><i class="fas fa-search"></i></span>
      <input id="teamSearch" class="form-control me-1" type="search"
             placeholder="Search team..."
             inputmode="search"
             i18n-placeholder="@@teams.header.search.placeholder"
             title="Search for teams" i18n-title="@@teams.header.search.title"
             aria-describedby="search-team1"
             [(ngModel)]="searchString">
    </div>
  </div>
  <div><i class="fa fa-fw m-1"></i></div>
</div>
<div *ngIf="teams | empty" class="text-muted p-1" i18n="@@teams.table.empty">There are no teams in your organization.</div>
<div class="teams-list">
  <div class="layer1" [class.blur]="!editableTeams">
    <team-component class="team py-2"
                    *ngFor="let team of teams | teamFilter:searchString; trackBy:trackByTeam"
                    [team]="team"
                    [allTeams]="teams"
                    [users]="users"
                    [permissionsMap]="permissionsMap"
                    [changeRoles]="changeRoles"
                    [editable]="editable"
                    [emitAllChanges]="emitAllChanges"
                    [transitiveRoles]="organization | teamTransitiveRoles:project:team:resourcePermissionType:viewsMap"
                    [deletable]="deletableTeamIds | includes:team.id"
                    [removable]="removableTeamIds | includes:team.id"
                    [resourcePermissionType]="resourcePermissionType"
                    (teamUpdated)="teamUpdated.emit($event)"
                    (teamDeleted)="teamDeleted.emit($event)"
                    (teamRolesChange)="teamRolesChange.emit({team: team, roles: $event})">
    </team-component>
  </div>
  <div class="layer2 w-100 text-center p-5" *ngIf="!editableTeams">
    <h5 i18n="@@teams.update.serviceLimits.title">Free plan</h5>
    <div class="px-5" i18n="@@teams.update.serviceLimits.message">You are currently on the Free plan which does not
      support Teams. Do you want to upgrade to Business now?
    </div>
    <button class="btn btn-primary mt-3">
      <a class="color-inherit" [routerLink]="['/o', organization?.code, 'detail']" [fragment]="'orderService'"><span
        i18n="@@teams.update.serviceLimits.button">Upgrade now!</span></a>
    </button>
  </div>
</div>

<ng-template #legend>
  <div class="text-start">
    <ng-container *ngIf="!(editableTeams && (resourcePermissionType | isOrganizationType))">
      <span i18n="@@teams.header.configuration.remark">Teams are created at the organizational level.</span><br/>
    </ng-container>
    <span class="badge badge-lg badge-outline-success mt-1" i18n="@@users.header.teams.roles.tooltip.rights">Rights</span>
    <span class="ms-1" i18n="@@users.header.teams.roles.tooltip.direct">directly assigned</span><br/>
    <span class="badge badge-lg badge-outline-secondary mt-1" i18n="@@users.header.teams.roles.tooltip.rights">Rights</span>
    <span class="ms-1" i18n="@@users.header.teams.roles.tooltip.indirect">gained from organization, project, or team</span>
  </div>
</ng-template>
