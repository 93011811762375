<ng-container *ngIf="{documents: documents$ | async, collection: collection$ | async} as data">
  <dropdown [origin]="origin"
            [positions]="dropdownPositions"
            [closeOnClickOutside]="false"
            [showBackdrop]="false"
            [minWidth]="minWidth"
            [minHeight]="(data.documents?.length || 0) * 30"
            (positionChange)="onDropdownPositionChange($event)">
    <div *ngIf="data.collection && data.documents?.length"
         class="dropdown-menu show position-static p-0"
         (mousedown)="preventEvent($event)"
         (click)="preventEvent($event)"
         data-test="document-hints">
      <div
        *ngFor="let doc of data.documents | reverseArrayByDropdownPosition:(dropdownPosition$ | async); index as documentIndex"
        [class.selected]="(selectedIndex$ | async) === documentIndex"
        (mousedown)="preventEvent($event)"
        (mouseup)="tryUseDocument($event, documentIndex, doc)"
        (click)="preventEvent($event); onUseDocument(documentIndex, doc)"
        class="dropdown-item p-0 d-flex">
        <ng-container *ngFor="let column of columns">
          <div
            *ngIf="{value: (doc.data[column.attributeId] | dataValue : (data.collection | collectionAttribute : column.attributeId)?.constraint : constraintData) | previewDataValue} as state"
            [id]="'hint-' + doc.id + '-' + column.attributeId"
            [class.fw-bold]="column.attributeId === attributeId"
            [style.width.px]="column.width"
            (click)="$event.preventDefault();"
            [innerHtml]="column.hidden ? '' : (column.attributeId === attributeId ? (state.value | highlightText: (filter$ | async)) : state.value) | safeHtml"
            [title]="column.hidden ? '' : state.value"
            class="text-truncate cursor-default p-1"
            data-test="document-hint">
          </div>
        </ng-container>
      </div>
    </div>
  </dropdown>
</ng-container>
