<resource-filter *ngFor="let filter of filters$ | async; trackBy: trackByAttributeFilter; let index = index"
                 class="form-control cursor-pointer p-0 h-auto d-block mb-1"
                 [attributes]="resource?.attributes"
                 [filter]="filter"
                 [inline]="inline"
                 [attributeSelectItems]="attributeSelectItems"
                 (remove)="onFilterDelete(index)"
                 (filterChange)="onFilterChange(index, $event)">
</resource-filter>

<button class="btn btn-link px-0 small d-block mt-1 text-start" (click)="onNewFilter()">
  <i class="fas fa-plus-circle me-2"></i><span i18n="@@modal.links.modify.filter.add">Add a filter</span>
</button>
