<ul *ngIf="{organization: organization$ | async, project: project$ | async, organizations: organizations$ | async} as data"
    [style.height.px]="contentHeight"
    class="navbar navbar-triangle my-0 p-0 me-3 flex-nowrap">
  <li class="nav-item dropdown">
    <div class="nav-link text-truncate" role="button"
         tooltip="Organization menu"
         i18n-tooltip="@@topPanel.organizationMenu.title"
         placement="bottom"
         [class.cursor-pointer]="showDropdowns"
         [isDisabled]="!showDropdowns"
         (click)="showDropdowns && organizationMenu.open()"
         [style.maxWidth.rem]="8" #organizationElement>
      <i [class]="data.organization?.icon" [style.color]="data.organization?.color">
      </i>
      &nbsp;{{data.organization?.code}}
    </div>
    <resource-menu #organizationMenu
                   [type]="organizationResourceType"
                   [resource]="data.organization"
                   [origin]="organizationElement"
                   [organizations]="data.organizations"
                   [workspace]="workspace"
                   (onResourceSelect)="selectOrganization($event)"
                   (onNewResource)="createNewOrganization()">
    </resource-menu>
  </li>
  <li class="nav-separator"><i class="fas fa-chevron-right"></i></li>
  <li class="nav-item dropdown">
    <div class="nav-link text-truncate" role="button"
         tooltip="Project menu"
         i18n-tooltip="@@topPanel.projectMenu.title"
         placement="bottom"
         [class.cursor-pointer]="showDropdowns"
         [isDisabled]="!showDropdowns"
         [style.maxWidth.rem]="8"
         (click)="showDropdowns && projectMenu.open()"
         #projectElement>
      <i [class]="data.project?.icon" [style.color]="data.project?.color"></i>
      &nbsp;{{data.project?.code}}
    </div>
    <resource-menu #projectMenu
                   [type]="projectResourceType"
                   [resource]="data.project"
                   [workspace]="workspace"
                   [projects]="projects$ | async"
                   [organizations]="data.organizations"
                   [origin]="projectElement"
                   (onResourceSelect)="selectProject(data.organization, $event)"
                   (onNewResource)="createNewProject(data.organization)">
    </resource-menu>
  </li>
</ul>
