<div [formGroup]="form">
  <p class="text-muted"
     i18n="@@constraint.user.description">
    Allows you to specify a colleague by email. You may allow only registered users or any valid email.
  </p>

  <div class="mt-3">
    <div class="d-flex flex-row flex-nowrap pt-1 align-items-center">
      <label class="switch" [for]="formControlName.Multi">
        <input type="checkbox" [formControlName]="formControlName.Multi" [id]="formControlName.Multi" #multi>
        <span class="slider round" (click)="$event.stopPropagation()"></span>
      </label>
      <span class="ps-2 cursor-pointer user-select-none" i18n="@@constraint.usersAndTeams.multi.label" (click)="multi.click()">Allow to select multiple users or teams</span>
    </div>

    <div class="d-flex flex-row flex-nowrap pt-2 align-items-center">
      <label class="switch" [for]="formControlName.ExternalUsers">
        <input type="checkbox" [formControlName]="formControlName.ExternalUsers" [id]="formControlName.ExternalUsers"
               #externalUsers>
        <span class="slider round" (click)="$event.stopPropagation()"></span>
      </label>
      <span class="ps-2 cursor-pointer user-select-none" i18n="@@constraint.user.externalUsers.label"
            (click)="externalUsers.click()">Allow unregistered users (any valid email)</span>
    </div>

    <div class="d-flex flex-row flex-nowrap pt-2 align-items-center">
      <label class="switch" [for]="formControlName.OnlyIcon">
        <input type="checkbox" [formControlName]="formControlName.OnlyIcon" [id]="formControlName.OnlyIcon" #onlyIcon>
        <span class="slider round" (click)="$event.stopPropagation()"></span>
      </label>
      <span class="ps-2 cursor-pointer user-select-none" i18n="@@constraint.usersAndTeams.onlyIcon.label"
            (click)="onlyIcon.click()">Display user name instead of email</span>
    </div>

  </div>

  <div class="mt-3">
    <label class="form-label" [for]="formControlName.Type"
           i18n="@@constraint.usersAndTeams.type.label">Allowed values</label>
    <select-item class="w-100 d-block"
                 [items]="typeItems"
                 [removable]="false"
                 [selectedId]="typeControl.value"
                 (selected)="onTypeSelect($event)"
                 buttonClasses="flex-grow-1 text-truncate">
    </select-item>
  </div>
</div>
